import { GoalFactory } from '../model/goal/goal.factory.class';
import { GoalAchievability } from '../model/household/result/goal.achievability';
import { Scenario } from '../model';
import { ScenarioUtils } from './scenario.utils';

export class GoalUtils {

  public static readonly GOALS = [
    {
      label: 'Buy a house', type: 'BUY_A_HOUSE', short: 'BuyAHouseGoal', icon: 'icon-house',
      create: () => GoalFactory.buyAHouse(GoalFactory.DEFAULT_AMOUNT_COSY_HOUSE),
      visible: () => true
    },
    {
      label: 'Buy a car', type: 'BUY_A_CAR', short: 'BuyACarGoal', icon: 'icon-car',
      create: () => GoalFactory.buyACar(GoalFactory.DEFAULT_AMOUNT_CAR),
      visible: () => true
    },
    {
      label: 'Travel', type: 'TRAVEL', short: 'TravelGoal', icon: 'icon-world',
      create: () => GoalFactory.travel(GoalFactory.DEFAULT_AMOUNT_TRAVEL_THE_WORLD),
      visible: () => true
    },
    {
      label: 'Get married', type: 'GET_MARRIED', short: 'GetMarriedGoal', icon: 'icon-just-married',
      create: () => GoalFactory.getMarried(GoalFactory.DEFAULT_AMOUNT_BIG_WEDDING),
      visible: () => true
    },
    {
      label: 'Educate myself', type: 'GO_TO_SCHOOL', short: 'GoToSchoolGoal', icon: 'icon-cap',
      create: () => GoalFactory.goToSchool(GoalFactory.DEFAULT_AMOUNT_MASTERS_DEGREE),
      visible: () => true
    },
    {
      label: 'Have a child', type: 'HAVE_A_CHILD', short: 'HaveAChildGoal', icon: 'icon-baby-boy',
      create: () => GoalFactory.haveAChild(),
      visible: () => true
    },
    {
      label: 'Time off work', type: 'SABBATICAL', short: 'SabbaticalGoal', icon: 'icon-umbrella',
      create: () => GoalFactory.sabbatical('Time off work'),
      visible: () => true
    },
    {
      label: 'Part-time work', type: 'SABBATICAL', short: 'SabbaticalGoal', icon: 'icon-coffee-togo',
      create: () => GoalFactory.sabbatical('Part-time work', 0.5),
      visible: () => true
    },
    {
      label: 'Be debt free', type: 'ELIMINATE_DEBT', short: 'EliminateDebtGoal', icon: 'icon-debt',
      create: (scenario: Scenario) => GoalFactory.eliminateDebt('Eliminate debt', ScenarioUtils.getUnsecuredDebtLiabilities(scenario)[0].id, GoalFactory.DEFAULT_AMOUNT_ELIMINATE_DEBT),
      visible: (scenario: Scenario) => scenario && ScenarioUtils.getUnsecuredDebtLiabilities(scenario).length > 0
    },
    {
      label: 'Retirement', type: 'RETIREMENT', short: 'RetirementGoal', icon: 'icon-retirement',
      create: () => GoalFactory.retirementGoal(new Date().toISOString()),
      visible: () => true
    },
    {
      label: 'User defined', type: 'USER_DEFINED', short: 'UserDefinedGoal', icon: 'icon-question',
      create: () => GoalFactory.other(),
      visible: () => true
    },
    {
      label: 'Recurring', type: 'RECURRING_USER_DEFINED', short: 'RecurringUserDefinedGoal', icon: 'icon-repeat',
      create: () => GoalFactory.otherRecurring(),
      visible: () => true
    }
  ];

  static shortToIcon = (short: string): string => {
    return (GoalUtils.GOALS
      .filter(g => short === g.short)[0] || GoalUtils.GOALS[GoalUtils.GOALS.length - 1]).icon;
  }

  public static readonly getSafeAchievability = (achievability: GoalAchievability) => {
    if (!achievability) {
      return 0;
    }

    let totalAlive = achievability.totalAlive || 0;
    let totalAchieved = achievability.totalAchieved || 0;

    return totalAlive == 0 || totalAchieved == 0 ? 0 : (totalAchieved / totalAlive);
  };

}
