import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from '@angular/material/dialog';
import { Constants } from 'src/app/app.constants';

@Component({
    selector: 'app-risk-dialog-component',
    templateUrl: './risk.dialog.component.html',
    styleUrls: ['./risk.dialog.component.scss']
})
export class RiskDialogComponent implements OnInit {

    public riskOptions = [
        {key: 'low', label: 'Low', ordinal: 1},
        {key: 'medium-low', label: 'Medium-low', ordinal: 2},
        {key: 'medium', label: 'Medium', ordinal: 3},
        {key: 'medium-high', label: 'Medium-high', ordinal: 4},
        {key: 'high', label: 'High', ordinal: 5}
    ];

    public selectedRisk;

    constructor(
        private dialogRef: MatDialogRef<RiskDialogComponent>,
    ) {}

    ngOnInit() {
        this.selectedRisk = this.riskOptions
            .find(r => r.ordinal.toString() === localStorage.getItem(Constants.LOCAL_STORAGE_RISK_PROFILE_SCORE)).key;

    }

    get riskLevelContent() {
        return localStorage.getItem(Constants.LOCAL_STORAGE_RISK_PROFILE_CONTENT);
    }

    get defaultRiskValue() {
        return this.riskOptions.find(r => r.ordinal.toString() === localStorage.getItem(Constants.LOCAL_STORAGE_RISK_PROFILE_SCORE)).key;
    }

    finish() {
        localStorage.setItem(Constants.LOCAL_STORAGE_RISK_HOUSEHOLD_RISK, this.selectedRisk);
        this.dialogRef.close();
    }

}