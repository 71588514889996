import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AnalyticsService, WizardService } from '../../../services';
import { ActivatedRoute, Router } from '@angular/router';
import { MoneyhubService } from '../../../services/moneyhub.service';
import {
  DEFAULT_MONEYHUB_USER,
  IMoneyhubBank,
  IMoneyhubUser,
  MoneyhubUser,
} from '../../../model/moneyhub/envizage-connect.model';
import { NotifierService } from 'angular-notifier';
import { Constants } from '../../../app.constants';
import { MatDialog } from '@angular/material/dialog';
import { MHConsentDialogComponent } from '../../common/moneyhub/consent-dialog/consent-dialog.component';
import { AppConfigService } from '../../../services/app.config.service';
import { UserPropertyService } from '../../../services/user.property.service';
import { AnalyticsEvent, AnalyticsLabel } from 'src/app/model/analytics';

@Component({
  selector: 'app-connect',
  templateUrl: './connect.component.html',
  styleUrls: ['./connect.component.scss'],
})
export class ConnectComponent implements OnInit, OnDestroy {
  banks: IMoneyhubBank[] = [];
  banksList: IMoneyhubBank[] = [];
  query: string;
  loading = false;
  error: string;
  navigation;
  isOverview = false;

  constructor(
    titleService: Title,
    private route: ActivatedRoute,
    private config: AppConfigService,
    private wizardService: WizardService,
    private moneyhub: MoneyhubService,
    private notifier: NotifierService,
    private dialog: MatDialog,
    private userPropertyService: UserPropertyService,
    private router: Router,
    private analyticsService: AnalyticsService
  ) {
    titleService.setTitle('Connect');
  }

  ngOnInit() {
    this.navigation = (this.route.data as any).value.navigation;
    this.isOverview =
      this.route.parent.snapshot.url[this.route.parent.snapshot.url.length - 1]
        .path === 'overview';
    // check if we are coming from a connection redirect
    // const connectedRedirect = localStorage.getItem(Constants.LOCAL_STORAGE_MONEYHUB_REDIRECT_SUCCESS);
    const errorRedirect = localStorage.getItem(
      Constants.LOCAL_STORAGE_MONEYHUB_REDIRECT_ERROR
    );
    // clean
    localStorage.removeItem(Constants.LOCAL_STORAGE_REDIRECT_URL);
    localStorage.removeItem(Constants.LOCAL_STORAGE_MONEYHUB_REDIRECT_SUCCESS);
    localStorage.removeItem(Constants.LOCAL_STORAGE_MONEYHUB_REDIRECT_ERROR);

    if (!!errorRedirect) {
      this.error = errorRedirect;
    }

    this.loading = true;
    this.moneyhub.getBanks().subscribe((b) => {
      const banks = b.filter((bank) => bank.userTypes.includes('personal'));
      this.banks = banks;
      this.banksList = banks;
      this.loading = false;
    });
  }

  search(query: string) {
    this.banks = this.banksList.filter((bank) =>
      bank.name.toLowerCase().includes(query.toLowerCase())
    );
  }

  reset() {
    this.query = '';
    this.search('');
  }

  private async discoverMoneyhubUserId(): Promise<string> {
    let id: string;
    try {
      const moneyhubUserIdProp = await this.userPropertyService
        .get(Constants.USER_PROPERTY_KEY_MONEYHUB_USER_ID)
        .toPromise();
      if (moneyhubUserIdProp && moneyhubUserIdProp.value) {
        id = moneyhubUserIdProp.value;
      }
    } catch (ex) {
      // try get from local storage
      const user = MoneyhubUser.get();
      if (user) {
        id = user.userId;
      }
    }
    if (!id) {
      try {
        const user = await this.moneyhub.createUser().toPromise();
        MoneyhubUser.set(user);
        id = user.userId;
        await Promise.all([
          this.userPropertyService
            .create(Constants.USER_PROPERTY_KEY_MONEYHUB_USER_ID, user.userId)
            .toPromise(),
          this.userPropertyService
            .create(
              Constants.USER_PROPERTY_KEY_MONEYHUB_USER_CREATION_DATE,
              user.createdAt
            )
            .toPromise(),
        ]);
      } catch (ex) {
        // do nothin
      }
    }
    return id;
  }

  async connect(bank: IMoneyhubBank) {
    this.loading = true;
    let userId: string;
    try {
      userId = await this.discoverMoneyhubUserId();
      if (!userId) {
        return this.notifier.notify(
          Constants.ERROR,
          'Could not authenticate with provider. Reason: user not found'
        );
      }

      localStorage.setItem(
        Constants.LOCAL_STORAGE_REDIRECT_URL,
        this.router.url
      );
    } catch (ex) {
      this.loading = false;
      this.notifier.notify(
        Constants.ERROR,
        'Could not authenticate with provider. Reason: user property failed'
      );
      return;
    }
    const redirectUri = this.config.getConfig().moneyHub.redirectUri;
    try {
      const authRequest = await this.moneyhub
        .createAuthRequest(redirectUri, bank.id)
        .toPromise();
      // todo: do we need to keep authRequest or is it handled by the moneyhub guard?
      if (authRequest) {
        if (!!authRequest.authRequestId) {
          localStorage.setItem(
            Constants.LOCAL_STORAGE_MONEYHUB_AUTH_REQUEST_ID,
            authRequest.authRequestId
          );
        }
        if (!!authRequest.authUrl) {
          localStorage.setItem(
            Constants.LOCAL_STORAGE_MONEYHUB_AUTH_REQUEST_REDIRECT_URL,
            authRequest.authUrl
          );
        }
        if (!!authRequest.state) {
          localStorage.setItem(
            Constants.LOCAL_STORAGE_MONEYHUB_AUTH_REQUEST_STATE,
            authRequest.state
          );
        }
      }
      this.consent(bank, authRequest.authUrl);
    } catch (ex) {
      this.notifier.notify(
        Constants.ERROR,
        'Could not reach the bank. Please select another bank'
      );
    } finally {
      this.loading = false;
    }
  }

  consent(bank: IMoneyhubBank, authUrl: string) {
    const dialogRef = this.dialog.open(MHConsentDialogComponent, {
      width: '350px',
      data: { bank },
    });

    dialogRef.afterClosed().subscribe((accepted) => {
      if (!accepted) {
        return;
      }
      window.location.href = authUrl;
    });
  }

  trackSkipItClicked(e: MouseEvent) {
    e.preventDefault();
    this.analyticsService.trackAnalyticsEvent(
      AnalyticsEvent.MANUAL_INPUT,
      AnalyticsLabel.SKIP_LINK_ACCOUNTS
    );
  }

  ngOnDestroy() {
    this.wizardService.setVisited(this.route.snapshot.url[0].path);
  }
}
