import { OnInit, Component, Input, Output, EventEmitter, ComponentFactoryResolver, ViewChildren, QueryList, ViewContainerRef, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Goal } from 'src/app/model/goal';
import { EditGoalContext } from 'src/app/model/goal/edit.goal.context.class';
import { Constants } from 'src/app/app.constants';
import { EditGoalBaseComponent } from '../edit-goal-base/edit-goal-base.component';
import { MortgageFundingSourceComponent } from '../../funding-sources/mortgage-funding-source/mortgage-funding-source.component';
import { FUNDING_SOURCE_COMPONENTS } from '../../funding-sources';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-edit-goal-buy-house',
    templateUrl: './edit-goal-buy-house.component.html',
    styleUrls: ['./edit-goal-buy-house.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditGoalBuyHouseComponent extends EditGoalBaseComponent {

    @Input() ctx: EditGoalContext;
    @Output() deleteGoal: EventEmitter<Goal> = new EventEmitter();
    @Output() updateGoal: EventEmitter<Goal> = new EventEmitter();
    @ViewChildren('sourceCmp', { read: ViewContainerRef }) viewComponents: QueryList<ViewContainerRef>;

    private _initialAmount: number;
    private _initialMortgageAmount: number;

    constructor(private resolver: ComponentFactoryResolver, private dialog: MatDialog, private ref: ChangeDetectorRef) {
        super(resolver, dialog, ref);
    }

    ngOnInit() {
        this._initialAmount = this.ctx.goal.desiredAmount;
        this._initialMortgageAmount = this.ctx.goal.fundingSources
            .filter(f => f.class === 'MortgageFundingSource')
            .map(s => parseFloat(s['amount']))[0] || 0;       
    }

    onUpdateGoalAmount() {
        this.ctx.goal.minimumAmount = this.ctx.goal.desiredAmount;
        this.ctx.goal.fundingSources = this.ctx.goal.fundingSources.map(s => {
            if (s.class === 'MortgageFundingSource') {
                s['amount'] = this._initialMortgageAmount * this.ctx.goal.desiredAmount / this._initialAmount;
            }
            return s;
        });
    }

    onYearChange(value: string) {
        this.ctx.goal.startDate = value;
        this.ctx.goal.endDate = value;
    }

    get minYear() {
        return new Date().getFullYear();
    }

    get maxYear() {
        return new Date().getFullYear() + Constants.GOAL_YEAR_RANGE;
    }
}