import { Injectable } from '@angular/core';
import { HandleError, HttpErrorHandler } from './http-error-handler.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ScenarioData } from '../model/household/result/scenario.data';
import { AppConfigService } from './app.config.service';

@Injectable()
export class ResultsScenarioDataService {
    private handleError: HandleError;
    private getUrl = () => this.configService.getConfig().apiUrl;

    constructor(
        private http: HttpClient,
        private configService: AppConfigService,
        private httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('resultsScenarioDataService');
    }

    get(scenarioId: string): Observable<ScenarioData> {
        return this.http.get<ScenarioData>(`${this.getUrl()}/results/${scenarioId}/data`)
            .pipe(
                catchError(this.handleError('query scenario'))
            );
    }

}