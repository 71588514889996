import { AuthService } from './../services/auth.service';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Constants } from '../app.constants';
import { MoneyhubService } from '../services/moneyhub.service';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class MoneyhubAuthGuard implements CanActivate {

  private loadingSubject: Subject<boolean> = new Subject<boolean>();

  constructor(
    private router: Router,
    private moneyhub: MoneyhubService
  ) { }

  public get loading$(): Observable<boolean> {
    return this.loadingSubject.asObservable();
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.loadingSubject.next(true);
    const path = localStorage.getItem(Constants.LOCAL_STORAGE_REDIRECT_URL);
    const token = route.queryParamMap.get('token');
    const error = route.queryParamMap.get('error');
    const mhState = route.queryParamMap.get('state');
    const code = route.queryParamMap.get('code');
    const idToken = route.queryParamMap.get('idToken');
    const redirectTree = path.split('/').map(r => r == '' ? '/' : r);

    if (!!token) {
      localStorage.setItem(Constants.LOCAL_STORAGE_MONEYHUB_TOKEN, token);
      localStorage.setItem(Constants.LOCAL_STORAGE_MONEYHUB_REDIRECT_SUCCESS, 'true');
    }
    if (!!mhState && !!code) {
      localStorage.setItem(Constants.LOCAL_STORAGE_MONEYHUB_STATE, mhState);
      localStorage.setItem(Constants.LOCAL_STORAGE_MONEYHUB_CODE, code);
      if (!!idToken) {
        localStorage.setItem(Constants.LOCAL_STORAGE_MONEYHUB_ID_TOKEN, idToken);
      }
      const authRequestId = localStorage.getItem(Constants.LOCAL_STORAGE_MONEYHUB_AUTH_REQUEST_ID);
      if (!!authRequestId) {
        try {
          await this.moneyhub.completeAuthRequest(authRequestId, code, mhState, idToken).toPromise();
        } catch (ex) {
          localStorage.setItem(Constants.LOCAL_STORAGE_MONEYHUB_REDIRECT_ERROR, 'Could not complete authentication. Please try again.');
          // send user back to 'connect' and show error
          return this.router.createUrlTree(redirectTree)
        } finally {
          this.loadingSubject.next(false);
        }
      }
    }

    if (!!error) {
      localStorage.setItem(Constants.LOCAL_STORAGE_MONEYHUB_REDIRECT_ERROR, 'connection was aborted by user or service.');
      // send user back to 'connect' and show error
      return this.router.createUrlTree(redirectTree)
    }
    // replace connect with accounts and send user there
    return this.router.createUrlTree(redirectTree.map(r => r == 'connect' ? 'accounts' : r));

  }
}
