// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
mat-select {
  box-sizing: border-box;
  max-width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/components/common/frequency-picker/frequency-picker.component.scss"],"names":[],"mappings":"AAAA,6HAAA;AACA;EACE,sBAAA;EACA,eAAA;AACF","sourcesContent":["/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */\nmat-select {\n  box-sizing: border-box;\n  max-width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
