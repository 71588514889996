import { Injectable } from '@angular/core';
import { HandleError, HttpErrorHandler } from './http-error-handler.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PageableResponse, PageableRequest } from '../model';
import { Asset } from '../model/financial/asset';
import { QueryOnlyServiceForPerson, QueryOnlyService } from './service';
import { AppConfigService } from './app.config.service';

@Injectable()
export class AssetService
  implements QueryOnlyService<Asset>, QueryOnlyServiceForPerson<Asset>
{
  private handleError: HandleError;
  private getUrl = (scenarioId, id = '') =>
    `${
      this.configService.getConfig().apiUrl
    }/scenarios/${scenarioId}/assets/${id}`;
  private getUrlForPerson = (scenarioId, personId, id = '') =>
    `${
      this.configService.getConfig().apiUrl
    }/scenarios/${scenarioId}/person/${personId}/assets/${id}`;

  constructor(
    private http: HttpClient,
    private configService: AppConfigService,
    private httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('assetService');
  }

  query(
    scenarioId: string,
    pageable: PageableRequest
  ): Observable<PageableResponse<Asset>> {
    return this.http
      .get<PageableResponse<Asset>>(this.getUrl(scenarioId), {
        params: pageable,
      })
      .pipe(catchError(this.handleError('query assets')));
  }

  get(scenarioId: string, id: string): Observable<Asset> {
    return this.http
      .get<Asset>(this.getUrl(scenarioId, id))
      .pipe(catchError(this.handleError('get asset')));
  }

  queryForPerson(
    scenarioId: string,
    personId: string,
    pageable: PageableRequest
  ): Observable<PageableResponse<Asset>> {
    return this.http
      .get<PageableResponse<Asset>>(
        this.getUrlForPerson(scenarioId, personId),
        { params: pageable }
      )
      .pipe(catchError(this.handleError('query assets')));
  }

  getForPerson(
    scenarioId: string,
    personId: string,
    id: string
  ): Observable<Asset> {
    return this.http
      .get<Asset>(this.getUrlForPerson(scenarioId, personId, id))
      .pipe(catchError(this.handleError('get asset')));
  }
}
