import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IMoneyhubConnection } from '../../../../model/moneyhub/envizage-connect.model';

@Component({
  selector: 'app-mh-cancel-dialog',
  templateUrl: './cancel-dialog.component.html',
  styleUrls: ['./cancel-dialog.component.scss']
})
export class MHCancelDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<MHCancelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { connection: IMoneyhubConnection }
  ) { }

  ngOnInit() {
  }

  get name() {
    return this.data.connection.connectionName
  }

  get bank() {
    return this.data.connection
  }

}
