import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  AfterViewInit,
  ViewContainerRef,
  ViewChildren,
  QueryList,
  AfterViewChecked,
  ChangeDetectorRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, mergeMap } from 'rxjs/operators';
import { GoalUtils } from 'src/app/utils/goal.utils';
import { TypedGoalService } from 'src/app/services/typed.goal.service';
import { Constants } from 'src/app/app.constants';
import { Observable, of } from 'rxjs';
import { GoalFactory } from 'src/app/model/goal/goal.factory.class';
import { Goal, GoalType } from 'src/app/model/goal';
import { Person, Scenario, DateRangeType } from 'src/app/model';
import { NotifierService } from 'angular-notifier';
import {
  EditGoalFormFactory,
  PersonsService,
  ScenarioService,
  UnsecuredLiabilityService,
  AnalyticsService,
} from 'src/app/services';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AnalyticsEvent, AnalyticsProperty } from 'src/app/model/analytics';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatMenuTrigger } from '@angular/material/menu';
import { GoalBuilder } from 'src/app/model/goal/goal.builder.class';
import * as moment from 'moment/moment';
import { WizardService } from '../../../services';
import { ScenarioUtils, DateUtils } from 'src/app/utils';
import { GoalRetirementProperties } from 'src/app/model/goal/goal.properties.class';
import { FeatureService } from 'src/app/feature/feature.service';
import { AppFeatureType } from 'src/app/feature';

interface XYCoords {
  top: number;
  left: number;
}

@Component({
  selector: 'app-future',
  templateUrl: './future.component.html',
  styleUrls: ['./future.component.scss'],
})
export class FutureComponent
  implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked
{
  private _navigation: any;
  private _scenarioId: string;
  private _scenario: Scenario;
  private _primary: Person;
  private _graphYearList: any[] = [];
  private _partner: Person;
  private _children: Person[];
  private _typedGoals: Goal[] = [];
  public _dragDetails: {
    goal: Goal;
    year: number;
    existingGoal: boolean;
    zoneName: string;
  };
  private _dropZones: {
    name: string;
    element: ElementRef;
    priority: number;
    menuContentRefList: QueryList<ViewContainerRef>;
    boundingRect: any;
  }[];
  public breakpoint: number;
  private _sameYearGoals: { [priority: string]: { [year: number]: string[] } };
  private _expandedGroup: { year: number; priority: number };

  private _latestEvent: DragEvent;

  private _dragging = false;
  private _dragMoveProcessing = false;
  private _isMobile = false;
  private _isTablet = false;
  private _isDesktop = false;

  private _wantZone: ElementRef;
  private _wishZone: ElementRef;
  private _needZone: ElementRef;
  public isRiskAssessmentEnabled = true;

  @ViewChildren('wishMenuRef', { read: ViewContainerRef })
  wishMenuRef: QueryList<ViewContainerRef>;
  @ViewChildren('wantMenuRef', { read: ViewContainerRef })
  wantMenuRef: QueryList<ViewContainerRef>;
  @ViewChildren('needMenuRef', { read: ViewContainerRef })
  needMenuRef: QueryList<ViewContainerRef>;

  @ViewChildren(MatMenuTrigger) trigger: QueryList<MatMenuTrigger>;

  private _duration: string;

  @ViewChild('wantZone') set wantZone(ref: ElementRef) {
    this._wantZone = ref;
  }

  @ViewChild('wishZone') set wishZone(ref: ElementRef) {
    this._wishZone = ref;
  }

  @ViewChild('needZone') set needZone(ref: ElementRef) {
    this._needZone = ref;
  }

  closeGoalMenu() {
    this.trigger.forEach((t) => t.closeMenu());
  }

  constructor(
    private changeDetector: ChangeDetectorRef,
    private goalComponentFactory: EditGoalFormFactory,
    private deviceService: DeviceDetectorService,
    private analyticsService: AnalyticsService,
    private unsecuredLiabilityService: UnsecuredLiabilityService,
    private route: ActivatedRoute,
    private titleService: Title,
    private t: TranslateService,
    private router: Router,
    private notifier: NotifierService,
    private scenarioService: ScenarioService,
    private personService: PersonsService,
    private typedGoalService: TypedGoalService,
    private wizardService: WizardService,
    private featureService: FeatureService
  ) {}

  ngOnInit() {
    this.isRiskAssessmentEnabled = this.featureService.hasFeature(
      AppFeatureType.RiskAssessment
    );
    this.titleService.setTitle(this.t.instant('Onboarding | Future'));
    this._duration = Constants.MAX_DURATION;
    this._scenarioId = this.router.routerState.snapshot.url.split('/')[2];
    this._navigation = (this.route.data as any).value.navigation;
    this.breakpoint =
      window.innerWidth < 599
        ? this.goals.length + 1
        : window.innerWidth <= 1100
          ? 1
          : 2;

    this._isMobile = this.deviceService.isMobile();
    this._isDesktop = this.deviceService.isDesktop();
    this._isTablet = this.deviceService.isTablet();

    this._sameYearGoals = {
      [Constants.WANT_PRIORITY]: {},
      [Constants.WISH_PRIORITY]: {},
      [Constants.NEED_PRIORITY]: {},
    };

    this.scenarioService
      .query(Constants.PAGE_ALL)
      .pipe(
        map(
          (r) =>
            (this._scenario = r.content.filter(
              (s) => s.household.preferences[Constants.PREFERENCE_KEY_CURRENT]
            )[0])
        ),
        mergeMap(() =>
          this.personService
            .getPrimary(this._scenarioId)
            .pipe(map((p) => (this._primary = p)))
        ),
        mergeMap(() =>
          this.personService
            .getPartner(this._scenarioId)
            .pipe(map((p) => (this._partner = p)))
        ),
        mergeMap(() =>
          this.personService
            .queryChildren(this._scenarioId, Constants.PAGE_ALL)
            .pipe(map((r) => (this._children = r.content)))
        )
      )
      .subscribe(() => {
        this.positionAxesLabels();
      });
  }

  ngAfterViewInit() {
    const elements = document.querySelectorAll(
      '[draggable=true].draggable-goal'
    );
    document.addEventListener(
      'dragover',
      (event: DragEvent) => {
        if (this._dragDetails) {
          this.dragMove(event);
        }
      },
      { passive: true }
    );

    this.refreshGoals();
    this.refreshDropZones();
  }

  private dragEndExistingGoalListener = (event: DragEvent) => {
    if (this._dragging) {
      this.dragEnd(event, true);
      this._dragging = false;
    }
  };

  private wishElementStartListeners = [];
  private wantElementStartListeners = [];
  private needElementStartListeners = [];
  private goalStartListeners = {};
  private goalEndListeners = {};

  ngAfterViewChecked() {
    const elements = document.querySelectorAll(
      '[draggable=true].draggable-goal'
    );

    elements.forEach((e, i) => {
      const goal = this.goals[i];
      if (!this.goalStartListeners[goal.label]) {
        this.goalStartListeners[goal.label] = (event: DragEvent) => {
          event.dataTransfer.setData('Text', 'test');
          this.dragStart(null, goal);
        };
      }
      if (!this.goalEndListeners[goal.label]) {
        this.goalEndListeners[goal.label] = (event: DragEvent) => {
          this.dragEnd(null, false);
        };
      }
      e.removeEventListener('dragstart', this.goalStartListeners[goal.label]);
      e.addEventListener('dragstart', this.goalStartListeners[goal.label], {
        passive: true,
      });
      e.removeEventListener('dragend', this.goalEndListeners[goal.label]);
      e.addEventListener('dragend', this.goalEndListeners[goal.label], {
        passive: true,
      });
    });

    const wishElements = document.querySelectorAll(
      '[draggable=true].coin-container-wish'
    );
    const wantElements = document.querySelectorAll(
      '[draggable=true].coin-container-want'
    );
    const needElements = document.querySelectorAll(
      '[draggable=true].coin-container-need'
    );
    wishElements.forEach((e, i) => {
      if (!this.wishElementStartListeners[i]) {
        this.wishElementStartListeners[i] = (event: DragEvent) => {
          event.dataTransfer.setData('Text', 'test');
          if (!this._dragging) {
            this.dragStartExisting(event, this.wishGoals[i]);
            this._dragging = true;
          }
        };
      }
      e.removeEventListener('dragstart', this.wishElementStartListeners[i]);
      e.addEventListener('dragstart', this.wishElementStartListeners[i], {
        passive: true,
      });

      e.removeEventListener('dragend', this.dragEndExistingGoalListener);
      e.addEventListener('dragend', this.dragEndExistingGoalListener, {
        passive: true,
      });
    });

    wantElements.forEach((e, i) => {
      if (!this.wantElementStartListeners[i]) {
        this.wantElementStartListeners[i] = (event: DragEvent) => {
          event.dataTransfer.setData('Text', 'test');
          if (!this._dragging) {
            this.dragStartExisting(event, this.wantGoals[i]);
            this._dragging = true;
          }
        };
      }
      e.removeEventListener('dragstart', this.wantElementStartListeners[i]);
      e.addEventListener('dragstart', this.wantElementStartListeners[i], {
        passive: true,
      });

      e.removeEventListener('dragend', this.dragEndExistingGoalListener);
      e.addEventListener('dragend', this.dragEndExistingGoalListener, {
        passive: true,
      });
    });

    needElements.forEach((e, i) => {
      if (!this.needElementStartListeners[i]) {
        this.needElementStartListeners[i] = (event: DragEvent) => {
          event.dataTransfer.setData('Text', 'test');
          if (!this._dragging) {
            this.dragStartExisting(event, this.needGoals[i]);
            this._dragging = true;
          }
        };
      }
      e.removeEventListener('dragstart', this.needElementStartListeners[i]);
      e.addEventListener('dragstart', this.needElementStartListeners[i], {
        passive: true,
      });

      e.removeEventListener('dragend', this.dragEndExistingGoalListener);
      e.addEventListener('dragend', this.dragEndExistingGoalListener, {
        passive: true,
      });
    });
  }

  get isMobile() {
    return this._isMobile;
  }

  get isTablet() {
    return this._isTablet;
  }

  get isDesktop() {
    return this._isDesktop;
  }

  groupedGoals(goals: Goal[]) {
    // add a fake goal for each year
    const yearsMap = {};
    let fakeGoal: Goal = null;
    const fakeGoals = [];
    goals.forEach((goal: Goal) => {
      const goalYear = this.swimLaneYear(goal.startDate);
      if (
        this._sameYearGoals[goal.priority] &&
        this._sameYearGoals[goal.priority][goalYear] &&
        this._sameYearGoals[goal.priority][goalYear].length > 1 &&
        !yearsMap[goalYear]
      ) {
        fakeGoal = new GoalBuilder()
          .userDefined()
          .withName(
            this._sameYearGoals[goal.priority][goalYear].length.toString()
          )
          .withDescription('FAKE')
          .inDate(goal.startDate)
          .build();
        yearsMap[goalYear] = true;
        fakeGoal.priority = goal.priority;
        fakeGoals.push(fakeGoal);
      }
    });
    return fakeGoals;
  }

  expandGroup(startDate: string, priority: number) {
    const year = this.swimLaneYear(startDate);
    this._expandedGroup =
      !this._expandedGroup ||
      this._expandedGroup.year !== year ||
      this._expandedGroup.priority !== priority
        ? { year, priority }
        : null;
  }

  onResize(event) {
    this.breakpoint =
      window.innerWidth < 599
        ? this.goals.length
        : window.innerWidth <= 1100
          ? 1
          : 2;
    // this.breakpoint = (event.target.innerWidth <= 1275) ? 1 : 2;
    this.refreshDropZones();
  }

  getGridWidth(total) {
    return window.innerWidth < 599 ? total * 90 + 'px' : 'auto';
  }

  ngOnDestroy() {
    this.wizardService.setVisited(this.route.snapshot.url[0].path);
    this.atLeastOneRetirementGoal().subscribe(() => {});
  }

  refreshDropZones() {
    this._dropZones = [
      {
        name: 'WISH',
        element: this._wishZone,
        priority: Constants.WISH_PRIORITY,
        menuContentRefList: this.wishMenuRef,
        boundingRect: this._wishZone.nativeElement.getBoundingClientRect(),
      },
      {
        name: 'WANT',
        element: this._wantZone,
        priority: Constants.WANT_PRIORITY,
        menuContentRefList: this.wantMenuRef,
        boundingRect: this._wantZone.nativeElement.getBoundingClientRect(),
      },
      {
        name: 'NEED',
        element: this._needZone,
        priority: Constants.NEED_PRIORITY,
        menuContentRefList: this.needMenuRef,
        boundingRect: this._needZone.nativeElement.getBoundingClientRect(),
      },
    ];
  }

  atLeastOneRetirementGoal(): Observable<any> {
    return this.typedGoalService
      .query(this._scenarioId, Constants.PAGE_ALL)
      .pipe(
        mergeMap((r) => {
          if (r.content.length > 0) {
            return of({});
          }
          this.analyticsService.trackAnalyticsEvent(
            AnalyticsEvent.ADD_GOAL,
            GoalType.RETIREMENT,
            {
              [AnalyticsProperty.GOAL_TYPE]: GoalType.RETIREMENT,
              [AnalyticsProperty.NUMBER_OF_GOALS]: 1,
            }
          );
          return this.personService.getPrimary(this._scenarioId).pipe(
            mergeMap((p) =>
              this.typedGoalService.create(
                this._scenarioId,
                GoalFactory.retirementGoal(
                  moment
                    .utc()
                    .year(p.yearOfBirth + p.expectedRetirementAge)
                    .format()
                )
              )
            )
          );
        })
      );
  }

  get goals() {
    return GoalUtils.GOALS.filter((g) => g.visible(this._scenario));
  }

  dragStart(event: DragEvent, newGoal: any) {
    this.refreshDropZones();
    this._dragDetails = {
      goal: newGoal.create(this._scenario),
      existingGoal: false,
      year: new Date().getFullYear(),
      zoneName: '',
    };
  }

  dragStartExisting(event: DragEvent, goal: Goal) {
    // event.target['style']['opacity'] = 0.1;
    this._dragDetails = {
      goal: goal,
      existingGoal: true,
      year: new Date(Date.parse(goal.startDate)).getFullYear(),
      zoneName: '',
    };
  }

  getYear(position: XYCoords, bound: DOMRect) {
    const maxValue = bound.left + bound.width;
    const minValue = bound.left;

    const minYear = new Date().getFullYear();
    const maxYear =
      this._duration === '120'
        ? new Date().getFullYear() + Constants.GOAL_YEAR_RANGE
        : new Date().getFullYear() + 5;

    // Find a and b in y = ax + b given the 2 points: (minValue, minYear) and (maxValue, maxYear)
    const a = (maxYear - minYear) / (maxValue - minValue);
    const b = minYear - a * bound.left;

    return Math.round(b + a * position.left);
  }

  positionAxesLabels() {
    const minYear = new Date().getFullYear();
    const maxYear =
      this._duration === '120'
        ? new Date().getFullYear() + Constants.GOAL_YEAR_RANGE
        : new Date().getFullYear() + 5;

    const zoneWidth = this._wantZone.nativeElement.offsetWidth;
    // y = ax + b with points (minYear, 100), (maxYear, 100 + zoneWidth)
    const a = zoneWidth / (maxYear - minYear);
    const b = 100 + zoneWidth - maxYear * a;

    this._graphYearList = [];
    for (
      let i = minYear;
      i <= (this._duration === '120' ? maxYear - 5 : maxYear);
      i++
    ) {
      if (
        (this._duration === '120' && i % 10 === 0) ||
        this._duration === '5'
      ) {
        this._graphYearList.push({
          year: i,
          primaryAge: this._primary ? i - this._primary.yearOfBirth : 0,
          partnerAge: this._partner ? i - this._partner.yearOfBirth : 0,
          childrenAges: (this._children || []).map((c) => i - c.yearOfBirth),
          leftOffset:
            window.innerWidth < 599
              ? `${a * i + b - (this._duration === '120' ? 40 : 50)}px`
              : `${a * i + b}px`,
        });
      }
    }
  }

  yearAxisOffset(offset) {
    return window.innerWidth < 599 && this._duration !== '120'
      ? `${Number(offset.split('px')[0]) - 10}px`
      : offset;
  }

  updateDuration(ev: MatButtonToggleChange) {
    this._duration = ev.value;
    this.positionAxesLabels();
    this._expandedGroup = null;

    this.refreshGoals();
    this.refreshDropZones();
  }

  get duration() {
    return this._duration;
  }

  set duration(v) {
    this._duration = v;
  }

  menuOpened(goal: Goal, idx: number, zoneName: string) {
    const menuContentRefList = this._dropZones.find(
      (z) => z.name === zoneName
    ).menuContentRefList;
    this.goalComponentFactory.appendEditForm(
      this._scenarioId,
      goal,
      this._scenario,
      menuContentRefList.find((_, i) => i === idx)
    );
  }

  isInDropZone(position: XYCoords, bound: DOMRect) {
    return (
      position.left >= bound.left &&
      position.left <= bound.left + bound.width &&
      position.top >= bound.top &&
      position.top <= bound.top + bound.height
    );
  }

  touchEnd(event: TouchEvent, update: boolean) {
    event.target['style']['opacity'] = 1;
    const finalPosition: XYCoords = {
      top: event.changedTouches[0].clientY,
      left: event.changedTouches[0].clientX,
    };
    this.addToSwimLane(finalPosition, update);
  }

  dragEnd(event: DragEvent, update: boolean) {
    if (this._latestEvent) {
      this._latestEvent.target['style']['opacity'] = 1;
      const finalPosition: XYCoords = {
        top: this._latestEvent.y,
        left: this._latestEvent.x,
      };
      this.addToSwimLane(finalPosition, update);
    }
  }

  addToSwimLane(finalPosition: XYCoords, update: boolean) {
    if (!this._dragDetails || !this._dragDetails.goal) {
      this._dragDetails = null;
      return;
    }
    const dropZone = this._dropZones.find((z) =>
      this.isInDropZone(finalPosition, z.boundingRect)
    );
    const goal = this._dragDetails.goal;
    if (dropZone) {
      goal.priority = dropZone.priority;
      const goalStartDate = new Date();
      goalStartDate.setFullYear(
        this.getYear(finalPosition, dropZone.boundingRect)
      );
      const duration =
        moment.utc(goal.endDate).year() - moment.utc(goal.startDate).year();
      const goalEndDate = new Date();
      goalEndDate.setFullYear(
        this.getYear(finalPosition, dropZone.boundingRect) + duration
      );

      goal.startDate = goalStartDate.toISOString();
      goal.endDate = goalEndDate.toISOString();

      if (update) {
        if (goal.type === 'RETIREMENT' && this._partner) {
          //Primary retirement, update partner retirement priority too
          const partnerRetirement = this._typedGoals.find(
            (g) =>
              g.description &&
              JSON.parse(g.description).type === 'PARTNER_RETIREMENT'
          );
          if (partnerRetirement) {
            partnerRetirement.priority = dropZone.priority;
          }
        }

        if (
          goal.description &&
          JSON.parse(goal.description).type === 'PARTNER_RETIREMENT'
        ) {
          this._partner.expectedRetirementAge =
            new Date(goal.startDate).getFullYear() - this._partner.yearOfBirth;
          this.personService
            .updatePartner(this._scenarioId, this._partner)
            .pipe(
              mergeMap(() => {
                const primaryRetirement = this._typedGoals.find(
                  (g) => g.type === 'RETIREMENT' && !g.description
                );
                primaryRetirement.priority = dropZone.priority;
                return this.typedGoalService.update(
                  this._scenarioId,
                  primaryRetirement
                );
              })
            )
            .subscribe(
              () => this.refreshGoals(),
              (err) => this.notifier.notify(Constants.ERROR, err),
              () => this.notifier.notify(Constants.SUCCESS, 'Goal updated')
            );
        } else {
          this.analyticsService.trackAnalyticsEvent(
            AnalyticsEvent.EDIT_GOAL,
            goal.type
          );
          this.typedGoalService.update(this._scenarioId, goal).subscribe(
            () => this.refreshGoals(),
            (err) => this.notifier.notify(Constants.ERROR, err),
            () => this.notifier.notify(Constants.SUCCESS, 'Goal updated')
          );
        }
      } else {
        this.typedGoalService.create(this._scenarioId, goal).subscribe(
          (g) => this.refreshGoals(),
          (err) => this.notifier.notify(Constants.ERROR, err),
          () => this.notifier.notify(Constants.SUCCESS, 'Goal created')
        );
      }
    } else {
      this._dragDetails.existingGoal && this.deleteGoal(this._dragDetails.goal);
    }
    this._dragDetails = null;
  }

  getGoalStartYear(goal: Goal) {
    let goalStartYear = new Date(Date.parse(goal.startDate))
      .getFullYear()
      .toString();
    if (
      new Date(goal.startDate).getFullYear() - new Date().getFullYear() >
      Constants.GOAL_YEAR_RANGE
    ) {
      goalStartYear =
        new Date().getFullYear() + Constants.GOAL_YEAR_RANGE + '+';
    }
    return goalStartYear;
  }

  getGoalTopOffset(goal: Goal): string {
    const goalYear = this.swimLaneYear(goal.startDate);
    /*
      && !!this._sameYearGoals[goal.priority][goalYear]
      && this._sameYearGoals[goal.priority][goalYear].length > 1
    */
    if (
      !!this._expandedGroup &&
      this._expandedGroup.year === goalYear &&
      this._expandedGroup.priority === goal.priority &&
      this._sameYearGoals[goal.priority][goalYear].length > 1
    ) {
      const index =
        this._sameYearGoals[goal.priority][goalYear].indexOf(goal.id) + 1;
      // const slots = 140 / this._sameYearGoals[goal.priority][goalYear].length - 5;
      // return (index % 2) ? index * 45 + 'px'
      const offset = 50;
      const initialOffset = 45;
      let maxTopExpandingGoals = 0;
      let afterMaxIndexStart = 0;
      const startingPointTop = -35;
      const startingPointBottom = 65;

      if (goal.priority === 1) {
        maxTopExpandingGoals = 2;
      } else if (goal.priority === 5) {
        maxTopExpandingGoals = 4;
      } else {
        maxTopExpandingGoals = 7;
      }

      afterMaxIndexStart = maxTopExpandingGoals + 1;

      if (index === 1) {
        return startingPointTop + 'px';
      } else if (index === 2) {
        return startingPointBottom + 'px';
      } else if (index % 2 !== 0 && index <= maxTopExpandingGoals * 2) {
        return Math.floor(index / 2) * -55 + startingPointTop + 'px';
      } else if (index % 2 === 0 && index <= maxTopExpandingGoals * 2) {
        return (index / 2 - 1) * 55 + startingPointBottom + 'px';
      } else {
        return (index - afterMaxIndexStart) * 55 + startingPointBottom + 'px';
      }
    }
    return '15px';
  }

  expandedGroup(goal: Goal) {
    return (
      !!this._expandedGroup &&
      this._expandedGroup.year === new Date(goal.startDate).getFullYear() &&
      this._expandedGroup.priority === goal.priority
    );
  }

  dragMove(event: DragEvent) {
    if (!this._dragMoveProcessing) {
      this._dragMoveProcessing = true;
      const finalPosition: XYCoords = { top: event.y, left: event.x };
      this._latestEvent = event;
      this.setDragDetails(finalPosition);
      this._dragMoveProcessing = false;
    }
  }

  setDragDetails(position: XYCoords) {
    const dropZone = this._dropZones.find((z) =>
      this.isInDropZone(position, z.boundingRect)
    );
    if (dropZone) {
      this._dragDetails.zoneName = dropZone.name;
      this._dragDetails.year = this.getYear(position, dropZone.boundingRect);
    }
  }

  touchMove(event: TouchEvent) {
    const finalPosition: XYCoords = {
      top: event.changedTouches[0].clientY,
      left: event.changedTouches[0].clientX,
    };
    this.setDragDetails(finalPosition);
  }

  refreshGoals() {
    this.scenarioService
      .query(Constants.PAGE_ALL)
      .pipe(
        map(
          (r) =>
            (this._scenario = r.content.filter(
              (s) => s.household.preferences[Constants.PREFERENCE_KEY_CURRENT]
            )[0])
        )
      )
      .pipe(
        mergeMap(() =>
          this.typedGoalService.query(this._scenarioId, Constants.PAGE_ALL)
        )
      )
      .subscribe(
        (resp) => {
          this.analyticsService.setAnalyticsUserProperty(
            AnalyticsProperty.NUMBER_OF_GOALS,
            resp.content.length
          );
          this._typedGoals = resp.content;

          this._sameYearGoals = {
            [Constants.WANT_PRIORITY]: {},
            [Constants.WISH_PRIORITY]: {},
            [Constants.NEED_PRIORITY]: {},
          };

          if (ScenarioUtils.hasPartner(this._scenario)) {
            const p = ScenarioUtils.getPartner(this._scenario);
            const g = new GoalBuilder()
              .retirement()
              .withAmount(0)
              .withProperties(new GoalRetirementProperties())
              .withName("Partner's retirement")
              .withDescription(JSON.stringify({ type: 'PARTNER_RETIREMENT' }))
              .inDate(DateUtils.atYear(p.yearOfBirth + p.expectedRetirementAge))
              .startsOn(DateRangeType.USER_DEFINED)
              .endsOn(DateRangeType.USER_DEFINED)
              .build();
            g.priority = this._typedGoals.find(
              (g) => g.type === 'RETIREMENT'
            ).priority;
            this._typedGoals.push(g);
          }

          // map goals per year to get same year goals and re-position them.
          this._typedGoals.forEach((goal: Goal) => {
            const goalYear = this.swimLaneYear(goal.startDate);
            if (!this._sameYearGoals[goal.priority][goalYear]) {
              this._sameYearGoals[goal.priority][goalYear] = [];
            }
            if (
              this._sameYearGoals[goal.priority][goalYear].indexOf(goal.id) ===
              -1
            ) {
              this._sameYearGoals[goal.priority][goalYear].push(goal.id);
            }
          });
        },
        () => {},
        () => {}
      );
  }

  deleteGoal(goal: Goal) {
    if (this._typedGoals && this._typedGoals.length <= 1) {
      return;
    }
    this.analyticsService.trackAnalyticsEvent(
      AnalyticsEvent.DELETE_GOAL,
      goal.type,
      {
        [AnalyticsProperty.GOAL_TYPE]: goal.type,
      }
    );
    this._dragDetails = null;
    this.typedGoalService.delete(this._scenarioId, goal.id).subscribe(
      () => this.refreshGoals(),
      (err) => this.notifier.notify(Constants.ERROR, err),
      () => {
        this.notifier.notify(Constants.SUCCESS, 'Goal deleted');
        const year = this.swimLaneYear(goal.startDate);
        if (this._sameYearGoals[goal.priority][year].length <= 2) {
          this._expandedGroup = null;
        }
      }
    );
  }

  updateGoal(goal: Goal) {
    this.analyticsService.trackAnalyticsEvent(
      AnalyticsEvent.EDIT_GOAL,
      goal.type,
      {
        [AnalyticsProperty.GOAL_TYPE]: goal.type,
      }
    );
    this._dragDetails = null;
    this._expandedGroup = null;
    if (
      goal.description &&
      JSON.parse(goal.description).type === 'PARTNER_RETIREMENT'
    ) {
      this._partner.expectedRetirementAge =
        new Date(goal.startDate).getFullYear() - this._partner.yearOfBirth;
      this.personService
        .updatePartner(this._scenarioId, this._partner)
        .subscribe(
          () => this.refreshGoals(),
          (err) => this.notifier.notify(Constants.ERROR, err),
          () => this.notifier.notify(Constants.SUCCESS, 'Goal updated')
        );
      return;
    }
    this.typedGoalService.update(this._scenarioId, goal).subscribe(
      () => this.refreshGoals(),
      (err) => this.notifier.notify(Constants.ERROR, err),
      () => this.notifier.notify(Constants.SUCCESS, 'Goal updated')
    );
  }

  getGoalIconName(goal: Goal): string {
    let goalDetails = null;
    if (goal.type === 'SABBATICAL') {
      goalDetails = GoalUtils.GOALS.find((g) => g.label === goal.name);
    } else {
      goalDetails = GoalUtils.GOALS.find((g) => g.type === goal.type);
    }
    return goalDetails ? goalDetails.icon : 'icon-question';
  }

  getGoalLeftOffset(goal: Goal): string {
    if (!this._primary) {
      return;
    }
    let startYear = new Date(goal.startDate).getFullYear();
    let yearOfBirth = this._primary.yearOfBirth;
    if (
      this._partner &&
      goal.type === 'RETIREMENT' &&
      goal.description &&
      JSON.parse(goal.description).type === 'PARTNERS_RETIREMENT'
    ) {
      yearOfBirth = this._partner.yearOfBirth;
    }
    const currnetAge = new Date().getFullYear() - yearOfBirth;
    if (startYear - new Date().getFullYear() > Constants.GOAL_YEAR_RANGE) {
      startYear = yearOfBirth + Constants.GOAL_YEAR_RANGE + currnetAge;
    }
    const dropZone =
      this._dropZones.find((d) => goal.priority === d.priority) ||
      this._dropZones[0];
    return (
      -50 + this.calculateGoalOffset(startYear, dropZone.boundingRect) + 'px'
    );
  }

  get wishGoals() {
    return this._typedGoals
      .filter(
        (g) =>
          g.priority === this._dropZones.find((d) => d.name === 'WISH').priority
      )
      .filter(
        (g) => new Date(g.startDate).getFullYear() >= new Date().getFullYear()
      )
      .filter(
        (g) =>
          (this._duration === '120' &&
            new Date(g.startDate).getFullYear() - new Date().getFullYear() <=
              Constants.GOAL_YEAR_RANGE) ||
          (this._duration === '5' &&
            new Date(g.startDate).getFullYear() - new Date().getFullYear() <= 5)
      );
  }

  get wantGoals() {
    return this._typedGoals
      .filter(
        (g) =>
          g.priority === this._dropZones.find((d) => d.name === 'WANT').priority
      )
      .filter(
        (g) => new Date(g.startDate).getFullYear() >= new Date().getFullYear()
      )
      .filter(
        (g) =>
          (this._duration === '120' &&
            (new Date(g.startDate).getFullYear() - new Date().getFullYear() <=
              Constants.GOAL_YEAR_RANGE ||
              g.type === 'RETIREMENT')) ||
          (this._duration === '5' &&
            new Date(g.startDate).getFullYear() - new Date().getFullYear() <= 5)
      );
  }

  get needGoals() {
    return this._typedGoals
      .filter(
        (g) =>
          g.priority === this._dropZones.find((d) => d.name === 'NEED').priority
      )
      .filter(
        (g) => new Date(g.startDate).getFullYear() >= new Date().getFullYear()
      )
      .filter(
        (g) =>
          (this._duration === '120' &&
            new Date(g.startDate).getFullYear() - new Date().getFullYear() <=
              Constants.GOAL_YEAR_RANGE) ||
          (this._duration === '5' &&
            new Date(g.startDate).getFullYear() - new Date().getFullYear() <= 5)
      );
  }

  getGoalName(goal: Goal): string {
    return GoalUtils.GOALS.find((g) => g.type === goal.type).label;
  }

  calculateGoalOffset(year: number, bound: DOMRect) {
    if (isNaN(year)) {
      return 0;
    }

    const maxValue = bound.width;
    const minValue = 0;

    const minYear = new Date().getFullYear();
    const maxYear =
      this._duration === '120'
        ? new Date().getFullYear() + Constants.GOAL_YEAR_RANGE
        : new Date().getFullYear() + 5;

    // Find a and b in y = ax + b given the 2 points: (minValue, minYear) and (maxValue, maxYear)
    const a = (maxYear - minYear) / (maxValue - minValue);
    const b = minYear;

    // x = (y - b) / a
    return (year - b) / a;
  }

  get graphYearList() {
    return this._graphYearList;
  }

  getPersonAgeInGraphYears(person: Person) {
    return this.graphYearList.map((year) => year - person.yearOfBirth);
  }

  get navigation() {
    return this._navigation;
  }

  get primary() {
    return this._primary;
  }

  get partner() {
    return this._partner;
  }

  get children() {
    return this._children;
  }

  getChildGender(child: Person) {
    if (
      child.gender === 'MALE' ||
      child.gender === 'TRANS_MALE' ||
      child.gender === 'OTHER_MALE'
    ) {
      return 'son';
    }
    if (
      child.gender === 'FEMALE' ||
      child.gender === 'TRANS_FEMALE' ||
      child.gender === 'OTHER_FEMALE'
    ) {
      return 'daughter';
    }
    return 'child';
  }

  get dragDetails() {
    if (this._dragDetails) {
    }
    return this._dragDetails;
  }

  get dragYear() {
    return this._dragDetails ? this._dragDetails.year : 0;
  }

  isGoalBeingDragged(goal: Goal) {
    if (
      this._dragDetails &&
      this._dragDetails.goal &&
      this._dragDetails.goal.id === goal.id
    ) {
      return true;
    }
    return false;
  }

  get showDeleteBtn() {
    return this._typedGoals && this._typedGoals.length > 1;
  }

  swimLaneYear(date: string) {
    let goalYear = new Date(date).getFullYear();
    if (goalYear > new Date().getFullYear() + Constants.GOAL_YEAR_RANGE) {
      goalYear = new Date().getFullYear() + Constants.GOAL_YEAR_RANGE;
    }
    return goalYear;
  }
}
