import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HandleError, HttpErrorHandler } from './http-error-handler.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PageableResponse, PageableRequest } from '../model';
import { GoalSnapshot } from '../model/household/result/goal.snapshot';
import { AppConfigService } from './app.config.service';

@Injectable()
export class ResultsScenarioGoalService {
    private handleError: HandleError;
    private getUrl = () => this.configService.getConfig().apiUrl;

    constructor(
        private http: HttpClient,
        private configService: AppConfigService,
        private httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('resultsScenarioGoalService');
    }

    query(scenarioId: string, pageable: PageableRequest): Observable<PageableResponse<GoalSnapshot>> {
        return this.http.get<PageableResponse<GoalSnapshot>>(`${this.getUrl()}/results/${scenarioId}/goals`, { params: pageable })
            .pipe(
                catchError(this.handleError('query scenario'))
            );
    }

    get(scenarioId: string, goalId: string): Observable<PageableResponse<GoalSnapshot>> {
        return this.http.get<GoalSnapshot>(`${this.getUrl()}/results/${scenarioId}/goals/${goalId}`)
            .pipe(
                catchError(this.handleError('query scenario'))
            );
    }

}