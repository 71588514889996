import { Injectable } from '@angular/core';
import { HandleError, HttpErrorHandler } from './http-error-handler.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PageableResponse, PageableRequest } from '../model';
import {
  EarnedIncome,
  UnearnedIncome,
  RentIncome,
  PensionContributionIncome,
} from '../model/household/income';
import {
  Service,
  ServiceForPerson,
  QueryOnlyServiceForPerson,
  QueryOnlyService,
} from './service';
import { Income } from '../model/household/income/income';
import { AppConfigService } from './app.config.service';

@Injectable()
export class IncomeService
  implements QueryOnlyService<Income>, QueryOnlyServiceForPerson<Income>
{
  private handleError: HandleError;
  private getUrl = (scenarioId, id = '') =>
    `${
      this.configService.getConfig().apiUrl
    }/scenarios/${scenarioId}/incomes/${id}`;
  private getUrlForPerson = (scenarioId, personId, id = '') =>
    `${
      this.configService.getConfig().apiUrl
    }/scenarios/${scenarioId}/persons/${personId}/incomes/${id}`;

  constructor(
    private http: HttpClient,
    private configService: AppConfigService,
    private httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('incomeService');
  }

  query(
    scenarioId: string,
    pageable: PageableRequest
  ): Observable<PageableResponse<Income>> {
    return this.http
      .get<PageableResponse<Income>>(this.getUrl(scenarioId), {
        params: pageable,
      })
      .pipe(catchError(this.handleError('query incomes')));
  }

  get(scenarioId: string, id: string): Observable<Income> {
    return this.http
      .get<Income>(this.getUrl(scenarioId, id))
      .pipe(catchError(this.handleError('get income')));
  }

  queryForPerson(
    scenarioId: string,
    personId: string,
    pageable: PageableRequest
  ): Observable<PageableResponse<Income>> {
    return this.http
      .get<PageableResponse<Income>>(
        this.getUrlForPerson(scenarioId, personId),
        { params: pageable }
      )
      .pipe(catchError(this.handleError('query incomes')));
  }

  getForPerson(
    scenarioId: string,
    personId: string,
    id: string
  ): Observable<Income> {
    return this.http
      .get<Income>(this.getUrlForPerson(scenarioId, personId, id))
      .pipe(catchError(this.handleError('get income')));
  }
}

@Injectable()
export class PensionContributionIncomeService
  implements ServiceForPerson<PensionContributionIncome>
{
  private handleError: HandleError;
  private getUrlForPerson = (scenarioId, personId, id = '') =>
    `${
      this.configService.getConfig().apiUrl
    }/scenarios/${scenarioId}/persons/${personId}/incomes/employer-pension-contribution/${id}`;

  constructor(
    private http: HttpClient,
    private configService: AppConfigService,
    private httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError(
      'pensionContributionIncomeService'
    );
  }

  queryForPerson(
    scenarioId: string,
    personId: string,
    pageable: PageableRequest
  ): Observable<PageableResponse<PensionContributionIncome>> {
    return this.http
      .get<PageableResponse<PensionContributionIncome>>(
        this.getUrlForPerson(scenarioId, personId),
        { params: pageable }
      )
      .pipe(catchError(this.handleError('query pension contribution incomes')));
  }

  getForPerson(
    scenarioId: string,
    personId: string,
    id: string
  ): Observable<PensionContributionIncome> {
    return this.http
      .get<PensionContributionIncome>(
        this.getUrlForPerson(scenarioId, personId, id)
      )
      .pipe(catchError(this.handleError('get pension contribution income')));
  }

  createForPerson(
    scenarioId: string,
    personId: string,
    income: PensionContributionIncome
  ): Observable<PensionContributionIncome> {
    return this.http
      .post<PensionContributionIncome>(
        this.getUrlForPerson(scenarioId, personId),
        income
      )
      .pipe(catchError(this.handleError('create pension contribution income')));
  }

  updateForPerson(
    scenarioId: string,
    personId: string,
    income: PensionContributionIncome
  ): Observable<PensionContributionIncome> {
    return this.http
      .put<PensionContributionIncome>(
        this.getUrlForPerson(scenarioId, personId, income.id),
        income
      )
      .pipe(catchError(this.handleError('update pension contribution income')));
  }

  deleteForPerson(
    scenarioId: string,
    personId: string,
    id: string
  ): Observable<any> {
    return this.http
      .delete<any>(this.getUrlForPerson(scenarioId, personId, id))
      .pipe(catchError(this.handleError('delete pension contribution income')));
  }
}

@Injectable()
export class EarnedIncomeService implements ServiceForPerson<EarnedIncome> {
  private handleError: HandleError;
  private getUrlForPerson = (scenarioId, personId, id = '') =>
    `${
      this.configService.getConfig().apiUrl
    }/scenarios/${scenarioId}/persons/${personId}/incomes/earned/${id}`;

  constructor(
    private http: HttpClient,
    private configService: AppConfigService,
    private httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError(
      'earnedIncomeService'
    );
  }

  queryForPerson(
    scenarioId: string,
    personId: string,
    pageable: PageableRequest
  ): Observable<PageableResponse<EarnedIncome>> {
    return this.http
      .get<PageableResponse<EarnedIncome>>(
        this.getUrlForPerson(scenarioId, personId),
        { params: pageable }
      )
      .pipe(catchError(this.handleError('query earned incomes')));
  }

  getForPerson(
    scenarioId: string,
    personId: string,
    id: string
  ): Observable<EarnedIncome> {
    return this.http
      .get<EarnedIncome>(this.getUrlForPerson(scenarioId, personId, id))
      .pipe(catchError(this.handleError('get earned income')));
  }

  createForPerson(
    scenarioId: string,
    personId: string,
    income: EarnedIncome
  ): Observable<EarnedIncome> {
    return this.http
      .post<EarnedIncome>(this.getUrlForPerson(scenarioId, personId), income)
      .pipe(catchError(this.handleError('create earned income')));
  }

  updateForPerson(
    scenarioId: string,
    personId: string,
    income: EarnedIncome
  ): Observable<EarnedIncome> {
    return this.http
      .put<EarnedIncome>(
        this.getUrlForPerson(scenarioId, personId, income.id),
        income
      )
      .pipe(catchError(this.handleError('update earned income')));
  }

  deleteForPerson(
    scenarioId: string,
    personId: string,
    id: string
  ): Observable<any> {
    return this.http
      .delete<any>(this.getUrlForPerson(scenarioId, personId, id))
      .pipe(catchError(this.handleError('delete earned income')));
  }
}

@Injectable()
export class UnearnedIncomeService implements ServiceForPerson<UnearnedIncome> {
  private handleError: HandleError;
  private getUrlForPerson = (scenarioId, personId, id = '') =>
    `${
      this.configService.getConfig().apiUrl
    }/scenarios/${scenarioId}/persons/${personId}/incomes/unearned/${id}`;

  constructor(
    private http: HttpClient,
    private configService: AppConfigService,
    private httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError(
      'unearnedIncomeService'
    );
  }

  queryForPerson(
    scenarioId: string,
    personId: string,
    pageable: PageableRequest
  ): Observable<PageableResponse<UnearnedIncome>> {
    return this.http
      .get<PageableResponse<UnearnedIncome>>(
        this.getUrlForPerson(scenarioId, personId),
        { params: pageable }
      )
      .pipe(catchError(this.handleError('query unearned incomes')));
  }

  getForPerson(
    scenarioId: string,
    personId: string,
    id: string
  ): Observable<UnearnedIncome> {
    return this.http
      .get<UnearnedIncome>(this.getUrlForPerson(scenarioId, personId, id))
      .pipe(catchError(this.handleError('get unearned income')));
  }

  createForPerson(
    scenarioId: string,
    personId: string,
    income: UnearnedIncome
  ): Observable<UnearnedIncome> {
    return this.http
      .post<UnearnedIncome>(this.getUrlForPerson(scenarioId, personId), income)
      .pipe(catchError(this.handleError('create unearned income')));
  }

  updateForPerson(
    scenarioId: string,
    personId: string,
    income: UnearnedIncome
  ): Observable<UnearnedIncome> {
    return this.http
      .put<UnearnedIncome>(
        this.getUrlForPerson(scenarioId, personId, income.id),
        income
      )
      .pipe(catchError(this.handleError('update unearned income')));
  }

  deleteForPerson(
    scenarioId: string,
    personId: string,
    id: string
  ): Observable<any> {
    return this.http
      .delete<any>(this.getUrlForPerson(scenarioId, personId, id))
      .pipe(catchError(this.handleError('delete unearned income')));
  }
}

@Injectable()
export class RentIncomeService implements ServiceForPerson<RentIncome> {
  private handleError: HandleError;
  private getUrlForPerson = (scenarioId, personId, id = '') =>
    `${
      this.configService.getConfig().apiUrl
    }/scenarios/${scenarioId}/persons/${personId}/incomes/rent/${id}`;

  constructor(
    private http: HttpClient,
    private configService: AppConfigService,
    private httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('rentIncomeService');
  }

  queryForPerson(
    scenarioId: string,
    personId: string,
    pageable: PageableRequest
  ): Observable<PageableResponse<RentIncome>> {
    return this.http
      .get<PageableResponse<RentIncome>>(
        this.getUrlForPerson(scenarioId, personId),
        { params: pageable }
      )
      .pipe(catchError(this.handleError('query rent incomes')));
  }

  getForPerson(
    scenarioId: string,
    personId: string,
    id: string
  ): Observable<RentIncome> {
    return this.http
      .get<RentIncome>(this.getUrlForPerson(scenarioId, personId, id))
      .pipe(catchError(this.handleError('get rent income')));
  }

  createForPerson(
    scenarioId: string,
    personId: string,
    income: RentIncome
  ): Observable<RentIncome> {
    return this.http
      .post<RentIncome>(this.getUrlForPerson(scenarioId, personId), income)
      .pipe(catchError(this.handleError('create rent income')));
  }

  updateForPerson(
    scenarioId: string,
    personId: string,
    income: RentIncome
  ): Observable<RentIncome> {
    return this.http
      .put<RentIncome>(
        this.getUrlForPerson(scenarioId, personId, income.id),
        income
      )
      .pipe(catchError(this.handleError('update rent income')));
  }

  deleteForPerson(
    scenarioId: string,
    personId: string,
    id: string
  ): Observable<any> {
    return this.http
      .delete<any>(this.getUrlForPerson(scenarioId, personId, id))
      .pipe(catchError(this.handleError('delete rent income')));
  }
}
