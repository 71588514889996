import { Injectable } from '@angular/core';
import { HandleError, HttpErrorHandler } from '../http-error-handler.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PageableRequest, FundsUnderManagement } from '../../model';
import { AppConfigService } from '../app.config.service';
import { QueryOnlyServiceForEntity } from './iress.service';

@Injectable()
export class IressFumPortfolioService
  implements QueryOnlyServiceForEntity<FundsUnderManagement>
{
  private handleError: HandleError;
  private getUrl = (entityId: string, id: string = ''): string =>
    `${
      this.configService.getConfig().iress.apiUrl
    }/resourceful/entity/client/${entityId}/portfolio/fum/${id}`;

  constructor(
    private http: HttpClient,
    private configService: AppConfigService,
    private httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError(
      'IressFumPortfolioService'
    );
  }

  query = (
    entityId: string,
    pageable: PageableRequest
  ): Observable<FundsUnderManagement[]> => {
    throw new Error('Not implemented');
  };

  get = (entityId: string, id?: string): Observable<FundsUnderManagement> =>
    this.http
      .get<FundsUnderManagement>(this.getUrl(entityId, id), {
        headers: { 'X-Iress-Api': '1' },
        withCredentials: true,
      })
      .pipe(catchError(this.handleError('get client portfolio')));
}
