// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  height: calc(100% - 70px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}

.diff-row {
  border: 1px solid #E7E2DC;
  padding: 6px 8px;
}

.info-box {
  background: #E7E2DC;
  padding: 12px 9px;
}

.diff-label {
  color: #7F7B7B;
}

.diff-amount {
  font-size: 20px;
}

.hor-sep {
  width: 100%;
  height: 1px;
  border-top: 1px rgba(0, 0, 0, 0.1) solid;
  margin: 5px 0;
}`, "",{"version":3,"sources":["webpack://./src/app/components/overview/components/what-if/what-if.component.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,oBAAA;AACF;;AAEA;EACE,yBAAA;EACA,gBAAA;AACF;;AAEA;EACE,mBAAA;EACA,iBAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,WAAA;EACA,WAAA;EACA,wCAAA;EACA,aAAA;AACF","sourcesContent":[":host{\n  height: calc(100% - 70px);\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: stretch;\n}\n\n.diff-row {\n  border: 1px solid #E7E2DC;\n  padding: 6px 8px;\n}\n\n.info-box {\n  background: #E7E2DC;\n  padding: 12px 9px;\n}\n\n.diff-label {\n  color: #7F7B7B;\n}\n\n.diff-amount {\n  font-size: 20px;\n}\n\n.hor-sep {\n  width: 100%;\n  height: 1px;\n  border-top: 1px rgba(0, 0, 0, 0.10) solid;\n  margin: 5px 0;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
