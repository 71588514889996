export interface Scenario {
    id?: string,
    name?: string,
    description?: string,
    household?: any,
}

export class ScenarioBuilder {
    private _scenario: Scenario;

    constructor() {
    }

    withId(id: string) {
        this._scenario.id = id;
        return this;
    }

    withName(name: string) {
        this._scenario.name = name;
        return this;
    }

    withDescription(description: string) {
        this._scenario.description = description;
        return this;
    }

    public build() {
        return this._scenario;
    }
}