import { Goal } from 'src/app/model/goal';
import { Input, Output, EventEmitter, Injectable } from '@angular/core';
import { FundingSource } from 'src/app/model/goal/funding.sources.class';
import { TypedGoalService } from 'src/app/services/typed.goal.service';
import { Scenario } from 'src/app/model';

@Injectable()
export class FundingSourceBaseComponent {
  @Input() goal: Goal;
  @Input() fundingSource: FundingSource;
  @Input() scenario: Scenario;

  @Output() updateGoal: EventEmitter<any>;

  constructor(typedGoalService: TypedGoalService) {}

  deleteSource() {
    this.goal.fundingSources = this.goal.fundingSources.filter(
      (s) => s.class !== this.fundingSource.class
    );
  }
}
