/* "Barrel" of Http Interceptors */
import { AuthInterceptor } from './auth.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BasicInterceptor } from './basic.interceptor';
import { TokenExpirationInterceptor } from './token.expiration.interceptor';
import { IressAPIInterceptor } from './iress-api.interceptor';

/** Http interceptor providers in outside-in order */
export const HttpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenExpirationInterceptor,
    multi: true,
  },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: BasicInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: IressAPIInterceptor, multi: true },
];
