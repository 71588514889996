import { Injectable } from '@angular/core';
import { HandleError, HttpErrorHandler } from './http-error-handler.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PageableResponse, PageableRequest, Person } from '../model';
import { AppConfigService } from './app.config.service';

@Injectable()
export class PersonsService {
  private getUrl = (scenarioId, id = '') =>
    `${
      this.configService.getConfig().apiUrl
    }/scenarios/${scenarioId}/persons/${id}`;
  private getPrimaryUrl = (scenarioId) =>
    `${
      this.configService.getConfig().apiUrl
    }/scenarios/${scenarioId}/persons/primary/`;
  private getPartnerUrl = (scenarioId) =>
    `${
      this.configService.getConfig().apiUrl
    }/scenarios/${scenarioId}/persons/partner/`;
  private getChildrenUrl = (scenarioId, id = '') =>
    `${
      this.configService.getConfig().apiUrl
    }/scenarios/${scenarioId}/persons/children/${id}`;
  private getParentsUrl = (scenarioId, id = '') =>
    `${
      this.configService.getConfig().apiUrl
    }/scenarios/${scenarioId}/persons/parents/${id}`;

  private handleError: HandleError;

  constructor(
    private http: HttpClient,
    private configService: AppConfigService,
    private httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('personsService');
  }

  query(
    scenarioId: string,
    pageable: PageableRequest
  ): Observable<PageableResponse<Person>> {
    return this.http
      .get<PageableResponse<Person>>(this.getUrl(scenarioId), {
        params: pageable,
      })
      .pipe(catchError(this.handleError('query persons')));
  }

  get(scenarioId: string, id: string): Observable<Person> {
    return this.http
      .get<Person>(this.getUrl(scenarioId, id))
      .pipe(catchError(this.handleError('get person')));
  }

  update(scenarioId: string, person: Person): Observable<Person> {
    return this.http
      .put<Person>(this.getUrl(scenarioId, person.id), person)
      .pipe(catchError(this.handleError('update person')));
  }

  delete(scenarioId: string, id: string): Observable<any> {
    return this.http
      .delete<Person>(this.getUrl(scenarioId, id))
      .pipe(catchError(this.handleError('delete person')));
  }

  // Primary
  getPrimary(scenarioId: string): Observable<Person> {
    return this.http
      .get<Person>(this.getPrimaryUrl(scenarioId))
      .pipe(catchError(this.handleError('get primary')));
  }

  updatePrimary(scenarioId: string, person: Person): Observable<Person> {
    return this.http
      .put<Person>(this.getPrimaryUrl(scenarioId), person)
      .pipe(catchError(this.handleError('update primary')));
  }

  // Partner
  getPartner(scenarioId: string): Observable<Person> {
    return this.http
      .get<Person>(this.getPartnerUrl(scenarioId))
      .pipe(catchError(this.handleError('get partner')));
  }

  createPartner(scenarioId: string, person: Person): Observable<Person> {
    return this.http
      .post<Person>(this.getPartnerUrl(scenarioId), person)
      .pipe(catchError(this.handleError('create partner')));
  }

  updatePartner(scenarioId: string, person: Person): Observable<Person> {
    return this.http
      .put<Person>(this.getPartnerUrl(scenarioId), person)
      .pipe(catchError(this.handleError('update partner')));
  }

  deletePartner(scenarioId: string): Observable<any> {
    return this.http
      .delete<Person>(this.getPartnerUrl(scenarioId))
      .pipe(catchError(this.handleError('delete partner')));
  }

  // Children
  queryChildren(
    scenarioId: string,
    pageable: PageableRequest
  ): Observable<PageableResponse<Person>> {
    return this.http
      .get<PageableResponse<Person>>(this.getChildrenUrl(scenarioId), {
        params: pageable,
      })
      .pipe(catchError(this.handleError('query children')));
  }

  getChild(scenarioId: string, id: string): Observable<Person> {
    return this.http
      .get<Person>(this.getChildrenUrl(scenarioId, id))
      .pipe(catchError(this.handleError('get child')));
  }

  createChild(scenarioId: string, child: Person): Observable<Person> {
    return this.http
      .post<Person>(this.getChildrenUrl(scenarioId), child)
      .pipe(catchError(this.handleError('create child')));
  }

  updateChild(scenarioId: string, child: Person): Observable<Person> {
    return this.http
      .put<Person>(this.getChildrenUrl(scenarioId, child.id), child)
      .pipe(catchError(this.handleError('update child')));
  }

  deleteChild(scenarioId: string, id: string): Observable<any> {
    return this.http
      .delete<Person>(this.getChildrenUrl(scenarioId, id))
      .pipe(catchError(this.handleError('delete child')));
  }

  // Parents
  queryParents(
    scenarioId: string,
    pageable: PageableRequest
  ): Observable<PageableResponse<Person>> {
    return this.http
      .get<PageableResponse<Person>>(this.getParentsUrl(scenarioId), {
        params: pageable,
      })
      .pipe(catchError(this.handleError('query parents')));
  }

  getParent(scenarioId: string, id: string): Observable<Person> {
    return this.http
      .get<Person>(this.getParentsUrl(scenarioId, id))
      .pipe(catchError(this.handleError('get parent')));
  }

  createParent(scenarioId: string, parent: Person): Observable<Person> {
    return this.http
      .post<Person>(this.getParentsUrl(scenarioId), parent)
      .pipe(catchError(this.handleError('create parent')));
  }

  updateParent(scenarioId: string, parent: Person): Observable<Person> {
    return this.http
      .put<Person>(this.getParentsUrl(scenarioId, parent.id), parent)
      .pipe(catchError(this.handleError('update parent')));
  }

  deleteParent(scenarioId: string, id: string): Observable<any> {
    return this.http
      .delete<Person>(this.getParentsUrl(scenarioId, id))
      .pipe(catchError(this.handleError('delete parent')));
  }
}
