export interface TermsOfUse {
  active: boolean;
  clientId: string;
  createdDate: number[];
  htmlContent: string;
  id: string;
  mdContent: string;
  rawContent: string;
  revision: number;
  updatedDate: number[];
  version: string;
}
