import {
    LoginResponse, LoginData, LoginRequest, ResetPasswordData, RegistrationData, RegistrationResponse, RegistrationRequest, UserAccount
} from './../model';

import { Injectable } from '@angular/core';
import { Constants } from './../app.constants';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { HttpErrorHandler, HandleError } from './http-error-handler.service';
import { GuestUpgradeRequest } from '../model/auth/guest.upgrade.request';
import { AppConfigService } from './app.config.service';
import * as moment from 'moment/moment';

@Injectable()
export class AuthService {
    private getUrl = () => this.configService.getConfig().apiUrl + '/uaa';
    private handleError: HandleError;

    constructor(
        private http: HttpClient,
        private configService: AppConfigService,
        private httpErrorHandler: HttpErrorHandler
    ) {
        this.handleError = httpErrorHandler.createHandleError('loginService');
    }

    // isLoggedIn(): boolean {
    //     if (localStorage.getItem(Constants.LOCAL_STORAGE_AUTH_TOKEN)) {
    //         return moment().isBefore(this.getExpiration());
    //     }
    //     return false;
    // }

    //hasExpired(): boolean {
    //     if (localStorage.getItem(Constants.LOCAL_STORAGE_AUTH_TOKEN)) {
    //         return moment().isAfter(this.getExpiration());
    //     }
    //     return false;
    // }

    getExpiration() {
        const expiration = localStorage.getItem(Constants.LOCAL_STORAGE_TOKEN_EXPIRATION);
        const expiresAt = JSON.parse(expiration);
        return moment.unix(expiresAt);
    }

    // getAuthorizationToken(): string {
    //     return localStorage.getItem(Constants.LOCAL_STORAGE_AUTH_TOKEN);
    // }

    getRefreshToken(): string {
        return localStorage.getItem(Constants.LOCAL_STORAGE_REFRESH_TOKEN);
    }

    register(registrationData: RegistrationData): Observable<RegistrationResponse> {
        const registrationRequest: RegistrationRequest = {
          emailAddress: registrationData.emailAddress,
          password: registrationData.password
        };
        return this.http.post<RegistrationResponse>(this.getUrl() + '/register', registrationRequest)
            .pipe(catchError(this.handleError('auth')));
    }

    reset(resetData: ResetPasswordData): Observable<any> {
        return this.http.post<any>(this.getUrl() + '/reset', resetData)
            .pipe(catchError(this.handleError('reset')));
    }

    login(loginRequest: LoginRequest): Observable<LoginResponse> {
        return this.http.post<LoginResponse>(this.getUrl() + '/login', loginRequest)
            .pipe(catchError(this.handleError('auth')));
    }

    guest(): Observable<LoginResponse> {
        return this.http.get<LoginResponse>(this.getUrl() + '/guest/login')
            .pipe(catchError(this.handleError('guest')));
    }

    // upgradeGuest(request: GuestUpgradeRequest): Observable<UserAccount> {
    //     return this.http.post<UserAccount>(this.getUrl() + '/guest/upgrade', request, {
    //         headers: {
    //             'Authorization': 'Bearer ' + this.getAuthorizationToken(),
    //             'Content-type': 'application/json'
    //         }
    //     }).pipe(catchError(this.handleError('guest upgrade')));
    // }

    // logout() {
    //     return of({})
    //         .pipe(
    //             map(() => {
    //                 localStorage.removeItem(Constants.LOCAL_STORAGE_AUTH_TOKEN);
    //                 localStorage.removeItem(Constants.LOCAL_STORAGE_USERNAME);
    //                 localStorage.removeItem(Constants.LOCAL_STORAGE_USER_ROLES);
    //                 localStorage.removeItem(Constants.LOCAL_STORAGE_TOKEN_EXPIRATION);
    //                 localStorage.removeItem(Constants.LOCAL_STORAGE_MONEYHUB_TOKEN);
    //                 localStorage.removeItem(Constants.LOCAL_STORAGE_MONEYHUB_ACCOUNTS);
    //                 localStorage.removeItem(Constants.LOCAL_STORAGE_MONEYHUB_USER);
    //                 localStorage.removeItem(Constants.LOCAL_STORAGE_MONEYHUB_TOKEN);
    //             })
    //         );
    // }

    refresh() {
        return this.http.post<LoginResponse>(`${this.getUrl()}/token/refresh`, { refreshToken: this.getRefreshToken() }, {
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json;charset=UTF-8',
            }
        })
    }
}

export interface JwtContent {
    sub: string;
    auth: string;
    iss: string;
    exp: number;
    iat: number;
}
