import { DateRangeType, Owner, Frequency } from '../enums';
import { GoalProperties } from './goal.properties.class';
import { FundingSource } from './funding.sources.class';
import { Goal, BuyAHouseGoal, BuyACarGoal, GetMarriedGoal, GoToSchoolGoal, HaveAChildGoal, RetirementGoal, UserDefinedGoal, TravelGoal, SabbaticalGoal, EliminateDebtGoal, RecurringUserDefinedGoal } from './goal.class';

export class GoalBuilder {

    private _goal: Goal;

    public buyAHouse() {
        this._goal = new BuyAHouseGoal();
        return this;
    }

    public buyACar() {
        this._goal = new BuyACarGoal();
        return this;
    }

    public getMarried() {
        this._goal = new GetMarriedGoal();
        return this;
    }

    public goToSchool() {
        this._goal = new GoToSchoolGoal();
        return this;
    }

    public haveAChild() {
        this._goal = new HaveAChildGoal();
        return this;
    }

    public retirement() {
        this._goal = new RetirementGoal();
        return this;
    }

    public travel() {
        this._goal = new TravelGoal();
        return this;
    }

    public eliminateDebt() {
        this._goal = new EliminateDebtGoal();
        return this;
    }

    public sabbatical() {
        this._goal = new SabbaticalGoal();
        return this;
    }

    public userDefined() {
        this._goal = new UserDefinedGoal();
        return this;
    }

    public recurringUserDefined(recurringAmount: number) {
        this._goal = new RecurringUserDefinedGoal(recurringAmount);
        return this;
    }

    public withName(name: string) {
        this._goal.name = name;
        return this;
    }

    public withOwner(owner: Owner) {
        this._goal.owner = owner;
        return this;
    }

    public withAmount(amount: number) {
        this._goal.minimumAmount = amount;
        this._goal.desiredAmount = amount;
        return this;
    }

    public withFrequency(frequency: Frequency) {
        this._goal.frequency = frequency;
        return this;
    }

    public withStartDate(date: string) {
        this._goal.startDate = date;
        return this;
    }

    public withEndDate(date: string) {
        this._goal.endDate = date;
        return this;
    }

    public withIcon(icon: string) {
        this._goal.icon = icon;
        return this;
    }

    public inDate(date: string) {
        this._goal.startDate = date;
        this._goal.endDate = date;
        return this;
    }

    public withProperties(goalProperties: GoalProperties) {
        this._goal.properties = goalProperties;
        return this;
    }

    public withDescription(text: string) {
        this._goal.description = text;
        return this;
    }

    public startsOn(startsOn: DateRangeType) {
        this._goal.startsOn = startsOn;
        return this;
    }
    public endsOn(endsOn: DateRangeType) {
        this._goal.endsOn = endsOn;
        return this;
    }

    public withFundingSources(fundingSources: Array<FundingSource>) {
        this._goal.fundingSources = fundingSources;
        return this;
    }

    public build() {
        return this._goal;
    }

}
