import { Component, Input, Output, EventEmitter, signal } from '@angular/core';
import { Constants } from 'src/app/app.constants';
import { Expense } from 'src/app/model/household/expense/expense';

interface IViewExpense extends Expense {
  id: string;
  name: string;
  isFromLinkedAccounts: boolean;
  nonDiscretionaryPercentage: number;
}

@Component({
  selector: 'app-expense',
  templateUrl: './expense.component.html',
  styleUrls: ['./expense.component.scss'],
})
export class ExpenseComponent {
  @Input() expense: IViewExpense;
  @Input() currency: string;

  @Output() update = new EventEmitter<Expense>();
  @Output() remove = new EventEmitter<Expense>();
  @Output() updateLinked = new EventEmitter<Expense>();
  @Output() removeLinked = new EventEmitter<Expense>();

  isEdit = signal(false);
  private _isDirty = false;

  onUpdate() {
    this._isDirty = true;
    // this.update.emit(this.expense);
  }

  onRemove() {
    this.remove.emit(this.expense);
  }

  onDismiss() {
    this._isDirty && this.update.emit(this.expense);
    this.isEdit.set(false);
  }

  onUpdateLinked() {
    this.updateLinked.emit(this.expense);
  }

  onRemoveLinked() {
    this.removeLinked.emit(this.expense);
  }

  getFrequencyLabel(frequency) {
    return Constants.FREQUENCY_LABELS.find((f) => f.id === frequency).label;
  }

  get frequencies() {
    return Constants.SELECTABLE_FREQUENCIES;
  }
}
