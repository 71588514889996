import { HealthStatus, JobType, EducationLevel } from './model/household/person';
import { PageableRequestBuilder } from './model/common/pageable.request';
import { RepaymentType, Frequency, DateRangeType } from './model/enums';
import { CurrencyMaskConfig } from 'ng2-currency-mask';

export class Constants {

    static readonly MINIMUM_LIVES_ALIVE_TO_SHOW = 30;

    static readonly ROUTE_LOGIN = 'auth/login';

    static readonly PAGE_ALL = new PageableRequestBuilder()
        .page(0)
        .size(2000)
        .build();

    static BUBELE_POPUP_WAIT_TIME = 25000;

    static readonly LOCALE_CONFIG = {
        US: { currencySymbol: '$', currency: 'USD', isaLabel: 'Tax Adv.', giaLabel: 'Taxable', assetTypes: [
            { label: '401K', wrapper: 'ESDCS', icon: 'icon-retirement' },
            { label: 'IRA', wrapper: 'sipp', icon: 'icon-retirement' },
            { label: 'Roth 401k', wrapper: 'isa', icon: 'icon-retirement' },
            { label: 'Roth IRA', wrapper: 'isa', icon: 'icon-retirement' },
            { label: '529 Plan', wrapper: 'isa', icon: 'icon-cap' },
            { label: 'Taxable', wrapper: 'general_investment_account', icon: 'icon-money' }
        ]},
        UK: { currencySymbol: '£', currency: 'GBP', isaLabel: 'ISA', giaLabel: 'GIA', assetTypes: [
            { label: 'ISA', wrapper: 'isa', icon: 'icon-money' },
            { label: 'Private Pension', wrapper: 'sipp', icon: 'icon-retirement' },
            { label: 'Employer Pension', wrapper: 'ESDCS', icon: 'icon-retirement' },
            { label: 'Other', wrapper: 'general_investment_account', icon: 'icon-money' }
        ] }
    };

    static readonly LoginEvent = 'login'

    static readonly ERROR = 'error';
    static readonly SUCCESS = 'success';
    static readonly WARNING = 'warning';

    static readonly WISH_PRIORITY = 1;
    static readonly WANT_PRIORITY = 5;
    static readonly NEED_PRIORITY = 10;

    static readonly PREFERENCE_KEY_CURRENT = 'is.current';
    static readonly PREFERENCE_KEY_INITIALIZED = 'is.initialized';

    static readonly LOCAL_STORAGE_RISK_HOUSEHOLD_RISK = 'risk_household_risk';
    static readonly LOCAL_STORAGE_RISK_PROFILE_BOUNDARY_TEXT = 'risk_profile_boundary_text';
    static readonly LOCAL_STORAGE_RISK_PROFILE_SCORE = 'risk_profile_score';
    static readonly LOCAL_STORAGE_RISK_PROFILE_CONTENT = 'risk_profile_content';
    static readonly LOCAL_STORAGE_LOCALE = 'locale';
    static readonly LOCAL_STORAGE_AUTH_TOKEN = 'auth_token';
    static readonly LOCAL_STORAGE_REFRESH_TOKEN = 'refresh_token';
    static readonly LOCAL_STORAGE_USERNAME = 'username';
    static readonly LOCAL_STORAGE_CLIENT_ID = 'client_id';
    static readonly LOCAL_STORAGE_TOKEN_EXPIRATION = 'auth_token_exp';
    static readonly LOCAL_STORAGE_USER_ROLES = 'roles';
    static readonly LOCAL_STORAGE_USER_EMAIL = 'email';
    static readonly LOCAL_STORAGE_REDIRECT_URL = 'redirect-url';
    static readonly LOCAL_STORAGE_MONEYHUB_USER = 'moneyhub-user';
    static readonly LOCAL_STORAGE_MONEYHUB_TOKEN = 'moneyhub-token';
    static readonly LOCAL_STORAGE_MONEYHUB_STATE = 'moneyhub-state';
    static readonly LOCAL_STORAGE_MONEYHUB_CODE = 'moneyhub-code';
    static readonly LOCAL_STORAGE_MONEYHUB_AUTH_REQUEST_ID = 'moneyhub-auth-request-id';
    static readonly LOCAL_STORAGE_MONEYHUB_AUTH_REQUEST_REDIRECT_URL = 'moneyhub-auth-request-redirect-url';
    static readonly LOCAL_STORAGE_MONEYHUB_AUTH_REQUEST_STATE = 'moneyhub-auth-request-state';
    static readonly LOCAL_STORAGE_MONEYHUB_ID_TOKEN = 'moneyhub-id-token';
    static readonly LOCAL_STORAGE_MONEYHUB_ACCOUNTS = 'moneyhub-accounts';
    static readonly LOCAL_STORAGE_MONEYHUB_CONNECTIONS = 'moneyhub-connections';
    static readonly LOCAL_STORAGE_MONEYHUB_REDIRECT_SUCCESS = 'moneyhub-redirect-success';
    static readonly LOCAL_STORAGE_MONEYHUB_REDIRECT_ERROR = 'moneyhub-redirect-error';
    static readonly LOCAL_STORAGE_BUBELE_REGISTERED = 'bubele-registered';

    static readonly MAX_DURATION = "120";
    static readonly SHORT_TERM_DURATION = "5";
    static readonly SOURCE_MONEYHUB = 'moneyhub';

    static readonly PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()+-])[a-zA-Z0-9!@#$%^&*()+-]{8,15}$/;
    static readonly EMAIL_REGEX = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';

    // todo: make this configurable per client: WA-256
    static readonly HEALTH_STATUSES = [
        { key: 'EXCELLENT', label: 'Excellent' },
        { key: 'GOOD', label: 'Good' },
        { key: 'AVERAGE', label: 'Average' },
        { key: 'POOR', label: 'Poor' }
    ];

    static readonly JOB_TYPES = [
        { key: 'SEDENTARY', label: 'Sedentary' },
        { key: 'ACTIVE', label: 'Active' },
        { key: 'PHYSICAL', label: 'Physical' }
    ];

    static readonly NET_WORTH_TOTAL = 'NET_WORTH_TOTAL';
    static readonly TOTAL_ALIVE = 'TOTAL_ALIVE';
    static readonly TOTAL_LIVES = 'TOTAL_LIVES';
    static readonly WILL_I_HAVE_MONEY = 'WILL_I_HAVE_MONEY';

    static readonly IMPROVEMENTS_SCENARIO_NAME = 'improvements';

    static readonly ASSET_ALLOCATION_PRESETS_PREFERENCE_NAME = 'modelling.default.data.asset.allocation.presets.matrix';
    static readonly DEFAULT_DATA_ASSET_ALLOCATION_MATRIX_PREFERENCE_NAME = 'modelling.default.data.asset.allocation.matrix';
    static readonly ID_ASSET_ALLOCATION_MATRIX_PREFERENCE_NAME = "ID.assetAllocationMatrix";
    static readonly SAVINGS_SEQUENCE_PREFERENCE_NAME = 'simulation.parameter.savings.sequence';
    static readonly USER_PROPERTY_KEY_MONEYHUB_USER_ID = 'moneyhub.user.id';
    static readonly USER_PROPERTY_KEY_MONEYHUB_USER_CREATION_DATE = 'moneyhub.user.creation.date';

    static readonly DEFAULT_SOURCE = 'web app';

    static readonly DEFAULT_PRIMARY_HEALTH_STATUS = HealthStatus.EXCELLENT;
    static readonly DEFAULT_PRIMARY_HEALTH_STATUS_IDΧ = 3;
    static readonly DEFAULT_BUY_HOUSE_DOWNPAYMENT_PERCENTAGE = 0.3;
    static readonly DEFAULT_BUY_HOUSE_DOWNPAYMENT_RATE_INCREASE = 0.01;
    static readonly RENT_VS_MORTGAGE_SPREAD_OVER_GROWTH_RATE = 0;
    static readonly DEFAULT_RENT_VS_MORTGAGE_ANNUAL_RENT = 12_000;
    static readonly DEFAULT_PARTNER_NAME = 'My partner';
    static readonly DEFAULT_PARTNER_HEALTH_STATUS = HealthStatus.EXCELLENT;
    static readonly DEFAULT_PARTNER_EDUCATION_LEVEL = EducationLevel.SECONDARY_EDUCATION;
    static readonly DEFAULT_PARTNER_HEALTH_STATUS_IDX = 3;
    static readonly DEFAULT_PARTNER_JOB_TYPE = JobType.ACTIVE;
    static readonly DEFAULT_CHILD_EDUCATION_LEVEL = EducationLevel.NO_EDUCATION;

    static readonly DEFAULT_CHILD_NAME = 'My child';
    static readonly DEFAULT_CHILD_YEAR_OF_BIRTH = new Date().getFullYear() - 2;

    static readonly DEFAULT_CURRENCY = 'GBP';
    static readonly DEFAULT_COUNTRY = 'UK';
    static readonly DEFAULT_EARNED_INCOME_AMOUNT = 50_000;
    static readonly DEFAULT_UNEARNED_INCOME_AMOUNT = 24_000;
    static readonly DEFAULT_RENT_INCOME_ANNUAL_YIELD_PERCENTAGE = 0.02;

    static readonly DEFAULT_BUY_TO_LET_SURPLUS_AMOUNT = 0;

    static readonly DEFAULT_STARTS_ON = DateRangeType.USER_DEFINED;
    static readonly DEFAULT_ENDS_ON = DateRangeType.ON_RETIREMENT;
    static readonly DEFAULT_LIFE_INSURANCE_NAME = 'My term life insurance';
    static readonly DEFAULT_LIFE_INSURANCE_NAME_PARTNER = "Partner's term life insurance";
    static readonly DEFAULT_DISABILITY_INSURANCE_NAME = 'My disability insurance';
    static readonly DEFAULT_DISABILITY_INSURANCE_NAME_PARTNER = "Partner's disability insurance";
    static readonly DEFAULT_CRITICAL_ILLNESS_INSURANCE_NAME = 'My critical illness insurance';
    static readonly DEFAULT_CRITICAL_ILLNESS_INSURANCE_NAME_PARTNER = "Partner's critical illness insurance";
    static readonly DEFAULT_HEALTH_INSURANCE_NAME = 'My health insurance';
    static readonly DEFAULT_HEALTH_INSURANCE_NAME_PARTNER = "Partner's health insurance";
    static readonly DEFAULT_MORTGAGE_LIFE_INSURANCE_NAME = 'My Mortgage insurance';
    static readonly DEFAULT_MORTGAGE_LIFE_INSURANCE_NAME_PARTNER = "Partner's Mortgage insurance";
    static readonly DEFAULT_INSURANCE_PAYOUT = 10_000;
    static readonly DEFAULT_INSURANCE_FREQUENCY = 'ONE_OFF';
    static readonly DEFAULT_INSURANCE_DISABILITY_INCOME_PERCENTAGE = 0.5;
    static readonly DEFAULT_UNSECURED_LIABILITY_NAME = 'My debt';

    static readonly DEFAULT_LIVING_EXPENSE_MONTHLY_AMOUNT = 2_000;
    static readonly DEFAULT_SURVIVOR_ADJUSTMENT_PERCENTAGE = 0.75;

    static readonly DEFAULT_FINANCIAL_ASSET_VALUE = 50_000;
    static readonly DEFAULT_FINANCIAL_ASSET_NAME = 'My asset';
    static readonly DEFAULT_FINANCIAL_ASSET_PARTNER_NAME = 'Partner\'s asset';
    static readonly DEFAULT_FINANCIAL_ASSET_FROM_REMORTGAGE_NAME = 'My cash from remortgage';
    static readonly DEFAULT_FINANCIAL_ASSET_WRAPPER = 'general_investment_account';
    static readonly DEFAULT_FINANCIAL_ASSET_CLASS = 'WGOV';
    static readonly CASH_FINANCIAL_ASSET_CLASS = 'cash';
    static readonly DEFAULT_FINANCIAL_PORTFOLIO_CASH_FEES = 0.0;
    static readonly DEFAULT_FINANCIAL_PORTFOLIO_INVESTMENT_FEES = 0.01;

    static readonly DEFAULT_RESIDENTIAL_PROPERTY_VALUE = 220_000;
    static readonly DEFAULT_RESIDENTIAL_PROPERTY_NAME = 'My residential property';

    static readonly DEFAULT_INVESTMENT_PROPERTY_VALUE = 220_000;
    static readonly DEFAULT_INVESTMENT_PROPERTY_NAME = 'My investment property';

    static readonly DEFAULT_UNSECURED_LIABILITY_REPAYMENT_TYPE = 'PRINCIPAL_AMORTIZATION';
    static readonly DEFAULT_UNSECURED_LIABILITY_AMOUNT = 20_000;

    static readonly DEFAULT_UNSECURED_LIABILITY_CAR_LOAN_AMOUNT = 10_000;
    static readonly DEFAULT_UNSECURED_LIABILITY_CREDIT_CARD_AMOUNT = 2_500;
    static readonly DEFAULT_UNSECURED_LIABILITY_STUDENT_LOAN_AMOUNT = 25_000;
    static readonly DEFAULT_UNSECURED_LIABILITY_OTHER_AMOUNT = 10_000;

    static readonly DEFAULT_MORTGAGE_IS_INTEREST_ONLY = false;
    static readonly DEFAULT_MORTGAGE_IS_FIXED_RATE = true;
    static readonly DEFAULT_MORTGAGE_NAME = 'My mortgage';

    static readonly DEFAULT_PENSION_EXPENSE_WRAPPER = 'ESDCS';
    static readonly DEFAULT_PENSION_EXPENSE_NAME = 'Pension expense';
    static readonly DEFAULT_PENSION_EXPENSE_INCOME_PERCENT_SAVED = 0.05;
    static readonly DEFAULT_PENSION_INCOME_PERCENT_SAVED = 0.05;

    static readonly DEFAULT_RENT_EXPENSE_NAME = 'Rent expense';
    static readonly DEFAULT_RENT_EXPENSE_AMOUNT = 1_000;

    static readonly DEFAULT_GOAL_ELIMINATE_DEBT_NAME = 'Eliminate debt';
    static readonly DEFAULT_GOAL_PRIORITY = 5;
    static readonly DEFAULT_GOAL_SABBATICAL_PERCENTAGE_EXPENDITURE = 0.75;
    static readonly DEFAULT_UNEARNED_INCOME_NAME = 'My other income';
    static readonly DEFAULT_RENT_INCOME_NAME = 'My rent income';
    static readonly DEFAULT_EXPENSE_NAME = 'My expense';

    static readonly DEFAULT_SELL_PROPERTY_INCOME_NAME = 'Sold property income';

    static readonly DEFAULT_LIQUID_ASSETS_FUNDING_SOURCE_NAME = 'Liquid assets';
    static readonly DEFAULT_OTHER_LOAN_FUNDING_SOURCE_NAME = 'Loan';
    static readonly DEFAULT_SAVE_FUNDING_SOURCE_NAME = 'Save in years';
    static readonly DEFAULT_BANK_OF_MUM_AND_DAD_FUNDING_SOURCE_NAME = 'Bank of mum and dad';
    static readonly DEFAULT_STUDENT_LOAN_FUNDING_SOURC_NAME = 'Student loan';
    static readonly DEFAULT_RESIDENTIAL_PROPERTY_FUNDING_SOURCE_NAME = 'Residential property';
    static readonly DEFAULT_INVESTMENT_PROPERTY_FUNDING_SOURCE_NAME = 'Investment property';
    static readonly DEFAULT_FUTURE_INVESTMENT_PROPERTY_FUNDING_SOURCE_NAME = 'Future investment property';
    static readonly DEFAULT_FUTURE_RESIDENTIAL_PROPERTY_FUNDING_SOURCE_NAME = 'Future residential property';
    static readonly DEFAULT_MORTGAGE_FUNDING_SOURCE_NAME = 'Mortgage';
    static readonly DEFAULT_MORTGAGE_FUNDING_SOURCE_INTEREST_RATE = 0.03;
    static readonly DEFAULT_STUDENT_LOAN_AMOUNT_PERCENTAGE = 0.5;
    static readonly DEFAULT_OTHER_LOAN_INTEREST_RATE = 0.05;
    static readonly DEFAULT_OTHER_LOAN_TERM_YEARS = 5;
    static readonly DEFAULT_OTHER_LOAN_AMOUNT_PERCENTAGE = 0.5;
    static readonly DEFAULT_BANK_OF_MUM_AND_DAD_AMOUNT_PERCENTAGE = 0.5;
    static readonly DEFAULT_MORTGAGE_FUNDING_SOURCE_AMOUNT_PERCENTAGE = 0.7;
    static readonly DEFAULT_MORTGAGE_FUNDING_SOURCE_YEARS = 15;
    static readonly DEFAULT_SAVE_FUNDING_SOURCE_YEARS = 5;

    static readonly MINIMUM_ACHIEVABILITY_VS_DIAGNOSTICS_DIFF = 0.05;

    static readonly DEFAULT_DEBT_MORTGAGE = {
        LTV: 0.8,
        term: 20,
        rate: 0.04
    };

    static readonly DEFAULT_DEBT_CAR_LOAN = {
        LTV: 0.75,
        term: 5,
        rate: 0.05,
        repaymentType: RepaymentType.PRINCIPAL_AMORTIZATION,
    };

    static readonly DEFAULT_DEBT_CREDIT_CARD = {
        LTV: 0.75,
        term: 2,
        rate: 0.15,
        repaymentType: RepaymentType.PRINCIPAL_AMORTIZATION,
    };

    static readonly DEFAULT_DEBT_STUDENT_LOAN = {
        LTV: 0.75,
        term: 15,
        rate: 0.065,
        repaymentType: RepaymentType.PRINCIPAL_AMORTIZATION,
    };

    static readonly DEFAULT_DEBT_OTHER = {
        LTV: 0.75,
        term: 5,
        rate: 0.08,
        repaymentType: RepaymentType.PRINCIPAL_AMORTIZATION,
    };

    static readonly DEFAULT_ACHIEVABILITY_BAND_ID_WORST = '1';
    static readonly DEFAULT_ACHIEVABILITY_BAND_ID_LIKELY = '2';

    static readonly DEFAULT_RESULTS_ACHIEVABILITY_THRESHOLDS = [0, 60, 90];

    public static readonly DIAGNOSTICS_INCOME_PART_TIME_AFTER_RETIREMENT_FACTOR = 0.50;
    public static readonly DIAGNOSTICS_INCOME_PART_TIME_MAX_AGE = 80;
    public static readonly DIAGNOSTICS_EXPENSE_REDUCE_FACTOR = 0.90;
    public static readonly DIAGNOSTICS_PENSION_CONTRIBUTION_WRAPPER = 'ESDCS';
    public static readonly DIAGNOSTICS_PENSION_CONTRIBUTION_INITIAL_PERCENTAGE = 0.03;
    public static readonly DIAGNOSTICS_PENSION_CONTRIBUTION_INCREASE_PERCENTAGE = 0.01;
    public static readonly DEFAULT_PENSION_INCOME_MAX_CONTRIBUTION = 0;

    public static readonly DIAGNOSTICS_INCOME_INCREASE_FACTOR = 1.1;
    public static readonly DIAGNOSTICS_INSURANCE_INCREASE_FACTOR = 1.5;
    public static readonly DIAGNOSTICS_GOAL_POSTPONE_YEARS = 2;

    // new
    public static readonly DIAGNOSTICS_GOAL_EXPENSE_REDUCE_FACTOR = 0.9;
    public static readonly DIAGNOSTICS_GOAL_PROPERTY_RELATED_EXPENSE_REDUCE_FACTOR = 0.8;
    public static readonly DIAGNOSTICS_GOAL_MOVE_IT_TO_THE_FUTURE_YEARS = 1;
    public static readonly DIAGNOSTICS_GOAL_RETIREMENT_MOVE_IT_TO_THE_FUTURE_YEARS = 3;
    public static readonly DIAGNOSTICS_GOAL_REMOVE_PREVIOUS_GOAL_IN_YEARS = 5;
    public static readonly DIAGNOSTICS_GOAL_WRAPPER_PRESET_INCREASE = 1;
    public static readonly DIAGNOSTICS_GOAL_WRAPPER_PRESET_DECREASE = 1;

    // new keys
    public static readonly DIAGNOSTICS_METADATA_ITEM_INCOME_EARNED_ADD = "INCOME_EARNED_ADD";
    public static readonly DIAGNOSTICS_METADATA_ITEM_INCOME_EARNED_INCREASE = "INCOME_EARNED_INCREASE";
    public static readonly DIAGNOSTICS_METADATA_ITEM_WORK_AFTER_RETIREMENT_MAX_AGE = 'WORK_PART_TIME_AFTER_RETIREMENT_MAX_AGE';
    public static readonly DIAGNOSTICS_METADATA_ITEM_WORK_AFTER_RETIREMENT_PERCENTAGE = 'WORK_PART_TIME_AFTER_RETIREMENT_PERCENTAGE';
    public static readonly DIAGNOSTICS_METADATA_ITEM_EXPENSE_AMOUNT_PERCENTAGE = "AMOUNT_PERCENTAGE ";
    public static readonly DIAGNOSTICS_METADATA_ITEM_EXPENSE_NEW_PROPERTY_VALUE = "NEW_PROPERTY_VALUE";
    public static readonly DIAGNOSTICS_METADATA_ITEM_BUY_TO_LET_SURPLUS = "BUY_TO_LET_SURPLUS";
    public static readonly DIAGNOSTICS_METADATA_ITEM_HEALTH_STATUS_INDEX = "HEALTH_STATUS_INDEX";
    public static readonly DIAGNOSTICS_METADATA_ITEM_GOAL_BUY_A_HOUSE_DOWNPAYMENT = "BUY_A_HOUSE_DOWNPAYMENT";
    public static readonly DIAGNOSTICS_METADATA_ITEM_BUY_HOUSE_DOWNPAYMENT_MORTGAGE_RATE = "BUY_A_HOUSE_DOWNPAYMENT_INCREASE_MORTGAGE_RATE";
    public static readonly DIAGNOSTICS_METADATA_ITEM_BUY_HOUSE_MORTGAGE = "BUY_A_HOUSE_MORTGAGE";

    public static readonly DIAGNOSTICS_METADATA_ITEM_RENT_VS_MORTGAGE_AMOUNT = "RENT";
    public static readonly DIAGNOSTICS_METADATA_ITEM_GOAL_REDUCE_AMOUNT_PERCENTAGE: 'AMOUNT_PERCENTAGE';
    public static readonly DIAGNOSTICS_METADATA_ITEM_GOAL_REMOVE_GOALS_YEARS_BEFORE = "REMOVE_GOALS_YEARS_BEFORE";
    public static readonly DIAGNOSTICS_METADATA_ITEM_GOAL_SAVE_IN_YEARS = "SAVE_IN_YEARS";
    public static readonly DIAGNOSTICS_METADATA_ITEM_GOAL_BORROW_TYPE = "BORROW_TYPE";
    public static readonly DIAGNOSTICS_METADATA_ITEM_GOAL_BORROW_TYPE_NONE = "NONE";
    public static readonly DIAGNOSTICS_METADATA_ITEM_GOAL_BORROW_AMOUNT = "BORROW_AMOUNT";
    public static readonly DIAGNOSTICS_METADATA_ITEM_GOAL_BORROW_TERM = "BORROW_TERM";
    public static readonly DIAGNOSTICS_METADATA_ITEM_GOAL_BORROW_RATE = "BORROW_RATE";
    public static readonly DIAGNOSTICS_METADATA_ITEM_GOAL_BORROW_RATE_MATRIX = "BORROW_RATE_MATRIX";
    public static readonly DIAGNOSTICS_METADATA_ITEM_GOAL_BORROW_LTV = "BORROW_LTV";
    public static readonly DIAGNOSTICS_METADATA_ITEM_GOAL_BORROW_AMOUNT_INCREASE_PERCENTAGE = "BORROW_AMOUNT_INCREASE_PERCENTAGE";
    public static readonly DIAGNOSTICS_METADATA_ITEM_PROPERTY_MOVE_TO_SMALLER_PROPERTY_PERCENTAGE = "MOVE_TO_SMALLER_PROPERTY_PERCENTAGE";
    public static readonly DIAGNOSTICS_METADATA_ITEM_PROPERTY_MOVE_TO_SMALLER_PROPERTY_YEAR = "MOVE_TO_SMALLER_PROPERTY_YEAR";

    public static readonly DIAGNOSTICS_METADATA_ITEM_WRAPPER_PRESETS_INDEX_PER_PORTFOLIO = "PRESETS_INDEX_PER_PORTFOLIO";
    public static readonly DIAGNOSTICS_METADATA_ITEM_WRAPPER_PRESETS_INDEX = 'PRESETS_INDEX';
    public static readonly DIAGNOSTICS_METADATA_ITEM_WRAPPER_EMPLOYER_PENSION_CONTRIBUTION_INCOME_INCREASE_PERCENTAGE = "EMPLOYER_PENSION_CONTRIBUTION_INCOME_INCREASE_PERCENTAGE";
    public static readonly DIAGNOSTICS_METADATA_ITEM_WRAPPER_EMPLOYEE_PENSION_CONTRIBUTION_EXPENSE_INCREASE_PERCENTAGE = "EMPLOYEE_PENSION_CONTRIBUTION_EXPENSE_INCREASE_PERCENTAGE";
    public static readonly DIAGNOSTICS_METADATA_ITEM_WRAPPER_EMPLOYEE_PENSION_CONTRIBUTION_EXPENSE_ADD_PERCENTAGE = "EMPLOYEE_PENSION_CONTRIBUTION_EXPENSE_ADD_PERCENTAGE";

    public static readonly DIAGNOSTICS_METADATA_ITEM_INSURANCE_COVER_AMOUNT = "COVER_AMOUNT";
    public static readonly DIAGNOSTICS_METADATA_ITEM_INSURANCE_END_YEAR = "END_YEAR";


    public static readonly FUNDING_SOURCE_CLASS_SAVE_IN_YEARS = "SaveFundingSource";
    public static readonly FUNDING_SOURCE_CLASS_MORTGAGE = "MortgageFundingSource";
    public static readonly FUNDING_SOURCE_CLASS_OTHER_LOAN = "OtherLoanFundingSource";
    public static readonly FUNDING_SOURCE_CLASS_UK_STUDENT_LOAN = "UKStudentLoanFundingSource";

    public static readonly GOAL_PROPERTY_TRADE_DOWN_HOUSE = "tradeDownHouse";
    public static readonly GOAL_PROPERTY_TRADE_DOWN_DATE = "tradeDownDate";
    public static readonly GOAL_PROPERTY_TRADE_DOWN_NEW_HOUSE_PERCENTAGE = "tradeDownNewHousePercentage";
    public static readonly GOAL_PROPERTY_LIABILITY_AND_DEBT_ID = "liabilityAndDebtId";

    public static readonly DIFF_TEXT_ISA_ENABLED = "Enabled";
    public static readonly DIFF_TEXT_ISA_DISABLED = "Disabled";

    static readonly FINANCIAL_WRAPPER_ID_ISA = 'isa';
    static readonly FINANCIAL_WRAPPER_ID_EMPLOYER_PENSION = 'ESDCS';
    static readonly FINANCIAL_WRAPPER_ID_PRIVATE_PENSION = 'sipp';
    static readonly FINANCIAL_WRAPPER_ID_GIA = 'general_investment_account';

    static readonly FINANCIAL_WRAPPER_NAMES = [
        { id: Constants.FINANCIAL_WRAPPER_ID_ISA, label: 'ISA' },
        { id: Constants.FINANCIAL_WRAPPER_ID_EMPLOYER_PENSION, label: 'Workplace Pension' },
        { id: Constants.FINANCIAL_WRAPPER_ID_PRIVATE_PENSION, label: 'Private Pension' },
        { id: Constants.FINANCIAL_WRAPPER_ID_GIA, label: 'Other' },
    ];

    static readonly ALLOCATION_PRESET_NAMES = [
        { id: 'cash only', label: 'Cash only' },
        { id: 'low', label: 'Low' },
        { id: 'medium-low', label: 'Medium-low' },
        { id: 'moderate', label: 'Moderate' },
        { id: 'medium-high', label: 'Medium-high' },
        { id: 'high', label: 'High' }
    ];

    static readonly DEFAULT_WRAPPER_ALLOCATION_NAME = 'default';

    static readonly DEFAULT_CURRENCY_MASK_CONFIG: CurrencyMaskConfig = {
        allowNegative: false,
        prefix: '$ ',
        align: 'left',
        decimal: '.',
        precision: 0,
        suffix: '',
        thousands: ' '
    }

    static readonly SELECTABLE_FREQUENCIES = [
        Frequency.ANNUALLY,
        Frequency.MONTHLY,
        Frequency.WEEKLY
    ]

    static readonly FREQUENCY_LABELS = [
        { id: Frequency.ANNUALLY, label: 'per year' },
        { id: Frequency.MONTHLY, label: 'per month' },
        { id: Frequency.WEEKLY, label: 'per week' },
        { id: Frequency.ONE_OFF, label: 'One-off' },
        { id: Frequency.UNSPECIFIED, label: 'Unspecified' }
    ];

    static readonly DEFAULT_GOAL_TYPE = 'DEFAULT';

    static readonly SCENARIO_MAIN = 'Me Today';
    static readonly SCENARIO_DIAGNOSTICS = 'Improvements';

    static readonly RETIREMENT_MINIMUM_AGE = 30;
    static readonly RETIREMENT_MAXIMUM_AGE = 90;

    static readonly GOAL_YEAR_RANGE = 48;
}

