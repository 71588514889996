import { OnInit, Component, Input, Output, EventEmitter } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { Frequency } from 'src/app/model/enums';

@Component({
  selector: 'app-frequency-picker',
  templateUrl: './frequency-picker.component.html',
  styleUrls: ['./frequency-picker.component.scss'],
})
export class FrequencyPickerComponent implements OnInit {
  @Input() value: string;
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  private _recurringValues: String[];
  private _selected: string;
  private _labels = {
    [Frequency.ONE_OFF]: 'One off',
    [Frequency.ANNUALLY]: 'Annually',
    [Frequency.DAILY]: 'Daily',
    [Frequency.MONTHLY]: 'Monthly',
    [Frequency.QUARTERLY]: 'Quarterly',
    [Frequency.SEMI_ANNUALLY]: 'Semi annually',
    [Frequency.WEEKLY]: 'Weekly',
    [Frequency.UNSPECIFIED]: 'Unspecified',
  };

  constructor() {
    this._recurringValues = Object.keys(Frequency);
  }

  onChange(ev: MatSelectChange) {
    this.valueChange.next(ev.value);
  }

  ngOnInit() {
    this._selected = this.value || Frequency.ONE_OFF;
  }

  getLabel(opt) {
    return this._labels[opt];
  }

  get options() {
    return this._recurringValues;
  }

  get selected() {
    return this._selected;
  }

  set selected(val: string) {
    this._selected = val;
  }
}
