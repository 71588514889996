import { Component, OnInit } from '@angular/core';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { SharedService } from 'src/app/services';
import { AppConfigService } from 'src/app/services/app.config.service';

@Component({
  selector: 'auth-monitor',
  templateUrl: './auth-monitor.component.html',
  styleUrls: ['./auth-monitor.component.scss']
})
export class AuthMonitorComponent implements OnInit {


  constructor(
    private configService: AppConfigService,
    private authService: OAuthService,
    private sharedService: SharedService
  ) {
    const oAuthConfig: AuthConfig = { ...this.configService.getConfig().oAuthConfig, redirectUri: window.location.origin + '/' }
    this.authService.configure(oAuthConfig);
    this.authService.setupAutomaticSilentRefresh();
    this.authService.loadDiscoveryDocumentAndLogin().then((loginSuccess: boolean) => {
      loginSuccess && this.sharedService.setIsAuthenticated(true)
    })
  }

  ngOnInit(): void { }

}
