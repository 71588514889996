import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // if (req.url.endsWith('uaa/token/refresh')) {
    //     return next.handle(req);
    // }
    // if (!this.authService.isLoggedIn()) {
    //     this.authService
    //         .logout()
    //         .subscribe(() => this.router
    //             .navigate([Constants.ROUTE_LOGIN]));
    //     return next.handle(req);
    // }

    // // Get the auth token from the service.
    // if (!req.headers.has('Authorization')) {
    //     const authReq = req.clone({
    //         headers: req.headers
    //             .set('Authorization', 'Bearer ' + this.authService.getAccessToken())
    //     });
    // send cloned request with header to the next handler.
    //     return next.handle(authReq);
    // }
    return next.handle(req);
  }
}
