import {
  MortgageFundingSourceBuilder,
  LiquidAssetsFundingSource,
  OtherLoanFundingSourceBuilder,
  UKStudentLoanFundingSourceBuilder,
} from './funding.sources.class';
import { GoalBuilder } from './goal.builder.class';
import {
  GoalRetirementProperties,
  GoalSabbaticalProperties,
  GoalEliminateDebtProperties,
} from './goal.properties.class';
import { DateRangeType, Frequency } from '../enums';
import { Constants } from 'src/app/app.constants';
import { DateUtils } from 'src/app/utils';

export class GoalFactory {
  // TODO - extract them to a json and load them into a price or default amount provider
  static readonly DEFAULT_AMOUNT_INTIMATE_WEDDING = 1_000;
  static readonly DEFAULT_AMOUNT_BIG_WEDDING = 12_000;
  static readonly DEFAULT_AMOUNT_SPECTACULAR_WEDDING = 25000;
  static readonly DEFAULT_AMOUNT_HAVE_CHILD = 7500;
  static readonly DEFAULT_AMOUNT_ANNUAL_HOLIDAY = 3000;
  static readonly DEFAULT_AMOUNT_LOW_KEY_HONEYMOON = 2_500;
  static readonly DEFAULT_AMOUNT_DREAM_HONEYMOON = 5_000;
  static readonly DEFAULT_AMOUNT_ONE_DOG = 1_000;
  static readonly DEFAULT_YEARLY_EXPENSE_ONE_DOG = 1_000;
  static readonly DEFAULT_AMOUNT_ONE_CAT = 1_000;
  static readonly DEFAULT_YEARLY_EXPENSE_ONE_CAT = 1_000;
  static readonly DEFAULT_AMOUNT_TWO_DOGS = 1_500;
  static readonly DEFAULT_YEARLY_EXPENSE_TWO_DOGS = 1_500;
  static readonly DEFAULT_AMOUNT_TWO_CATS = 1_500;
  static readonly DEFAULT_YEARLY_EXPENSE_TWO_CATS = 1_500;
  static readonly DEFAULT_YEARLY_EXPENSE_ONE_HOLIDAY = 1_000;
  static readonly DEFAULT_YEARLY_EXPENSE_TWO_HOLIDAYS = 2_000;
  static readonly DEFAULT_YEARLY_EXPENSE_FESTIVAL = 1_000;
  static readonly DEFAULT_AMOUNT_MOPED = 2_000;
  static readonly DEFAULT_AMOUNT_CAR = 25_000;
  static readonly DEFAULT_AMOUNT_SPORTS_CAR = 75_000;
  static readonly DEFAULT_AMOUNT_TWO_CARS = 50_000;
  static readonly DEFAULT_AMOUNT_CAMPERVAN = 35_000;
  static readonly DEFAULT_AMOUNT_FLAT = 320_000;
  static readonly DEFAULT_AMOUNT_BUY_TO_LET_PROPERTY = 400_000;
  static readonly DEFAULT_AMOUNT_COSY_HOUSE = 400_000;
  static readonly DEFAULT_AMOUNT_BIG_HOUSE = 600_000;
  static readonly DEFAULT_AMOUNT_MANSION = 800_000;
  static readonly DEFAULT_BUY_TO_LET_SURVIVOR_PERCENTAGE = 1.0;
  static readonly DEFAULT_BUY_TO_LET_ANNUAL_RENTAL_YIELD_PERCENTAGE = 0.035;
  static readonly DEFAULT_AMOUNT_TRAVEL_THE_WORLD = 10_000;
  static readonly DEFAULT_AMOUNT_VISIT_ALL_CONTINENTS = 25_000;
  static readonly DEFAULT_AMOUNT_US_ROAD_TRIP = 1_500;
  static readonly DEFAULT_AMOUNT_FLY_TO_SPACE = 100_000;
  static readonly DEFAULT_AMOUNT_MASTERS_DEGREE = 10_000;
  static readonly DEFAULT_AMOUNT_ELIMINATE_DEBT = 10_000;
  static readonly DEFAULT_AMOUNT_MBA = 20_000;
  static readonly DEFAULT_RETIRE_COMFORTABLY_EXPENSE = 1000;
  static readonly DEFAULT_RETIRE_IN_LUXURY_EXPENSE = 5000;
  static readonly DEFAULT_PHILANTHROPY_EXPENSE = 5000;
  static readonly DEFAULT_AMOUNT_START_BUSINESS = 100000;
  static readonly DEFAULT_DURATION_MASTERS_DEGREE = 2;
  static readonly DEFAULT_DURATION_MBA = 2;
  static readonly DEFAULT_DURATION_FESTIVAL = 10;
  static readonly DEFAULT_DURATION_PETS = 12;
  static readonly DEFAULT_RETIRE_COMFORTABLY_EXPENDITURE_PERCENTAGE = 0.75;
  static readonly DEFAULT_RETIRE_IN_LUXURY_EXPENDITURE_PERCENTAGE = 1.25;
  static readonly DEFAULT_WORK_PART_TIME_PRIMARY_YEARS = 5;
  static readonly DEFAULT_WORK_PART_TIME_PARTNER_YEARS = 5;
  static readonly DEFAULT_WORK_PART_TIME_PRIMARY_INCOME_PERCENT = 0.5;
  static readonly DEFAULT_WORK_PART_TIME_PARTNER_INCOME_PERCENT = 0.5;
  static readonly DEFAULT_WORK_PART_TIME_PRIMARY_EXPENSE_PERCENT = 1.0;
  static readonly DEFAULT_WORK_PART_TIME_PARTNER_EXPENSE_PERCENT = 1.0;
  static readonly DEFAULT_AMOUNT_USER_DEFINED = 10_000;
  static readonly DEFAULT_RECURRING_AMOUNT = 5_000;
  static readonly DEFAULT_RECURRING_FREQUENCY = Frequency.ANNUALLY;
  static readonly DEFAULT_RECURRING_FREQUENCY_MULTIPLIER = 1;

  static haveAChild(amount?: number) {
    return new GoalBuilder()
      .haveAChild()
      .withName('Have a child')
      .withAmount(amount || GoalFactory.DEFAULT_AMOUNT_HAVE_CHILD)
      .withFundingSources([new LiquidAssetsFundingSource()])
      .withIcon('icon-baby-boy')
      .build();
  }

  static getMarried(amount?: number) {
    return new GoalBuilder()
      .getMarried()
      .withName('My wedding')
      .withAmount(amount || GoalFactory.DEFAULT_AMOUNT_INTIMATE_WEDDING)
      .withFundingSources([new LiquidAssetsFundingSource()])
      .withIcon('icon-just-married')
      .build();
  }

  static buyACar(amount?: number) {
    const value = amount || GoalFactory.DEFAULT_AMOUNT_CAR;
    const fundingSource = new OtherLoanFundingSourceBuilder()
      .withAmount(value * Constants.DEFAULT_DEBT_CAR_LOAN.LTV)
      .withTermYears(Constants.DEFAULT_DEBT_CAR_LOAN.term)
      .withAnnualInterestRate(Constants.DEFAULT_DEBT_CAR_LOAN.rate)
      .build();

    return new GoalBuilder()
      .buyACar()
      .withName('My car')
      .withAmount(value)
      .withFundingSources([new LiquidAssetsFundingSource(), fundingSource])
      .withIcon('icon-car')
      .build();
  }

  static buyAHouse(amount: number, name: string = 'My house') {
    const fundingSource = new MortgageFundingSourceBuilder()
      .withAmount(amount * Constants.DEFAULT_DEBT_MORTGAGE.LTV)
      .withTermYears(Constants.DEFAULT_DEBT_MORTGAGE.term)
      .withAnnualInterestRate(Constants.DEFAULT_DEBT_MORTGAGE.rate)
      .build();

    return new GoalBuilder()
      .buyAHouse()
      .withName(name)
      .withAmount(amount)
      .withFundingSources([new LiquidAssetsFundingSource(), fundingSource])
      .withIcon('icon-house')
      .build();
  }

  static travel(amount?: number) {
    return new GoalBuilder()
      .travel()
      .withAmount(amount || GoalFactory.DEFAULT_AMOUNT_TRAVEL_THE_WORLD)
      .withFundingSources([new LiquidAssetsFundingSource()])
      .withName('Travel')
      .withIcon('icon-world')
      .build();
  }

  static goToSchool(amount: number, name: string = 'Go to school') {
    const fundingSource = new UKStudentLoanFundingSourceBuilder()
      .withAmount(amount * Constants.DEFAULT_STUDENT_LOAN_AMOUNT_PERCENTAGE)
      .withName(Constants.DEFAULT_STUDENT_LOAN_FUNDING_SOURC_NAME)
      .build();

    return new GoalBuilder()
      .goToSchool()
      .withFundingSources([new LiquidAssetsFundingSource(), fundingSource])
      .withName(name)
      .withAmount(amount)
      .withIcon('icon-cap')
      .build();
  }

  static retirementGoal(date: string) {
    return new GoalBuilder()
      .retirement()
      .withAmount(0)
      .withProperties(new GoalRetirementProperties())
      .withName('My retirement')
      .inDate(date)
      .startsOn(DateRangeType.USER_DEFINED)
      .endsOn(DateRangeType.USER_DEFINED)
      .build();
  }

  static sabbatical(
    name: string,
    incomePercentage?: number,
    expensePercentage?: number
  ) {
    return new GoalBuilder()
      .sabbatical()
      .withAmount(0)
      .withProperties(
        new GoalSabbaticalProperties(
          incomePercentage || 0,
          expensePercentage || 1
        )
      )
      .withName(name || 'Time off work')
      .build();
  }

  static eliminateDebt(name: string, liabilityId: string, amount: number) {
    return new GoalBuilder()
      .eliminateDebt()
      .withProperties(new GoalEliminateDebtProperties(liabilityId))
      .withFundingSources([new LiquidAssetsFundingSource()])
      .withAmount(amount)
      .withName(name || 'Eliminate Debt')
      .build();
  }

  static other(amount?: number) {
    return new GoalBuilder()
      .userDefined()
      .withAmount(amount || GoalFactory.DEFAULT_AMOUNT_USER_DEFINED)
      .withFundingSources([new LiquidAssetsFundingSource()])
      .withName('User defined goal')
      .withIcon('icon-question')
      .build();
  }

  static otherRecurring(
    amount?: number,
    recurringAmount?: number,
    frequency?: Frequency,
    multiplier?: number,
    startDate?: string,
    endDate?: string
  ) {
    return new GoalBuilder()
      .recurringUserDefined(
        recurringAmount || GoalFactory.DEFAULT_RECURRING_AMOUNT
      )
      .withName('Recurring goal')
      .withIcon('icon-repeat')
      .withFundingSources([new LiquidAssetsFundingSource()])
      .withFrequency(frequency || Frequency.ONE_OFF)
      .withAmount(amount || GoalFactory.DEFAULT_RECURRING_AMOUNT)
      .withStartDate(startDate || DateUtils.thisYear())
      .withEndDate(endDate || DateUtils.inYears(10))
      .build();
  }
}
