import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { WizardService, FinancialAssetsService, PersonsService } from '../../../../services';
import { Constants } from '../../../../app.constants';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, flatMap, map, catchError, concatMap, filter } from 'rxjs/operators';
import { FundsUnderManagement, Portfolio, Person } from '../../../../model';
import { IressFumPortfolioService } from 'src/app/services/iress/fum.portfolio.iress.service';
import { IressClientService } from 'src/app/services/iress/client.iress.service';
import { Client, PortfolioPosition } from 'src/app/model/iress';
import { IressPortfolioService } from 'src/app/services/iress/portfolio.iress.service';
import { NotifierService } from 'angular-notifier';
import { IressSessionService } from 'src/app/services/iress/session.iress.service';
import { of, from } from 'rxjs';
import { AppConfigService } from 'src/app/services/app.config.service';
import { IressPortfolioPositionService } from 'src/app/services/iress/position.portfolio.iress.service';

enum accountViewStateEnum {
  unconnected, clients, entities
}

@Component({
  selector: 'app-iress',
  templateUrl: './iress.component.html',
  styleUrls: ['./iress.component.scss']
})
export class IRESSComponent implements OnInit, OnDestroy {

  _scenarioId: string;
  _primary: Person;

  state: accountViewStateEnum;
  loading = false;

  _client: Client;
  _clients: Client[] = [];
  _portfolios: Portfolio[] = [];
  _positions: PortfolioPosition[] = [];
  _fundsUnderManagement: FundsUnderManagement;

  _iressConfiguration: { url: string, clientId: string, username: string, password: string } = { url: 'thirdpartyintegrations', clientId: 'does-not-matter', username: 'api.envizage', password: 'does-not-matter' };

  constructor(
    titleService: Title,
    private router: Router,
    private route: ActivatedRoute,
    private config: AppConfigService,
    private notifier: NotifierService,
    private wizardService: WizardService,
    private iressSessionService: IressSessionService,
    private iressClientService: IressClientService,
    private iressPortfolioService: IressPortfolioService,
    private iressFumPortfolioService: IressFumPortfolioService,
    private iressPortfolioPositionService: IressPortfolioPositionService,
    private financialAssetsService: FinancialAssetsService,
    private personsService: PersonsService,
  ) {
    this.state = accountViewStateEnum.unconnected;
    titleService.setTitle('My accounts');
  }

  ngOnInit() {
    this._scenarioId = this.router.routerState.snapshot.url.split('/')[2];

    this.state = accountViewStateEnum.unconnected;
  }

  ngOnDestroy() {
    this.wizardService.setVisited(this.route.snapshot.url[0].path);
  }

  get isUnconnected() { return this.state === accountViewStateEnum.unconnected; }
  get isClients() { return this.state === accountViewStateEnum.clients; }
  get isEntities() { return this.state === accountViewStateEnum.entities; }

  captureConfiguration = (event: any) => {
    localStorage.setItem(event.target.attributes['name'], event.target.value);
  }

  tryLogin = () => {
    return this.iressSessionService
      .login();
    // return this.iressSessionService
    //   .getUser()
    //   .pipe(
    //     catchError(err => this.iressSessionService
    //       .login()));
  }

  queryClients = () => {
    delete this._client;
    this.loading = true;

    this.tryLogin()
      .subscribe(r => this.iressClientService
        .query(null)
        .pipe(
          map(r => this._clients = r))
        .pipe(
          finalize(() => this.loading = false))
        .subscribe(r => this.state = accountViewStateEnum.clients));
  }

  queryEntities = (client: Client) => {
    this._client = client;
    this._positions = [];
    this.loading = true;

    this.iressFumPortfolioService
      .get(client.id)
      .pipe(
        map(r => this._fundsUnderManagement = r))
      .pipe(
        flatMap(r => this.iressPortfolioService
          .query(client.id, null)))
      .pipe(
        map(r => this._portfolios = r))
      .pipe(
        concatMap(r => from(r)
          .pipe(
            flatMap(p => this.iressPortfolioPositionService
              .query(p.portfolioid, null)
              .pipe(
                map(p => this._positions = this._positions.concat(p)))))))
      .pipe(
        finalize(() => this.loading = false))
      .subscribe(r => this.state = accountViewStateEnum.entities);
  }

  importEntities = (client: Client) => {
    this.loading = true;
    this.iressFumPortfolioService
      .get(client.id)
      .pipe(
        map(r => this._fundsUnderManagement = r))
      .pipe(
        flatMap(r => this.iressPortfolioService
          .query(client.id, null)))
      .pipe(
        map(r => this._portfolios = r))
      .pipe(
        flatMap(() => this.personsService
          .getPrimary(this._scenarioId)))
      .pipe(
        map(p => this._primary = p))
      .pipe(
        concatMap(r => from(this._positions)
          .pipe(
            filter(r => r.value._val > 0))
          .pipe(
            flatMap(p => this.financialAssetsService
              .createFinancialPortfolio(this._scenarioId, this._primary.id, {
                name: 'Imported from Iress Xplan',
                value: p.value._val,
                currency: p.native_currency,
                wrapper: p.subfund === 'DEFAULT' ? 'sipp' : Constants.DEFAULT_FINANCIAL_ASSET_WRAPPER,
                profile: p.subfund === 'DEFAULT' ? 'low' : 'medium-high',
                fees: p.security.fee_component_management_fee_percent._val / 100,
              })))))
      .pipe(
        finalize(() => this.loading = false))
      .subscribe(r => {
        this.notifier.notify(Constants.SUCCESS, 'Portfolios imported');
        this.router.navigate(['scenario', this._scenarioId, 'onboarding', 'income']);
      });
  }

  get iressConfiguration() {
    return this._iressConfiguration;
  }

  get client() {
    return this._client;
  }

  get clients() {
    return this._clients;
  }

  get portfolios() {
    return this._portfolios;
  }

  get positions() {
    return this._positions
      .filter(p => p.value._val > 0);
  }

  get fundsUnderManagement() {
    return this._fundsUnderManagement;
  }

}
