import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FundingSourceBaseComponent } from '../funding-source-base/funding-source-base';
import { Goal } from 'src/app/model/goal';
import { MortgageFundingSource } from 'src/app/model/goal/funding.sources.class';
import { TypedGoalService } from 'src/app/services/typed.goal.service';
import { Scenario } from 'src/app/model';

@Component({
    selector: 'app-funding-source-mortgage',
    templateUrl: './mortgage-funding-source.component.html',
    styleUrls: ['./mortgage-funding-source.component.scss']
})
export class MortgageFundingSourceComponent extends FundingSourceBaseComponent implements OnInit {

    @Input() goal: Goal;
    @Input() fundingSource: MortgageFundingSource;
    @Input() scenario: Scenario;
    @Output() updateGoal: EventEmitter<any>;

    constructor(private typedGoalService: TypedGoalService) {
        super(typedGoalService);
    }

    ngOnInit() {

    }

}