import { Router } from '@angular/router';
import { PageableResponse, Scenario } from '../../../../model';
import { Component, OnInit } from '@angular/core';
import { ScenarioService } from 'src/app/services';
import { Constants } from '../../../../app.constants';
import { map, mergeMap } from 'rxjs/operators';
import { of, iif } from 'rxjs';
import { AppConfigService } from '../../../../services/app.config.service';
import { PreferenceService } from 'src/app/services/preference.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit {
  private loading: boolean;
  private loginFormError: string;

  constructor(
    private scenarioService: ScenarioService,
    private preferenceService: PreferenceService,
    private router: Router,
    private configService: AppConfigService
  ) {}

  ngOnInit() {}

  createOrSelectScenario = () =>
    this.scenarioService
      .query(Constants.PAGE_ALL)
      .pipe(map((response: PageableResponse<Scenario>) => response.content[0]))
      .pipe(
        mergeMap((current) =>
          iif(
            () => !!current,
            of(current),
            this.scenarioService.create({ name: 'Me Today' }).pipe(
              map((s) => {
                this.preferenceService
                  .update(s.id, Constants.PREFERENCE_KEY_CURRENT, true)
                  .subscribe();
                return s;
              })
            )
          )
        )
      );

  navigateToOnboarding = (scenarioId) =>
    this.router.navigate(['scenario', scenarioId, 'onboarding', 'family']);

  navigateToGraph = (scenarioId) =>
    this.router.navigate(['scenario', scenarioId, 'overview', 'graph']);

  proceed = () =>
    this.createOrSelectScenario().subscribe(
      (s) => this.navigateToOnboarding(s.id),
      (message) => (this.loginFormError = message),
      () => (this.loading = false)
    );
}
