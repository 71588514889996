export enum AnalyticsEvent {
  ADD_GOAL = "add_goal",
  EDIT_GOAL = "edit_goal",
  DELETE_GOAL = "delete_goal",
  CHANGE_SCENARIO = "change_scenario",
  CREATE_SCENARIO = "create_scenario",
  DELETE_SCENARIO = "delete_scenario",
  CREATE_DEBT = "create_debt",
  CREATE_ASSET = "create_asset",
  CREATE_INCOME = "create_income",
  CREATE_EXPENSE = "create_expense",
  CREATE_PROTECTION = "create_protection",
  ADD_PARTNER = "add_partner",
  REMOVE_PARTNER = "remove_partner",
  ADD_CHILD = "add_child",
  REMOVE_CHILD = "remove_child",
  START_CALCULATION = "start_calculation",
  CHANGE_BAND = "change_band",
}

export enum AnalyticsProperty {
  CURRENT_SCENARIO = "current_scenario",
  GOAL_TYPE = "goal_type",
  NUMBER_OF_GOALS = "number_of_goals",
  GENDER = "gender",
  PARTNER = "partner",
  HOME_OWNER = "home_owner",
  DEBT_AMOUNT = "debt_amount",
  AGE = "age",
  TOTAL_INCOME = "total_income",
  LABEL = "label",
}

export enum AnalyticsLabel {
  FINANCIAL = "financial",
  RESIDENTIAL_PROPERTY = "residential_property",
  INVESTMENT_PROPERTY = "investment_property",
  TYPE = "type",
  EARNED_INCOME = "earned_income",
  UNEARNED_INCOME = "unearned_income",
  RENT_INCOME = "rent_income",
  LIVING_EXPENSE = "living_expense",
  RENT_EXPENSE = "rent_expense",
  LIFE_INSURANCE = "life_insurance",
  DISABILITY_INSURANCE = "disability_insurance",
  MORTGAGE_INSURANCE = "mortgage_insurance",
  CRITICAL_ILLNESS_INSURANCE = "critical_illness_insurance",
}
