export interface UserAccount {
  clientId: string;
  consentAcceptances?: string[];
  creationDate?: string;
  deletionDate?: string;
  description?: string;
  emailAddress: string;
  emailAddressValidated: boolean;
  enabled: boolean;
  expired: boolean;
  firstName?: string;
  id: string;
  lastModificationDate?: string;
  lastName?: string;
  locked: boolean;
  markedForDeletion: boolean;
  name?: string;
  roles: string[];
  username: string;
}
