import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  private isAuthenticatedSubject$ = new Subject<boolean>();
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  constructor() { }

  getIsAuthenticated(): Observable<boolean> {
    return this.isAuthenticated$;
  }

  setIsAuthenticated(isAuth: boolean) {
    return this.isAuthenticatedSubject$.next(isAuth);
  }
}
