import { Injectable } from '@angular/core';
import { HandleError, HttpErrorHandler } from '../http-error-handler.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PageableRequest } from '../../model';
import { AppConfigService } from '../app.config.service';
import { QueryOnlyServiceForEntity } from './iress.service';
import { PortfolioPosition } from 'src/app/model/iress';


@Injectable()
export class IressPortfolioPositionService implements QueryOnlyServiceForEntity<PortfolioPosition> {

  private handleError: HandleError;
  private getUrl = (entityId: string, id: string = ''): string =>
    `${this.configService.getConfig().iress.apiUrl}/resourceful/portfolio/portfolio/${entityId}/position/${id}?fields.0=accountid&fields.1=value&fields.2=subfund&fields.3=fullName&fields.4=security_id&fields.5=security.ext_managername_short&fields.6=native_currency&fields.7=security.fee_component_management_fee_percent`;

  constructor(
    private http: HttpClient,
    private configService: AppConfigService,
    private httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('IressPortfolioPositionService');
  }

  query = (entityId: string, pageable: PageableRequest): Observable<PortfolioPosition[]> =>
    this.http.get<PortfolioPosition[]>(this.getUrl(entityId), { params: pageable, headers: { 'X-Iress-Api': '1' }, withCredentials: true })
      .pipe(
        catchError(this.handleError('query client portfolio position')));

  get = (entityId: string, id: string): Observable<PortfolioPosition> =>
    this.http.get<PortfolioPosition>(this.getUrl(entityId, id), { headers: { 'X-Iress-Api': '1' } })
      .pipe(
        catchError(this.handleError('get client portfolio position')));

}
