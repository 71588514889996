export class AppFeatureType {
  public static Advisor = 'FEATURE_ADVISOR';
  public static Login = 'FEATURE_LOGIN';
  public static Register = 'FEATURE_REGISTER';
  public static Terms = 'FEATURE_TERMS';
  public static GuestLogin = 'FEATURE_GUEST_LOGIN';
  public static SampleAppWarning = 'FEATURE_SAMPLE_APP_WARN';
  public static SocialLogin = 'FEATURE_SOCIAL_LOGIN';
  public static GoogleLogin = 'FEATURE_SOCIAL_GOOGLE_LOGIN';
  public static FacebookLogin = 'FEATURE_SOCIAL_FACEBOOK_LOGIN';
  public static TwitterLogin = 'FEATURE_SOCIAL_TWITTER_LOGIN';
  public static MoneyHubAccounts = 'FEATURE_MONEYHUB_ACCOUNTS';
  public static LinkToBubelePopup = 'FEATURE_LINK_TO_BUBELE_POPUP';
  public static RiskAssessment = 'FEATURE_RISK_ASSESSMENT';
  public static Disclaimer = 'FEATURE_DISCLAIMER';
  public static SolverUniformAction = 'FEATURE_SOLVER_UNIFORM_ACTION';
  public static SolverWeightedAction = 'FEATURE_SOLVER_WEIGHTED_ACTION';
}
