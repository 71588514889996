// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  padding: 20px;
  left: 50%;
  top: calc(50vh - 50px);
  margin-left: -90px;
  z-index: 100;
  max-width: 200px;
  text-align: center;
}

.overlay {
  position: absolute;
  z-index: 1002;
  background-color: rgba(255, 255, 255, 0.2);
  left: -10px;
  right: -10px;
  top: -10px;
  bottom: -10px;
  border-radius: 10px;
}
@media screen and (max-width: 599px) {
  .overlay {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}

mat-spinner {
  margin: 30px;
  display: inline-block;
}

.tutorial {
  color: white;
  margin-top: 20px;
}

.tutorial-title {
  text-align: left;
  font-size: 1.2em;
  font-weight: 600;
  margin-bottom: 5px;
}

.tutorial-descr {
  text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/app/components/common/spinner/spinner.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,8BAAA;EACA,kBAAA;EACA,aAAA;EACA,SAAA;EACA,sBAAA;EACA,kBAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,aAAA;EACA,0CAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;EACA,aAAA;EACA,mBAAA;AACF;AAAE;EATF;IAUI,OAAA;IACA,QAAA;IACA,MAAA;IACA,SAAA;EAGF;AACF;;AAAA;EACE,YAAA;EACA,qBAAA;AAGF;;AAAA;EACE,YAAA;EACA,gBAAA;AAGF;;AAAA;EACE,gBAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;AAGF;;AAAA;EACE,gBAAA;AAGF","sourcesContent":[".spinner {\n  position: fixed;\n  background: transparentize($color: black, $amount: 0.5);\n  border-radius: 5px;\n  padding: 20px;\n  left: 50%;\n  top: calc(50vh - 50px);\n  margin-left: -90px;\n  z-index: 100;\n  max-width: 200px;\n  text-align: center;\n}\n\n.overlay {\n  position: absolute;\n  z-index: 1002;\n  background-color: rgba(255, 255, 255, 0.2);\n  left: -10px;\n  right: -10px;\n  top: -10px;\n  bottom: -10px;\n  border-radius: 10px;\n  @media screen and (max-width: 599px) {\n    left: 0;\n    right: 0;\n    top: 0;\n    bottom: 0;\n  }\n}\n\nmat-spinner {\n  margin: 30px;\n  display: inline-block;\n}\n\n.tutorial {\n  color: white;\n  margin-top: 20px;\n}\n\n.tutorial-title {\n  text-align: left;\n  font-size: 1.2em;\n  font-weight: 600;\n  margin-bottom: 5px;\n}\n\n.tutorial-descr {\n  text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
