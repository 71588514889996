import {
  Component,
  signal,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => LabelInputComponent),
  multi: true,
};

@Component({
  selector: 'app-label-input',
  templateUrl: './label-input.component.html',
  styleUrls: ['./label-input.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class LabelInputComponent implements ControlValueAccessor {
  @Output() update = new EventEmitter<string>();

  @ViewChild('inputRef') inputRef: ElementRef<HTMLInputElement>;

  isEdit = signal(false);

  private innerValue = '';

  private onTouchedCallback: () => void = () => {
    //empty
  };
  private onChangeCallback: (_: string) => void = () => {
    //empty
  };

  get value(): string {
    return this.innerValue;
  }

  set value(v: string) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  writeValue(value: string): void {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  registerOnChange(fn: () => void): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouchedCallback = fn;
  }

  // setDisabledState?(isDisabled: boolean): void {
  // Handle the disabled state if necessary
  // }

  onBlur() {
    this.onTouchedCallback();
    this.isEdit.set(false);
    this.update.emit(this.value);
  }

  edit() {
    this.isEdit.set(true);
    setTimeout(() => this.inputRef.nativeElement.focus(), 10);
  }
}
