import { Injectable } from '@angular/core';
import { HandleError, HttpErrorHandler } from './http-error-handler.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PageableResponse, TermsOfUse, PageableRequest, TermsStatus } from '../model';
import { AppConfigService } from './app.config.service';

@Injectable()
export class TermsService {
    private getUrl = () => this.configService.getConfig().apiUrl + '/uaa/terms-of-use/';
    private handleError: HandleError;

    constructor(
            private http: HttpClient,
            private configService: AppConfigService,
            private httpErrorHandler: HttpErrorHandler
        ) {
            this.handleError = httpErrorHandler.createHandleError('termsService');
    }

    query(pageable: PageableRequest): Observable<PageableResponse<TermsOfUse>> {
        return this.http.get<PageableResponse<TermsOfUse>>(this.getUrl(), { params: pageable })
            .pipe(catchError(this.handleError('query terms')));
    }

    get(id: string): Observable<TermsOfUse> {
        return this.http.get<TermsOfUse>(this.getUrl() + id)
            .pipe(catchError(this.handleError('get terms')));
    }

    create(terms: TermsOfUse): Observable<TermsOfUse> {
        return this.http.post<TermsOfUse>(this.getUrl(), terms)
            .pipe(catchError(this.handleError('create terms')));
    }

    update(terms: TermsOfUse): Observable<TermsOfUse> {
        return this.http.put<TermsOfUse>(this.getUrl() + terms.id, terms)
            .pipe(catchError(this.handleError('update terms')));
    }

    delete(id: string): Observable<any> {
        return this.http.delete<any>(this.getUrl() + id)
            .pipe(catchError(this.handleError('delete terms')));
    }

    current(): Observable<TermsOfUse> {
        return this.http.get(this.getUrl() + 'current')
        .pipe(catchError(this.handleError('get current terms')));
    }

    getStatus(termsId: string): Observable<TermsStatus> {
        return this.http.get<TermsStatus>(this.getUrl() + termsId + '/users')
            .pipe(catchError(this.handleError('get terms status')));
    }

    updateStatus(terms: TermsStatus): Observable<TermsStatus> {
        return this.http.put<TermsStatus>(this.getUrl() + terms.id + '/users', terms)
            .pipe(catchError(this.handleError('get terms status')));
    }
}