import {
  OnInit,
  Component,
  Input,
  Output,
  EventEmitter,
  ComponentFactoryResolver,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { Goal } from 'src/app/model/goal';
import { EditGoalContext } from 'src/app/model/goal/edit.goal.context.class';
import { Constants } from 'src/app/app.constants';
import { EditGoalBaseComponent } from '../edit-goal-base/edit-goal-base.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-goal-buy-car',
  templateUrl: './edit-goal-buy-car.component.html',
  styleUrls: ['./edit-goal-buy-car.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditGoalBuyCarComponent extends EditGoalBaseComponent {
  @Input() ctx: EditGoalContext;
  @Output() deleteGoal: EventEmitter<Goal> = new EventEmitter();
  @Output() updateGoal: EventEmitter<Goal> = new EventEmitter();

  private _initialAmount: number;
  private _initialMortgageAmount: number;

  constructor(
    resolver: ComponentFactoryResolver,
    private dialog: MatDialog,
    private ref: ChangeDetectorRef
  ) {
    super(resolver, dialog, ref);
  }

  ngOnInit() {
    this._initialAmount = this.ctx.goal.desiredAmount;
    this._initialMortgageAmount =
      this.ctx.goal.fundingSources
        .filter((f) => f.class === 'OtherLoanFundingSource')
        .map((s) => parseFloat(s['amount']))[0] || 0;
  }

  onUpdateGoalAmount() {
    this.ctx.goal.minimumAmount = this.ctx.goal.desiredAmount;
    this.ctx.goal.fundingSources = this.ctx.goal.fundingSources.map((s) => {
      if (s.class === 'OtherLoanFundingSource') {
        s['amount'] =
          (this._initialMortgageAmount * this.ctx.goal.desiredAmount) /
          this._initialAmount;
      }
      return s;
    });
  }

  onYearChange(value: string) {
    this.ctx.goal.startDate = value;
    this.ctx.goal.endDate = value;
  }

  get minYear() {
    return new Date().getFullYear();
  }

  get maxYear() {
    return new Date().getFullYear() + Constants.GOAL_YEAR_RANGE;
  }
}
