import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-solver-dialog',
  templateUrl: './solver-dialog.component.html',
  styleUrls: ['./solver-dialog.component.scss'],
})
export class SolverDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<SolverDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  public applyOnClose = false;

  close() {
    this.dialogRef.close(this.applyOnClose);
  }
}
