import { Injectable } from '@angular/core';
import { TypedGoalService } from './typed.goal.service';
import { LivingExpenseService, PensionExpenseService, RentExpenseService } from './expense.service';
import { EarnedIncomeService, UnearnedIncomeService, PensionContributionIncomeService } from './income.service';
import { CriticalIllnessInsuranceService, LifeInsuranceService, DisabilityInsuranceService, MortgageLifeInsuranceService } from './protection.service';
import { Scenario } from '../model';
import { PersonsService } from './persons.service';
import { NotifierService } from 'angular-notifier';
import { UnsecuredLiabilityService, MortgageLiabilityService } from './liability.service';
import { PropertyService } from './property.service';
import { FinancialAssetsService } from './financial.assets.service';
import { GoalDiagnostic } from '../model/household/result/goal.diagnostic';
import { PreferenceService } from './preference.service';


export class DiagnosticContext {
    goalId: string;
    scenario: Scenario;
    mortgageLifeInsuranceService: MortgageLifeInsuranceService;
    goalDiagnostic: GoalDiagnostic;
    notifier: NotifierService;
    financialAssetsService: FinancialAssetsService;
    preferenceService: PreferenceService;
    personService: PersonsService;
    rentExpenseService: RentExpenseService;
    typedGoalService: TypedGoalService;
    livingExpensesService: LivingExpenseService;
    earnedIncomesService: EarnedIncomeService;
    unearnedIncomesService: UnearnedIncomeService;
    pensionExpenseService: PensionExpenseService;
    pensionIncomeService: PensionContributionIncomeService;
    criticalIllnessInsuranceService: CriticalIllnessInsuranceService;
    lifeInsuranceService: LifeInsuranceService;
    propertyService: PropertyService;
    mortgageService: MortgageLiabilityService;
    unsecuredLiabilityService: UnsecuredLiabilityService;
    disabilityInsuranceService: DisabilityInsuranceService;
}


@Injectable()
export class DiagnosticsContextFactory {

    constructor(
        private notifier: NotifierService,
        private propertyService: PropertyService,
        private mortgageService: MortgageLiabilityService,
        private preferenceService: PreferenceService,
        private personService: PersonsService,
        private mortgageLifeInsuranceService: MortgageLifeInsuranceService,
        private financialAssetsService: FinancialAssetsService,
        private typedGoalService: TypedGoalService,
        private rentExpenseService: RentExpenseService,
        private unearnedIncomeService: UnearnedIncomeService,
        private livingExpensesService: LivingExpenseService,
        private earnedIncomesService: EarnedIncomeService,
        private pensionExpenseService: PensionExpenseService,
        private pensionIncomeService: PensionContributionIncomeService,
        private criticalIllnessInsuranceService: CriticalIllnessInsuranceService,
        private lifeInsuranceService: LifeInsuranceService,
        private unsecuredLiabilityService: UnsecuredLiabilityService,
        private disabilityInsuranceService: DisabilityInsuranceService) {
    }

    public create(scenario: Scenario, goalDiagnostic: GoalDiagnostic): DiagnosticContext {
        const ctx: DiagnosticContext = new DiagnosticContext();

        ctx.scenario = scenario;
        ctx.rentExpenseService = this.rentExpenseService;
        ctx.preferenceService = this.preferenceService;
        ctx.goalId = goalDiagnostic.goalId;
        ctx.goalDiagnostic = goalDiagnostic;
        ctx.notifier = this.notifier;
        ctx.mortgageLifeInsuranceService = this.mortgageLifeInsuranceService;
        ctx.financialAssetsService = this.financialAssetsService;
        ctx.personService = this.personService;
        ctx.typedGoalService = this.typedGoalService;
        ctx.livingExpensesService = this.livingExpensesService;
        ctx.earnedIncomesService = this.earnedIncomesService;
        ctx.pensionExpenseService = this.pensionExpenseService;
        ctx.pensionIncomeService = this.pensionIncomeService;
        ctx.criticalIllnessInsuranceService = this.criticalIllnessInsuranceService;
        ctx.lifeInsuranceService = this.lifeInsuranceService;
        ctx.disabilityInsuranceService = this.disabilityInsuranceService;
        ctx.unsecuredLiabilityService = this.unsecuredLiabilityService;
        ctx.unearnedIncomesService = this.unearnedIncomeService;
        ctx.propertyService = this.propertyService;
        ctx.mortgageService = this.mortgageService;
        return ctx;
    }

}