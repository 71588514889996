import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  signal,
} from '@angular/core';
import { Constants } from 'src/app/app.constants';
import {
  Liability,
  MortgageLiability,
  ResidentialProperty,
  UnsecuredLiability,
} from 'src/app/model';
import { AppConfigService } from 'src/app/services/app.config.service';
import * as moment from 'moment/moment';
import { ExpensesCalculatorUtils } from 'src/app/utils/expenses.calculator.utils';

@Component({
  selector: 'app-balance-liability',
  templateUrl: './liability.component.html',
  styleUrls: ['./liability.component.scss'],
})
export class BalanceLiabilityComponent implements OnInit {
  @Input() entity: Liability;
  @Input() properties: ResidentialProperty[];

  @Output() update = new EventEmitter<Liability>();
  @Output() remove = new EventEmitter<Liability>();
  @Output() updateLinked = new EventEmitter<Liability>();
  @Output() removeLinked = new EventEmitter<Liability>();

  isMortgage = false;
  isEdit = signal(false);
  private _locale: string;
  private _isDirty = false;

  constructor(private configService: AppConfigService) {}

  ngOnInit(): void {
    this._locale = this.configService.getConfig().locale;
    this.isMortgage = !!this.properties;
  }

  onUpdate() {
    this._isDirty = true;
  }

  onRemove() {
    this.remove.emit(this.entity);
  }

  onDismiss() {
    this._isDirty && this.update.emit(this.entity);
    this.isEdit.set(false);
  }

  onUpdateLinked() {
    this.updateLinked.emit(this.entity);
  }

  onRemoveLinked() {
    this.removeLinked.emit(this.entity);
  }

  calculateMonthlyPayment() {
    return this.isMortgage
      ? this.calculateMortgageMonthlyPayment()
      : this.calculateUnsecuredLiabilityMonthlyPayment();
  }

  calculateUnsecuredLiabilityMonthlyPayment() {
    const mortgageTerm = moment
      .utc(this.entity.endDate)
      .diff(moment.utc(this.entity.startDate), 'years');
    const interest = Math.abs(
      ExpensesCalculatorUtils.IPMT(
        this.entity.amount,
        mortgageTerm,
        (this.entity as UnsecuredLiability).annualAverageInterestRate,
        0
      )
    );
    const principal = Math.abs(
      ExpensesCalculatorUtils.PPMT(
        (this.entity as UnsecuredLiability).annualAverageInterestRate,
        1,
        mortgageTerm,
        this.entity.amount,
        0,
        0
      )
    );
    return (interest + principal) / 12;
  }

  calculateMortgageMonthlyPayment() {
    const mortgageTerm = moment
      .utc(this.entity.endDate)
      .diff(moment.utc(this.entity.startDate), 'years');
    const interest = Math.abs(
      ExpensesCalculatorUtils.IPMT(
        this.entity.amount,
        mortgageTerm,
        (this.entity as MortgageLiability).annualAverageInterestRate,
        0
      )
    );
    const principal = Math.abs(
      ExpensesCalculatorUtils.PPMT(
        (this.entity as MortgageLiability).annualAverageInterestRate,
        1,
        mortgageTerm,
        this.entity.amount,
        0,
        0
      )
    );
    return (interest + principal) / 12;
  }

  isFromLinkedAccounts(data) {
    // return (data.source === Constants.SOURCE_MONEYHUB || data.description === Constants.SOURCE_MONEYHUB);
    return data.source === Constants.SOURCE_MONEYHUB;
  }

  get propertyName() {
    return this.properties.find(
      (p) => p.id === (this.entity as MortgageLiability).propertyAssetId
    )?.name;
  }

  get nextYear() {
    return new Date().getFullYear() + 1;
  }

  get currency() {
    return Constants.LOCALE_CONFIG[this._locale].currencySymbol;
  }
}
