import { AuthService, JwtContent } from './../services/auth.service';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Constants } from '../app.constants';
import { NotifierService } from 'angular-notifier';
import { LoginResponse } from '../model';
import jwt_decode from 'jwt-decode';
import { flatMap, map, catchError } from 'rxjs/operators';

@Injectable()
export class TokenExpirationInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private notifier: NotifierService,
    private authService: AuthService
  ) {}

  storeUserData(token, decoded, refresh) {
    //localStorage.setItem(Constants.LOCAL_STORAGE_AUTH_TOKEN, token);
    //localStorage.setItem(Constants.LOCAL_STORAGE_REFRESH_TOKEN, refresh);
    //localStorage.setItem(Constants.LOCAL_STORAGE_USERNAME, decoded['user_name']);
    //localStorage.setItem(Constants.LOCAL_STORAGE_TOKEN_EXPIRATION, '' + decoded['exp']);
    //localStorage.setItem(Constants.LOCAL_STORAGE_USER_ROLES, decoded['authorities'].filter(a => a.startsWith('R')));
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // const navigateToLoginOnFailure = () => this.router.navigate([Constants.ROUTE_LOGIN])
    //     .finally(() => this.notifier.notify(Constants.WARNING, 'Failed to refresh session'));

    // if (this.authService.hasExpired()) {
    //     localStorage.setItem(Constants.LOCAL_STORAGE_AUTH_TOKEN, '');
    //     return this.authService.refresh()
    //         .pipe(
    //             map((response: LoginResponse) => {
    //                 if (response && response.token && response.token.accessToken && response.token.refreshToken) {
    //                     const decoded: JwtContent = jwt_decode(response.token.accessToken);
    //                     this.storeUserData(response.token.accessToken, decoded, response.token.refreshToken);
    //                 } else {
    //                     navigateToLoginOnFailure();
    //                 }
    //             }))
    //         .pipe(
    //             catchError(() => navigateToLoginOnFailure()))
    //         .pipe(
    //             flatMap(() => next.handle(req)))
    // }
    return next.handle(req);
  }
}
