export * from './family/family.component';
export * from './onboarding.container';
export * from './balance/balance.component';
export * from './income/income.component';
export * from './protection/protection.component';
export * from './future/future.component';
export * from './connect/connect.component';
export * from './assessment/risk-dialog/risk.dialog.component';
export * from './import/moneyhub/moneyhub.component';
export * from './import/iress/iress.component';
export * from './assessment/assessment.component';
export * from './connections/connections.component';
