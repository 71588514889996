// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h4 {
  margin: 5px auto;
}

.mat-mdc-icon-button {
  height: 25px;
  width: 25px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/common/funding-sources/investment-property-funding-source/investment-property-funding-source.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAEA;EACE,YAAA;EACA,WAAA;AACF","sourcesContent":["h4 {\n  margin: 5px auto;\n}\n\n.mat-mdc-icon-button {\n  height: 25px;\n  width: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
