import { Injectable } from '@angular/core';
import { HandleError, HttpErrorHandler } from './http-error-handler.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PageableResponse, PageableRequest, Scenario } from '../model';
import { AppConfigService } from './app.config.service';

@Injectable()
export class ScenarioService {
  private handleError: HandleError;
  private getUrl = (id = '') =>
    `${this.configService.getConfig().apiUrl}/scenarios/${id}`;
  private getCloneUrl = (id: string) =>
    `${this.configService.getConfig().apiUrl}/scenarios/${id}/clone`;
  private getReplaceUrl = (id: string) =>
    `${this.configService.getConfig().apiUrl}/scenarios/${id}/replace`;
  private getExecuteUrl = (id: string) =>
    `${this.configService.getConfig().apiUrl}/scenarios/${id}/execute`;
  private getDiagnoseUrl = (id: string, goalId: string) =>
    `${
      this.configService.getConfig().apiUrl
    }/scenarios/${id}/diagnose/${goalId}`;
  private getDiffUrl = (baseId: string, otherId: string) =>
    `${
      this.configService.getConfig().apiUrl
    }/scenarios/${baseId}/diff/${otherId}`;

  private static readonly REAL_EXECUTION: HttpParams = new HttpParams().set(
    'resultMode',
    'REAL'
  );

  constructor(
    private http: HttpClient,
    private configService: AppConfigService,
    private httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('scenarioService');
  }

  query(pageable: PageableRequest): Observable<PageableResponse<Scenario>> {
    return this.http
      .get<PageableResponse<Scenario>>(this.getUrl(), { params: pageable })
      .pipe(catchError(this.handleError('query scenario')));
  }

  get(id: string): Observable<Scenario> {
    return this.http
      .get<Scenario>(this.getUrl(id))
      .pipe(catchError(this.handleError('get scenario')));
  }

  create(scenario: Scenario): Observable<Scenario> {
    return this.http
      .post<PageableResponse<Scenario>>(this.getUrl(), scenario)
      .pipe(catchError(this.handleError('create scenario')));
  }

  clone(scenario: Scenario): Observable<Scenario> {
    return this.http
      .post<PageableResponse<Scenario>>(this.getCloneUrl(scenario.id), scenario)
      .pipe(catchError(this.handleError('clone scenario')));
  }

  update(scenario: Scenario): Observable<Scenario> {
    return this.http
      .put<PageableResponse<Scenario>>(this.getUrl(scenario.id), scenario)
      .pipe(catchError(this.handleError('update scenario')));
  }

  delete(id: string): Observable<any> {
    return this.http
      .delete<any>(this.getUrl(id))
      .pipe(catchError(this.handleError('delete scenario')));
  }

  replace(scenario: Scenario): Observable<Scenario> {
    return this.http
      .put<PageableResponse<Scenario>>(
        `${this.getReplaceUrl(scenario.id)}`,
        scenario
      )
      .pipe(catchError(this.handleError('replace scenario')));
  }

  execute(id: string): Observable<any> {
    return this.http
      .get<any>(`${this.getExecuteUrl(id)}`, {
        params: ScenarioService.REAL_EXECUTION,
      })
      .pipe(catchError(this.handleError('execute scenario')));
  }

  diagnose(id: string, goalId: string): Observable<any> {
    return this.http
      .get<any>(`${this.getDiagnoseUrl(id, goalId)}`, {
        params: ScenarioService.REAL_EXECUTION,
      })
      .pipe(catchError(this.handleError('execute scenario')));
  }

  diff(baseScenarioId: string, otherScenarioId: string): Observable<any> {
    return this.http
      .get<any>(`${this.getDiffUrl(baseScenarioId, otherScenarioId)}`)
      .pipe(catchError(this.handleError('scenario diff')));
  }
}
