import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HandleError, HttpErrorHandler } from './http-error-handler.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PageableResponse, PageableRequest, FinancialWrapper, FinancialClass, FinancialPortfolio, FinancialProfile } from '../model';
import { FinancialAsset } from '../model/financial/financial.asset';
import { AppConfigService } from './app.config.service';

@Injectable()
export class FinancialAssetsService {
    private getUrl = (scenarioId, personId, id = '') => `${this.configService.getConfig().apiUrl}/scenarios/${scenarioId}/persons/${personId}/assets/financial/${id}`;
    private getPortfoliosUrl = (scenarioId, personId, id = '') => `${this.configService.getConfig().apiUrl}/scenarios/${scenarioId}/persons/${personId}/assets/portfolio/${id}`;
    private getWrappersUrl = () => `${this.configService.getConfig().apiUrl}/scenarios/reference/assets/financial/wrappers`;
    private getClassesUrl = () => `${this.configService.getConfig().apiUrl}/scenarios/reference/assets/financial/classes`;
    private getProfilesUrl = () => `${this.configService.getConfig().apiUrl}/scenarios/reference/assets/financial/profiles`;

    private handleError: HandleError;

    constructor(
        private http: HttpClient,
        private configService: AppConfigService,
        private httpErrorHandler: HttpErrorHandler
    ) {
        this.handleError = httpErrorHandler.createHandleError('liabilityService');
    }

    // Financial assets
    queryFinancialAssets(scenarioId: string, personId: string, pageable: PageableRequest): Observable<PageableResponse<FinancialAsset>> {
        return this.http.get<PageableResponse<FinancialAsset>>(this.getUrl(scenarioId, personId), { params: pageable })
            .pipe(catchError(this.handleError('query financial assets')));
    }

    getFinancialAsset(scenarioId: string, personId: string, id: string): Observable<FinancialAsset> {
        return this.http.get<FinancialAsset>(this.getUrl(scenarioId, personId, id))
            .pipe(catchError(this.handleError('get financial asset')));
    }

    createFinancialAsset(scenarioId: string, personId: string, asset: FinancialAsset): Observable<FinancialAsset> {
        return this.http.post<FinancialAsset>(this.getUrl(scenarioId, personId), asset)
            .pipe(catchError(this.handleError('create financial asset')));
    }

    updateFinancialAsset(scenarioId: string, personId: string, asset: FinancialAsset): Observable<FinancialAsset> {
        return this.http.put<FinancialAsset>(this.getUrl(scenarioId, personId, asset.id), asset)
            .pipe(catchError(this.handleError('update financial asset')));
    }

    deleteFinancialAsset(scenarioId: string, personId: string, id: string): Observable<any> {
        return this.http.delete<FinancialAsset>(this.getUrl(scenarioId, personId, id))
            .pipe(catchError(this.handleError('delete financial asset')));
    }

    queryFinancialPortfolios(scenarioId: string, personId: string, pageable: PageableRequest): Observable<PageableResponse<FinancialPortfolio>> {
        return this.http.get<PageableResponse<FinancialPortfolio>>(this.getPortfoliosUrl(scenarioId, personId), { params: pageable })
            .pipe(catchError(this.handleError('query financial portfolios')));
    }

    getFinancialPortfolio(scenarioId: string, personId: string, id: string): Observable<FinancialPortfolio> {
        return this.http.get<FinancialPortfolio>(this.getPortfoliosUrl(scenarioId, personId, id))
            .pipe(catchError(this.handleError('get financial portfolio')));
    }

    createFinancialPortfolio(scenarioId: string, personId: string, asset: FinancialPortfolio): Observable<FinancialPortfolio> {
        return this.http.post<FinancialPortfolio>(this.getPortfoliosUrl(scenarioId, personId), asset)
            .pipe(catchError(this.handleError('create financial portfolio')));
    }

    updateFinancialPortfolio(scenarioId: string, personId: string, asset: FinancialPortfolio): Observable<FinancialPortfolio> {
        return this.http.put<FinancialPortfolio>(this.getPortfoliosUrl(scenarioId, personId, asset.id), asset)
            .pipe(catchError(this.handleError('update financial portfolio')));
    }

    deleteFinancialPortfolio(scenarioId: string, personId: string, id: string): Observable<any> {
        return this.http.delete<FinancialPortfolio>(this.getPortfoliosUrl(scenarioId, personId, id))
            .pipe(catchError(this.handleError('delete financial portfolio')));
    }


    // Wrappers, classes, institutions
    queryFinancialWrappers(pageable: PageableRequest): Observable<PageableResponse<FinancialWrapper>> {
        return this.http.get<PageableResponse<FinancialWrapper>>(this.getWrappersUrl(), { params: pageable })
            .pipe(catchError(this.handleError('query financial assets')));
    }

    queryFinancialClasses(pageable: PageableRequest): Observable<PageableResponse<FinancialClass>> {
        return this.http.get<PageableResponse<FinancialClass>>(this.getClassesUrl(), { params: pageable })
            .pipe(catchError(this.handleError('query financial classes')));
    }

    queryFinancialProfiles(pageable: PageableRequest): Observable<PageableResponse<FinancialProfile>> {
        return this.http.get<PageableResponse<FinancialProfile>>(this.getProfilesUrl(), { params: pageable })
            .pipe(catchError(this.handleError('query financial profiles')));
    }

}