import { RepaymentType, MortgageType } from '../enums';
import { Constants } from 'src/app/app.constants';
import { GoalType, Goal } from './goal.class';
import { Scenario } from '../household';
import { ScenarioUtils } from 'src/app/utils/scenario.utils';
import * as moment from 'moment/moment';

/* Base Funding Source */
export abstract class FundingSource {
    public class: string;
    public id: string;
    public name: string;
}

/* Default liquid assets funding source */
export class LiquidAssetsFundingSource extends FundingSource {
    wrappers: Array<string> = ['GENERAL_INVESTMENT_ACCOUNT', 'TAX_ADVANTAGED', 'PENSION'];
    constructor() {
        super();
        this.class = 'LiquidAssetsFundingSource';
        this.name = 'Liquid Assets';
    }
}

export class OtherLoanFundingSource extends FundingSource {
    public amount: number;
    public currency: string;
    public repaymentType: RepaymentType;
    public annualInterestRate: number;
    public termYears: number;
    constructor() {
        super();
        this.class = 'OtherLoanFundingSource';
        this.name = 'Loan';
        this.currency = Constants.LOCALE_CONFIG[localStorage.getItem(Constants.LOCAL_STORAGE_LOCALE)].currency;
        this.repaymentType = RepaymentType.PRINCIPAL_AMORTIZATION;
    }
}

export class SaveFundingSource extends FundingSource {
    public class: string;
    public saveInYears: number;

    constructor() {
        super();
        this.class = 'SaveFundingSource';
        this.name = 'Save in years';
    }
}

export class SaveFundingSourceBuilder {
    private _instance = new SaveFundingSource();

    public withName(name: string) {
        this._instance.name = name;
        return this;
    }

    public withSaveInYears(saveInYears: number) {
        this._instance.saveInYears = saveInYears;
        return this;
    }

    public build() {
        return this._instance;
    }
}

export class ResidentialPropertyFundingSource extends FundingSource {
    public class: string;
    public propertyAssetId: string;
    
    constructor() {
        super();
        this.class = 'ResidentialPropertyFundingSource';
        this.name = 'Residential property';
    }
}

export class ResidentialPropertyFundingSourceBuilder {
    private _instance = new ResidentialPropertyFundingSource();

    public withName(name: string) {
        this._instance.name = name;
        return this;
    }

    public withPropertyAssetId(id: string) {
        this._instance.propertyAssetId = id;
        return this;
    }

    public build() {
        return this._instance;
    }
}

export class InvestmentPropertyFundingSource extends FundingSource {
    public class: string;
    public propertyAssetId: string;
    
    constructor() {
        super();
        this.class = 'InvestmentPropertyFundingSource';
        this.name = 'Investment property';
    }
}

export class InvestmentPropertyFundingSourceBuilder {
    private _instance = new InvestmentPropertyFundingSource();

    public withName(name: string) {
        this._instance.name = name;
        return this;
    }

    public withPropertyAssetId(id: string) {
        this._instance.propertyAssetId = id;
        return this;
    }

    public build() {
        return this._instance;
    }
}

export class FutureInvestmentPropertyAssetFundingSource extends FundingSource {
    public class: string;
    public goalId: string;
    
    constructor() {
        super();
        this.class = 'FutureInvestmentPropertyAssetFundingSource';
        this.name = 'Future investment property';
    }
}

export class FutureInvestmentPropertyAssetFundingSourceBuilder {
    private _instance = new FutureInvestmentPropertyAssetFundingSource();

    public withName(name: string) {
        this._instance.name = name;
        return this;
    }

    public withGoalId(id: string) {
        this._instance.goalId = id;
        return this;
    }

    public build() {
        return this._instance;
    }
}

export class FutureResidentialPropertyAssetFundingSource extends FundingSource {
    public class: string;
    public goalId: string;
    
    constructor() {
        super();
        this.class = 'FutureResidentialPropertyAssetFundingSource';
        this.name = 'Future resudential property';
    }
}

export class FutureResidentialPropertyAssetFundingSourceBuilder {
    private _instance = new FutureResidentialPropertyAssetFundingSource();

    public withName(name: string) {
        this._instance.name = name;
        return this;
    }

    public withGoalId(id: string) {
        this._instance.goalId = id;
        return this;
    }

    public build() {
        return this._instance;
    }
}

/* Mortgage funding source */
export class MortgageFundingSource extends FundingSource {

    public class: string;
    public amount: number;
    public currency: string;
    public mortgageType: MortgageType;
    public repaymentType: RepaymentType;
    public annualInterestRate: number;
    public termYears: number;

    constructor() {
        super();
        this.class = 'MortgageFundingSource';
        this.name = 'MortgageFundingSource';
        this.currency = Constants.LOCALE_CONFIG[localStorage.getItem(Constants.LOCAL_STORAGE_LOCALE)].currency;
        this.mortgageType = MortgageType.FIXED_RATE;
        this.repaymentType = RepaymentType.PRINCIPAL_AMORTIZATION;
    }

}

export class BankOfMumAndDadFundingSource extends FundingSource {
    public class: string;
    public amount: number;
    public currency: string;

    constructor() {
        super();
        this.class = 'BankOfMumAndDadFundingSource'
        this.name = 'BankOfMumAndDadFundingSource';
        this.currency = Constants.LOCALE_CONFIG[localStorage.getItem(Constants.LOCAL_STORAGE_LOCALE)].currency;
    }

}

export class UKStudentLoanFundingSource extends FundingSource {
    public class: string;
    public amount: number;
    public currency: string;

    constructor() {
        super();
        this.class = 'UKStudentLoanFundingSource';
        this.name = 'UKStudentLoanFundingSource';
        this.currency = Constants.LOCALE_CONFIG[localStorage.getItem(Constants.LOCAL_STORAGE_LOCALE)].currency;
    }
}

export class UKStudentLoanFundingSourceBuilder {
    private _instance = new UKStudentLoanFundingSource();

    public withName(name: string) {
        this._instance.name = name;
        return this;
    }

    public withAmount(amount: number) {
        this._instance.amount = amount;
        return this;
    }

    public build() {
        return this._instance;
    }
}

export class BankOfMumAndDadFundingSourceBuilder {
    private _instance = new BankOfMumAndDadFundingSource();

    public withName(name: string) {
        this._instance.name = name;
        return this;
    }

    public withAmount(amount: number) {
        this._instance.amount = amount;
        return this;
    }

    public build() {
        return this._instance;
    }
}

export class LiquidAssetsFundingSourceBuilder {
    private _instance = new LiquidAssetsFundingSource();

    public withName(name: string) {
        this._instance.name = name;
        return this;
    }

    public withISAEnabled(isa: boolean) {
        this._instance.wrappers = this._instance.wrappers.filter(w => w !== 'TAX_ADVANTAGED');
        if (isa) {
            this._instance.wrappers.push('TAX_ADVANTAGED');
        }
        return this;
    }

    public withPensionEnabled(pension: boolean) {
        this._instance.wrappers = this._instance.wrappers.filter(w => w !== 'PENSION');
        if (pension) {
            this._instance.wrappers.push('PENSION');
        }
        return this;
    }

    public withGIAEnabled(gia: boolean) {
        this._instance.wrappers = this._instance.wrappers.filter(w => w !== 'GENERAL_INVESTMENT_ACCOUNT');
        if (gia) {
            this._instance.wrappers.push('GENERAL_INVESTMENT_ACCOUNT');
        }
        return this;
    }

    public build() {
        return this._instance;
    }
}

export class MortgageFundingSourceBuilder {
    private _instance = new MortgageFundingSource();

    public withName(name: string) {
        this._instance.name = name;
        return this;
    }

    public withAmount(amount: number) {
        this._instance.amount = amount;
        return this;
    }

    public withTermYears(years: number) {
        this._instance.termYears = years;
        return this;
    }

    public withAnnualInterestRate(interestRate: number) {
        this._instance.annualInterestRate = interestRate;
        return this;
    }

    public build() {
        return this._instance;
    }
}

export class OtherLoanFundingSourceBuilder {
    private _instance = new OtherLoanFundingSource();

    public withName(name: string) {
        this._instance.name = name;
        return this;
    }

    public withAmount(amount: number) {
        this._instance.amount = amount;
        return this;
    }

    public withTermYears(years: number) {
        this._instance.termYears = years;
        return this;
    }

    public withAnnualInterestRate(interestRate: number) {
        this._instance.annualInterestRate = interestRate;
        return this;
    }

    public build() {
        return this._instance;
    }
}

export class FundingSourceUtils {
    static GOAL_TYPE_TO_AVAILABLE_FUNDING_SOURCES = {
        [GoalType.BUY_A_CAR]: ['LiquidAssetsFundingSource', 'SaveFundingSource', 'OtherLoanFundingSource', 'BankOfMumAndDadFundingSource', 'ResidentialPropertyFundingSource', 'InvestmentPropertyFundingSource', 'FutureResidentialPropertyAssetFundingSource', 'FutureInvestmentPropertyAssetFundingSource'],
        [GoalType.BUY_A_HOUSE]: ['LiquidAssetsFundingSource', 'MortgageFundingSource', 'SaveFundingSource', 'OtherLoanFundingSource', 'BankOfMumAndDadFundingSource', 'ResidentialPropertyFundingSource', 'InvestmentPropertyFundingSource', 'FutureResidentialPropertyAssetFundingSource', 'FutureInvestmentPropertyAssetFundingSource'],
        [GoalType.BUY_TO_LET]: ['LiquidAssetsFundingSource', 'MortgageFundingSource', 'SaveFundingSource', 'OtherLoanFundingSource', 'BankOfMumAndDadFundingSource', 'ResidentialPropertyFundingSource', 'InvestmentPropertyFundingSource', 'FutureResidentialPropertyAssetFundingSource', 'FutureInvestmentPropertyAssetFundingSource'],
        [GoalType.ELIMINATE_DEBT]: ['LiquidAssetsFundingSource', 'SaveFundingSource', 'OtherLoanFundingSource', 'BankOfMumAndDadFundingSource', 'ResidentialPropertyFundingSource', 'InvestmentPropertyFundingSource', 'FutureResidentialPropertyAssetFundingSource', 'FutureInvestmentPropertyAssetFundingSource'],
        [GoalType.GET_MARRIED]: ['LiquidAssetsFundingSource', 'SaveFundingSource', 'OtherLoanFundingSource', 'BankOfMumAndDadFundingSource', 'ResidentialPropertyFundingSource', 'InvestmentPropertyFundingSource', 'FutureResidentialPropertyAssetFundingSource', 'FutureInvestmentPropertyAssetFundingSource'],
        [GoalType.GO_TO_SCHOOL]: ['LiquidAssetsFundingSource', 'UKStudentLoanFundingSource', 'SaveFundingSource', 'OtherLoanFundingSource', 'BankOfMumAndDadFundingSource', 'ResidentialPropertyFundingSource', 'InvestmentPropertyFundingSource', 'FutureResidentialPropertyAssetFundingSource', 'FutureInvestmentPropertyAssetFundingSource'],
        [GoalType.HAVE_A_CHILD]: ['LiquidAssetsFundingSource', 'SaveFundingSource', 'OtherLoanFundingSource', 'BankOfMumAndDadFundingSource', 'ResidentialPropertyFundingSource', 'InvestmentPropertyFundingSource', 'FutureResidentialPropertyAssetFundingSource', 'FutureInvestmentPropertyAssetFundingSource'],
        [GoalType.RECURRING_USER_DEFINED]: ['LiquidAssetsFundingSource', 'SaveFundingSource', 'OtherLoanFundingSource', 'BankOfMumAndDadFundingSource', 'ResidentialPropertyFundingSource', 'InvestmentPropertyFundingSource', 'FutureResidentialPropertyAssetFundingSource', 'FutureInvestmentPropertyAssetFundingSource'],
        [GoalType.SABBATICAL]: ['LiquidAssetsFundingSource'],
        [GoalType.TRAVEL]: ['LiquidAssetsFundingSource', 'SaveFundingSource', 'OtherLoanFundingSource', 'BankOfMumAndDadFundingSource', 'ResidentialPropertyFundingSource', 'InvestmentPropertyFundingSource', 'FutureResidentialPropertyAssetFundingSource', 'FutureInvestmentPropertyAssetFundingSource'],
        [GoalType.USER_DEFINED]: ['LiquidAssetsFundingSource', 'SaveFundingSource', 'OtherLoanFundingSource', 'BankOfMumAndDadFundingSource', 'ResidentialPropertyFundingSource', 'InvestmentPropertyFundingSource', 'FutureResidentialPropertyAssetFundingSource', 'FutureInvestmentPropertyAssetFundingSource'],
        [GoalType.RETIREMENT]: []
    };

    static FUNDING_SOURCE_TYPES = {
        LiquidAssetsFundingSource: {
            id: 'LiquidAssetsFundingSource',
            name: 'Liquid Assets',
            getInstances: (scenario: Scenario, goal: Goal) => {
                if ((goal.fundingSources || []).filter(s => 'LiquidAssetsFundingSource' === s.class).length !== 0) {
                    return [];
                }
                return [new LiquidAssetsFundingSourceBuilder()
                    .withName(Constants.DEFAULT_LIQUID_ASSETS_FUNDING_SOURCE_NAME)
                    .build()]
            },
            create: (goal: Goal) => new LiquidAssetsFundingSourceBuilder()
                .withName(Constants.DEFAULT_LIQUID_ASSETS_FUNDING_SOURCE_NAME)
                .build()
        },
        SaveFundingSource: {
            id: 'SaveFundingSource',
            name: 'Save in years',
            getInstances: (scenario: Scenario, goal: Goal) => {
                if ((goal.fundingSources || []).filter(s => 'SaveFundingSource' === s.class).length !== 0) {
                    return [];
                }
                return [new SaveFundingSourceBuilder()
                    .withName(Constants.DEFAULT_SAVE_FUNDING_SOURCE_NAME)
                    .withSaveInYears(Constants.DEFAULT_SAVE_FUNDING_SOURCE_YEARS)
                    .build()]
            },
            create: (goal: Goal) => new SaveFundingSourceBuilder()
                .withName(Constants.DEFAULT_SAVE_FUNDING_SOURCE_NAME)
                .withSaveInYears(Constants.DEFAULT_SAVE_FUNDING_SOURCE_YEARS)
                .build()
        },
        OtherLoanFundingSource: {
            id: 'OtherLoanFundingSource',
            name: 'Other loan',
            getInstances: (scenario: Scenario, goal: Goal) => {
                return [new OtherLoanFundingSourceBuilder()
                    .withName(Constants.DEFAULT_OTHER_LOAN_FUNDING_SOURCE_NAME)
                    .withAnnualInterestRate(Constants.DEFAULT_OTHER_LOAN_INTEREST_RATE)
                    .withTermYears(Constants.DEFAULT_OTHER_LOAN_TERM_YEARS)
                    .withAmount(Constants.DEFAULT_OTHER_LOAN_AMOUNT_PERCENTAGE * goal.minimumAmount)
                    .build()]
            },
            create: (goal: Goal) => new OtherLoanFundingSourceBuilder()
                .withName(Constants.DEFAULT_OTHER_LOAN_FUNDING_SOURCE_NAME)
                .withAnnualInterestRate(Constants.DEFAULT_OTHER_LOAN_INTEREST_RATE)
                .withTermYears(Constants.DEFAULT_OTHER_LOAN_TERM_YEARS)
                .withAmount(Constants.DEFAULT_OTHER_LOAN_AMOUNT_PERCENTAGE * goal.minimumAmount)
                .build()
        },
        BankOfMumAndDadFundingSource: {
            id: 'BankOfMumAndDadFundingSource',
            name: 'Bank of mum and dad',
            getInstances: (scenario: Scenario, goal: Goal) => {
                if ((goal.fundingSources || []).filter(s => 'BankOfMumAndDadFundingSource' === s.class).length !== 0) {
                    return [];
                }
                return [new BankOfMumAndDadFundingSourceBuilder()
                    .withName(Constants.DEFAULT_BANK_OF_MUM_AND_DAD_FUNDING_SOURCE_NAME)
                    .withAmount(Constants.DEFAULT_BANK_OF_MUM_AND_DAD_AMOUNT_PERCENTAGE * goal.minimumAmount)
                    .build()]
            },
            create: (goal: Goal) => new BankOfMumAndDadFundingSourceBuilder()
                .withName(Constants.DEFAULT_BANK_OF_MUM_AND_DAD_FUNDING_SOURCE_NAME)
                .withAmount(Constants.DEFAULT_BANK_OF_MUM_AND_DAD_AMOUNT_PERCENTAGE * goal.minimumAmount)
                .build()
        },
        ResidentialPropertyFundingSource: {
            id: 'ResidentialPropertyFundingSource',
            name: 'Residential property',
            getInstances: (scenario: Scenario, goal: Goal) => 
                ScenarioUtils.getResidentialPropertyAssets(scenario)
                    .filter(r => (goal.fundingSources || [])
                        .filter(s => s.class === 'ResidentialPropertyFundingSource' && (s as ResidentialPropertyFundingSource).propertyAssetId === r.id)
                        .length === 0)
                .map(r => new ResidentialPropertyFundingSourceBuilder()
                    .withName(Constants.DEFAULT_RESIDENTIAL_PROPERTY_FUNDING_SOURCE_NAME)
                    .withPropertyAssetId(r.id)
                    .build()
                )
            ,
            create: (goal: Goal) => null
        },
        InvestmentPropertyFundingSource: {
            id: 'InvestmentPropertyFundingSource',
            name: 'Investment property',
            getInstances: (scenario: Scenario, goal: Goal) =>
                ScenarioUtils.getInvestmentPropertyAssets(scenario)
                    .filter(r => (goal.fundingSources || [])
                        .filter(s => s.class === 'InvestmentPropertyFundingSource' && (s as InvestmentPropertyFundingSource).propertyAssetId === r.id)
                        .length === 0)
                .map(r => new InvestmentPropertyFundingSourceBuilder()
                    .withName(Constants.DEFAULT_INVESTMENT_PROPERTY_FUNDING_SOURCE_NAME)
                    .withPropertyAssetId(r.id)
                    .build()
                )
            ,
            create: (goal: Goal) => null
        },
        FutureInvestmentPropertyAssetFundingSource: {
            id: 'FutureInvestmentPropertyAssetFundingSource',
            name: 'Future investment property',
            getInstances: (scenario: Scenario, goal: Goal) =>
                ScenarioUtils.getAllGoals(scenario)
                .filter(g => g.class === 'BuyToLetGoal')
                .filter(g => moment.utc(goal.startDate).isAfter(g.startDate))
                .filter(g => (goal.fundingSources || [])
                    .filter(s => s.class === 'FutureInvestmentPropertyAssetFundingSource' && (s as FutureInvestmentPropertyAssetFundingSource).goalId === g.id)
                    .length === 0)
                .map(r => new FutureInvestmentPropertyAssetFundingSourceBuilder()
                    .withName(Constants.DEFAULT_FUTURE_INVESTMENT_PROPERTY_FUNDING_SOURCE_NAME)
                    .withGoalId(r.id)
                    .build()
                )
            ,
            create: (goal: Goal) => null
        },
        FutureResidentialPropertyAssetFundingSource: {
            id: 'FutureResidentialPropertyAssetFundingSource',
            name: 'Future residential property',
            getInstances: (scenario: Scenario, goal: Goal) => 
                ScenarioUtils.getAllGoals(scenario)
                .filter(g => g.class === 'BuyAHouseGoal')
                .filter(g => moment.utc(goal.startDate).isAfter(g.startDate))
                .filter(g => (goal.fundingSources || [])
                    .filter(s => s.class === 'FutureResidentialPropertyAssetFundingSource' && (s as FutureResidentialPropertyAssetFundingSource).goalId === g.id)
                    .length === 0)
                .map(r => new FutureResidentialPropertyAssetFundingSourceBuilder()
                    .withName(Constants.DEFAULT_FUTURE_RESIDENTIAL_PROPERTY_FUNDING_SOURCE_NAME)
                    .withGoalId(r.id)
                    .build()
                )
            ,
            create: (goal: Goal) => null
        },
        MortgageFundingSource: {
            id: 'MortgageFundingSource',
            name: 'Mortgage',
            getInstances: (scenario: Scenario, goal: Goal) => {
                if ((goal.fundingSources || []).filter(s => 'MortgageFundingSource' === s.class).length !== 0) {
                    return [];
                }
                return [new MortgageFundingSourceBuilder()
                    .withAmount(Constants.DEFAULT_MORTGAGE_FUNDING_SOURCE_AMOUNT_PERCENTAGE * goal.minimumAmount)
                    .withAnnualInterestRate(Constants.DEFAULT_MORTGAGE_FUNDING_SOURCE_INTEREST_RATE)
                    .withName(Constants.DEFAULT_MORTGAGE_FUNDING_SOURCE_NAME)
                    .withTermYears(Constants.DEFAULT_MORTGAGE_FUNDING_SOURCE_YEARS)
                    .build()]
            },
            create: (goal: Goal) => [new MortgageFundingSourceBuilder()
                .withAmount(Constants.DEFAULT_MORTGAGE_FUNDING_SOURCE_AMOUNT_PERCENTAGE * goal.minimumAmount)
                .withAnnualInterestRate(Constants.DEFAULT_MORTGAGE_FUNDING_SOURCE_INTEREST_RATE)
                .withName(Constants.DEFAULT_MORTGAGE_FUNDING_SOURCE_NAME)
                .withTermYears(Constants.DEFAULT_MORTGAGE_FUNDING_SOURCE_YEARS)
                .build()]
        },
        UKStudentLoanFundingSource: {
            id: 'UKStudentLoanFundingSource',
            name: 'Student loan',
            getInstances: (scenario: Scenario, goal: Goal) => {
                if ((goal.fundingSources || []).filter(s => 'UKStudentLoanFundingSource' === s.class).length !== 0) {
                    return [];
                }
                return [new UKStudentLoanFundingSourceBuilder()
                    .withName(Constants.DEFAULT_STUDENT_LOAN_FUNDING_SOURC_NAME)
                    .withAmount(Constants.DEFAULT_STUDENT_LOAN_AMOUNT_PERCENTAGE * goal.minimumAmount)
                    .build()]
            },
            create: (goal: Goal) => new UKStudentLoanFundingSourceBuilder()
                .withName(Constants.DEFAULT_STUDENT_LOAN_FUNDING_SOURC_NAME)
                .withAmount(Constants.DEFAULT_STUDENT_LOAN_AMOUNT_PERCENTAGE * goal.minimumAmount)
                .build()
        }
    }
}