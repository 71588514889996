import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Resolve } from '@angular/router';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthConfig } from 'angular-oauth2-oidc';

export interface IConfig {
  locale: string;
  apiUrl: string;
  termsUrl: string;
  moneyHub: {
    clientId: string;
    redirectUri: string;
    apiUrl: string;
    authUrl: string;
    dataType: 'test' | 'api';
  };
  iress: {
    apiUrl: string;
    clientId: string;
    username: string;
    password: string;
  };
  trackingIds: string[];
  features: { [feature: string]: boolean };
  keycloak: {
    url: string;
    realm: string;
    clientId: string;
  };
  oAuthConfig: AuthConfig;
}

@Injectable()
export class AppConfigService implements Resolve<Observable<any>> {
  private appConfig;

  constructor(private http: HttpClient) {}

  public resolve() {
    if (this.appConfig) {
      return of(this.appConfig);
    }
    return this.http.get('assets/data/app.config.json').pipe(
      map((c) => {
        this.appConfig = c;
        return c;
      })
    );
  }

  public getConfig(): IConfig {
    return this.appConfig || environment;
  }
}
