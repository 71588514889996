import {
    RealmUserAccount
} from '../model';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, concatMap, map } from 'rxjs/operators';
import { HttpErrorHandler, HandleError } from './http-error-handler.service';
import { AppConfigService } from './app.config.service';

@Injectable()
export class RealmUserService {
    private getUrl = () => this.configService.getConfig().oAuthConfig["issuer"] + '/account';

    private handleError: HandleError;

    constructor(
        private http: HttpClient,
        private configService: AppConfigService,
        private httpErrorHandler: HttpErrorHandler
    ) {
        this.handleError = httpErrorHandler.createHandleError('loginService');
    }

    getAccount(): Observable<RealmUserAccount> {
        return this.http.get<any>(this.getUrl())
            .pipe(catchError(this.handleError('user account')));
    }

    upgradeGuest(request: any): Observable<RealmUserAccount> {
        return this.http.get<any>(this.getUrl())
            .pipe(concatMap(
                response => {
                    response.email = request.emailAddress;
                    return this.http.post<any>(this.getUrl(), response);
                }
            ))
            .pipe(catchError(this.handleError('guest upgrade')));
    }

}
