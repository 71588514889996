import { Injectable } from '@angular/core';
import { HandleError, HttpErrorHandler } from './http-error-handler.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PageableResponse, PageableRequest } from '../model';
import { GoalAchievability } from '../model/household/result/goal.achievability';
import { AppConfigService } from './app.config.service';

@Injectable()
export class ResultsBandedGoalAchievabilityService {
  private handleError: HandleError;
  private getUrl = () => this.configService.getConfig().apiUrl;

  constructor(
    private http: HttpClient,
    private configService: AppConfigService,
    private httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError(
      'resultsBandedGoalAchievabilityService'
    );
  }

  query(
    scenarioId: string,
    pageable: PageableRequest
  ): Observable<PageableResponse<GoalAchievability>> {
    return this.http
      .get<PageableResponse<GoalAchievability>>(
        `${this.getUrl()}/results/${scenarioId}/achievability/band/goal`,
        { params: pageable }
      )
      .pipe(catchError(this.handleError('query goal achievability')));
  }

  queryByBandId(
    scenarioId: string,
    bandId: string,
    pageable: PageableRequest
  ): Observable<PageableResponse<GoalAchievability>> {
    return this.http
      .get<PageableResponse<GoalAchievability>>(
        `${this.getUrl()}/results/${scenarioId}/achievability/band/${bandId}/goal`,
        { params: pageable }
      )
      .pipe(catchError(this.handleError('query goal achievability by bandId')));
  }

  queryByGoalId(
    scenarioId: string,
    goalId: string,
    pageable: PageableRequest
  ): Observable<PageableResponse<GoalAchievability>> {
    return this.http
      .get<PageableResponse<GoalAchievability>>(
        `${this.getUrl()}/results/${scenarioId}/achievability/band/goal/${goalId}`,
        { params: pageable }
      )
      .pipe(catchError(this.handleError('query goal achievability by goalId')));
  }

  getByGoalIdAndBandId(
    scenarioId: string,
    goalId: string,
    bandId: string,
    id: string
  ): Observable<GoalAchievability> {
    return this.http
      .get<GoalAchievability>(
        `${this.getUrl()}/results/${scenarioId}/achievability/band/${bandId}/goal/${goalId}`
      )
      .pipe(catchError(this.handleError('get goal achievability')));
  }
}
