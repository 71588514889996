import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Constants } from 'src/app/app.constants';
import { Scenario } from 'src/app/model';
import { AppConfigService } from 'src/app/services/app.config.service';

@Component({
  selector: 'app-what-if',
  templateUrl: './what-if.component.html',
  styleUrls: ['./what-if.component.scss'],
})
export class WhatIfComponent implements OnInit {
  @Input() whatIfScenarios = [];
  @Input() incomesDiff = 0;
  @Input() expensesDiff = 0;
  @Input() assetsDiff = 0;
  @Input() liabilitiesDiff = 0;
  @Input() insurancesDiff = 0;

  public newScenarioName = '';
  public comparison = false;
  public compare = false;
  public infoBoxOpen = true;
  public comparedScenarioId = '';
  public comparedScenarioName = '';
  private _locale: string;

  @Output() cloneCurrentScenario = new EventEmitter<string>();
  @Output() setAsCurrent = new EventEmitter<Scenario>();
  @Output() scenarioChange = new EventEmitter<string>();

  constructor(private configService: AppConfigService) {}

  ngOnInit(): void {
    this._locale = this.configService.getConfig().locale;
  }

  emitCloneCurrentScenario(scenarioName: string) {
    this.newScenarioName = '';
    this.cloneCurrentScenario.emit(scenarioName);
  }

  emitSetAsCurrent(scenario: Scenario) {
    this.setAsCurrent.emit(scenario);
  }

  emitOnScenarioChanged(comparedScenarioId: string) {
    this.scenarioChange.emit(comparedScenarioId);
  }

  onInfoBoxClose() {
    this.infoBoxOpen = false;
  }

  onScenarioSelect(scenario: Scenario) {
    if (this.compare) {
      this.comparedScenarioId = scenario.id;
      this.comparedScenarioName = scenario.name;
      this.comparison = true;
      this.emitOnScenarioChanged(this.comparedScenarioId);
    } else {
      this.emitSetAsCurrent(scenario);
    }
  }

  onToggleChange(event) {
    if (!event.checked) {
      this.comparison = false;
      this.comparedScenarioId = '';
    }
  }

  abs = (val: number) => Math.abs(val);

  get currency() {
    return Constants.LOCALE_CONFIG[this._locale].currencySymbol;
  }
}
