// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  height: 400px;
  display: block;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.content {
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.actions {
  flex: 1 1 0;
}`, "",{"version":3,"sources":["webpack://./src/app/components/overview/components/graph/solver-dialog/solver-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,cAAA;EACA,aAAA;EACA,sBAAA;EACA,2BAAA;AACF;;AAEA;EACE,WAAA;EACA,aAAA;EACA,sBAAA;EACA,2BAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AACF;;AAEA;EACE,WAAA;AACF","sourcesContent":[":host {\n  height: 400px;\n  display: block;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n}\n\n.content {\n  flex: 1 1 0;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n}\n\n.row {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}\n\n.actions {\n  flex: 1 1 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
