import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

  @Input() show: boolean;
  @Input() tutorialOn: boolean;


  tutorials = [
    { title: 'spinner title 1', descr: 'spinner descr 1' },
    { title: 'spinner title 2', descr: 'spinner descr 2' },
    { title: 'spinner title 3', descr: 'spinner descr 3' },
    { title: 'spinner title 4', descr: 'spinner descr 4' },
    { title: 'spinner title 5', descr: 'spinner descr 5' },
    { title: 'spinner title 6', descr: 'spinner descr 6' },
    { title: 'spinner title 7', descr: 'spinner descr 7' },
    { title: 'spinner title 8', descr: 'spinner descr 8' },
  ];

  activeTutorial;

  constructor() { }

  ngOnInit() {
    if (!!this.tutorialOn) {
      try {
        const activeIndex = Math.floor(Math.random() * (this.tutorials.length - 1)) + 1;
        this.activeTutorial = this.tutorials[activeIndex];
      } catch (ex) {
        console.error(ex);
      }
    }
  }

}
