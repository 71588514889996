// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  border-bottom: 1px solid var(--nvz-separator-border);
}
:host:last-child {
  border-bottom: none;
}

.mat-icon {
  display: inline;
}`, "",{"version":3,"sources":["webpack://./src/app/components/onboarding/balance/liability/liability.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,oDAAA;AACF;AAAE;EACE,mBAAA;AAEJ;;AAEA;EACE,eAAA;AACF","sourcesContent":[":host {\n  display: block;\n  border-bottom: 1px solid var(--nvz-separator-border);\n  &:last-child {\n    border-bottom: none;\n  }\n}\n\n.mat-icon {\n  display: inline;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
