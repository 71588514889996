import { BaseModel } from '../base.model';
import { Constants } from 'src/app/app.constants';
import { DateRangeType, Owner, Frequency } from '../enums';
import { FundingSource } from './funding.sources.class';
import { GoalRecurringGoalProperties } from './goal.properties.class';
import * as moment from 'moment/moment';

export enum GoalType {
  BUY_A_HOUSE = 'BUY_A_HOUSE',
  BUY_TO_LET = 'BUY_TO_LET',
  BUY_A_CAR = 'BUY_A_CAR',
  GO_TO_SCHOOL = 'GO_TO_SCHOOL',
  GET_MARRIED = 'GET_MARRIED',
  HAVE_A_CHILD = 'HAVE_A_CHILD',
  RETIREMENT = 'RETIREMENT',
  TRAVEL = 'TRAVEL',
  SABBATICAL = 'SABBATICAL',
  ELIMINATE_DEBT = 'ELIMINATE_DEBT',
  RECURRING_USER_DEFINED = 'RECURRING_USER_DEFINED',
  USER_DEFINED = 'USER_DEFINED',
}

export class Goal extends BaseModel {
  public owner: Owner;
  public priority: number;
  public frequency: string;
  public enabled: boolean;
  public minimumAmount: number;
  public desiredAmount: number;
  public currency: string;
  public startDate: string;
  public endDate: string;
  public startsOn: DateRangeType;
  public endsOn: DateRangeType;
  public type: GoalType;
  public icon: string;
  public properties: any;
  public fundingSources: Array<FundingSource> = new Array();

  constructor() {
    super();
    this.frequency = Frequency.ONE_OFF;
    this.enabled = true;
    this.currency =
      Constants.LOCALE_CONFIG[
        localStorage.getItem(Constants.LOCAL_STORAGE_LOCALE)
      ].currency;
    this.priority = Constants.DEFAULT_GOAL_PRIORITY;
  }

  compareTo(other: Goal) {
    return this.type === other.type && this.name === other.name;
  }

  setStartDate(date: string) {
    this.startDate = date;
    this.endDate = date;
  }

  getStartDate() {
    return this.startDate;
  }
}

export class BuyAHouseGoal extends Goal {
  public primary = true;

  constructor() {
    super();
    this.type = GoalType.BUY_A_HOUSE;
  }
}

export class BuyACarGoal extends Goal {
  constructor() {
    super();
    this.type = GoalType.BUY_A_CAR;
  }
}

export class GoToSchoolGoal extends Goal {
  constructor() {
    super();
    this.type = GoalType.GO_TO_SCHOOL;
  }
}

export class GetMarriedGoal extends Goal {
  constructor() {
    super();
    this.type = GoalType.GET_MARRIED;
  }
}

export class HaveAChildGoal extends Goal {
  constructor() {
    super();
    this.type = GoalType.HAVE_A_CHILD;
  }
}

export class RetirementGoal extends Goal {
  constructor() {
    super();
    this.type = GoalType.RETIREMENT;
  }
}

export class TravelGoal extends Goal {
  constructor() {
    super();
    this.type = GoalType.TRAVEL;
  }
}

export class EliminateDebtGoal extends Goal {
  constructor() {
    super();
    this.type = GoalType.ELIMINATE_DEBT;
  }
}

export class UserDefinedGoal extends Goal {
  constructor() {
    super();
    this.type = GoalType.USER_DEFINED;
  }
}

export class RecurringUserDefinedGoal extends Goal {
  constructor(recurringAmount: number) {
    super();
    this.type = GoalType.RECURRING_USER_DEFINED;
    this.properties = new GoalRecurringGoalProperties(recurringAmount);
  }
}

export class SabbaticalGoal extends Goal {
  static DEFAULT_PERCENTAGE_EXPENDITURE = 0.75;

  public percentageOfExpenditure: number;

  constructor() {
    super();
    this.type = GoalType.SABBATICAL;
    this.percentageOfExpenditure =
      SabbaticalGoal.DEFAULT_PERCENTAGE_EXPENDITURE;
  }

  public setStartDate = (date: string) => {
    this.startDate = date;
    this.endDate = moment(date, moment.ISO_8601).add('years', 1).toISOString();
  };
}
