import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[appInputValidation][ngModel]',
  standalone: true,
})
export class InputValidationDirective {
  @Input() max: string | number | null;
  @Input('appInputValidation') inputType: string | null;

  constructor(
    private el: ElementRef,
    private ngModel: NgModel
  ) {}

  @HostListener('focusout')
  @HostListener('input')
  onInput() {
    this.validate();
  }

  private validate() {
    let maxAllowed = this.max;
    const inputValue = this.el.nativeElement.value;
    let value = inputValue;
    if (this.inputType === 'percentage') {
      value = inputValue / 100;
      maxAllowed = 1;
    }
    if (isNaN(parseFloat(inputValue))) {
      this.ngModel.control?.setErrors({ required: true });
    } else if (value < 0) {
      this.ngModel.control?.setErrors({ negativeValue: true });
    } else if (this.max && value > maxAllowed) {
      this.ngModel.control.setErrors({ maxValue: true });
    } else if (value >= 0 || (this.max && value <= maxAllowed)) {
      this.ngModel.control?.setErrors(null);
    }
  }
}
