import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FundingSourceBaseComponent } from '../funding-source-base/funding-source-base';
import { Goal } from 'src/app/model/goal';
import { OtherLoanFundingSource, InvestmentPropertyFundingSource } from 'src/app/model/goal/funding.sources.class';
import { TypedGoalService } from 'src/app/services/typed.goal.service';
import { Scenario } from 'src/app/model';
import { ScenarioUtils } from 'src/app/utils';

@Component({
    selector: 'app-funding-source-investment-property',
    templateUrl: './investment-property-funding-source.component.html',
    styleUrls: ['./investment-property-funding-source.component.scss']
})
export class InvestmentPropertyFundingSourceComponent extends FundingSourceBaseComponent implements OnInit {

    @Input() goal: Goal;
    @Input() fundingSource: InvestmentPropertyFundingSource;
    @Input() scenario: Scenario;
    @Output() updateGoal: EventEmitter<any>;

    constructor(private typedGoalService: TypedGoalService) {
        super(typedGoalService);
    }

    ngOnInit() {

    }
    
    deleteSource() {
        this.goal.fundingSources = this.goal.fundingSources
            .filter(s => (s as InvestmentPropertyFundingSource).propertyAssetId !== this.fundingSource.propertyAssetId);
    }

    get availableProperties() {
        return ScenarioUtils.getInvestmentPropertyAssets(this.scenario)
            .filter(s => this.fundingSource.propertyAssetId === s.id
                || this.goal.fundingSources.filter(f => f.class === 'InvestmentPropertyFundingSource'
                    && (f as InvestmentPropertyFundingSource).propertyAssetId=== s.id).length === 0);
    }

}