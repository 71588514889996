import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HandleError, HttpErrorHandler } from './http-error-handler.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserAccount } from '../model';
import { AppConfigService } from './app.config.service';

@Injectable()
export class AccountService {
    getUrl = () => this.configService.getConfig().apiUrl + '/uaa/users/account';
    private handleError: HandleError;

    constructor(
            private http: HttpClient,
            private configService: AppConfigService,
            private httpErrorHandler: HttpErrorHandler
        ) {
            this.handleError = httpErrorHandler.createHandleError('accountService');
    }

    get(): Observable<UserAccount> {
        return this.http.get<UserAccount>(this.getUrl())
            .pipe(catchError(this.handleError('get account')));
    }

}