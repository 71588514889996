export interface FinancialAsset {
  assetClass: string;
  city?: string;
  country: string;
  currency: string;
  description?: string;
  id?: string;
  name: string;
  region?: string;
  source?: string;
  state?: string;
  valuationDate: string;
  value: number;
}
