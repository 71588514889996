import { Injectable } from '@angular/core';
import { HandleError, HttpErrorHandler } from './http-error-handler.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PageableResponse, PageableRequest, MortgageLiability, UnsecuredLiability, Liability } from '../model';
import { Service, QueryOnlyService, QueryOnlyServiceForPerson } from './service';
import { AppConfigService } from './app.config.service';

@Injectable()
export class LiabilityService implements QueryOnlyService<Liability>, QueryOnlyServiceForPerson<Liability> {
    private handleError: HandleError;
    private getUrl = (scenarioId, id = '') => `${this.configService.getConfig().apiUrl}/scenarios/${scenarioId}/liabilities/${id}`;
    private getUrlForPerson = (scenarioId, personId = '', id = '') => `${this.configService.getConfig().apiUrl}/scenarios/${scenarioId}/persons/${personId}/liabilities/${id}`;

    constructor(
        private http: HttpClient,
        private configService: AppConfigService,
        private httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('liabilityService');
    }

    query(scenarioId: string, pageable: PageableRequest): Observable<PageableResponse<Liability>> {
        return this.http.get<PageableResponse<Liability>>(this.getUrl(scenarioId), { params: pageable })
            .pipe(
                catchError(this.handleError('query liabilities'))
            );
    }

    get(scenarioId: string, id: string): Observable<Liability> {
        return this.http.get<Liability>(this.getUrl(scenarioId, id))
            .pipe(
                catchError(this.handleError('get liability'))
            );
    }

    queryForPerson(scenarioId: string, personId: string, pageable: PageableRequest): Observable<PageableResponse<Liability>> {
        return this.http.get<PageableResponse<Liability>>(this.getUrlForPerson(scenarioId, personId), { params: pageable })
            .pipe(
                catchError(this.handleError('query liabilities'))
            );
    }

    getForPerson(scenarioId: string, personId: string, id: string): Observable<Liability> {
        return this.http.get<Liability>(this.getUrlForPerson(scenarioId, personId, id))
            .pipe(
                catchError(this.handleError('get liability'))
            );
    }

}

@Injectable()
export class MortgageLiabilityService implements Service<MortgageLiability> {
    private handleError: HandleError;
    private getUrl = (scenarioId, id = '') => `${this.configService.getConfig().apiUrl}/scenarios/${scenarioId}/liabilities/mortgages/${id}`;

    constructor(
        private http: HttpClient,
        private configService: AppConfigService,
        private httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('mortgageLiabilityService');
    }

    query(scenarioId: string, pageable: PageableRequest): Observable<PageableResponse<MortgageLiability>> {
        return this.http.get<PageableResponse<MortgageLiability>>(this.getUrl(scenarioId), { params: pageable })
            .pipe(
                catchError(this.handleError('query mortgage liability'))
            );
    }

    get(scenarioId: string, id: string): Observable<MortgageLiability> {
        return this.http.get<MortgageLiability>(this.getUrl(scenarioId, id))
            .pipe(
                catchError(this.handleError('get mortgage liability'))
            );
    }

    create(scenarioId: string, liability: MortgageLiability): Observable<MortgageLiability> {
        return this.http.post<MortgageLiability>(this.getUrl(scenarioId), liability)
            .pipe(
                catchError(this.handleError('create mortgage liability'))
            );
    }

    update(scenarioId: string, liability: MortgageLiability): Observable<MortgageLiability> {
        return this.http.put<MortgageLiability>(this.getUrl(scenarioId, liability.id), liability)
            .pipe(
                catchError(this.handleError('update mortgage liability'))
            );
    }

    delete(scenarioId: string, id: string): Observable<any> {
        return this.http.delete<any>(this.getUrl(scenarioId, id))
            .pipe(
                catchError(this.handleError('delete mortgage liability'))
            );
    }

}

@Injectable()
export class UnsecuredLiabilityService implements Service<UnsecuredLiability> {
    private handleError: HandleError;
    private getUrl = (scenarioId, id = '') => `${this.configService.getConfig().apiUrl}/scenarios/${scenarioId}/liabilities/unsecured-debts/${id}`;

    constructor(
        private http: HttpClient,
        private configService: AppConfigService,
        private httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('unsecuredLiabilityService');
    }

    query(scenarioId: string, pageable: PageableRequest): Observable<PageableResponse<UnsecuredLiability>> {
        return this.http.get<PageableResponse<UnsecuredLiability>>(this.getUrl(scenarioId), { params: pageable })
            .pipe(
                catchError(this.handleError('query mortgage liability'))
            );
    }

    get(scenarioId: string, id: string): Observable<UnsecuredLiability> {
        return this.http.get<UnsecuredLiability>(this.getUrl(scenarioId, id))
            .pipe(
                catchError(this.handleError('get mortgage liability'))
            );
    }

    create(scenarioId: string, liability: UnsecuredLiability): Observable<UnsecuredLiability> {
        return this.http.post<MortgageLiability>(this.getUrl(scenarioId), liability)
            .pipe(
                catchError(this.handleError('create mortgage liability'))
            );
    }

    update(scenarioId: string, liability: UnsecuredLiability): Observable<UnsecuredLiability> {
        return this.http.put<UnsecuredLiability>(this.getUrl(scenarioId, liability.id), liability)
            .pipe(
                catchError(this.handleError('update mortgage liability'))
            );
    }

    delete(scenarioId: string, id: string): Observable<any> {
        return this.http.delete<any>(this.getUrl(scenarioId, id))
            .pipe(
                catchError(this.handleError('delete mortgage liability'))
            );
    }

}