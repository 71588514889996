import { BaseGrowthRate } from '../enums';


export abstract class GoalProperties { }

export class GoalBuyHouseProperties extends GoalProperties {
    readonly mortgageTermYears: number;
    readonly mortgageAmount: number;
    readonly mortgageRate: number;

    constructor(
        mortgageTermYears: number,
        mortgageAmount: number,
        mortgageRate: number) {
        super();
        this.mortgageTermYears = mortgageTermYears;
        this.mortgageAmount = mortgageAmount;
        this.mortgageRate = mortgageRate;
    }
}

export class GoalBuyToLetProperties extends GoalProperties {
    readonly annualNetRentalYieldPercentage: number;
    readonly toSurvivorPercentage: number;

    constructor(
        annualNetRentalYieldPercentage: number,
        toSurvivorPercentage: number) {
        super();
        this.annualNetRentalYieldPercentage = annualNetRentalYieldPercentage;
        this.toSurvivorPercentage = toSurvivorPercentage;
    }
}

export class GoalRecurringProperties extends GoalProperties {
    readonly recurringAmount: number;

    constructor(
        recurringAmount: number) {
        super();
        this.recurringAmount = recurringAmount;
    }
}

export class GoalSabbaticalProperties extends GoalProperties {
    readonly percentageOfIncome: number;
    readonly percentageOfExpenditure: number;

    constructor(
        percentageOfIncome: number,
        percentageOfExpenditure: number) {
        super();
        this.percentageOfIncome = percentageOfIncome;
        this.percentageOfExpenditure = percentageOfExpenditure;
    }
}

export class GoalEliminateDebtProperties extends GoalProperties {
    readonly liabilityAndDebtId: string;

    constructor(
        liabilityAndDebtId: string) {
        super();
        this.liabilityAndDebtId = liabilityAndDebtId;
    }
}

export class GoalRetirementProperties extends GoalProperties {
    static DEFAULT_PERCENTAGE = 1;

    percentageOfSurvivorExpenditureSpend: number;
    percentageOfPreRetirementSpendFirst10Years: number;
    percentageOfPreRetirementSpendAfterFirst10Years: number;
    tradeDownHouse: boolean;
    tradeDownNewHousePercentage: number;
    tradeDownDate: string;

    constructor(tradeDownYear?: number) {
        super();
        this.percentageOfSurvivorExpenditureSpend = GoalRetirementProperties.DEFAULT_PERCENTAGE;
        this.percentageOfPreRetirementSpendFirst10Years = GoalRetirementProperties.DEFAULT_PERCENTAGE;
        this.percentageOfPreRetirementSpendAfterFirst10Years = GoalRetirementProperties.DEFAULT_PERCENTAGE;
        this.tradeDownHouse = false;
        this.tradeDownNewHousePercentage = GoalRetirementProperties.DEFAULT_PERCENTAGE;
    }
}

export class GoalRecurringGoalProperties extends GoalProperties {
    static DEFAULT_SPREAD = 0;
    readonly recurringAmount: number;
    readonly frequencyMultiplier: number;
    readonly growthRate: BaseGrowthRate = BaseGrowthRate.CALCULATED;
    readonly spreadOverGrowthRate: number = GoalRecurringGoalProperties.DEFAULT_SPREAD;
    constructor(recurringAmount: number) {
        super();
        this.recurringAmount = recurringAmount;
        this.growthRate = BaseGrowthRate.CALCULATED;
        this.frequencyMultiplier = 1;
        this.spreadOverGrowthRate = GoalRecurringGoalProperties.DEFAULT_SPREAD;
    }
}

export class GoalValueFunctionOfEarningProperties extends GoalProperties {
    readonly coefficient: number;
    constructor(coefficient: number) {
        super();
        this.coefficient = coefficient;
    }
}
