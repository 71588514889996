import { AuthService } from './../services/auth.service';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Constants } from '../app.constants';
import { NotifierService } from 'angular-notifier';

@Injectable()
export class BasicInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    let res = req;
    if (req.method === 'PUT') {
      res = req.clone({
        headers: req.headers.set('Content-type', 'application/json'),
      });
    }
    return next.handle(res);
  }
}
