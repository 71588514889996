import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '../edit/edit.dialog.component';
import { Scenario } from 'src/app/model';

export interface DeleteScenarioDialogData {
    scenario: Scenario
};

@Component({
    selector: 'app-delete-scenario-dialog-component',
    templateUrl: './delete.scenario.dialog.component.html',
    styleUrls: ['./delete.scenario.dialog.component.scss']
})
export class DeleteScenarioDialogComponent implements OnInit {
 
    constructor(
        private dialogRef: MatDialogRef<DeleteScenarioDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private dialogData: DeleteScenarioDialogData
    ) {}

    ngOnInit() {

    }

    get scenarioName() {
        return this.dialogData.scenario.name;
    }

    cancel = () => this.dialogRef.close(false);

    delete = () => this.dialogRef.close(true);
}