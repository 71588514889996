import {
  Component,
  Input,
  Output,
  EventEmitter,
  ComponentFactoryResolver,
  ViewChildren,
  ViewContainerRef,
  QueryList,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { Goal } from 'src/app/model/goal';
import { EditGoalContext } from 'src/app/model/goal/edit.goal.context.class';
import { Constants } from 'src/app/app.constants';
import { MatDialog } from '@angular/material/dialog';
import { EditGoalBaseComponent } from '../edit-goal-base/edit-goal-base.component';

@Component({
  selector: 'app-edit-goal-recurring',
  templateUrl: './edit-goal-recurring.component.html',
  styleUrls: ['./edit-goal-recurring.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditGoalRecurringComponent extends EditGoalBaseComponent {
  private static readonly RECURRING_PERIODS = [
    { period: 1, description: 'Annually' },
    { period: 2, description: 'Every 2 years' },
    { period: 3, description: 'Every 3 years' },
    { period: 4, description: 'Every 4 years' },
    { period: 5, description: 'Every 5 years' },
    { period: 6, description: 'Every 6 years' },
    { period: 7, description: 'Every 7 years' },
    { period: 8, description: 'Every 8 years' },
    { period: 9, description: 'Every 9 years' },
    { period: 10, description: 'Every 10 years' },
  ];

  private _minEndDate: string;

  @Input() ctx: EditGoalContext;
  @Output() deleteGoal: EventEmitter<Goal> = new EventEmitter();
  @Output() updateGoal: EventEmitter<Goal> = new EventEmitter();
  @ViewChildren('sourceCmp', { read: ViewContainerRef })
  viewComponents: QueryList<ViewContainerRef>;

  constructor(
    private resolver: ComponentFactoryResolver,
    private dialog: MatDialog,
    private ref: ChangeDetectorRef
  ) {
    super(resolver, dialog, ref);
  }

  ngOnInit() {
    this._minEndDate = this.ctx.goal.startDate;
  }

  ngAfterViewInit() {
    this.renderFundingSources();
  }

  onStartYearChange(value: string) {
    this._minEndDate = value;
    this.ctx.goal.startDate = value;
    if (this._minEndDate > this.ctx.goal.endDate) {
      this.ctx.goal.endDate = this._minEndDate;
    }
  }

  onEndYearChange(value: string) {
    this.ctx.goal.endDate = value;
  }

  get minYear() {
    return new Date().getFullYear();
  }

  get minEndYear() {
    return new Date(this._minEndDate).getFullYear();
  }

  get maxYear() {
    return new Date().getFullYear() + Constants.GOAL_YEAR_RANGE;
  }

  get periods() {
    return EditGoalRecurringComponent.RECURRING_PERIODS;
  }
}
