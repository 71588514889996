import { FormGroup, ValidatorFn, ValidationErrors } from '@angular/forms';

export class PasswordValidator {
  static matchPassword =
    (passwordField: string, confirmationField: string): ValidatorFn =>
    (control: FormGroup): ValidationErrors | null => {
      const password = control.get(passwordField);
      const confirm = control.get(confirmationField);
      if (password.value !== confirm.value) {
        control.get(confirmationField).setErrors(
          Object.assign({}, control.get(confirmationField).errors, {
            passwordsMismatch: true,
          })
        );
        return {};
      }
      const newErrors = Object.assign(
        {},
        control.get(confirmationField).errors
      );
      delete newErrors['passwordsMismatch'];
      control
        .get(confirmationField)
        .setErrors(Object.keys(newErrors).length === 0 ? null : newErrors);
      return null;
    };
}
