import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';

import { Observable, of, throwError } from 'rxjs';

import { MessageService } from './message.service';

/** Type of the handleError function returned by HttpErrorHandler.createHandleError */
export type HandleError = <T>(
  operation?: string,
  result?: T
) => (error: HttpErrorResponse) => Observable<any>;

/** Handles HttpClient errors */
@Injectable()
export class HttpErrorHandler {
  constructor(
    private messageService: MessageService,
    private notifierService: NotifierService
  ) {}

  /** Create curried handleError function that already knows the service name */
  createHandleError =
    (serviceName = '') =>
    (operation = 'operation') =>
      this.handleError(serviceName, operation);

  /**
   * Returns a function that handles Http operation failures.
   * This error handler lets the app continue to run as if no error occurred.
   * @param serviceName = name of the data service that attempted the operation
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  handleError(serviceName = '', operation = 'operation') {
    return (error: HttpErrorResponse): Observable<any> => {
      // TODO: send the error to remote logging infrastructure

      let message =
        error.error.message ||
        `server returned code ${error.status} with body "${error.message}"`;

      if (error.error && error.error.message === 'Validation failed') {
        message += '. ' + this.buildValidationMessage(error);
      }

      this.messageService.add(
        `${serviceName}: ${operation} failed: ${message}`
      );
      // Let the app keep running by returning a safe result.
      return throwError(message);
    };
  }

  buildValidationMessage(error: HttpErrorResponse): string {
    if (!error.error.errors) {
      return '';
    }

    return error.error.errors.map((e) => `${e.field} ${e.message}`).join('\n');
  }
}
