import { Injectable, ViewContainerRef, ComponentFactory, ComponentFactoryResolver } from '@angular/core';
import { EditGoalBaseComponent, EditGoalRetirementComponent, EditGoalSabbaticalComponent, EditGoalEliminateDebtComponent, EditGoalBuyHouseComponent, EditGoalBuyCarComponent } from '../components/common/edit-goals';
import { GoalType, Goal } from '../model/goal';
import { Scenario } from '../model';
import { EditGoalRecurringComponent } from '../components/common/edit-goals/edit-goal-recurring/edit-goal-recurring.component';

class GoalComponentFactoryEntry {
    goalType: string;
    factory: ComponentFactory<EditGoalBaseComponent>
}

@Injectable()
export class EditGoalFormFactory {

    private _componentsMap: { type: GoalType | string, component: typeof EditGoalBaseComponent }[] = [
        { type: 'DEFAULT', component: EditGoalBaseComponent },
        { type: GoalType.RETIREMENT, component: EditGoalRetirementComponent },
        { type: GoalType.BUY_A_HOUSE, component: EditGoalBuyHouseComponent },
        { type: GoalType.BUY_TO_LET, component: EditGoalBuyHouseComponent },
        { type: GoalType.BUY_A_CAR, component: EditGoalBuyCarComponent },
        { type: GoalType.SABBATICAL, component: EditGoalSabbaticalComponent },
        { type: GoalType.ELIMINATE_DEBT, component: EditGoalEliminateDebtComponent },
        { type: GoalType.RECURRING_USER_DEFINED, component: EditGoalRecurringComponent }
    ];

    private _goalComponentFactories: GoalComponentFactoryEntry[];

    constructor(
        private resolver: ComponentFactoryResolver
    ) {
        this._goalComponentFactories = [];
    }

    appendEditForm(scenarioId: string, goal: Goal, scenario: Scenario, parent: ViewContainerRef) {
        let cmpFactoryEntry = this._goalComponentFactories.find(c => c.goalType === goal.type);
        if (!cmpFactoryEntry) {
            const cmpEntry = this._componentsMap.find(c => c.type === goal.type) || this._componentsMap.find(c => c.type === 'DEFAULT');
            const cmpFactory = this.resolver.resolveComponentFactory(cmpEntry.component);
            cmpFactoryEntry = { goalType: goal.type, factory: cmpFactory };
            this._goalComponentFactories.push(cmpFactoryEntry);
        }

        const ref = parent.createComponent(cmpFactoryEntry.factory);
        ref.instance.ctx = {
            goal: goal,
            scenario: scenario,
            scenarioId: scenarioId
        };
    }

    getComponent(goal: Goal) {
        const cmpEntry = this._componentsMap.find(c => c.type === goal.type) || this._componentsMap.find(c => c.type === 'DEFAULT');
        return cmpEntry.component;
    }
}