import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IMoneyhubBank } from 'src/app/model';

@Component({
  selector: 'app-mh-renew-dialog',
  templateUrl: './renew-dialog.component.html',
  styleUrls: ['./renew-dialog.component.scss'],
})
export class MHRenewDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<MHRenewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { bank: IMoneyhubBank }
  ) {}

  ngOnInit() {}
}
