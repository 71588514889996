import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FeatureDirective } from './feature.directive';

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    FeatureDirective,
  ],
  declarations: [
    FeatureDirective
  ],
})
export class FeatureModule { }
