export interface Person {
  description?: string;
  expectedRetirementAge: number;
  gender: Gender;
  healthStatus: HealthStatus;
  id?: string;
  jobType: JobType;
  maritalStatus: MaritalStatus;
  educationLevel: EducationLevel;
  lastName?: string;
  name?: string;
  primary: boolean;
  yearOfBirth: number;
}

export enum EducationLevel {
  NO_EDUCATION = 'NO_EDUCATION',
  PRIMARY_EDUCATION = 'PRIMARY_EDUCATION',
  SECONDARY_EDUCATION = 'SECONDARY_EDUCATION',
  VOCATIONAL_EDUCATION = 'VOCATIONAL_EDUCATION',
  TERTIARY_1_EDUCATION = 'TERTIARY_1_EDUCATION',
  TERTIARY_2_EDUCATION = 'TERTIARY_2_EDUCATION',
  TERTIARY_3_EDUCATION = 'TERTIARY_3_EDUCATION',
  TERTIARY_4_EDUCATION = 'TERTIARY_4_EDUCATION',
}

export enum HealthStatus {
  CRITICALLY_ILL = 'CRITICALLY_ILL',
  ILL = 'ILL',
  LONG_TERM_CARE = 'LONG_TERM_CARE',
  DISABLED = 'DISABLED',
  POOR = 'POOR',
  SMOKER = 'SMOKER',
  AVERAGE = 'AVERAGE',
  GOOD = 'GOOD',
  EXCELLENT = 'EXCELLENT',
}

export enum JobType {
  SEDENTARY = 'SEDENTARY',
  ACTIVE = 'ACTIVE',
  PHYSICAL = 'PHYSICAL',
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  TRANS_MALE = 'TRANS_MALE',
  TRANS_FEMALE = 'TRANS_FEMALE',
  OTHER_MALE = 'OTHER_MALE',
  OTHER_FEMALE = 'OTHER_FEMALE',
  OTHER = 'OTHER',
}

export enum MaritalStatus {
  SINGLE = 'SINGLE',
  MARRIED = 'MARRIED',
  UNSPECIFIED = 'UNSPECIFIED',
  ENGAGED = 'ENGAGED',
  WIDOWED = 'WIDOWED',
  SEPARATED = 'SEPARATED',
  DIVORCED = 'DIVORCED',
  IN_A_RELATIONSHIP = 'IN_A_RELATIONSHIP',
  IN_A_CIVIL_UNION = 'IN_A_CIVIL_UNION',
  IN_A_DOMESTIC_PARTNERSHIP = 'IN_A_DOMESTIC_PARTNERSHIP',
}
