import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private router: Router,
    private oAuthService: OAuthService
  ) {}

  canActivate() {
    if (!this.oAuthService.hasValidAccessToken()) {
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }

  // public async isAccessAllowed(
  //   route: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot,
  // ) {

  // Force the user to log in if currently unauthenticated.
  // if (!this.authenticated) {
  //   await this.keycloak.login({
  //     redirectUri: window.location.origin + state.url
  //   });
  // }

  // Get the roles required from the route.
  //const requiredRoles = ['role_ru'];

  // Allow the user to proceed if no additional roles are required to access the route.
  // if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
  //   return true;
  // }

  // Allow the user to proceed if all the required roles are present.
  //return requiredRoles.every((role) => this.roles.includes(role));
  //}
}
