import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HandleError, HttpErrorHandler } from './http-error-handler.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PageableResponse, PageableRequest } from '../model';
import { Band } from '../model/household/result/band';
import { AppConfigService } from './app.config.service';

@Injectable()
export class ResultsScenarioBandService {
    private handleError: HandleError;
    private getUrl = () => this.configService.getConfig().apiUrl;

    constructor(
        private http: HttpClient,
        private configService: AppConfigService,
        private httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('resultsScenarioBandService');
    }

    query(scenarioId: string, pageable: PageableRequest): Observable<PageableResponse<Band>> {
        return this.http.get<PageableResponse<Band>>(`${this.getUrl()}/results/${scenarioId}/bands`, { params: pageable })
            .pipe(
                catchError(this.handleError('query band'))
            );
    }

    get(scenarioId: string, bandId: string): Observable<Band> {
        return this.http.get<Band>(`${this.getUrl()}/results/${scenarioId}/bands/${bandId}`)
            .pipe(
                catchError(this.handleError('get band'))
            );
    }

}