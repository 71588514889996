import { BaseModel } from '../../base.model';
import { DateRangeType, Frequency, BaseGrowthRate } from '../../enums';
import { Constants } from 'src/app/app.constants';
import { IMoneyhubTransactionData } from '../..';

export class LivingExpense extends BaseModel {
  amount: number;
  currency: string;
  startDate: string;
  startsOn: DateRangeType;
  endDate: string;
  endsOn: DateRangeType;
  frequency: Frequency;
  growthRate: BaseGrowthRate;
  spreadOverGrowthRate: number;
  nonDiscretionaryPercentage: number;
  survivorAdjustmentPercentage: number;
  isFromLinkedAccounts?: boolean;
  linkedLivingExpenses?: IMoneyhubTransactionData[];
}

export class LivingExpenseBuilder {
  private _livingExpense: LivingExpense;

  constructor() {
    this._livingExpense = new LivingExpense();
    this._livingExpense.source = Constants.DEFAULT_SOURCE;
    return this;
  }

  public id(id: string) {
    this._livingExpense.id = id;
    return this;
  }

  public name(name: string) {
    this._livingExpense.name = name;
    return this;
  }

  public description(description: string) {
    this._livingExpense.description = description;
    return this;
  }

  public amount(amount: number) {
    this._livingExpense.amount = amount;
    return this;
  }

  public currency(currency: string) {
    this._livingExpense.currency = currency;
    return this;
  }

  public startDate(startDate: string) {
    this._livingExpense.startDate = startDate;
    return this;
  }

  public startsOn(startsOn: DateRangeType) {
    this._livingExpense.startsOn = startsOn;
    return this;
  }

  public endDate(endDate: string) {
    this._livingExpense.endDate = endDate;
    return this;
  }

  public endsOn(endsOn: DateRangeType) {
    this._livingExpense.endsOn = endsOn;
    return this;
  }

  public frequency(frequency: Frequency) {
    this._livingExpense.frequency = frequency;
    return this;
  }

  public growthRate(growthRate: BaseGrowthRate) {
    this._livingExpense.growthRate = growthRate;
    return this;
  }

  public spreadOverGrowthRate(spreadOverGrowthRate: number) {
    this._livingExpense.spreadOverGrowthRate = spreadOverGrowthRate;
    return this;
  }

  public nonDiscretionaryPercentage(nonDiscretionaryPercentage: number) {
    this._livingExpense.nonDiscretionaryPercentage = nonDiscretionaryPercentage;
    return this;
  }

  public survivorAdjustmentPercentage(survivorAdjustmentPercentage: number) {
    this._livingExpense.survivorAdjustmentPercentage =
      survivorAdjustmentPercentage;
    return this;
  }

  public linkedAccount(linked: boolean) {
    this._livingExpense.isFromLinkedAccounts = linked;
    return this;
  }

  public linkedLivingExpenses(expenses: IMoneyhubTransactionData[]) {
    this._livingExpense.linkedLivingExpenses = expenses;
    return this;
  }

  public build() {
    return this._livingExpense;
  }
}
