import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
  OnDestroy,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AppConfigService } from '../../../../services/app.config.service';
import { MoneyhubService } from '../../../../services/moneyhub.service';
import { WizardService } from '../../../../services';
import { Constants } from '../../../../app.constants';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { catchError, finalize, flatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  IMoneyhubUser,
  DEFAULT_MONEYHUB_USER,
  IAccount,
  IAccountData,
  DEFAULT_MONEYHUB_ACCOUNT_DATA,
} from '../../../../model';
import { UserPropertyService } from 'src/app/services/user.property.service';

enum accountViewStateEnum {
  onboarding,
  connected,
  list,
  widget,
}

@Component({
  selector: 'app-moneyhub',
  templateUrl: './moneyhub.component.html',
  styleUrls: ['./moneyhub.component.scss'],
})
export class MoneyhubComponent implements OnInit, OnDestroy {
  state: accountViewStateEnum;
  loading = false;
  error: string;
  user: IMoneyhubUser;

  accounts: { provider: string; accounts: IAccount[] }[] = [];
  dataType = 'test';
  // accounts: IAccount[] = [];

  private mhWidget: ElementRef;
  @ViewChild('moneyhubContainer') set content(content: ElementRef) {
    if (content) {
      // initially setter gets called with undefined
      this.mhWidget = content;
    }
  }

  constructor(
    titleService: Title,
    private userPropertyService: UserPropertyService,
    private config: AppConfigService,
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    private moneyhubService: MoneyhubService,
    private wizardService: WizardService
  ) {
    this.state = accountViewStateEnum.onboarding;
    titleService.setTitle('My accounts');
  }

  ngOnInit() {
    // check if we are coming from a connection redirect
    const connectedRedirect = localStorage.getItem(
      Constants.LOCAL_STORAGE_MONEYHUB_REDIRECT_SUCCESS
    );
    const errorRedirect = localStorage.getItem(
      Constants.LOCAL_STORAGE_MONEYHUB_REDIRECT_ERROR
    );
    // clean
    localStorage.removeItem(Constants.LOCAL_STORAGE_REDIRECT_URL);
    localStorage.removeItem(Constants.LOCAL_STORAGE_MONEYHUB_REDIRECT_SUCCESS);
    localStorage.removeItem(Constants.LOCAL_STORAGE_MONEYHUB_REDIRECT_ERROR);

    if (!!errorRedirect) {
      this.error = errorRedirect;
    }

    let moneyhubToken;
    // get the moneyhub user saved before the redirect
    try {
      this.user = JSON.parse(
        localStorage.getItem(Constants.LOCAL_STORAGE_MONEYHUB_USER)
      );
      moneyhubToken = localStorage.getItem(
        Constants.LOCAL_STORAGE_MONEYHUB_TOKEN
      );
    } catch (ex) {
      this.user = null;
    }

    // decide the flow
    if (connectedRedirect) {
      this.state = accountViewStateEnum.connected;
    } else if (!!this.user && !!moneyhubToken) {
      this.state = accountViewStateEnum.list;
    }

    // parse the user and get the accounts
    if ((!!this.user && !!moneyhubToken) || connectedRedirect) {
      this.getAccounts(connectedRedirect);
    }
  }

  ngOnDestroy() {
    this.wizardService.setVisited(this.route.snapshot.url[0].path);
  }

  get isOnboarding() {
    return this.state === accountViewStateEnum.onboarding;
  }
  get isConnected() {
    return this.state === accountViewStateEnum.connected;
  }
  get isList() {
    return this.state === accountViewStateEnum.list;
  }
  get isWidget() {
    return this.state === accountViewStateEnum.widget;
  }

  getAccounts(connectedRedirect: string) {
    this.loading = true;
    if (!!connectedRedirect) {
      this.refreshAccountData();
    }
    this.moneyhubService
      .getAccounts()
      .pipe(
        catchError((err) => {
          this.error = err;
          return of(DEFAULT_MONEYHUB_ACCOUNT_DATA);
        }),
        finalize(() => (this.loading = false))
      )
      .subscribe((accounts) => {
        this.accounts = [];
        if (accounts.data.length === 0) {
          this.wizardService.hideNotificationForPath('income');
          this.wizardService.hideNotificationForPath('balance');
          localStorage.removeItem(Constants.LOCAL_STORAGE_MONEYHUB_ACCOUNTS);
          return;
        }
        let freshAccounts: IAccount[] = [];
        if (!!connectedRedirect) {
          let existingAccounts: IAccount[] = [];
          try {
            existingAccounts = JSON.parse(
              localStorage.getItem(Constants.LOCAL_STORAGE_MONEYHUB_ACCOUNTS)
            );
            if (!existingAccounts) {
              existingAccounts = [];
            }
          } catch (ex) {
            existingAccounts = [];
          }

          // get the newly added accounts
          accounts.data.forEach((account) => {
            const exists = existingAccounts.find((a) => a.id === account.id);
            if (!exists) {
              freshAccounts.push(account);
            }
          });
        } else {
          freshAccounts = accounts.data;
        }

        // store accounts
        localStorage.setItem(
          Constants.LOCAL_STORAGE_MONEYHUB_ACCOUNTS,
          JSON.stringify(accounts.data)
        );

        // convert to ui-friendly loops
        const providers: { [provider: string]: IAccount[] } = {};
        freshAccounts.forEach((account) => {
          if (!providers[account.providerReference]) {
            providers[account.providerReference] = [];
          }
          providers[account.providerReference].push(account);
        });
        Object.keys(providers).forEach((provider) => {
          this.accounts.push({ provider, accounts: providers[provider] });
        });
      });
  }

  createUser() {
    this.state = accountViewStateEnum.widget;
    this.loading = true;
    this.error = null;
    if (!this.user) {
      this.moneyhubService
        .createUser()
        .pipe(
          flatMap((u) => {
            this.user = u;
            return this.userPropertyService.create(
              Constants.USER_PROPERTY_KEY_MONEYHUB_USER_ID,
              u.userId
            );
          }),
          catchError((err) => {
            this.error = err;
            return of(DEFAULT_MONEYHUB_USER);
          }),
          finalize(() => (this.loading = false))
        )
        .subscribe(() => {
          if (this.user.userId) {
            localStorage.setItem(
              Constants.LOCAL_STORAGE_MONEYHUB_USER,
              JSON.stringify(this.user)
            );
            this.setupWidget(this.user.userId);
          }
        });
    } else {
      this.linkMore();
    }
  }

  linkMore() {
    this.state = accountViewStateEnum.widget;
    setTimeout(() => this.setupWidget(this.user.userId), 200);
  }

  unlink(account: IAccount) {
    this.loading = true;
    this.moneyhubService
      .removeConnection(account.connectionId)
      .pipe(
        catchError((err) => {
          this.error = err;
          return of(err);
        }),
        finalize(() => (this.loading = false))
      )
      .subscribe((error) => {
        if (!error) {
          this.getAccounts(null);
        }
      });
  }

  async refreshAccountData() {
    try {
      this.loading = true;
      const data = await Promise.all([
        this.moneyhubService.getIncomesAndExpenses(),
        this.moneyhubService.getBalanceSheet().toPromise(),
      ]);
      this.loading = false;
      if (!!data) {
        if (!!data[0] && data[0].length > 0) {
          this.wizardService.showNotificationForPath('income');
        }
        if (
          !!data[1] &&
          (data[1].assets.length > 0 || data[1].liabilities.length > 0)
        ) {
          this.wizardService.showNotificationForPath('balance');
        }
      }
    } catch (ex) {
      this.error = ex;
    }
  }

  setupWidget(userId) {
    const clientId = this.config.getConfig().moneyHub.clientId;
    const redirectUri = this.config.getConfig().moneyHub.redirectUri;
    // const dataType = this.config.getConfig().moneyHub.dataType;

    // @ts-ignore
    window.moneyhubAccountConnectWidget(this.mhWidget.nativeElement, {
      clientid: clientId,
      redirecturi: redirectUri,
      userid: userId,
      posturi: '/result',
      finishuri: `#${this.router.url.replace('accounts', 'income')}`,
      type: this.dataType,
      meta: { userId },
      identityuri: 'https://identity.moneyhub.co.uk',
    });

    localStorage.setItem(Constants.LOCAL_STORAGE_REDIRECT_URL, this.router.url);
    this.loading = false;
  }

  bankChange(event) {
    this.dataType = event.checked ? 'all' : 'test';
  }
}
