import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WizardService } from 'src/app/services';
import { FeatureService } from 'src/app/feature/feature.service';
import { AppFeatureType } from 'src/app/feature';
import { AppConfigService } from 'src/app/services/app.config.service';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.container.html',
  styleUrls: ['./onboarding.container.scss'],
  animations:[
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({transform: 'translateY(100%)', opacity: 0}),
          animate('500ms', style({transform: 'translateY(0)', opacity: 1}))
        ]),
        transition(':leave', [
          style({transform: 'translateX(0)', opacity: 1}),
          animate('500ms', style({transform: 'translateX(100%)', opacity: 0}))
        ])
      ]
    )
  ]
})
export class OnboardingContainerComponent implements OnInit {

  private _steps = [{
    label: 'My family',
    step: 1,
    path: ['family']
  },
  {
    label: 'Our income statement',
    step: 4,
    path: ['income']
  },
  {
    label: 'Our balance sheet',
    step: 5,
    path: ['balance']
  },
  {
    label: 'Our protection',
    step: 6,
    path: ['protection']
  },
  {
    label: 'Our future',
    step: 8,
    path: ['future']
  },
  ];

  visited: string[] = [];
  notifications: { [path: string]: boolean } = {};
  showDisclaimer: boolean = !!AppFeatureType.Disclaimer;
  featureSampleAppWarn = AppFeatureType.SampleAppWarning;


  constructor(
    private route: ActivatedRoute,
    private configService: AppConfigService,
    private wizardService: WizardService,
    private featureService: FeatureService
  ) {
    const appConfig = this.configService.getConfig();
    this.wizardService.listenToVisited().subscribe(paths => this.visited = paths);
    this.wizardService.listenToNotification().subscribe(notifications => this.notifications = notifications);
    if (this.featureService.hasFeature(AppFeatureType.MoneyHubAccounts)) {
      this._steps.push({
        label: 'My connections',
        step: 2,
        path: ['connect']
      }, {
        label: 'My accounts',
        step: 3,
        path: ['accounts'] // 'moneyhub', 'iress',
      });
    }
    if (this.featureService.hasFeature(AppFeatureType.RiskAssessment)) {
      this._steps.push({
        label: 'My risk tolerance',
        step: 7,
        path: ['risk']
      });
    }
  }

  get steps() {
    return this._steps.sort((a, b) => a.step - b.step);
  }

  get activeStep() {
    const activePath = this.route.snapshot.firstChild.routeConfig.path;
    const activeStep = this.steps.find(i => !!(i.path || []).find(p => p === activePath));
    return activeStep.step;
  }

  ngOnInit() {
    setTimeout(() => this.showDisclaimer = false, 6000)
  }

  isVisited(path) {
    return this.visited.includes(path);
  }

  hasNotification(path) {
    return !!this.notifications[path];
  }

}
