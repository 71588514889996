import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { flatMap, map } from 'rxjs/operators';
import { of, empty, iif, forkJoin, Subscription } from 'rxjs';
import {
  DisabilityInsuranceService, LifeInsuranceService,
  CriticalIllnessInsuranceService, MortgageLifeInsuranceService, AnalyticsService, EarnedIncomeService
} from 'src/app/services';
import { Person, MortgageLiability, Frequency } from 'src/app/model';
import { PersonsService } from 'src/app/services/persons.service';
import { Constants } from 'src/app/app.constants';
import { Insurance, MortgageInsurance } from 'src/app/model/protection';
import { NotifierService } from 'angular-notifier';
import { MortgageLiabilityService } from 'src/app/services/liability.service';
import { DateUtils } from 'src/app/utils/date.utils';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsEvent, AnalyticsLabel } from 'src/app/model/analytics';
import { WizardService } from '../../../services';
import { DragulaService } from 'ng2-dragula';
import { AppConfigService } from 'src/app/services/app.config.service';
import { EarnedIncome } from 'src/app/model/household/income';
import { FeatureService } from 'src/app/feature/feature.service';
import { AppFeatureType } from 'src/app/feature';

@Component({
  selector: 'app-protection',
  templateUrl: './protection.component.html',
  styleUrls: ['./protection.component.scss']
})
export class ProtectionComponent implements OnInit, OnDestroy {

  private _locale: string;
  private _subs: Subscription[] = [];
  private _navigation: any;
  private _loading: boolean = false;
  private _onRefresh = {
    next: () => this._loading = false,
    error: () => { this.notifier.notify(Constants.ERROR, 'Failed to refresh insurances'); this._loading = false; }
  };

  private _scenarioId: string;
  private _primary: Person;
  private _partner: Person;

  private _primaryEarnedIncomes: EarnedIncome[] = [];
  private _partnerEarnedIncomes: EarnedIncome[] = [];
  private _mortgages: MortgageLiability[] = [];
  private _primaryDisabilityInsurances: Insurance[] = [];
  private _partnerDisabilityInsurances: Insurance[] = [];
  private _primaryCriticalIllnessInsurances: Insurance[] = [];
  private _partnerCriticalIllnessInsurances: Insurance[] = [];
  private _primaryLifeInsurances: Insurance[] = [];
  private _partnerLifeInsurances: Insurance[] = [];
  private _primaryMortgageLifeInsurances: MortgageInsurance[] = [];
  private _partnerMortgageLifeInsurances: MortgageInsurance[] = [];
  private _showInputForId: string;
  private _scrollable = true;

  private _onDragEnd: Function;
  private _onDragEndPerson: Person;
  private _dragging: boolean;

  public breakpoint: number;
  public total = 10;

  public isRiskAssessmentEnabled = true;

  constructor(
    private analyticsService: AnalyticsService,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private t: TranslateService,
    private notifier: NotifierService,
    private personsService: PersonsService,
    private mortgageLiabilityService: MortgageLiabilityService,
    private disabilityInsuranceService: DisabilityInsuranceService,
    private lifeInsuranceService: LifeInsuranceService,
    private criticalIllnessInsuranceService: CriticalIllnessInsuranceService,
    private mortgageLifeInsuranceService: MortgageLifeInsuranceService,
    private wizardService: WizardService,
    private dragulaService: DragulaService,
    private configService: AppConfigService,
    private featureService: FeatureService,
    private earnedIncomeService: EarnedIncomeService
  ) {
    this.dragulaService.createGroup('PROTECTIONS', {
      direction: 'horizontal',
      copy: () => true,
      moves: el => !el.parentElement.classList.value.includes("disabled"),
      accepts: (el, target) => { return (target === document.getElementById('dropzone_protections_1') || target === document.getElementById("dropzone_protections_2")); }
    });
    this._subs.push(this.dragulaService.drop('PROTECTIONS').subscribe((el) => this.handleProtectionDrop(el.source)));
    this._subs.push(this.dragulaService.drag('PROTECTIONS').subscribe(() => this._scrollable = false));
    this._subs.push(this.dragulaService.dragend('PROTECTIONS').subscribe(() => this._scrollable = true));
    this._subs.push(this.dragulaService.drop('PROTECTIONS').subscribe((d) => { this._scrollable = true; }));
    window.addEventListener('touchmove', e => !this._scrollable && e.preventDefault(), { passive: false });

  }

  ngOnInit() {
    this._locale = this.configService.getConfig().locale;
    this.isRiskAssessmentEnabled = this.featureService.hasFeature(AppFeatureType.RiskAssessment);
    this._loading = true;
    this.titleService.setTitle(this.t.instant('Onboarding | Protection'));
    this._navigation = (this.route.data as any).value.navigation;
    this.onResize(null);
    this.refreshInsurances();
  }

  ngOnDestroy() {
    this.wizardService.setVisited(this.route.snapshot.url[0].path);
    this._subs.forEach(s => s.unsubscribe());
    this.dragulaService.destroy('PROTECTIONS');
  }

  getDraggedElementText(el: Element) {
    return el.childNodes[1].textContent.trim();
  }

  handleProtectionDrop(el: Element) {
    const inDropzone = document.getElementById('dropzone_protections_2').childNodes.length === 3 || document.getElementById('dropzone_protections_1').childNodes.length === 3;
    if (inDropzone) {
      const nodeText = this.getDraggedElementText(el);
      switch (nodeText) {
        case 'My Term Life':
          this.createLifeInsuranceForPerson(this._primary);
          break;
        case "My Partner's Term Life":
          this.createLifeInsuranceForPerson(this._partner);
          break;
        case 'My Critical Illness':
          this.createCriticalIllnessInsuranceForPerson(this._primary);
          break;
        case "Partner's Critical Illness":
          this.createCriticalIllnessInsuranceForPerson(this._partner);
          break;
        case "My Income":
          this.createDisabilityInsuranceForPerson(this._primary);
          break;
        case "Partner's Income":
          this.createDisabilityInsuranceForPerson(this._partner);
          break;
        case "My Mortgage":
          this.createMortgageInsuranceForPerson(this._primary);
          break;
        case "Partner's Mortgage":
          this.createMortgageInsuranceForPerson(this._partner);
          break;
        default:
          break;
      }
    }
    //empty drop zones
    document.getElementById('dropzone_protections_2').childNodes.forEach(c => {
      if (c['classList'] && c['classList'].item && c['classList'].item(0) === 'coin') {
        c.remove();
      }
    });
    document.getElementById('dropzone_protections_1').childNodes.forEach(c => {
      if (c['classList'] && c['classList'].item && c['classList'].item(0) === 'coin') {
        c.remove();
      }
    });
  }

  onResize(event) {
    this.breakpoint = (window.innerWidth < 599) ? this.total : (window.innerWidth <= 1100) ? 1 : 2;
  }

  getGridWidth(total) {
    return (window.innerWidth < 599) ? total * 90 + 'px' : 'auto';
  }

  get showInputForId() {
    return this._showInputForId;
  }

  set showInputForId(id: string) {
    this._showInputForId = id;
  }

  showNameInput(ref: HTMLInputElement) {
    setTimeout(() => ref.focus(), 50);
  }

  refreshDisabilityInsurancesForPrimary = () => this.$refreshDisabilityInsurancesForPrimary()
    .subscribe(this._onRefresh);

  refreshDisabilityInsurancesForPartner = () => this.$refreshDisabilityInsurancesForPartner()
    .subscribe(this._onRefresh);

  refreshCriticalIllnessInsurancesForPrimary = () => this.$refreshCriticalIllnessInsurancesForPrimary()
    .subscribe(this._onRefresh);

  refreshCriticalIllnessInsurancesForPartner = () => this.$refreshCriticalIllnessInsuranceForPartner()
    .subscribe(this._onRefresh);

  refreshLifeInsurancesForPrimary = () => this.$refreshLifeInsurancesForPrimary()
    .subscribe(this._onRefresh);

  refreshLifeInsurancesForPartner = () => this.$refreshLifeInsuranceForPartner()
    .subscribe(this._onRefresh);

  refreshMortgageLifeInsurancesForPrimary = () => this.$refreshMortgageLifeInsuranceForPrimary()
    .subscribe(this._onRefresh);

  refreshMortgageLifeInsurancesForPartner = () => this.$refreshMortgageLifeInsuranceForPartner()
    .subscribe(this._onRefresh);

  refreshEarnedIncomesForPrimary = () => this.$refreshEarnedIncomesForPrimary()
    .subscribe(this._onRefresh);

  refreshEarnedIncomesForPartner = () => this.$refreshEarnedIncomesForPartner()
    .subscribe(this._onRefresh);

  $refreshEarnedIncomesForPrimary = () => this.earnedIncomeService
    .queryForPerson(this._scenarioId, this._primary.id, Constants.PAGE_ALL)
    .pipe(
      map(r => {
        this._primaryEarnedIncomes = r.content;
      })
    )

  $refreshEarnedIncomesForPartner = () => this.earnedIncomeService
    .queryForPerson(this._scenarioId, this._partner.id, Constants.PAGE_ALL)
    .pipe(
      map(r => {
        this._partnerEarnedIncomes = r.content;
      })
    )

  $refreshDisabilityInsurancesForPrimary = () => this.disabilityInsuranceService
    .queryForPerson(this._scenarioId, this._primary.id, Constants.PAGE_ALL)
    .pipe(
      map(r => {
        this._primaryDisabilityInsurances = r.content.map(d => {
          const income = this._primaryEarnedIncomes.find(i => i.id === d.description);
          const amount = income.frequency === Frequency.ANNUALLY ? income.amount : income.amount * 12;
          return {
            ...d,
            percentage: d.payout / amount
          };
        });
      }))

  $refreshMortgageLiabilities = () => this.mortgageLiabilityService
    .query(this._scenarioId, Constants.PAGE_ALL)
    .pipe(
      map(r => this._mortgages = r.content));

  $refreshDisabilityInsurancesForPartner = () => this.disabilityInsuranceService
  .queryForPerson(this._scenarioId, this._partner.id, Constants.PAGE_ALL)
  .pipe(
    map(r => {
      this._partnerDisabilityInsurances = r.content.map(d => {
        const income = this._partnerEarnedIncomes.find(i => i.id === d.description);
        const amount = income.frequency === Frequency.ANNUALLY ? income.amount : income.amount * 12;
        return {
          ...d,
          percentage: d.payout / amount
        };
      });
    }))

  $refreshCriticalIllnessInsurancesForPrimary = () => this.criticalIllnessInsuranceService
    .queryForPerson(this._scenarioId, this._primary.id, Constants.PAGE_ALL)
    .pipe(
      map(r => this._primaryCriticalIllnessInsurances = r.content))

  $refreshMortgageLifeInsuranceForPrimary = () => this.mortgageLifeInsuranceService
    .queryForPerson(this._scenarioId, this._primary.id, Constants.PAGE_ALL)
    .pipe(
      map(r => this._primaryMortgageLifeInsurances = r.content))

  $refreshCriticalIllnessInsuranceForPartner = () => this.criticalIllnessInsuranceService
    .queryForPerson(this._scenarioId, this._partner.id, Constants.PAGE_ALL)
    .pipe(
      map(r => this._partnerCriticalIllnessInsurances = r.content))

  $refreshLifeInsurancesForPrimary = () => this.lifeInsuranceService
    .queryForPerson(this._scenarioId, this._primary.id, Constants.PAGE_ALL)
    .pipe(
      map(r => this._primaryLifeInsurances = r.content))

  $refreshLifeInsuranceForPartner = () => this.lifeInsuranceService
    .queryForPerson(this._scenarioId, this._partner.id, Constants.PAGE_ALL)
    .pipe(
      map(r => this._partnerLifeInsurances = r.content))

  $refreshMortgageLifeInsuranceForPartner = () => this.mortgageLifeInsuranceService
    .queryForPerson(this._scenarioId, this._partner.id, Constants.PAGE_ALL)
    .pipe(
      map(r => this._partnerMortgageLifeInsurances = r.content))

  refreshInsurances() {
    this._loading = true;
    this._scenarioId = this.router.routerState.snapshot.url.split('/')[2];
    forkJoin([
      this.personsService.getPrimary(this._scenarioId),
      this.personsService.getPartner(this._scenarioId)
    ]).pipe(
      flatMap(resp => {
        this._primary = resp[0];
        this._partner = resp[1];
        return forkJoin([
          this.$refreshEarnedIncomesForPrimary(),
          this._partner ? this.$refreshEarnedIncomesForPartner() : of(null),
          this.$refreshMortgageLiabilities(),
          this.$refreshDisabilityInsurancesForPrimary(),
          this.$refreshCriticalIllnessInsurancesForPrimary(),
          this.$refreshLifeInsurancesForPrimary(),
          this.$refreshMortgageLifeInsuranceForPrimary(),
          this._partner ? this.$refreshDisabilityInsurancesForPartner() : of(null),
          this._partner ? this.$refreshCriticalIllnessInsuranceForPartner() : of(null),
          this._partner ? this.$refreshLifeInsuranceForPartner() : of(null),
          this._partner ? this.$refreshMortgageLifeInsuranceForPartner() : of(null)
        ]);
      }))
      .subscribe(() => { }, () => this._loading = false, () => this._loading = false);
  }

  // Create
  createLifeInsuranceForPerson(person: Person) {
    this._loading = true;
    this.analyticsService.trackAnalyticsEvent(AnalyticsEvent.CREATE_PROTECTION, AnalyticsLabel.LIFE_INSURANCE);
    const lifeInsurance: Insurance = {
      frequency: Constants.DEFAULT_INSURANCE_FREQUENCY,
      joint: false,
      name: person.primary ? Constants.DEFAULT_LIFE_INSURANCE_NAME : Constants.DEFAULT_LIFE_INSURANCE_NAME_PARTNER,
      payout: Constants.DEFAULT_INSURANCE_PAYOUT,
      payoutCurrency: Constants.LOCALE_CONFIG[this._locale].currency,
      startDate: DateUtils.thisYear(),
      endDate: DateUtils.thisYear(),
      startsOn: Constants.DEFAULT_STARTS_ON,
      endsOn: Constants.DEFAULT_ENDS_ON,
    };

    this.lifeInsuranceService.createForPerson(this._scenarioId, person.id, lifeInsurance)
      .subscribe(
        () => person.id === this._primary.id ? this.refreshLifeInsurancesForPrimary() : this.refreshLifeInsurancesForPartner(),
        (err) => { this._loading = false; this.notifier.notify(Constants.ERROR, err); },
        () => this.notifier.notify(Constants.SUCCESS, 'Life insurance created'));
  }

  createDisabilityInsuranceForPerson(person: Person) {
    this._loading = true;
    this.analyticsService.trackAnalyticsEvent(AnalyticsEvent.CREATE_PROTECTION, AnalyticsLabel.DISABILITY_INSURANCE);
    const income = person.primary ? this._primaryEarnedIncomes[0] : this._partnerEarnedIncomes[0];

    const disabilityInsurance: Insurance = {
      frequency: Frequency.ANNUALLY,
      joint: false,
      description: income.id,
      name: person.primary ? Constants.DEFAULT_DISABILITY_INSURANCE_NAME : Constants.DEFAULT_DISABILITY_INSURANCE_NAME_PARTNER,
      payout: income.frequency === Frequency.ANNUALLY ? income.amount * Constants.DEFAULT_INSURANCE_DISABILITY_INCOME_PERCENTAGE
        : income.amount * 12 * Constants.DEFAULT_INSURANCE_DISABILITY_INCOME_PERCENTAGE,
      payoutCurrency: Constants.LOCALE_CONFIG[this._locale].currency,
      startDate: DateUtils.thisYear(),
      endDate: DateUtils.thisYear(),
      startsOn: Constants.DEFAULT_STARTS_ON,
      endsOn: Constants.DEFAULT_ENDS_ON,
    };

    this.disabilityInsuranceService.createForPerson(this._scenarioId, person.id, disabilityInsurance)
      .subscribe(
        () => person.id === this._primary.id ? this.refreshDisabilityInsurancesForPrimary() : this.refreshDisabilityInsurancesForPartner(),
        (err) => { this._loading = false; this.notifier.notify(Constants.ERROR, err); },
        () => this.notifier.notify(Constants.SUCCESS, 'Disability insurance created'));
  }

  createMortgageInsuranceForPerson(person: Person) {
    this._loading = true;
    this.analyticsService.trackAnalyticsEvent(AnalyticsEvent.CREATE_PROTECTION, AnalyticsLabel.MORTGAGE_INSURANCE);
    const mortgageInsurance: MortgageInsurance = {
      frequency: Constants.DEFAULT_INSURANCE_FREQUENCY,
      joint: false,
      name: person.primary ? Constants.DEFAULT_MORTGAGE_LIFE_INSURANCE_NAME : Constants.DEFAULT_MORTGAGE_LIFE_INSURANCE_NAME_PARTNER,
      payout: Constants.DEFAULT_INSURANCE_PAYOUT,
      payoutCurrency: Constants.LOCALE_CONFIG[this._locale].currency,
      startDate: DateUtils.thisYear(),
      endDate: DateUtils.thisYear(),
      startsOn: Constants.DEFAULT_STARTS_ON,
      endsOn: Constants.DEFAULT_ENDS_ON,
      mortgageId: this._mortgages[0].id,
    };

    this.mortgageLifeInsuranceService.createForPerson(this._scenarioId, person.id, mortgageInsurance)
      .subscribe(
        () => person.id === this._primary.id ? this.refreshMortgageLifeInsurancesForPrimary() : this.refreshMortgageLifeInsurancesForPartner(),
        (err) => { this.notifier.notify(Constants.ERROR, err); this._loading = false; },
        () => this.notifier.notify(Constants.SUCCESS, 'Mortgage life insurance created'));
  }

  createCriticalIllnessInsuranceForPerson(person: Person) {
    this._loading = true;
    this.analyticsService.trackAnalyticsEvent(AnalyticsEvent.CREATE_PROTECTION, AnalyticsLabel.CRITICAL_ILLNESS_INSURANCE);
    const criticalIllnessInsurance: Insurance = {
      frequency: Constants.DEFAULT_INSURANCE_FREQUENCY,
      joint: false,
      name: person.primary ? Constants.DEFAULT_CRITICAL_ILLNESS_INSURANCE_NAME : Constants.DEFAULT_CRITICAL_ILLNESS_INSURANCE_NAME_PARTNER,
      payout: Constants.DEFAULT_INSURANCE_PAYOUT,
      payoutCurrency: Constants.LOCALE_CONFIG[this._locale].currency,
      startDate: DateUtils.thisYear(),
      endDate: DateUtils.thisYear(),
      startsOn: Constants.DEFAULT_STARTS_ON,
      endsOn: Constants.DEFAULT_ENDS_ON,
    };

    this.criticalIllnessInsuranceService.createForPerson(this._scenarioId, person.id, criticalIllnessInsurance)
      .subscribe(
        () => person.id === this._primary.id ? this.refreshCriticalIllnessInsurancesForPrimary() : this.refreshCriticalIllnessInsurancesForPartner(),
        (err) => { this.notifier.notify(Constants.ERROR, err); this._loading = false; },
        () => this.notifier.notify(Constants.SUCCESS, 'Critical illness insurance created'));
  }

  // Update
  updatePrimaryLifeInsurance = (entity: Insurance) => {
    this._loading = true;
    this.lifeInsuranceService.updateForPerson(this._scenarioId, this._primary.id, entity)
      .subscribe(
        () => this.refreshLifeInsurancesForPrimary(),
        (err) => { this._loading = false; this.notifier.notify(Constants.ERROR, err); },
        () => this.notifier.notify(Constants.SUCCESS, 'Life insurance updated'));
  }

  updatePartnerLifeInsurance = (entity: Insurance) => {
    this._loading = true;
    this.lifeInsuranceService.updateForPerson(this._scenarioId, this._partner.id, entity)
      .subscribe(
        () => this.refreshLifeInsurancesForPartner(),
        (err) => { this._loading = false; this.notifier.notify(Constants.ERROR, err); },
        () => this.notifier.notify(Constants.SUCCESS, 'Life insurance updated'));
  }

  updatePrimaryCriticalIllnessInsurance = (entity: Insurance) => {
    this._loading = true;
    this.criticalIllnessInsuranceService.updateForPerson(this._scenarioId, this._primary.id, entity)
      .subscribe(
        () => this.refreshCriticalIllnessInsurancesForPrimary(),
        (err) => { this._loading = false; this.notifier.notify(Constants.ERROR, err); },
        () => this.notifier.notify(Constants.SUCCESS, 'Critical illness insurance updated'));
  }

  updatePartnerCriticalIllnessInsurance = (entity: Insurance) => {
    this._loading = true;
    this.criticalIllnessInsuranceService.updateForPerson(this._scenarioId, this._partner.id, entity)
      .subscribe(
        () => this.refreshCriticalIllnessInsurancesForPartner(),
        (err) => { this._loading = false; this.notifier.notify(Constants.ERROR, err); },
        () => this.notifier.notify(Constants.SUCCESS, 'Critical illness insurance updated'));
  }

  updatePrimaryDisabilityInsurance = (entity: Insurance) => {
    let percentage = entity['percentage'];
    if (percentage > 0.75) {
      percentage = 0.75;
    }
    if (percentage < 0) {
      percentage = 0;
    }
    const income = this._primaryEarnedIncomes.find(i => i.id === entity.description);
    const incomeAmount = income.frequency === Frequency.ANNUALLY ? income.amount : income.amount * 12;
    entity.payout = percentage * incomeAmount;
    this._loading = true;
    this.disabilityInsuranceService.updateForPerson(this._scenarioId, this._primary.id, entity)
      .subscribe(
        () => this.refreshDisabilityInsurancesForPrimary(),
        (err) => { this._loading = false; this.notifier.notify(Constants.ERROR, err); },
        () => this.notifier.notify(Constants.SUCCESS, 'Disability insurance updated'));
  }

  updatePartnerDisabilityInsurance = (entity: Insurance) => {
    let percentage = entity['percentage'];
    if (percentage > 0.75) {
      percentage = 0.75;
    }
    if (percentage < 0) {
      percentage = 0;
    }
    const income = this._partnerEarnedIncomes.find(i => i.id === entity.description);
    const incomeAmount = income.frequency === Frequency.ANNUALLY ? income.amount : income.amount * 12;
    entity.payout = percentage * incomeAmount;
    this._loading = true;
    this.disabilityInsuranceService.updateForPerson(this._scenarioId, this._partner.id, entity)
      .subscribe(
        () => this.refreshDisabilityInsurancesForPartner(),
        (err) => { this._loading = false; this.notifier.notify(Constants.ERROR, err); },
        () => this.notifier.notify(Constants.SUCCESS, 'Disability insurance updated'));
  }

  updatePrimaryMortgageLifeInsurance = (entity: MortgageInsurance) => {
    this._loading = true;
    this.mortgageLifeInsuranceService.updateForPerson(this._scenarioId, this._primary.id, entity)
      .subscribe(
        () => this.refreshMortgageLifeInsurancesForPrimary(),
        (err) => { this._loading = false; this.notifier.notify(Constants.ERROR, err); },
        () => this.notifier.notify(Constants.SUCCESS, 'Mortgage life insurance updated'));
  }

  updatePartnerMortgageLifeInsurance = (entity: MortgageInsurance) => {
    this._loading = true;
    this.mortgageLifeInsuranceService.updateForPerson(this._scenarioId, this._partner.id, entity)
      .subscribe(
        () => this.refreshMortgageLifeInsurancesForPartner(),
        (err) => { this._loading = false; this.notifier.notify(Constants.ERROR, err); },
        () => this.notifier.notify(Constants.SUCCESS, 'Mortgage life insurance updated'));
  }

  // Delete
  deletePrimaryLifeInsurance = (entity: Insurance) => {
    this._loading = true;
    this.lifeInsuranceService.deleteForPerson(this._scenarioId, this._primary.id, entity.id)
      .subscribe(
        () => this.refreshLifeInsurancesForPrimary(),
        (err) => { this._loading = false; this.notifier.notify(Constants.ERROR, err); },
        () => this.notifier.notify(Constants.SUCCESS, 'Life insurance deleted'));
  }

  deletePartnerLifeInsurance = (entity: Insurance) => {
    this._loading = true;
    this.lifeInsuranceService.deleteForPerson(this._scenarioId, this._partner.id, entity.id)
      .subscribe(
        () => this.refreshLifeInsurancesForPartner(),
        (err) => { this._loading = false; this.notifier.notify(Constants.ERROR, err); },
        () => this.notifier.notify(Constants.SUCCESS, 'Life insurance deleted'));
  }

  deletePrimaryCriticalIllnessInsurance = (entity: Insurance) => {
    this._loading = true;
    this.criticalIllnessInsuranceService.deleteForPerson(this._scenarioId, this._primary.id, entity.id)
      .subscribe(
        () => this.refreshCriticalIllnessInsurancesForPrimary(),
        (err) => { this._loading = false; this.notifier.notify(Constants.ERROR, err); },
        () => this.notifier.notify(Constants.SUCCESS, 'Critical illness insurance deleted'));
  }

  deletePartnerCriticalIllnessInsurance = (entity: Insurance) => {
    this._loading = true;
    this.criticalIllnessInsuranceService.deleteForPerson(this._scenarioId, this._partner.id, entity.id)
      .subscribe(
        () => this.refreshCriticalIllnessInsurancesForPartner(),
        (err) => { this._loading = false; this.notifier.notify(Constants.ERROR, err); },
        () => this.notifier.notify(Constants.SUCCESS, 'Critical illness insurance deleted'));
  }

  deletePrimaryDisabilityInsurance = (entity: Insurance) => {
    this._loading = true;
    this.disabilityInsuranceService.deleteForPerson(this._scenarioId, this._primary.id, entity.id)
      .subscribe(
        () => this.refreshDisabilityInsurancesForPrimary(),
        (err) => { this._loading = false; this.notifier.notify(Constants.ERROR, err); },
        () => this.notifier.notify(Constants.SUCCESS, 'Disability insurance deleted'));
  }

  deletePartnerDisabilityInsurance = (entity: Insurance) => {
    this._loading = true;
    this.disabilityInsuranceService.deleteForPerson(this._scenarioId, this._partner.id, entity.id)
      .subscribe(
        () => this.refreshDisabilityInsurancesForPartner(),
        (err) => { this._loading = false; this.notifier.notify(Constants.ERROR, err); },
        () => this.notifier.notify(Constants.SUCCESS, 'Disability insurance deleted'));
  }

  deletePrimaryMortgageLifeInsurance = (entity: Insurance) => {
    this._loading = true;
    this.mortgageLifeInsuranceService.deleteForPerson(this._scenarioId, this._primary.id, entity.id)
      .subscribe(
        () => this.refreshMortgageLifeInsurancesForPrimary(),
        (err) => { this._loading = false; this.notifier.notify(Constants.ERROR, err); },
        () => this.notifier.notify(Constants.SUCCESS, 'Mortgage life insurance deleted'));
  }

  deletePartnerMortgageLifeInsurance = (entity: Insurance) => {
    this.mortgageLifeInsuranceService.deleteForPerson(this._scenarioId, this._partner.id, entity.id)
      .subscribe(
        () => this.refreshMortgageLifeInsurancesForPartner(),
        (err) => { this._loading = false; this.notifier.notify(Constants.ERROR, err); },
        () => this.notifier.notify(Constants.SUCCESS, 'Mortgage life insurance deleted'));
  }

  dragStart(cb: Function, p: Person) {
    this._onDragEnd = cb;
    this._onDragEndPerson = p;
    this._dragging = true;
  }

  dragEnd() {
    this._dragging = false;
  }

  onDrop() {
    this._onDragEnd(this._onDragEndPerson);
  }

  get navigation() {
    return this._navigation;
  }

  get loading() {
    return this._loading;
  }

  get primary() {
    return this._primary;
  }

  get partner() {
    return this._partner;
  }

  get mortgages() {
    return this._mortgages;
  }

  get primaryEarnedIncomes() {
    return this._primaryEarnedIncomes;
  }

  get partnerEarnedIncomes() {
    return this._partnerEarnedIncomes;
  }

  get primaryCriticalIllnessInsurances() {
    return this._primaryCriticalIllnessInsurances;
  }

  get primaryDisabilityInsurances() {
    return this._primaryDisabilityInsurances;
  }

  get primaryLifeInsurances() {
    return this._primaryLifeInsurances;
  }

  get primaryMortgageLifeInsurances() {
    return this._primaryMortgageLifeInsurances;
  }

  get partnerCriticalIllnessInsurances() {
    return this._partnerCriticalIllnessInsurances;
  }

  get partnerDisabilityInsurances() {
    return this._partnerDisabilityInsurances;
  }

  get partnerLifeInsurances() {
    return this._partnerLifeInsurances;
  }

  get partnerMortgageLifeInsurances() {
    return this._partnerMortgageLifeInsurances;
  }

  get extractInsurancePayoutMethod() {
    return (ins: Insurance) => ins.payout;
  }

  get dragging() {
    return !this._scrollable;
  }

  updatePrimaryInsuranceJoint(event, insurance, type) {
    insurance.joint = event.checked;
    switch (type) {
      case 'life':
        this.updatePrimaryLifeInsurance(insurance);
        break;
      case 'illness':
        this.updatePrimaryCriticalIllnessInsurance(insurance);
        break;
      case 'disability':
        this.updatePrimaryDisabilityInsurance(insurance);
        break;
      case 'mortgage':
        this.updatePrimaryMortgageLifeInsurance(insurance);
        break;
      default:
        return;
    }
  }

  updatePartnerInsuranceJoint(event, insurance, type) {
    insurance.joint = event.checked;
    switch (type) {
      case 'life':
        this.updatePartnerLifeInsurance(insurance);
        break;
      case 'illness':
        this.updatePartnerCriticalIllnessInsurance(insurance);
        break;
      case 'disability':
        this.updatePartnerDisabilityInsurance(insurance);
        break;
      case 'mortgage':
        this.updatePartnerMortgageLifeInsurance(insurance);
        break;
      default:
        return;
    }
  }

  extractYearFromDate(d) {
    return new Date(d).getFullYear();
  }

  get minYear() {
    return new Date().getFullYear();
  }

  get maxYear() {
      return new Date().getFullYear() + Constants.GOAL_YEAR_RANGE;
  }

  onPrimaryInsuranceYearChange(event, insurance, type, isStartDate) {
    if (isStartDate) {
      insurance.startDate = event;
    } else {
      insurance.endDate = event;
      insurance.endsOn = 'USER_DEFINED';
    }
    switch (type) {
      case 'life':
        this.updatePrimaryLifeInsurance(insurance);
        break;
      case 'illness':
        this.updatePrimaryCriticalIllnessInsurance(insurance);
        break;
      case 'disability':
        this.updatePrimaryDisabilityInsurance(insurance);
        break;
      case 'mortgage':
        this.updatePrimaryMortgageLifeInsurance(insurance);
        break;
      default:
        return;
    }
  }

  onPartnerInsuranceYearChange(event, insurance, type, isStartDate) {
    if (isStartDate) {
      insurance.startDate = event;
    } else {
      insurance.endDate = event;
      insurance.endsOn = 'USER_DEFINED';
    }
    switch (type) {
      case 'life':
        this.updatePartnerLifeInsurance(insurance);
        break;
      case 'illness':
        this.updatePartnerCriticalIllnessInsurance(insurance);
        break;
      case 'disability':
        this.updatePartnerDisabilityInsurance(insurance);
        break;
      case 'mortgage':
        this.updatePartnerMortgageLifeInsurance(insurance);
        break;
      default:
        return;
    }
  }

  changePrimaryInsuranceEndsOn(event, insurance, type) {
    insurance.endsOn = event.value;
    switch (type) {
      case 'life':
        this.updatePrimaryLifeInsurance(insurance);
        break;
      case 'illness':
        this.updatePrimaryCriticalIllnessInsurance(insurance);
        break;
      case 'disability':
        this.updatePrimaryDisabilityInsurance(insurance);
        break;
      case 'mortgage':
        this.updatePrimaryMortgageLifeInsurance(insurance);
        break;
      default:
        return;
    }
  }

  changePartnerInsuranceEndsOn(event, insurance, type) {
    insurance.endsOn = event.value;
    switch (type) {
      case 'life':
        this.updatePartnerLifeInsurance(insurance);
        break;
      case 'illness':
        this.updatePartnerCriticalIllnessInsurance(insurance);
        break;
      case 'disability':
        this.updatePartnerDisabilityInsurance(insurance);
        break;
      case 'mortgage':
        this.updatePartnerMortgageLifeInsurance(insurance);
        break;
      default:
        return;
    }
  }

  getIncomePercentAssured(insurance, primary: boolean) {
    const income = (primary ? this._primaryEarnedIncomes : this._partnerEarnedIncomes).find(i => i.id === insurance.description);
    return income.frequency === Frequency.ANNUALLY ? insurance.payout / income.amount : insurance.payout / (income.amount * 12);
  }
}
