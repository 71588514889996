import { BaseModel } from '../../base.model';
import { DateRangeType, Frequency, UnearnedIncomeType, BaseGrowthRate } from '../../enums';
import { Constants } from 'src/app/app.constants';

export class UnearnedIncome extends BaseModel {
    amount: number;
    currency: string;
    startDate: string;
    startsOn: DateRangeType;
    endDate: string;
    endsOn: DateRangeType;
    frequency: Frequency;
    type: UnearnedIncomeType;
    growthRate: BaseGrowthRate;
    spreadOverGrowthRate: number;
    maximumAbsoluteSpreadAllowed: number;
    toSurvivorPercentage: number;
    taxable: boolean;
    isFromLinkedAccounts?: boolean;
}

export class UnearnedIncomeBuilder {
    private _unearnedIncome: UnearnedIncome;

    constructor() {
        this._unearnedIncome = new UnearnedIncome();
        this._unearnedIncome.source = Constants.DEFAULT_SOURCE;
        return this;
    }

    public id(id: string) {
        this._unearnedIncome.id = id;
        return this;
    }

    public name(name: string) {
        this._unearnedIncome.name = name;
        return this;
    }

    public description(description: string) {
        this._unearnedIncome.description = description;
        return this;
    }

    public amount(amount: number) {
        this._unearnedIncome.amount = amount;
        return this;
    }

    public currency(currency: string) {
        this._unearnedIncome.currency = currency;
        return this;
    }

    public startDate(startDate: string) {
        this._unearnedIncome.startDate = startDate;
        return this;
    }

    public startsOn(startsOn: DateRangeType) {
        this._unearnedIncome.startsOn = startsOn;
        return this;
    }

    public endDate(endDate: string) {
        this._unearnedIncome.endDate = endDate;
        return this;
    }

    public endsOn(endsOn: DateRangeType) {
        this._unearnedIncome.endsOn = endsOn;
        return this;
    }

    public frequency(frequency: Frequency) {
        this._unearnedIncome.frequency = frequency;
        return this;
    }

    public type(type: UnearnedIncomeType) {
        this._unearnedIncome.type = type;
        return this;
    }

    public growthRate(growthRate: BaseGrowthRate) {
        this._unearnedIncome.growthRate = growthRate;
        return this;
    }

    public spreadOverGrowthRate(spreadOverGrowthRate: number) {
        this._unearnedIncome.spreadOverGrowthRate = spreadOverGrowthRate;
        return this;
    }

    public maximumAbsoluteSpreadAllowed(maximumAbsoluteSpreadAllowed: number) {
        this._unearnedIncome.maximumAbsoluteSpreadAllowed = maximumAbsoluteSpreadAllowed;
        return this;
    }

    public toSurvivorPercentage(toSurvivorPercentage: number) {
        this._unearnedIncome.toSurvivorPercentage = toSurvivorPercentage;
        return this;
    }

    public taxable(taxable: boolean) {
        this._unearnedIncome.taxable = taxable;
        return this;
    }

  public linkedAccount(linked: boolean) {
    this._unearnedIncome.isFromLinkedAccounts = linked;
    return this;
  }

    public build() {
        return this._unearnedIncome;
    }
}
