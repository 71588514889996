import { BaseModel } from '../../base.model';
import { DateRangeType, Frequency, BaseGrowthRate } from '../../enums';
import { Constants } from 'src/app/app.constants';
import {IMoneyhubTransactionData} from '../..';

export class RentExpense extends BaseModel {
    amount: number;
    currency: string;
    startDate: string;
    startsOn: DateRangeType;
    endDate: string;
    endsOn: DateRangeType;
    frequency: Frequency;
    growthRate: BaseGrowthRate;
    spreadOverGrowthRate: number;
    nonDiscretionaryPercentage: number;
    survivorAdjustmentPercentage: number;
    isFromLinkedAccounts?: boolean;
    linkedRentExpenses?: IMoneyhubTransactionData[];
}

export class RentExpenseBuilder {
    private _rentExpense: RentExpense;

    constructor() {
        this._rentExpense = new RentExpense();
        this._rentExpense.source = Constants.DEFAULT_SOURCE;
        return this;
    }

    public id(id: string) {
        this._rentExpense.id = id;
        return this;
    }

    public name(name: string) {
        this._rentExpense.name = name;
        return this;
    }

    public description(description: string) {
        this._rentExpense.description = description;
        return this;
    }

    public amount(amount: number) {
        this._rentExpense.amount = amount;
        return this;
    }

    public currency(currency: string) {
        this._rentExpense.currency = currency;
        return this;
    }

    public startDate(startDate: string) {
        this._rentExpense.startDate = startDate;
        return this;
    }

    public startsOn(startsOn: DateRangeType) {
        this._rentExpense.startsOn = startsOn;
        return this;
    }

    public endDate(endDate: string) {
        this._rentExpense.endDate = endDate;
        return this;
    }

    public endsOn(endsOn: DateRangeType) {
        this._rentExpense.endsOn = endsOn;
        return this;
    }

    public frequency(frequency: Frequency) {
        this._rentExpense.frequency = frequency;
        return this;
    }

    public growthRate(growthRate: BaseGrowthRate) {
        this._rentExpense.growthRate = growthRate;
        return this;
    }

    public spreadOverGrowthRate(spreadOverGrowthRate: number) {
        this._rentExpense.spreadOverGrowthRate = spreadOverGrowthRate;
        return this;
    }

    public nonDiscretionaryPercentage(nonDiscretionaryPercentage: number) {
        this._rentExpense.nonDiscretionaryPercentage = nonDiscretionaryPercentage;
        return this;
    }

    public survivorAdjustmentPercentage(survivorAdjustmentPercentage: number) {
        this._rentExpense.survivorAdjustmentPercentage = survivorAdjustmentPercentage;
        return this;
    }

    public linkedAccount(linked: boolean) {
      this._rentExpense.isFromLinkedAccounts = linked;
      return this;
    }

    public linkedRentExpenses(expenses: IMoneyhubTransactionData[]) {
      this._rentExpense.linkedRentExpenses = expenses;
      return this;
    }

    public build() {
        return this._rentExpense;
    }
}
