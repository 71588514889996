import { Injectable } from '@angular/core';
// import * as EnvizageConnect from 'moneyhub-client';
import { HandleError, HttpErrorHandler } from './http-error-handler.service';
import {
  ICompleteAuthRequestResponse,
  ICreateAuthRequestResponse,
  MonthlyFinancialDataWrapper,
  IMoneyhubBank,
  IMoneyhubBalanceSheet,
  IMoneyhubIncomeStatement,
} from '../model/moneyhub/envizage-connect.model';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../app.constants';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IMoneyhubUser, IAccountData } from '../model';
import { AppConfigService } from './app.config.service';
import { IMoneyhubConnection } from '../model/moneyhub/envizage-connect.model';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class MoneyhubService {
  private envizageConnect: any;
  private handleError: HandleError;

  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandler,
    private config: AppConfigService,
    private authService: OAuthService
  ) {
    this.handleError = httpErrorHandler.createHandleError('personsService');
  }

  private async initConnect() {
    console.log('Initialising moneyhub connect');
    const config = {
      moneyhubApiUrl: this.moneyhubApiUrl,
      envizageApiUrl: '/envizage',
    };
    // const con = await (EnvizageConnect.default(config) as Promise<any>);
    // console.log('Connect created');
    // this.envizageConnect = con;
    // return this.envizageConnect;
  }

  async getIncomesAndExpenses(): Promise<MonthlyFinancialDataWrapper[]> {
    if (!this.envizageConnect) {
      await this.initConnect();
    }
    if (!this.token) {
      return;
    }
    const transactions = await this.envizageConnect.getTransactions(
      this.token,
      { limit: 1000, offset: 0 }
    );
    return transactions as MonthlyFinancialDataWrapper[];
  }

  async getAssetsAndLiabilities(): Promise<{ assets: []; liabilities: [] }> {
    if (!this.envizageConnect) {
      await this.initConnect();
    }
    if (!this.token) {
      return;
    }
    const assets = await this.envizageConnect.getAssetsAndLiabilities(
      this.token,
      { limit: 1000, offset: 0 }
    );
    return assets;
  }

  getBalanceSheet(): Observable<IMoneyhubBalanceSheet> {
    return this.http
      .get(`${this.moneyhubAuthUrl}/connect/users/balance-sheet`, {
        headers: { authorization: `Bearer ${this.envizageToken}` },
      })
      .pipe(catchError(this.handleError('moneyhub get balance sheet')));
  }

  getIncomeStatement(fromDate?: string): Observable<IMoneyhubIncomeStatement> {
    let url = `${this.moneyhubAuthUrl}/connect/users/income-statement`;
    if (fromDate) {
      url = url + '?fromDate=' + fromDate;
    }
    return this.http
      .get(url, { headers: { authorization: `Bearer ${this.envizageToken}` } })
      .pipe(catchError(this.handleError('moneyhub get income statement')));
  }

  getUserToken(): Observable<any> {
    return this.http
      .get(`${this.moneyhubAuthUrl}/connect/users/token`)
      .pipe(catchError(this.handleError('moneyhub user creation')));
  }

  getBanks(): Observable<IMoneyhubBank[]> {
    return this.http
      .get(
        `https://identity.moneyhub.co.uk/oidc/.well-known/${
          this.config.getConfig().moneyHub.dataType
        }-connections`
      )
      .pipe(catchError(this.handleError('moneyhub get banks')));
  }

  createUser(): Observable<IMoneyhubUser> {
    //TODO
    const clientUserId = localStorage.getItem(Constants.LOCAL_STORAGE_USERNAME);
    return this.http
      .post(
        `${this.moneyhubAuthUrl}/connect/users`,
        { clientUserId },
        { headers: { authorization: `Bearer ${this.envizageToken}` } }
      )
      .pipe(catchError(this.handleError('moneyhub user creation')));
  }

  getAccounts(): Observable<IAccountData> {
    if (!this.token) {
      return;
    }
    return this.http
      .get(`${this.moneyhubApiUrl}/accounts`, {
        headers: { authorization: `Bearer ${this.token}` },
      })
      .pipe(catchError(this.handleError('moneyhub user creation')));
  }

  getConnections(): Observable<any> {
    return this.http
      .get(`${this.moneyhubAuthUrl}/connect/users/connections`, {
        headers: { authorization: `Bearer ${this.envizageToken}` },
      })
      .pipe(catchError(this.handleError('moneyhub get connections')));
  }

  getConnectionsWithDetails(): Observable<IMoneyhubConnection[]> {
    return this.http
      .get(`${this.moneyhubAuthUrl}/connect/users/connections/details`, {
        headers: { authorization: `Bearer ${this.envizageToken}` },
      })
      .pipe(catchError(this.handleError('moneyhub get connections')));
  }

  removeConnection(id: string) {
    return this.http
      .delete(`${this.moneyhubAuthUrl}/connect/users/connections/${id}`, {
        headers: { authorization: `Bearer ${this.envizageToken}` },
      })
      .pipe(catchError(this.handleError('moneyhub user creation')));
  }

  createAuthRequest(
    redirectUri: string,
    bankId: string
  ): Observable<ICreateAuthRequestResponse> {
    return this.http
      .post(
        `${this.moneyhubAuthUrl}/connect/auth-request-token`,
        { redirectUri, bankId },
        { headers: { authorization: `Bearer ${this.envizageToken}` } }
      )
      .pipe(catchError(this.handleError('moneyhub auth request creation')));
  }

  completeAuthRequest(
    authRequestId: string,
    code: string,
    state: string,
    idToken?: string
  ): Observable<ICompleteAuthRequestResponse> {
    let body: {
      code: string;
      idToken?: string;
      authRequestId: string;
      state: string;
    } = {
      authRequestId,
      code,
      state,
    };
    if (idToken) {
      body = {
        ...body,
        idToken,
      };
    }
    return this.http
      .post(
        `${this.moneyhubAuthUrl}/connect/auth-request-token/complete`,
        body,
        { headers: { authorization: `Bearer ${this.envizageToken}` } }
      )
      .pipe(catchError(this.handleError('moneyhub auth request creation')));
  }

  private get token() {
    return localStorage.getItem(Constants.LOCAL_STORAGE_MONEYHUB_TOKEN);
  }

  private get envizageToken() {
    return this.authService.getAccessToken();
    //return localStorage.getItem(Constants.LOCAL_STORAGE_AUTH_TOKEN);
  }

  private get moneyhubApiUrl() {
    return this.config.getConfig().moneyHub.apiUrl;
  }

  private get moneyhubAuthUrl() {
    return this.config.getConfig().moneyHub.authUrl;
  }
}
