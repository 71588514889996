import { Injectable } from '@angular/core';
import { HandleError, HttpErrorHandler } from './http-error-handler.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PageableResponse, PageableRequest } from '../model';
import { Service } from './service';
import { RetirementGoal, Goal } from '../model/goal';
import { GoalDto, GoalMapper } from '../model/goal/goal.dto';
import { AppConfigService } from './app.config.service';

@Injectable()
export class TypedGoalService implements Service<Goal> {
    private handleError: HandleError;
    private getUrl = (scenarioId, id = '') => `${this.configService.getConfig().apiUrl}/scenarios/${scenarioId}/goals/typed/${id}`;

    constructor(
        private http: HttpClient,
        private configService: AppConfigService,
        private httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('typedGoalService');
    }

    query(scenarioId: string, pageable: PageableRequest): Observable<PageableResponse<Goal>> {
        return this.http.get<PageableResponse<GoalDto>>(this.getUrl(scenarioId), { params: pageable })
            .pipe(
                map(resp => {
                    resp.content = resp.content.map(g => GoalMapper.fromDto(g));
                    return resp;
                }),
                catchError(this.handleError('query goals'))
            );
    }

    get(scenarioId: string, id: string): Observable<Goal> {
        return this.http.get<GoalDto>(this.getUrl(scenarioId, id))
            .pipe(
                map(g => GoalMapper.fromDto(g)),
                catchError(this.handleError('get goal'))
            );
    }

    create(scenarioId: string, goal: Goal): Observable<Goal> {
        return this.http.post<GoalDto>(this.getUrl(scenarioId), GoalMapper.toDto(goal))
            .pipe(
                map(g => GoalMapper.fromDto(g)),
                catchError(this.handleError('create goal'))
            );
    }

    update(scenarioId: string, goal: Goal): Observable<Goal> {
        return this.http.put<GoalDto>(this.getUrl(scenarioId, goal.id), GoalMapper.toDto(goal))
            .pipe(
                map(g => GoalMapper.fromDto(g)),
                catchError(this.handleError('update goal'))
            );
    }

    delete(scenarioId: string, id: string): Observable<any> {
        return this.http.delete<any>(this.getUrl(scenarioId, id))
            .pipe(
                catchError(this.handleError('delete goal'))
            );
    }

}
