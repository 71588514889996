import { HttpParams } from '@angular/common/http';

export class PageableRequest extends HttpParams {
  constructor() {
    super();
  }
}

export class PageableRequestBuilder {
  private _request: PageableRequest;

  constructor() {
    this._request = new PageableRequest();
  }

  page(page: number) {
    this._request = this._request.set('page', page.toString());
    return this;
  }

  size(size: number) {
    this._request = this._request.set('size', size.toString());
    return this;
  }

  sort(sort: string) {
    this._request = this._request.set('sort', sort);
    return this;
  }

  build() {
    return this._request;
  }
}
