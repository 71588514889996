import {
  Component,
  ComponentFactoryResolver,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnInit,
} from '@angular/core';
import { EditGoalBaseComponent } from '../edit-goal-base/edit-goal-base.component';
import { Constants } from 'src/app/app.constants';
import { ScenarioUtils } from 'src/app/utils';
import { Person } from 'src/app/model';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-edit-goal-retirement',
  templateUrl: './edit-goal-retirement.component.html',
  styleUrls: ['./edit-goal-retirement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditGoalRetirementComponent
  extends EditGoalBaseComponent
  implements OnInit
{
  private _primary: Person;
  private _partner: Person;
  public selectedAge: number;
  public selectedTradeDownAge: number;
  public retirementAgeList: number[] = [];
  public tradeDownAgeList: number[] = [];
  public ageDiff = 0;

  constructor(
    resolver: ComponentFactoryResolver,
    dialog: MatDialog,
    private ref: ChangeDetectorRef
  ) {
    super(resolver, dialog, ref);
  }

  ngOnInit() {
    this._primary = ScenarioUtils.getPrimaryPerson(this.ctx.scenario) as Person;
    this._partner = ScenarioUtils.getPartner(this.ctx.scenario) as Person;
    let currentAge = new Date().getFullYear() - this._primary.yearOfBirth;
    if (this.isVirtualGoal && this._partner) {
      currentAge = new Date().getFullYear() - this._partner.yearOfBirth;
      this.ageDiff = this._primary.yearOfBirth - this._partner.yearOfBirth;
    }
    for (
      let i = Math.max(currentAge, Constants.RETIREMENT_MINIMUM_AGE);
      i <= Constants.RETIREMENT_MAXIMUM_AGE;
      i++
    ) {
      this.retirementAgeList.push(i);
      this.tradeDownAgeList.push(i);
    }
    if (this.retirementAgeList.length === 0) {
      this.retirementAgeList.push(currentAge);
    }
    this.selectedAge =
      new Date(Date.parse(this.ctx.goal.startDate)).getFullYear() -
      this._primary.yearOfBirth;
    if (this.isVirtualGoal && this._partner) {
      this.selectedAge =
        new Date(Date.parse(this.ctx.goal.startDate)).getFullYear() -
        this._partner.yearOfBirth -
        this.ageDiff;
    }
    this.selectedTradeDownAge =
      new Date(
        Date.parse(this.ctx.goal.properties.tradeDownDate)
      ).getFullYear() - this._primary.yearOfBirth;
  }

  onAgeChange(event: MatSelectChange) {
    const date = new Date();
    date.setFullYear(this._primary.yearOfBirth + event.value);
    this.ctx.goal.startDate = date.toISOString();
    this.ctx.goal.endDate = date.toISOString();
  }

  onTradeDownChange(event: MatSelectChange) {
    const date = new Date();
    date.setFullYear(this._primary.yearOfBirth + event.value);
    this.ctx.goal.properties.tradeDownDate = date.toISOString();
  }

  get minYear() {
    return new Date().getFullYear();
  }

  get maxYear() {
    return new Date().getFullYear() + Constants.GOAL_YEAR_RANGE;
  }

  get isVirtualGoal() {
    return (
      this.ctx.goal.description &&
      JSON.parse(this.ctx.goal.description)['type'] === 'PARTNER_RETIREMENT'
    );
  }
}
