import { MortgageFundingSourceComponent } from './mortgage-funding-source/mortgage-funding-source.component';
import { LiquidityFundingSourceComponent } from './liquidity-funding-source/liquidity-funding-source.component';
import { FundingSourceBaseComponent } from './funding-source-base/funding-source-base';
import { OtherLoanFundingSourceComponent } from './other-loan-funding-source/other-loan-funding-source.component';
import { StudentLoanFundingSourceComponent } from './student-loan-funding-source/student-loan-funding-source.component';
import { SaveFundingSourceComponent } from './save-funding-source/save-funding-source.component';
import { BankOfMumAndDadFundingSourceComponent } from './bank-mum-dad-funding-source/bank-mum-dad-funding-source.component';
import { ResidentialPropertyFundingSourceComponent } from './residential-property-funding-source/residential-property-funding-source.component';
import { InvestmentPropertyFundingSourceComponent } from './investment-property-funding-source/investment-property-funding-source.component';
import { FutureInvestmentPropertyFundingSourceComponent } from './future-investment-property-funding-source/future-investment-property-funding-source.component';
import { FutureResidentialPropertyFundingSourceComponent } from './future-residential-property-funding-source/future-residential-property-funding-source.component';

export const FUNDING_SOURCE_COMPONENTS: {
  class: string;
  component: typeof FundingSourceBaseComponent;
}[] = [
  { class: 'MortgageFundingSource', component: MortgageFundingSourceComponent },
  {
    class: 'LiquidAssetsFundingSource',
    component: LiquidityFundingSourceComponent,
  },
  {
    class: 'UKStudentLoanFundingSource',
    component: StudentLoanFundingSourceComponent,
  },
  { class: 'SaveFundingSource', component: SaveFundingSourceComponent },
  {
    class: 'OtherLoanFundingSource',
    component: OtherLoanFundingSourceComponent,
  },
  {
    class: 'BankOfMumAndDadFundingSource',
    component: BankOfMumAndDadFundingSourceComponent,
  },
  {
    class: 'ResidentialPropertyFundingSource',
    component: ResidentialPropertyFundingSourceComponent,
  },
  {
    class: 'InvestmentPropertyFundingSource',
    component: InvestmentPropertyFundingSourceComponent,
  },
  {
    class: 'FutureInvestmentPropertyAssetFundingSource',
    component: FutureInvestmentPropertyFundingSourceComponent,
  },
  {
    class: 'FutureResidentialPropertyAssetFundingSource',
    component: FutureResidentialPropertyFundingSourceComponent,
  },
];
