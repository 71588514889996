import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Resolve } from '@angular/router';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AppConfigService } from '../services/app.config.service';
import { AppFeatureType } from './AppFeatureType';

@Injectable()
export class FeatureService {
  constructor(private configService: AppConfigService) {}

  public hasFeature = (feature: string) =>
    (this.configService.getConfig().features || {})[feature];
}
