import { Constants } from "../app.constants";

export class GuestUtils {

  public static registered(): boolean {
    //WA-356 - Disable guest upgrade feature completely
    return true;
    return !!localStorage.getItem(Constants.LOCAL_STORAGE_USER_EMAIL);
  }

}
