import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Constants } from 'src/app/app.constants';
import { ScenarioUtils } from 'src/app/utils';
import { HttpClient } from '@angular/common/http';
import { catchError, finalize } from 'rxjs/operators';
import { HttpErrorHandler, HandleError } from 'src/app/services';

@Component({
    selector: 'app-link-to-bubele-dialog',
    templateUrl: './link-to-bubele.dialog.component.html',
    styleUrls: ['./link-to-bubele.dialog.component.scss']
})
export class LinkToBubeleDialogComponent {

    static readonly SMTP_GATEWAY = 'https://d0sc2t5vu0.execute-api.eu-west-1.amazonaws.com/prod/email';

    private handleError: HandleError;
    private _agreed: boolean;

    constructor(
        private http: HttpClient,
        private httpErrorHandler: HttpErrorHandler,
        private dialogRef: MatDialogRef<LinkToBubeleDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: any) {
        this.handleError = httpErrorHandler.createHandleError('mortgageLiabilityService');
    }

    cancel = () => this.dialogRef.close();

    goToBubele = () => {
        this.http.post<any>(LinkToBubeleDialogComponent.SMTP_GATEWAY, ScenarioUtils.toSummary(this.data.scenario))
            .pipe(
                finalize(() => this.dialogRef.close()))
            .pipe(
                catchError(this.httpErrorHandler.handleError('create rent expense')))
            .subscribe(r => {
                localStorage.setItem(Constants.LOCAL_STORAGE_BUBELE_REGISTERED, "true");
                window.open("https://bubeleapp.com/subscribe/", "_blank");
            });
    };

    get agreed() {
        return this._agreed;
    }

    set agreed(agreed: boolean) {
        this._agreed = agreed;
    }

}
