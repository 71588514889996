import { BaseModel } from '../../base.model';
import { Constants } from 'src/app/app.constants';

export class PensionExpense extends BaseModel {
  earnedIncomePercentSaved: number;
  portfolioId: string;
  earnedIncomeId: string;
}

export class PensionExpenseBuilder {
  private _pensionExpense: PensionExpense;

  constructor() {
    this._pensionExpense = new PensionExpense();
    this._pensionExpense.source = Constants.DEFAULT_SOURCE;
    return this;
  }

  public id(id: string) {
    this._pensionExpense.id = id;
    return this;
  }

  public name(name: string) {
    this._pensionExpense.name = name;
    return this;
  }

  public description(description: string) {
    this._pensionExpense.description = description;
    return this;
  }

  public earnedIncomePercentSaved(earnedIncomePercentSaved: number) {
    this._pensionExpense.earnedIncomePercentSaved = earnedIncomePercentSaved;
    return this;
  }

  public portfolioId(portfolioId: string) {
    this._pensionExpense.portfolioId = portfolioId;
    return this;
  }

  public earnedIncomeId(earnedIncomeId: string) {
    this._pensionExpense.earnedIncomeId = earnedIncomeId;
    return this;
  }

  public build() {
    return this._pensionExpense;
  }
}
