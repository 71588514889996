import { BaseModel } from '../../base.model';
import { DateRangeType, Frequency } from '../../enums';
import { Constants } from 'src/app/app.constants';
import { Income } from './income';

export class RentIncome extends BaseModel implements Income {
  currency: string;
  startDate: string;
  startsOn: DateRangeType;
  endDate: string;
  endsOn: DateRangeType;
  annualNetRentalYieldPercentage: number;
  toSurvivorPercentage: number;
  propertyAssetId: string;
  // not used
  amount: number;
  frequency: Frequency;
}

export class RentIncomeBuilder {
  private _rentIncome: RentIncome;

  constructor() {
    this._rentIncome = new RentIncome();
    this._rentIncome.source = Constants.DEFAULT_SOURCE;
    return this;
  }

  public id(id: string) {
    this._rentIncome.id = id;
    return this;
  }

  public name(name: string) {
    this._rentIncome.name = name;
    return this;
  }

  public description(description: string) {
    this._rentIncome.description = description;
    return this;
  }

  public currency(currency: string) {
    this._rentIncome.currency = currency;
    return this;
  }

  public startDate(startDate: string) {
    this._rentIncome.startDate = startDate;
    return this;
  }

  public startsOn(startsOn: DateRangeType) {
    this._rentIncome.startsOn = startsOn;
    return this;
  }

  public endDate(endDate: string) {
    this._rentIncome.endDate = endDate;
    return this;
  }

  public endsOn(endsOn: DateRangeType) {
    this._rentIncome.endsOn = endsOn;
    return this;
  }

  public annualNetRentalYieldPercentage(
    annualNetRentalYieldPercentage: number
  ) {
    this._rentIncome.annualNetRentalYieldPercentage =
      annualNetRentalYieldPercentage;
    return this;
  }

  public toSurvivorPercentage(toSurvivorPercentage: number) {
    this._rentIncome.toSurvivorPercentage = toSurvivorPercentage;
    return this;
  }

  public propertyAssetId(propertyAssetId: string) {
    this._rentIncome.propertyAssetId = propertyAssetId;
    return this;
  }

  public build() {
    return this._rentIncome;
  }
}
