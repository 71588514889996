import { Component, OnInit, OnDestroy } from '@angular/core';
import { PersonsService } from 'src/app/services/persons.service';
import { Person, Gender, MaritalStatus } from 'src/app/model';
import { map, flatMap, single } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { of, forkJoin, Subscription } from 'rxjs';
import { Constants } from 'src/app/app.constants';
import { NotifierService } from 'angular-notifier';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService, TypedGoalService, WizardService } from 'src/app/services';
import { AnalyticsEvent, AnalyticsProperty } from 'src/app/model/analytics';
import { AnalyticsUtils } from 'src/app/utils/analytics.utils';
import { GoalFactory } from 'src/app/model/goal/goal.factory.class';
import { DateUtils, ScenarioUtils } from 'src/app/utils';
import { AppFeatureType } from 'src/app/feature';
import { FeatureService } from 'src/app/feature/feature.service';
import { DragulaService } from 'ng2-dragula';

@Component({
  selector: 'app-family',
  templateUrl: './family.component.html',
  styleUrls: ['./family.component.scss']
})
export class FamilyComponent implements OnInit, OnDestroy {
  private _navigation: any;

  private _primary: Person;
  private _partner: Person;
  private _children: Person[] = [];
  private _scenarioId: string;

  private _primaryForm: FormGroup;
  private _partnerForm: FormGroup;
  private _childrenForms: FormGroup[];
  private _healthStatuses = Constants.HEALTH_STATUSES;
  private _jobTypes = Constants.JOB_TYPES;

  private _onDragEnd: Function;
  private _selectedGender: string;

  private _dragging: boolean;
  private _loading: boolean = false;

  private _scrollable = true;

  private _subs: Subscription[] = [];

  constructor(
    private dragulaService: DragulaService,
    private router: Router,
    private analyticsService: AnalyticsService,
    private titleService: Title,
    private t: TranslateService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private notifier: NotifierService,
    private typedGoalService: TypedGoalService,
    private personsService: PersonsService,
    private wizardService: WizardService,
    private featureService: FeatureService
  ) {
    this.dragulaService.createGroup('PERSON', {
      direction: 'horizontal',
      copy: () => true,
      accepts: (el, target) => { return (target === document.getElementById('dropzone_1') || target === document.getElementById("dropzone_2")); }
    });
    this._subs.push(this.dragulaService.drop('PERSON').subscribe((el) => this.handleDrop(el.source) ));
    this._subs.push(this.dragulaService.drag().subscribe(() => this._scrollable = false));
    this._subs.push(this.dragulaService.dragend().subscribe(() => this._scrollable = true));
    this._subs.push(this.dragulaService.drop().subscribe((d) => { this._scrollable = true; }));
    this._dragging = false;
    window.addEventListener('touchmove', e => !this._scrollable && e.preventDefault(), { passive: false });
  }

  handleDrop(el: Element) {
    const inDropzone = document.getElementById('dropzone_2').childNodes.length === 3 || document.getElementById('dropzone_1').childNodes.length === 3;
    if (inDropzone) {
      const nodeText = this.getDraggedElementText(el);
      switch(nodeText) {
        case 'Man': 
          this.createDefaultPartner(Gender.MALE);
          break;
        case 'Woman': 
          this.createDefaultPartner(Gender.FEMALE);
          break;
        case 'Boy': 
          this.createDefaultChild(Gender.MALE);
          break;
        case 'Girl': 
          this.createDefaultChild(Gender.FEMALE);
          break;
        default:
          break;
      }
    }

    //empty drop zones
    document.getElementById('dropzone_2').childNodes.forEach(c => {
      if (c['classList'] && c['classList'].item && c['classList'].item(0) === 'coin') {
        c.remove();
      }
    });
    document.getElementById('dropzone_1').childNodes.forEach(c => {
      if (c['classList'] && c['classList'].item && c['classList'].item(0) === 'coin') {
        c.remove();
      }
    });
  }

  getDraggedElementText(el: Element) {
    return el.childNodes[1].textContent.trim();
  }

  private _onRefreshDone = {
    next: () => {
      this._loading = false;
    },
    error: err => {
      this._loading = false;
      this.notifier.notify('error', err);
    }
  };

  ngOnInit() {
    this.titleService.setTitle(this.t.instant('Onboarding | Family'));
    this._loading = true;
    this._navigation = (this.route.data as any).value.navigation;
    this.refreshPersons();
  }

  ngOnDestroy() {
    this.wizardService.setVisited(this.route.snapshot.url[0].path);
    this._subs.forEach(s => s.unsubscribe());
    this.dragulaService.destroy('PERSON');
    this.dragulaService.destroy('DROPZONE');
  }

  refreshPrimary() {
    this.$refreshPrimary().subscribe(this._onRefreshDone);
  }

  refreshPartner() {
    this.$refreshPartner().subscribe(this._onRefreshDone);
  }

  refreshChildren() {
    this.$refreshChildren().subscribe(this._onRefreshDone);
  }

  $refreshPartner() {
    this._scenarioId = this.router.routerState.snapshot.url.split('/')[2];
    return this.personsService.getPartner(this._scenarioId)
      .pipe(
        flatMap(partner => {
          if (!partner) {
            this._partner = null;
            return of({});
          }
          this._partner = partner;
          this._partnerForm = this.formBuilder.group({
            name: this._partner.name,
            age: new Date().getFullYear() - this._partner.yearOfBirth,
            retirementAge: this._partner.expectedRetirementAge,
            health: this._partner.healthStatus,
            jobType: this._partner.jobType
          });
          return of({});
        })
      )
  }

  $refreshPrimary() {
    return this.personsService.getPrimary(this._scenarioId)
      .pipe(
        map(primary => {
          this._primary = primary;
          this._primaryForm = this.formBuilder.group({
            name: this._primary.name,
            age: new Date().getFullYear() - this._primary.yearOfBirth,
            retirementAge: this._primary.expectedRetirementAge,
            health: this._primary.healthStatus,
            jobType: this._primary.jobType
          });
          return of({});
        })
      )
  }

  $refreshChildren() {
    return this.personsService.queryChildren(this._scenarioId, Constants.PAGE_ALL)
      .pipe(
        map(r => {
          if (!r || !r.content) {
            return of({});
          }
          this._children = r.content;
          this._childrenForms = new Array();
          this._children.forEach(child => {
            this._childrenForms.push(this.formBuilder.group({
              name: child.name,
              age: new Date().getFullYear() - child.yearOfBirth,
              health: child.healthStatus,
            }));
          });
          return of({});
        })
      );
  }

  refreshPersons() {
    this._scenarioId = this.router.routerState.snapshot.url.split('/')[2];
    forkJoin([
      forkJoin([
        this.$refreshPrimary(),
        this.typedGoalService.query(this._scenarioId, Constants.PAGE_ALL)
      ])
        .pipe(
          flatMap(resp => {
            if (resp[1].content.filter(g => g.type === 'RETIREMENT').length === 0) {
              return this.typedGoalService.create(this._scenarioId,
                GoalFactory.retirementGoal(DateUtils.atYear(this._primary.yearOfBirth + this._primary.expectedRetirementAge)));
            } else {
              return of({});
            }
          })
        ),
      this.$refreshPartner(),
      this.$refreshChildren()
    ]).subscribe(this._onRefreshDone);
  }

  get ageListAdult() {
    return Array.from(Array(100-18).keys()).map(i => i + 18);
  }
  
  get ageListMinor() {
    return Array.from(Array(18).keys())
  }

  updatePrimary() {
    this._loading = true;
    const clonedPerson = Object.assign({}, this._primary);
    clonedPerson.name = this._primaryForm.get('name').value;
    clonedPerson.healthStatus = this._primaryForm.get('health').value;
    clonedPerson.jobType = this._primaryForm.get('jobType').value;
    clonedPerson.yearOfBirth = new Date().getFullYear() - this._primaryForm.get('age').value;
    this.personsService.updatePrimary(this._scenarioId, clonedPerson)
      .subscribe(
        () => this.refreshPrimary(),
        (err) => { this._loading = false; this.notifier.notify(Constants.ERROR, err); },
        () => this.notifier.notify(Constants.SUCCESS, 'Primary person updated'));
  }

  updateGenderForPerson(context: any, gender: string) {
    if (context.primary) {
      this.updatePrimaryGender(gender);
    } else if (context.partner) {
      this.updatePartnerGender(gender);
    } else {
      this.updateChildGender(context.personId, gender);
    }
  }

  updateChildGender(childId: string, gender: string) {
    this._loading = true;
    const clonedPerson = Object.assign({}, this._children.find(c => c.id === childId));
    clonedPerson.gender = Gender[gender];
    this.personsService.updateChild(this._scenarioId, clonedPerson)
      .subscribe(
        () => this.refreshChildren(),
        (err) => { this._loading = false; this.notifier.notify(Constants.ERROR, err); },
        () => this.notifier.notify(Constants.SUCCESS, 'Child updated'));
  }

  updatePrimaryGender(gender: string) {
    this._loading = true;
    const clonedPerson = Object.assign({}, this._primary);
    clonedPerson.gender = Gender[gender];
    this.personsService.updatePrimary(this._scenarioId, clonedPerson)
      .subscribe(
        () => this.refreshPrimary(),
        (err) => { this._loading = false; this.notifier.notify(Constants.ERROR, err); },
        () => this.notifier.notify(Constants.SUCCESS, 'Primary person updated'));
  }

  updatePartnerGender(gender: string) {
    this._loading = true;
    const clonedPerson = Object.assign({}, this._partner);
    clonedPerson.gender = Gender[gender];
    this.personsService.updatePartner(this._scenarioId, clonedPerson)
      .subscribe(
        () => this.refreshPartner(),
        (err) => { this._loading = false; this.notifier.notify(Constants.ERROR, err); },
        () => this.notifier.notify(Constants.SUCCESS, 'Partner person updated'));
  }

  updatePartner() {
    this._loading = true;
    const clonedPerson = Object.assign({}, this._partner);
    clonedPerson.name = this._partnerForm.get('name').value;
    clonedPerson.healthStatus = this._partnerForm.get('health').value;
    clonedPerson.jobType = this._partnerForm.get('jobType').value;
    clonedPerson.yearOfBirth = new Date().getFullYear() - this._partnerForm.get('age').value;
    this.personsService.updatePartner(this._scenarioId, clonedPerson)
      .subscribe(
        () => this.refreshPartner(),
        (err) => { this._loading = false; this.notifier.notify(Constants.ERROR, err); },
        () => this.notifier.notify(Constants.SUCCESS, 'Partner updated'));
  }

  updateChild(idx: number) {
    this._loading = true;
    const clonedPerson = Object.assign({}, this._children[idx]);
    clonedPerson.name = this._childrenForms[idx].get('name').value;
    clonedPerson.healthStatus = this._childrenForms[idx].get('health').value;
    clonedPerson.yearOfBirth = new Date().getFullYear() - this._childrenForms[idx].get('age').value;
    this.personsService.updateChild(this._scenarioId, clonedPerson)
      .subscribe(
        () => this.refreshChildren(),
        (err) => { this._loading = false; this.notifier.notify(Constants.ERROR, err); },
        () => this.notifier.notify(Constants.SUCCESS, 'Child updated'));
  }

  createDefaultPartner(gender: string) {
    if (this._partner) {
      return;
    }
    this._loading = true;
    this.analyticsService.trackAnalyticsEvent(AnalyticsEvent.ADD_PARTNER, '');
    const person: Person = {
      expectedRetirementAge: this.primary.expectedRetirementAge,
      gender: Gender[gender],
      healthStatus: Constants.DEFAULT_PARTNER_HEALTH_STATUS,
      jobType: Constants.DEFAULT_PARTNER_JOB_TYPE,
      educationLevel: Constants.DEFAULT_PARTNER_EDUCATION_LEVEL,
      maritalStatus: MaritalStatus.MARRIED,
      name: Constants.DEFAULT_PARTNER_NAME,
      primary: false,
      yearOfBirth: this.primary.yearOfBirth
    };
    this.personsService.createPartner(this._scenarioId, person)
      .subscribe(
        () => this.refreshPartner(),
        (err) => { this.notifier.notify(Constants.ERROR, err); this._loading = false; },
        () => this.notifier.notify(Constants.SUCCESS, 'Partner created'));
  }

  createDefaultChild(gender: string) {
    this._loading = true;
    this.analyticsService.trackAnalyticsEvent(AnalyticsEvent.ADD_CHILD, '');
    const person: Person = {
      expectedRetirementAge: this.primary.expectedRetirementAge,
      gender: Gender[gender],
      healthStatus: Constants.DEFAULT_PARTNER_HEALTH_STATUS,
      jobType: Constants.DEFAULT_PARTNER_JOB_TYPE,
      educationLevel: Constants.DEFAULT_CHILD_EDUCATION_LEVEL,
      maritalStatus: MaritalStatus.UNSPECIFIED,
      name: Constants.DEFAULT_CHILD_NAME,
      yearOfBirth: Constants.DEFAULT_CHILD_YEAR_OF_BIRTH,
      primary: false,
    };
    this.personsService.createChild(this._scenarioId, person)
      .subscribe(
        () => this.refreshChildren(),
        (err) => { this.notifier.notify(Constants.ERROR, err); this._loading = false; },
        () => this.notifier.notify(Constants.SUCCESS, 'Child created'));
  }

  deletePartner() {
    this._loading = true;
    this.analyticsService.trackAnalyticsEvent(AnalyticsEvent.REMOVE_PARTNER, '');
    this.personsService.deletePartner(this._scenarioId)
      .subscribe(
        () => this.refreshPartner(),
        (err) => { this.notifier.notify(Constants.ERROR, err); this._loading = false; },
        () => this.notifier.notify(Constants.SUCCESS, 'Partner deleted'));
  }

  deleteChild(idx: number) {
    this._loading = true;
    this.analyticsService.trackAnalyticsEvent(AnalyticsEvent.REMOVE_CHILD, '');
    this.personsService.deleteChild(this._scenarioId, this._children[idx].id)
      .subscribe(
        () => this.refreshChildren(),
        (err) => { this.notifier.notify(Constants.ERROR, err); this._loading = false; },
        () => this.notifier.notify(Constants.SUCCESS, 'Child deleted'));
  }

  get navigation() {
    return this._navigation;
  }

  get primary(): Person {
    return this._primary;
  }

  get partner(): Person {
    return this._partner;
  }

  get children(): Person[] {
    return this._children;
  }

  get primaryForm(): FormGroup {
    return this._primaryForm;
  }

  get partnerForm(): FormGroup {
    return this._partnerForm;
  }

  get childrenForms(): FormGroup[] {
    return this._childrenForms;
  }

  get healthStatuses(): { key: string; label: string; }[] {
    return this._healthStatuses;
  }

  get jobTypes(): { key: string; label: string; }[] {
    return this._jobTypes;
  }

  get isMoneyhubEnabled(): boolean {
    return this.featureService.hasFeature(AppFeatureType.MoneyHubAccounts);
  }

  onDragStart(cb: Function, gender: string) {
    this._onDragEnd = cb;
    this._selectedGender = gender;
    this._dragging = true;
  }

  onDragEnd() {
    this._dragging = false;
  }

  onDrop() {
    this._onDragEnd(this._selectedGender);
    this._dragging = false;
  }

  get dragging() {
    return !this._scrollable;
  }

  get loading() {
    return this._loading;
  }

}
