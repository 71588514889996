import {
  Component,
  Input,
  Output,
  EventEmitter,
  ComponentFactoryResolver,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { Goal } from 'src/app/model/goal';
import { EditGoalBaseComponent } from '../edit-goal-base/edit-goal-base.component';
import { Constants } from 'src/app/app.constants';
import { UnsecuredLiability } from 'src/app/model';
import { MatDialog } from '@angular/material/dialog';
import { ScenarioUtils } from 'src/app/utils';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-edit-goal-eliminate-debt',
  templateUrl: './edit-goal-eliminate-debt.component.html',
  styleUrls: ['./edit-goal-eliminate-debt.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditGoalEliminateDebtComponent extends EditGoalBaseComponent {
  @Output() deleteGoal: EventEmitter<Goal> = new EventEmitter();
  @Output() updateGoal: EventEmitter<Goal> = new EventEmitter();

  public unsecuredLiabilities: UnsecuredLiability[] = [];
  public selectedLiability: UnsecuredLiability;

  constructor(
    resolver: ComponentFactoryResolver,
    private dialog: MatDialog,
    private ref: ChangeDetectorRef
  ) {
    super(resolver, dialog, ref);
  }

  ngOnInit() {
    this.unsecuredLiabilities = ScenarioUtils.getUnsecuredDebtLiabilities(
      this.ctx.scenario
    ) as UnsecuredLiability[];
    this.selectedLiability = this.unsecuredLiabilities.find(
      (l) => l.id === this.ctx.goal.properties['liabilityAndDebtId']
    );
  }

  onYearChange(value: string) {
    this.ctx.goal.startDate = value;
    this.ctx.goal.endDate = value;
  }

  get minYear() {
    return new Date().getFullYear();
  }

  get maxYear() {
    return new Date().getFullYear() + Constants.GOAL_YEAR_RANGE;
  }

  onLiabilitySelect(ev: MatSelectChange) {
    this.ctx.goal.properties['liabilityAndDebtId'] = ev.value;
  }
}
