import { Component, OnInit } from '@angular/core';
import { MoneyhubService } from '../../../services/moneyhub.service';
import { UserPropertyService } from '../../../services/user.property.service';
import { Constants } from '../../../app.constants';
import {
  MoneyhubUser,
  IMoneyhubConnection,
} from '../../../model/moneyhub/envizage-connect.model';
import { NotifierService } from 'angular-notifier';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { WizardService } from '../../../services/wizard.service';

@Component({
  selector: 'app-connections',
  templateUrl: './connections.component.html',
  styleUrls: ['./connections.component.scss'],
})
export class ConnectionsComponent implements OnInit {
  loading = false;
  connections: IMoneyhubConnection[] = [];
  navigation;
  moneyhubUserId: string;
  moneyhubUserCreatedAt: string;

  constructor(
    titleService: Title,
    private moneyhub: MoneyhubService,
    private userProperty: UserPropertyService,
    private notifier: NotifierService,
    private router: Router,
    private route: ActivatedRoute,
    private wizard: WizardService
  ) {
    titleService.setTitle('Import data');
  }

  async ngOnInit() {
    this.navigation = (this.route.data as any).value.navigation;

    this.loading = true;
    try {
      const data = await this.userProperty
        .get(Constants.USER_PROPERTY_KEY_MONEYHUB_USER_ID)
        .toPromise();
      this.moneyhubUserId = data.value;
      this.moneyhubUserCreatedAt = this.getDate(data.lastUpdatedOn);
    } catch (ex) {
      // get from local storage
      const user = MoneyhubUser.get();
      if (user) {
        this.moneyhubUserId = user.userId;
        this.moneyhubUserCreatedAt = this.getDate(user.createdAt);
      }
    }
    if (!this.moneyhubUserId) {
      this.loading = false;
      this.notifier.notify(
        Constants.ERROR,
        'Could not find a connected user. Please re-authenticate with your bank'
      );
      // go back to connect
      const connectionsUrl = this.router.url
        .split('/')
        .map((s) => (s === '' ? '/' : s))
        .map((s) => (s === 'accounts' ? 'connect' : s));
      this.router.navigate(connectionsUrl);
      return;
    }
    await Promise.all([this.getConnections(), this.getAccountData()]);
    this.loading = false;
  }

  private async getConnections() {
    try {
      this.connections = await this.moneyhub
        .getConnectionsWithDetails()
        .toPromise();
      if (this.connections.length > 0) {
        localStorage.setItem(
          Constants.LOCAL_STORAGE_MONEYHUB_CONNECTIONS,
          JSON.stringify(this.connections)
        );
      } else {
        if (
          localStorage.getItem(Constants.LOCAL_STORAGE_MONEYHUB_CONNECTIONS)
        ) {
          localStorage.removeItem(Constants.LOCAL_STORAGE_MONEYHUB_CONNECTIONS);
        }
      }
    } catch (ex) {
      this.notifier.notify(Constants.ERROR, 'Could not retrieve connections');
    }
  }

  private async getAccountData() {
    try {
      const data = await Promise.all([
        this.moneyhub
          .getIncomeStatement(this.moneyhubUserCreatedAt)
          .toPromise(),
        this.moneyhub.getBalanceSheet().toPromise(),
      ]);
      const [incomes, expenses] = data;
      // check if account data exist, notify wizard
      if (incomes) {
        let count = 0;
        // income statement
        Object.keys(incomes).forEach((category) => {
          count += !!incomes[category] ? 1 : 0;
        });
        if (count > 0) {
          this.wizard.showNotificationForPath('income');
        }
      }
      if (expenses) {
        // balance sheet
        let count = 0;
        Object.keys(expenses).forEach((category) => {
          count += expenses[category].length;
        });
        if (count > 0) {
          this.wizard.showNotificationForPath('balance');
        }
      }
    } catch (ex) {
      // do nothing
    }
  }

  private getDate(createdAt: string) {
    const createdDate = new Date(createdAt);
    const fromDate = new Date(
      createdDate.setFullYear(createdDate.getFullYear() - 1)
    );
    return fromDate.toISOString().split('T')[0];
  }

  async removeConnection(id: string) {
    try {
      this.loading = true;
      await this.moneyhub.removeConnection(id).toPromise();
      await this.getConnections();
    } catch (ex) {
      this.notifier.notify(Constants.ERROR, 'Could not remove connection');
    } finally {
      this.loading = false;
    }
  }
}
