import { Injectable } from '@angular/core';
import { HandleError, HttpErrorHandler } from './http-error-handler.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PageableResponse, PageableRequest } from '../model';
import {
  ServiceForPerson,
  QueryOnlyService,
  QueryOnlyServiceForPerson,
} from './service';
import { Insurance, MortgageInsurance } from '../model/protection';
import { AppConfigService } from './app.config.service';

@Injectable()
export class InsuranceService
  implements QueryOnlyService<Insurance>, QueryOnlyServiceForPerson<Insurance>
{
  private handleError: HandleError;
  private getUrl = (scenarioId, id = '') =>
    `${
      this.configService.getConfig().apiUrl
    }/scenarios/${scenarioId}/insurances/${id}`;
  private getUrlForPerson = (scenarioId, personId, id = '') =>
    `${
      this.configService.getConfig().apiUrl
    }/scenarios/${scenarioId}/persons/${personId}/insurances/${id}`;

  constructor(
    private http: HttpClient,
    private configService: AppConfigService,
    private httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('liabilityService');
  }

  query(
    scenarioId: string,
    pageable: PageableRequest
  ): Observable<PageableResponse<Insurance>> {
    return this.http
      .get<PageableResponse<Insurance>>(this.getUrl(scenarioId), {
        params: pageable,
      })
      .pipe(catchError(this.handleError('query insurances')));
  }

  get(scenarioId: string, id: string): Observable<Insurance> {
    return this.http
      .get<Insurance>(this.getUrl(scenarioId, id))
      .pipe(catchError(this.handleError('get insurance')));
  }

  queryForPerson(
    scenarioId: string,
    personId: string,
    pageable: PageableRequest
  ): Observable<PageableResponse<Insurance>> {
    return this.http
      .get<PageableResponse<Insurance>>(
        this.getUrlForPerson(scenarioId, personId),
        { params: pageable }
      )
      .pipe(catchError(this.handleError('query insurances')));
  }

  getForPerson(
    scenarioId: string,
    personId: string,
    id: string
  ): Observable<Insurance> {
    return this.http
      .get<Insurance>(this.getUrlForPerson(scenarioId, personId, id))
      .pipe(catchError(this.handleError('get insurance')));
  }
}

@Injectable()
export class LifeInsuranceService implements ServiceForPerson<Insurance> {
  private handleError: HandleError;
  private getUrlForPerson = (scenarioId, personId, id = '') =>
    `${
      this.configService.getConfig().apiUrl
    }/scenarios/${scenarioId}/persons/${personId}/insurances/life/${id}`;

  constructor(
    private http: HttpClient,
    private configService: AppConfigService,
    private httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError(
      'lifeInsuranceService'
    );
  }

  // Life insurance
  queryForPerson(
    scenarioId: string,
    personId: string,
    pageable: PageableRequest
  ): Observable<PageableResponse<Insurance>> {
    return this.http
      .get<PageableResponse<Insurance>>(
        this.getUrlForPerson(scenarioId, personId),
        { params: pageable }
      )
      .pipe(catchError(this.handleError('query life insurances')));
  }

  getForPerson(
    scenarioId: string,
    personId: string,
    id: string
  ): Observable<Insurance> {
    return this.http
      .get<Insurance>(this.getUrlForPerson(scenarioId, personId, id))
      .pipe(catchError(this.handleError('get life insurance')));
  }

  createForPerson(
    scenarioId: string,
    personId: string,
    insurance: Insurance
  ): Observable<Insurance> {
    return this.http
      .post<Insurance>(this.getUrlForPerson(scenarioId, personId), insurance)
      .pipe(catchError(this.handleError('create life insurance')));
  }

  updateForPerson(
    scenarioId: string,
    personId: string,
    insurance: Insurance
  ): Observable<Insurance> {
    return this.http
      .put<Insurance>(
        this.getUrlForPerson(scenarioId, personId, insurance.id),
        insurance
      )
      .pipe(catchError(this.handleError('update life insurance')));
  }

  deleteForPerson(
    scenarioId: string,
    personId: string,
    id: string
  ): Observable<any> {
    return this.http
      .delete<any>(this.getUrlForPerson(scenarioId, personId, id))
      .pipe(catchError(this.handleError('delete life insurance')));
  }
}

@Injectable()
export class CriticalIllnessInsuranceService
  implements ServiceForPerson<Insurance>
{
  private handleError: HandleError;
  private getUrlForPerson = (scenarioId, personId, id = '') =>
    `${
      this.configService.getConfig().apiUrl
    }/scenarios/${scenarioId}/persons/${personId}/insurances/critical-illness/${id}`;

  constructor(
    private http: HttpClient,
    private configService: AppConfigService,
    private httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError(
      'criticalIllnessInsuranceService'
    );
  }

  // Critical illness insurance
  queryForPerson(
    scenarioId: string,
    personId: string,
    pageable: PageableRequest
  ): Observable<PageableResponse<Insurance>> {
    return this.http
      .get<PageableResponse<Insurance>>(
        this.getUrlForPerson(scenarioId, personId),
        { params: pageable }
      )
      .pipe(catchError(this.handleError('query critical illness insurances')));
  }

  getForPerson(
    scenarioId: string,
    personId: string,
    id: string
  ): Observable<Insurance> {
    return this.http
      .get<Insurance>(this.getUrlForPerson(scenarioId, personId, id))
      .pipe(catchError(this.handleError('get critical illness insurance')));
  }

  createForPerson(
    scenarioId: string,
    personId: string,
    insurance: Insurance
  ): Observable<Insurance> {
    return this.http
      .post<Insurance>(this.getUrlForPerson(scenarioId, personId), insurance)
      .pipe(catchError(this.handleError('create critical illness insurance')));
  }

  updateForPerson(
    scenarioId: string,
    personId: string,
    insurance: Insurance
  ): Observable<Insurance> {
    return this.http
      .put<Insurance>(
        this.getUrlForPerson(scenarioId, personId, insurance.id),
        insurance
      )
      .pipe(catchError(this.handleError('update critical illness insurance')));
  }

  deleteForPerson(
    scenarioId: string,
    personId: string,
    id: string
  ): Observable<any> {
    return this.http
      .delete<any>(this.getUrlForPerson(scenarioId, personId, id))
      .pipe(catchError(this.handleError('delete critical illness insurance')));
  }
}

@Injectable()
export class DisabilityInsuranceService implements ServiceForPerson<Insurance> {
  private handleError: HandleError;
  private getUrlForPerson = (scenarioId, personId, id = '') =>
    `${
      this.configService.getConfig().apiUrl
    }/scenarios/${scenarioId}/persons/${personId}/insurances/disability/${id}`;

  constructor(
    private http: HttpClient,
    private configService: AppConfigService,
    private httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError(
      'disabilityInsurance'
    );
  }

  // Disability insurance
  queryForPerson(
    scenarioId: string,
    personId: string,
    pageable: PageableRequest
  ): Observable<PageableResponse<Insurance>> {
    return this.http
      .get<PageableResponse<Insurance>>(
        this.getUrlForPerson(scenarioId, personId),
        { params: pageable }
      )
      .pipe(catchError(this.handleError('query disability insurances')));
  }

  getForPerson(
    scenarioId: string,
    personId: string,
    id: string
  ): Observable<Insurance> {
    return this.http
      .get<Insurance>(this.getUrlForPerson(scenarioId, personId, id))
      .pipe(catchError(this.handleError('get disability insurance')));
  }

  createForPerson(
    scenarioId: string,
    personId: string,
    insurance: Insurance
  ): Observable<Insurance> {
    return this.http
      .post<Insurance>(this.getUrlForPerson(scenarioId, personId), insurance)
      .pipe(catchError(this.handleError('create disability insurance')));
  }

  updateForPerson(
    scenarioId: string,
    personId: string,
    insurance: Insurance
  ): Observable<Insurance> {
    return this.http
      .put<Insurance>(
        this.getUrlForPerson(scenarioId, personId, insurance.id),
        insurance
      )
      .pipe(catchError(this.handleError('update disability insurance')));
  }

  deleteForPerson(
    scenarioId: string,
    personId: string,
    id: string
  ): Observable<any> {
    return this.http
      .delete<any>(this.getUrlForPerson(scenarioId, personId, id))
      .pipe(catchError(this.handleError('delete disability insurance')));
  }
}

@Injectable()
export class MortgageLifeInsuranceService
  implements ServiceForPerson<MortgageInsurance>
{
  private handleError: HandleError;
  private getUrlForPerson = (scenarioId, personId, id = '') =>
    `${
      this.configService.getConfig().apiUrl
    }/scenarios/${scenarioId}/persons/${personId}/insurances/mortgage-life/${id}`;

  constructor(
    private http: HttpClient,
    private configService: AppConfigService,
    private httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError(
      'mortgageLifeInsuranceService'
    );
  }

  queryForPerson(
    scenarioId: string,
    personId: string,
    pageable: PageableRequest
  ): Observable<PageableResponse<MortgageInsurance>> {
    return this.http
      .get<PageableResponse<MortgageInsurance>>(
        this.getUrlForPerson(scenarioId, personId),
        { params: pageable }
      )
      .pipe(catchError(this.handleError('query mortgage-life insurances')));
  }

  getForPerson(
    scenarioId: string,
    personId: string,
    id: string
  ): Observable<MortgageInsurance> {
    return this.http
      .get<MortgageInsurance>(this.getUrlForPerson(scenarioId, personId, id))
      .pipe(catchError(this.handleError('get mortgage-life insurance')));
  }

  createForPerson(
    scenarioId: string,
    personId: string,
    insurance: MortgageInsurance
  ): Observable<MortgageInsurance> {
    return this.http
      .post<MortgageInsurance>(
        this.getUrlForPerson(scenarioId, personId),
        insurance
      )
      .pipe(catchError(this.handleError('create mortgage-life insurance')));
  }

  updateForPerson(
    scenarioId: string,
    personId: string,
    insurance: MortgageInsurance
  ): Observable<MortgageInsurance> {
    return this.http
      .put<MortgageInsurance>(
        this.getUrlForPerson(scenarioId, personId, insurance.id),
        insurance
      )
      .pipe(catchError(this.handleError('update mortgage-life insurance')));
  }

  deleteForPerson(
    scenarioId: string,
    personId: string,
    id: string
  ): Observable<any> {
    return this.http
      .delete<any>(this.getUrlForPerson(scenarioId, personId, id))
      .pipe(catchError(this.handleError('delete mortgage-life insurance')));
  }
}
