import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HandleError, HttpErrorHandler } from './http-error-handler.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PageableResponse, PageableRequest } from '../model';
import { GoalDiagnostic } from '../model/household/result/goal.diagnostic';
import { AppConfigService } from './app.config.service';

@Injectable()
export class ResultsOverallGoalDiagnosticService {
  private handleError: HandleError;
  private getUrl = () => this.configService.getConfig().apiUrl;

  constructor(
    private http: HttpClient,
    private configService: AppConfigService,
    private httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError(
      'resultsOverallGoalDiagnosticService'
    );
  }

  query(
    scenarioId: string,
    pageable: PageableRequest
  ): Observable<PageableResponse<GoalDiagnostic>> {
    return this.http
      .get<PageableResponse<GoalDiagnostic>>(
        `${this.getUrl()}/results/${scenarioId}/diagnostics/goal`,
        { params: pageable }
      )
      .pipe(catchError(this.handleError('query diagnostics')));
  }

  get(
    scenarioId: string,
    goalId: string,
    pageable: PageableRequest
  ): Observable<PageableResponse<GoalDiagnostic>> {
    return this.http
      .get<PageableResponse<GoalDiagnostic>>(
        `${this.getUrl()}/results/${scenarioId}/diagnostics/goal/${goalId}`,
        { params: pageable }
      )
      .pipe(catchError(this.handleError('query diagnostics')));
  }
}
