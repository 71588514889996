import { BaseModel } from '../../base.model';

export class PensionContributionIncome extends BaseModel {
  currency: string;
  earnedIncomeId: string;
  earnedIncomePercentSaved: number;
  maximumEmployerContribution: number;
  portfolioId: string;
  name: string;
  id: string;
}

export class PensionContributionIncomeBuilder {
  private _income: PensionContributionIncome;

  constructor() {
    this._income = new PensionContributionIncome();
  }

  id(id: string) {
    this._income.id = id;
    return this;
  }

  name(name: string) {
    this._income.name = name;
    return this;
  }

  portfolioId(portfolioId: string) {
    this._income.portfolioId = portfolioId;
    return this;
  }

  maximumEmployerContribution(contribution: number) {
    this._income.maximumEmployerContribution = contribution;
    return this;
  }

  earnedIncomePercentSaved(percent: number) {
    this._income.earnedIncomePercentSaved = percent;
    return this;
  }

  earnedIncomeId(id: string) {
    this._income.earnedIncomeId = id;
    return this;
  }

  currency(currency: string) {
    this._income.currency = currency;
    return this;
  }

  build() {
    return this._income;
  }
}
