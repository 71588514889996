export interface Action {
  type: string;
  amount: number;
}

export interface Actions {
  actions: Action[];
}

export interface PercentagedAction {
  entityId: string;
  percentage: number;
}

export interface RentAction {
  yield: number;
  rentId: string;
}
