import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.scss'],
})
export class OptionsComponent {
  @Input() band = '';
  @Input() duration = '';
  @Output() updateBand = new EventEmitter<MouseEvent>();
  @Output() updateDuration = new EventEmitter<MouseEvent>();

  emitUpdateBand(event: MouseEvent) {
    this.updateBand.emit(event);
  }

  emitUpdateDuration(event: MouseEvent) {
    this.updateDuration.emit(event);
  }
}
