import { BaseModel } from '../../base.model';
import { DateRangeType, Frequency, BaseGrowthRate } from '../../enums';
import { Constants } from 'src/app/app.constants';
import { Income } from './income';

export class EarnedIncome extends BaseModel implements Income {
  amount: number;
  currency: string;
  startDate: string;
  startsOn: DateRangeType;
  endDate: string;
  endsOn: DateRangeType;
  frequency: Frequency;
  growthRate: BaseGrowthRate;
  spreadOverGrowthRate: number;
  maximumAbsoluteSpreadAllowed: number;
  isFromLinkedAccounts?: boolean;
}

export class EarnedIncomeBuilder {
  private _earnedIncome: EarnedIncome;

  constructor() {
    this._earnedIncome = new EarnedIncome();
    this._earnedIncome.source = Constants.DEFAULT_SOURCE;
    return this;
  }

  public id(id: string) {
    this._earnedIncome.id = id;
    return this;
  }

  public name(name: string) {
    this._earnedIncome.name = name;
    return this;
  }

  public description(description: string) {
    this._earnedIncome.description = description;
    return this;
  }

  public amount(amount: number) {
    this._earnedIncome.amount = amount;
    return this;
  }

  public currency(currency: string) {
    this._earnedIncome.currency = currency;
    return this;
  }

  public startDate(startDate: string) {
    this._earnedIncome.startDate = startDate;
    return this;
  }

  public startsOn(startsOn: DateRangeType) {
    this._earnedIncome.startsOn = startsOn;
    return this;
  }

  public endDate(endDate: string) {
    this._earnedIncome.endDate = endDate;
    return this;
  }

  public endsOn(endsOn: DateRangeType) {
    this._earnedIncome.endsOn = endsOn;
    return this;
  }

  public frequency(frequency: Frequency) {
    this._earnedIncome.frequency = frequency;
    return this;
  }

  public growthRate(growthRate: BaseGrowthRate) {
    this._earnedIncome.growthRate = growthRate;
    return this;
  }

  public spreadOverGrowthRate(spreadOverGrowthRate: number) {
    this._earnedIncome.spreadOverGrowthRate = spreadOverGrowthRate;
    return this;
  }

  public maximumAbsoluteSpreadAllowed(maximumAbsoluteSpreadAllowed: number) {
    this._earnedIncome.maximumAbsoluteSpreadAllowed =
      maximumAbsoluteSpreadAllowed;
    return this;
  }

  public linkedAccount(linked: boolean) {
    this._earnedIncome.isFromLinkedAccounts = linked;
    return this;
  }

  public build() {
    return this._earnedIncome;
  }
}
