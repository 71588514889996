import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';

export const IressAPIHeader = 'X-Iress-Api';
export const IressAPIAuthenticateHeader = 'X-Iress-Api-Authenticate';

@Injectable()
export class IressAPIInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        private notifier: NotifierService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        if (!req.headers.has(IressAPIHeader)) {
            return next.handle(req);
        }

        let newHeaders = req.headers.delete('Authorization');

        if (req.headers.has(IressAPIAuthenticateHeader)) {
            let token = btoa('api.envizage:todayisWednesday20');
            newHeaders = newHeaders.set('Authorization', `Basic ${token}`);
            newHeaders = newHeaders.set('Content-Type', 'text/plain');
        }
        
        newHeaders = newHeaders.delete(IressAPIHeader);
        newHeaders = newHeaders.delete(IressAPIAuthenticateHeader);
        
        // newHeaders = newHeaders.set('x-xplan-app-id', localStorage.getItem('x-xplan-app-id'));
        // newHeaders = newHeaders.set('x-forwarded-host', localStorage.getItem('x-forwarded-host'));
        // newHeaders = newHeaders.set('Cookie', 'XPLANID=BHVhR5_vT60Iuxst8CsP.');

        newHeaders = newHeaders.set('x-xplan-app-id', '1EzuIeyPX2Y9NkL7VeTA');
        // newHeaders = newHeaders.set('x-forwarded-host', 'thirdpartyintegrations.xplan.iress.co.uk');
        
        // document.cookie = `XPLANID=NnxvX4U8OJfi1CPPGPLB.; path=/`;
        // this.cookieService.set('XPLANID', '+6qoeVX59QSG9hMByBSQ.');

        return next.handle(req.clone({ headers: newHeaders }));
    }
}
