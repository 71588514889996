import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HandleError, HttpErrorHandler } from './http-error-handler.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PageableResponse, PageableRequest } from '../model';
import { GoalDiagnostic } from '../model/household/result/goal.diagnostic';
import { AppConfigService } from './app.config.service';

@Injectable()
export class ResultsBandedGoalDiagnosticService {
    private handleError: HandleError;
    private getUrl = () => this.configService.getConfig().apiUrl;

    constructor(
        private http: HttpClient,
        private configService: AppConfigService,
        private httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('resultsBandedGoalDiagnosticService');
    }

    query(scenarioId: string, pageable: PageableRequest): Observable<PageableResponse<GoalDiagnostic>> {
        return this.http.get<PageableResponse<GoalDiagnostic>>(`${this.getUrl()}/results/${scenarioId}/diagnostics/band/goal`, { params: pageable })
            .pipe(
                catchError(this.handleError('query goal diagnostics'))
            );
    }

    queryByBandId(scenarioId: string, bandId: string, pageable: PageableRequest): Observable<PageableResponse<GoalDiagnostic>> {
        return this.http.get<PageableResponse<GoalDiagnostic>>(`${this.getUrl()}/results/${scenarioId}/diagnostics/band/${bandId}/goal`, { params: pageable })
            .pipe(
                catchError(this.handleError('query goal diagnostics by bandId'))
            );
    }

    queryByGoalId(scenarioId: string, goalId: string, pageable: PageableRequest): Observable<PageableResponse<GoalDiagnostic>> {
        return this.http.get<PageableResponse<GoalDiagnostic>>(`${this.getUrl()}/results/${scenarioId}/diagnostics/band/goal/${goalId}`, { params: pageable })
            .pipe(
                catchError(this.handleError('query goal diagnostics by goalId'))
            );
    }

    getByGoalIdAndBandId(scenarioId: string, goalId: string, bandId: string, pageable: PageableRequest): Observable<PageableResponse<GoalDiagnostic>> {
        return this.http.get<PageableResponse<GoalDiagnostic>>(`${this.getUrl()}/results/${scenarioId}/diagnostics/band/${bandId}/goal/${goalId}`, { params: pageable })
            .pipe(
                catchError(this.handleError('get goal diagnostics'))
            );
    }

}