export interface Liability {
  id?: string;
  name: string;
  description?: string;
  source?: string;
  amount: number;
  currency: string;
  endDate: string;
  startDate: string;
}
