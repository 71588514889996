import { Injectable } from '@angular/core';
import { HandleError, HttpErrorHandler } from './http-error-handler.service';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './app.config.service';
import { Constants } from '../app.constants';

@Injectable()
export class UserPropertyService {
    private getUrl = (clientId, username) => `${this.configService.getConfig().apiUrl}/config/user/${clientId}/${username}`;

    private handleError: HandleError;

    constructor(
        private http: HttpClient,
        private configService: AppConfigService,
        private httpErrorHandler: HttpErrorHandler
    ) {
        this.handleError = httpErrorHandler.createHandleError('personsService');
    }

    create(key, value) {
        return this.http.post<any>(this.getUrl(`${localStorage.getItem(Constants.LOCAL_STORAGE_CLIENT_ID)}`, localStorage.getItem('username')), { key, value });
    }

    get(key) {
        return this.http.get<any>(this.getUrl(`${localStorage.getItem(Constants.LOCAL_STORAGE_CLIENT_ID)}`, localStorage.getItem('username') + '/' + key));
    }
}
