import { Component, Input, Output, EventEmitter, signal } from '@angular/core';
import { Constants } from 'src/app/app.constants';
import { ResidentialProperty } from 'src/app/model';

@Component({
  selector: 'app-balance-asset-property',
  templateUrl: './asset-property.component.html',
  styleUrls: ['./asset-property.component.scss'],
})
export class BalanceAssetPropertyComponent {
  @Input() entity: ResidentialProperty;
  @Input() currency: string;

  @Output() update = new EventEmitter<ResidentialProperty>();
  @Output() remove = new EventEmitter<ResidentialProperty>();
  @Output() updateLinked = new EventEmitter<ResidentialProperty>();
  @Output() removeLinked = new EventEmitter<ResidentialProperty>();

  isEdit = signal(false);
  private _isDirty = false;

  onUpdate() {
    this._isDirty = true;
  }

  onRemove() {
    this.remove.emit(this.entity);
  }

  onDismiss() {
    this._isDirty && this.update.emit(this.entity);
    this.isEdit.set(false);
  }

  onUpdateLinked() {
    this.updateLinked.emit(this.entity);
  }

  onRemoveLinked() {
    this.removeLinked.emit(this.entity);
  }

  isFromLinkedAccounts(data) {
    return data.source === Constants.SOURCE_MONEYHUB;
  }
}
