import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IMoneyhubConnection } from '../../../../model/moneyhub/envizage-connect.model';
import { MHCancelDialogComponent } from '../cancel-dialog/cancel-dialog.component';

@Component({
  selector: 'app-connected-bank',
  templateUrl: './connected-bank.component.html',
  styleUrls: ['./connected-bank.component.scss'],
})
export class ConnectedBankComponent implements OnInit {
  @Input() connection: IMoneyhubConnection;

  @Output() remove: EventEmitter<string> = new EventEmitter();

  constructor(private dialog: MatDialog) {}

  ngOnInit() {}

  manage() {
    const dialogRef = this.dialog.open(MHCancelDialogComponent, {
      width: '350px',
      data: { connection: this.connection },
    });

    dialogRef.afterClosed().subscribe((accepted) => {
      if (!accepted) {
        return;
      }
      this.remove.emit(this.connection.connectionId);
    });
  }
}
