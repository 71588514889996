import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AppFeatureType } from './AppFeatureType';
import { AppConfigService } from '../services/app.config.service';

@Directive({
  selector: '[appIfFeature]'
})
export class FeatureDirective implements OnInit {

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private configService: AppConfigService,
  ) { }

  @Input() appIfFeature: string;

  ngOnInit() {
    const self = this;
    this.configService.resolve().toPromise().then(config => {
      const features = config.features;
      if (!features ||
        !!features && (typeof features[self.appIfFeature] === undefined || features[self.appIfFeature])) {
        if (!self.viewContainer.get(0)) {
          self.viewContainer.createEmbeddedView(self.templateRef);
        }
      } else {
        self.viewContainer.clear();
      }
    });
  }
}
