import { BaseModel } from '../base.model';
import { Owner, DateRangeType } from '../enums';
import { GoalType, Goal } from './goal.class';
import { FundingSource } from './funding.sources.class';
import { GoalUtils } from 'src/app/utils';

export class GoalDto extends BaseModel {
    public owner: Owner;
    public priority: number;
    public frequency: string;
    public enabled: boolean;
    public minimumAmount: number;
    public desiredAmount: number;
    public currency: string;
    public startDate: string;
    public endDate: string;
    public startsOn: DateRangeType;
    public endsOn: DateRangeType;
    public type: GoalType;
    public icon: string;
    public properties: any;
    public fundingSources: Array<FundingSource> = new Array();

    constructor() {
        super();
    }
}

export class GoalMapper {
    static fromDto(dto: GoalDto): Goal {
        const goalEntry = GoalUtils.GOALS.find(g => g.type === dto.type);

        const goal = new Goal();
        goal.id = dto.id;
        goal.name = dto.name;
        goal.description = dto.description;
        goal.source = dto.source;
        goal.owner = dto.owner;
        goal.priority = dto.priority;
        goal.frequency = dto.frequency;
        goal.enabled = dto.enabled;
        goal.minimumAmount = dto.minimumAmount;
        goal.desiredAmount = dto.desiredAmount;
        goal.startDate = dto.startDate;
        goal.endDate = dto.endDate;
        goal.startsOn = dto.startsOn;
        goal.endsOn = dto.endsOn;
        goal.currency = dto.currency;
        goal.icon = goalEntry ? goalEntry.icon : dto.icon;
        goal.properties = dto.properties;
        goal.fundingSources = dto.fundingSources;
        goal.type = dto.type;

        return goal;
    }

    static toDto(goal: Goal): GoalDto {
        return new GoalDtoBuilder()
            .withId(goal.id)
            .withName(goal.name)
            .withDescription(goal.description)
            .withSource(goal.source)
            .withOwner(goal.owner)
            .withPriority(goal.priority)
            .withEnabled(goal.enabled)
            .withFrequency(goal.frequency)
            .withMinimumAmount(goal.minimumAmount)
            .withDesiredAmount(goal.desiredAmount)
            .withCurrency(goal.currency)
            .withStartDate(goal.startDate)
            .withEndDate(goal.endDate)
            .withStartsOn(goal.startsOn)
            .withEndsOn(goal.endsOn)
            .withType(goal.type)
            .withProperties(goal.properties)
            .withFundingSources(goal.fundingSources)
            .build();
    }
}

export class GoalDtoBuilder {
    private _goalDto: GoalDto;

    constructor() {
        this._goalDto = new GoalDto();
    }

    withId(id: string) {
        this._goalDto.id = id;
        return this;
    }

    withName(name: string) {
        this._goalDto.name = name;
        return this;
    }

    withDescription(description: string) {
        this._goalDto.description = description;
        return this;
    }

    withSource(source: string) {
        this._goalDto.source = source;
        return this;
    }

    withOwner(owner: Owner) {
        this._goalDto.owner = owner;
        return this;
    }

    withPriority(priority: number) {
        this._goalDto.priority = priority;
        return this;
    }

    withEnabled(enabled: boolean) {
        this._goalDto.enabled = enabled;
        return this;
    }

    withFrequency(frequency: string) {
        this._goalDto.frequency = frequency;
        return this;
    }

    withMinimumAmount(amount: number) {
        this._goalDto.minimumAmount = amount;
        return this;
    }

    withDesiredAmount(amount: number) {
        this._goalDto.desiredAmount = amount;
        return this;
    }

    withCurrency(currency: string) {
        this._goalDto.currency = currency;
        return this;
    }

    withStartDate(date: string) {
        this._goalDto.startDate = date;
        return this;
    }

    withEndDate(date: string) {
        this._goalDto.endDate = date;
        return this;
    }

    withStartsOn(startsOn: DateRangeType) {
        this._goalDto.startsOn = startsOn;
        return this;
    }

    withEndsOn(endsOn: DateRangeType) {
        this._goalDto.endsOn = endsOn;
        return this;
    }

    withType(type: GoalType) {
        this._goalDto.type = type;
        return this;
    }

    withProperties(properties: any) {
        this._goalDto.properties = properties;
        return this;
    }

    withFundingSources(sources: Array<FundingSource>) {
        this._goalDto.fundingSources = sources;
        return this;
    }

    build() {
        return this._goalDto;
    }
}
