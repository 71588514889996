import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FundingSourceBaseComponent } from '../funding-source-base/funding-source-base';
import { Goal } from 'src/app/model/goal';
import { FutureResidentialPropertyAssetFundingSource } from 'src/app/model/goal/funding.sources.class';
import { TypedGoalService } from 'src/app/services/typed.goal.service';
import { Scenario } from 'src/app/model';
import { ScenarioUtils } from 'src/app/utils';
import * as moment from 'moment/moment';

@Component({
  selector: 'app-funding-source-future-residential-property',
  templateUrl: './future-residential-property-funding-source.component.html',
  styleUrls: ['./future-residential-property-funding-source.component.scss'],
})
export class FutureResidentialPropertyFundingSourceComponent
  extends FundingSourceBaseComponent
  implements OnInit
{
  @Input() goal: Goal;
  @Input() fundingSource: FutureResidentialPropertyAssetFundingSource;
  @Input() scenario: Scenario;
  @Output() updateGoal: EventEmitter<any>;

  constructor(private typedGoalService: TypedGoalService) {
    super(typedGoalService);
  }

  ngOnInit() {}

  get availableGoals() {
    return ScenarioUtils.getAllGoals(this.scenario)
      .filter((g) => g.class === 'BuyAHouseGoal')
      .filter((g) => moment.utc(this.goal.startDate).isAfter(g.startDate))
      .filter(
        (g) =>
          g.id === this.fundingSource.goalId ||
          this.goal.fundingSources.filter(
            (s) =>
              s.class === 'FutureResidentialPropertyAssetFundingSource' &&
              (s as FutureResidentialPropertyAssetFundingSource).goalId === g.id
          ).length === 0
      );
  }

  deleteSource() {
    this.goal.fundingSources = this.goal.fundingSources.filter(
      (s) =>
        (s as FutureResidentialPropertyAssetFundingSource).goalId !==
        this.fundingSource.goalId
    );
  }
}
