import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Scenario } from 'src/app/model';
import { FundingSourceUtils } from 'src/app/model/goal/funding.sources.class';
import { Goal } from 'src/app/model/goal';

export interface DeleteScenarioDialogData {
    scenario: Scenario
};

@Component({
    selector: 'app-add-funding-source-dialog-component',
    templateUrl: './funding.source.dialog.component.html',
    styleUrls: ['./funding.source.dialog.component.scss']
})
export class AddFundingSourceDialogComponent implements OnInit {
 
    availableFundingSources: any[];
    goal: Goal;
    scenario: Scenario;

    constructor(
        private dialogRef: MatDialogRef<AddFundingSourceDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private dialogData: { scenario: Scenario, goal: Goal }
    ) {
        this.availableFundingSources = [];
        this.goal = dialogData.goal;
        this.scenario = dialogData.scenario;
        FundingSourceUtils.GOAL_TYPE_TO_AVAILABLE_FUNDING_SOURCES[this.goal.type].forEach(source => {
            this.availableFundingSources = this.availableFundingSources
                .concat(FundingSourceUtils.FUNDING_SOURCE_TYPES[source].getInstances(this.scenario, this.goal))
        });
    }

    ngOnInit() {
        
    }

    addFundingSource(source) {
        this.goal.fundingSources.push(source);
        this.dialogRef.close();
    }

    cancel() {
        this.dialogRef.close();
    }
}