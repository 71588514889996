// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-mdc-form-field {
  padding: 0 10px 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/common/edit-goals/edit-goal-buy-house/edit-goal-buy-house.component.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;AACF","sourcesContent":[".mat-mdc-form-field {\n  padding: 0 10px 10px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
