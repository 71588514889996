import { ElementRef, Directive, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';


@Directive({
    selector: '[ngModel][percentage]',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => ToPercentDirective),
        multi: true
    }],
    host: {
        "[value]": 'ngModel',
        '(blur)': 'onBlur()'
    }
})
export class ToPercentDirective implements ControlValueAccessor {

    constructor(private el: ElementRef) { }

    private innerValue: string;
    private toNumber: number;
    private toPercent: number;

    public onChangeCallback: any = () => { }
    public onTouched: any = () => { }


    onBlur() {
        var input = this.el.nativeElement.value;
        this.toPercent = parseFloat(input) / 100;

        if (input != this.toNumber) {
            this.onChangeCallback(this.toPercent);
        }
    }

    get value(): any {
        return this.innerValue;
    };

    set value(v: any) {
        if (v !== this.innerValue) {
            this.innerValue = v;
            this.onChangeCallback(v);
        }
    }

    writeValue(val: string): void {
        this.toNumber = parseFloat(val) * 100;
        this.el.nativeElement.value = Math.round(this.toNumber * 100) / 100;
    }

    registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
}