// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sign-up-btn {
  color: white;
}

.notice {
  font-size: 12px;
  line-height: 23px !important;
  white-space: normal !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/common/link-to-bubele-dialog/link-to-bubele.dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,eAAA;EACA,4BAAA;EACA,8BAAA;AACF","sourcesContent":[".sign-up-btn {\n  color: white;\n}\n\n.notice {\n  font-size: 12px;\n  line-height: 23px !important;\n  white-space: normal !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
