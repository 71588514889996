import { Constants } from '../../app.constants';
export interface TransactionItem {
  date: string;
  year: number;
  month: number;
  monthOffset: number;
  amount: number;
  currency: string;
  description: string;
  categoryId: string;
}

export interface MonthlyTransactions {
  offset: number; // the month offset from current
  year: number;
  month: number;
  incomeValue: number;
  expenseValue: number;
  monthComplete: boolean;
  incomes: TransactionItem[];
  expenses: TransactionItem[];
  transactions: TransactionItem[];
}

export interface MonthlyFinancialData {
  avgMonthlyExpenses: number;
  avgMonthlyIncomes: number;
  monthlyTransactions: MonthlyTransactions[];
}

export interface MonthlyFinancialDataWrapper {
  currency: string;
  data: MonthlyFinancialData;
}

export interface IAccount {
  accountName: string;
  accountReference: string;
  balance: { amount: { value: number; currency: string }; date: string };
  connectionId: string;
  currency: string;
  dateAdded: string;
  dateModified: string;
  details: {
    overdraftLimit: number;
    sortCodeAccountNumber: string;
  };
  id: string;
  providerId: string;
  providerName: string;
  providerReference: string;
  type: string;
}

export interface IAccountData {
  data: IAccount[];
  links: { self: string };
  meta: { correlationId: string };
}

export const DEFAULT_MONEYHUB_ACCOUNT_DATA: IAccountData = {
  data: [],
  links: null,
  meta: null,
};

export interface IMoneyhubUser {
  clientId: string;
  clientUserId: IMoneyhubUserDetails;
  managedBy: string;
  scopes: string;
  userId: string;
  userType: string;
  connectionIds?: string[];
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  lastAccessed?: string;
}

export interface IMoneyhubUserDetails {
  clientId: string;
  connectionIds: string[];
  createdAt?: string;
  deletedAt?: string;
  lastAccessed?: string;
  managedBy?: string;
  scopes?: string;
  updatedAt?: string;
  userId: string;
  userType?: string;
}

export const DEFAULT_MONEYHUB_USER: IMoneyhubUser = {
  clientId: null,
  clientUserId: null,
  managedBy: null,
  scopes: null,
  userId: null,
  userType: null,
  createdAt: null,
};

export interface ICreateAuthRequestResponse {
  authRequestId: string;
  authUrl: string;
  state: string;
}

export interface ICompleteAuthRequestResponse {
  status: string;
  connectionId: string;
  createdDate: string;
}

export interface IMoneyhubAccount {
  name: string;
  beta?: boolean;
}
export interface IMoneyhubBank {
  id: string;
  name: string;
  type: string;
  country: string;
  parentRef: string;
  bankRef: string;
  isBeta: boolean;
  iconUrl?: string;
  accountTypes: IMoneyhubAccount[];
  userTypes: string[];
  payments: string[];
}

export interface IMoneyhubConnection {
  connectionName: string;
  connectionId: string;
  authorizedOn: string;
  expiresOn: string;
  accounts: IMoneyhubConnectionAccount[];
}

export interface IMoneyhubConnectionAccount {
  accountNumber: string;
  name: string;
}

export interface IMoneyhubBalanceSheet {
  assets: any[];
  liabilities: any[];
}

export interface IMoneyhubIncomeStatement {
  income?: IMoneyhubIncomeStatementData;
  otherIncome?: IMoneyhubIncomeStatementData;
  basicExpense?: IMoneyhubIncomeStatementData;
  lifestyleExpense?: IMoneyhubIncomeStatementData;
  rentExpense?: IMoneyhubIncomeStatementData;
}

export interface IMoneyhubIncomeStatementData {
  amount: number;
  currency: string;
  frequency: string;
  transactions: IMoneyhubTransactionData[];
}

export interface IMoneyhubTransactionData {
  date: string;
  year: number;
  month: number;
  amount: number;
  currency: string;
  description: string;
}

export class MoneyhubUser {
  static set(value: any) {
    localStorage.setItem(
      Constants.LOCAL_STORAGE_MONEYHUB_USER,
      JSON.stringify(value)
    );
  }

  static get(): IMoneyhubUser {
    try {
      const userObj = localStorage.getItem(
        Constants.LOCAL_STORAGE_MONEYHUB_USER
      );
      if (!userObj) {
        return null;
      }
      return JSON.parse(userObj);
    } catch (ex) {
      return null;
    }
  }
}
