import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IMoneyhubBank } from 'src/app/model';

@Component({
  selector: 'app-mh-consent-dialog',
  templateUrl: './consent-dialog.component.html',
  styleUrls: ['./consent-dialog.component.scss']
})
export class MHConsentDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<MHConsentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { bank: IMoneyhubBank }
  ) { }

  ngOnInit() {

  }

}
