import { Liability } from './liability';
import { RepaymentType, MortgageType } from '../enums';

export interface MortgageLiability extends Liability {
  balanceAmount: number;
  balanceDate: string;
  annualAverageInterestRate: number;
  mortgageType: MortgageType;
  repaymentType: RepaymentType;
  propertyAssetId: string;
}
