import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FundingSourceBaseComponent } from '../funding-source-base/funding-source-base';
import { Goal } from 'src/app/model/goal';
import { FundingSource, LiquidAssetsFundingSource } from 'src/app/model/goal/funding.sources.class';
import { TypedGoalService } from 'src/app/services/typed.goal.service';
import { Scenario } from 'src/app/model';
import { Constants } from 'src/app/app.constants';

@Component({
    selector: 'app-funding-source-liquidity',
    templateUrl: './liquidity-funding-source.component.html',
    styleUrls: ['./liquidity-funding-source.component.scss']
})
export class LiquidityFundingSourceComponent extends FundingSourceBaseComponent implements OnInit  {

    @Input() goal: Goal;
    @Input() fundingSource: LiquidAssetsFundingSource;
    @Input() scenario: Scenario;
    @Output() updateGoal: EventEmitter<any>;

    isaEnabled: boolean;
    pensionEnabled: boolean;
    giaEnabled: boolean;
    locale = 'UK';
    isaLabel = 'ISA';
    giaLabel = 'GIA';

    public assetTypes = {
        isa: 'TAX_ADVANTAGED',
        gia: 'GENERAL_INVESTMENT_ACCOUNT',
        pension: 'PENSION'
    };

    constructor(private typedGoalService: TypedGoalService) {
        super(typedGoalService);
    }

    ngOnInit() {
        this.locale = localStorage.getItem(Constants.LOCAL_STORAGE_LOCALE);
        this.isaLabel = Constants.LOCALE_CONFIG[this.locale].isaLabel;
        this.giaLabel = Constants.LOCALE_CONFIG[this.locale].giaLabel;
        this.giaEnabled = (this.fundingSource['wrappers'] as Array<any>).indexOf('GENERAL_INVESTMENT_ACCOUNT') >= 0;
        this.isaEnabled = (this.fundingSource['wrappers'] as Array<any>).indexOf('TAX_ADVANTAGED') >= 0;
        this.pensionEnabled = (this.fundingSource['wrappers'] as Array<any>).indexOf('PENSION') >= 0;
    }
    
    onToggleChange(e, asset: string) {
        console.log("assetType: ", asset);
        const index = (this.fundingSource['wrappers'] as Array<any>).indexOf(asset);
        if( asset === this.assetTypes.isa){
            this.isaEnabled = e.checked;
            if(!this.isaEnabled){
                (this.fundingSource['wrappers'] as Array<any>).splice(index, 1);
            }
            else {
                (this.fundingSource['wrappers'] as Array<any>).splice(index, 0, asset);
            }
        }
        else if(asset === this.assetTypes.gia) {
            this.giaEnabled = e.checked;
            if(this.giaEnabled === false){
                (this.fundingSource['wrappers'] as Array<any>).splice(index, 1);
            }
            else {
                (this.fundingSource['wrappers'] as Array<any>).splice(index, 0, asset);
            }
        }
        else {
            this.pensionEnabled = e.checked;
            if(!this.pensionEnabled){
                (this.fundingSource['wrappers'] as Array<any>).splice(index, 1);
            }
            else {
                (this.fundingSource['wrappers'] as Array<any>).splice(index, 0, asset);
            }
        }
    }

}