import { Injectable } from '@angular/core';
import { HandleError, HttpErrorHandler } from './http-error-handler.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, flatMap, retry, map } from 'rxjs/operators';
import { Observable, of, ObservableInput } from 'rxjs';
import { AppConfigService } from './app.config.service';

@Injectable()
export class OxfordRiskService {

    private userToken: string;

    private envizageConnect: any;
    private handleError;

    constructor(
        private http: HttpClient,
        httpErrorHandler: HttpErrorHandler,
        private config: AppConfigService
    ) {
        this.handleError = (action$: any, err: HttpErrorResponse) => {
            if (err.status === 401) {
                return this.getClientToken()
                    .pipe(
                        flatMap(t => this.getUserToken(t.id)),
                        flatMap(r => {
                            this.userToken = r.token;
                            return action$();
                        })
                    );
            } else {
                throw new Error('Unhandled error');
            }
        };
    }

    getClientToken(): Observable<any> {
        return this.http.post<any>('https://doc-gen.oxfordrisk.tech/tokens',
            { username: 'client', api_key: '8Ao?msjuMWkxBux(BagvXvAQUtWnLD3sQAYj)UscALtt'})
            .pipe(retry(1));
    }

    getUserToken(clientToken: string): Observable<any> {
        return this.http.post<any>('https://org.oxfordrisk.tech/tokens',
            { subdomain: 'envizage', username: 'lefteris.koxias@lifescale.me', password: 'Envizage_d3v' },
            { headers: { authorization: `${clientToken}` } })
            .pipe(retry(1));
    }

    queryQuestions(): Observable<any> {
        return this.doQueryQuestions()
            .pipe(catchError(this.handleError.bind(this, this.doQueryQuestions.bind(this))));
    }

    queryLiveInstruments(): Observable<any> {
        return this.doQueryLiveInstruments()
            .pipe(catchError(this.handleError.bind(this, this.doQueryLiveInstruments.bind(this))));
    }

    queryAssessmentsForInstrument(instrumentId: number): Observable<any> {
        return this.doQueryAssessmentsForInstrument(instrumentId)
            .pipe(catchError(this.handleError.bind(this, this.doQueryAssessmentsForInstrument.bind(this, instrumentId))));
    }

    doQueryAssessmentsForInstrument(instrumentId: number): Observable<any> {
        return this.http.get<any>(`https://org.oxfordrisk.tech/instruments/${instrumentId}/assessments`,
            { headers: { authorization: `${this.userToken}` }});
    }

    doQueryLiveInstruments(): Observable<any> {
        return this.http.get<any>('https://org.oxfordrisk.tech/instruments?status=LIVE',
            { headers: { authorization: `${this.userToken}` }});
    }

    doQueryQuestions(): Observable<any> {
        return this.http.get<any>('https://org.oxfordrisk.tech/questions',
            { headers: { authorization: `${this.userToken}` }});
    }

    queryQuestionGroupsForAssessment(assessmentId: number): Observable<any> {
        return this.doQueryQuestionGroupsForAssessment(assessmentId)
            .pipe(catchError(this.handleError.bind(this, this.doQueryQuestionGroupsForAssessment.bind(this, assessmentId))));
    }

    createClient(client: any): Observable<any> {
        return this.doCreateClient(client)
            .pipe(catchError(this.handleError.bind(this, this.doCreateClient.bind(this, client))));
    }

    createInstrumentResponse(instrumentResponse: any): Observable<any> {
        return this.doCreateInstrumentResponse(instrumentResponse)
            .pipe(catchError(this.handleError.bind(this, this.doCreateInstrumentResponse.bind(this, instrumentResponse))));
    }

    updateInstrumentResponse(instrumentResponse: any): Observable<any> {
        return this.doUpdateInstrumentResponse(instrumentResponse)
            .pipe(catchError(this.handleError.bind(this, this.doUpdateInstrumentResponse.bind(this, instrumentResponse))));
    }

    createQuestionResponse(questionResponse: any): Observable<any> {
        return this.doCreateQuestionResponse(questionResponse)
            .pipe(catchError(this.handleError.bind(this, this.doCreateQuestionResponse.bind(this, questionResponse))));
    }

    getReportForInstrumentResponse(instrumentResponseId: number): Observable<any> {
        return this.doGetReportForInstrumentResponse(instrumentResponseId)
            .pipe(catchError(this.handleError.bind(this, this.doGetReportForInstrumentResponse.bind(this, instrumentResponseId))));
    }

    doGetReportForInstrumentResponse(instrumentResponseId: number): Observable<any> {
        return this.http.get<any>(`https://org.oxfordrisk.tech/instrumentResponses/${instrumentResponseId}/reports`,
            { headers: { authorization: `${this.userToken}` }});
    }

    doQueryQuestionGroupsForAssessment(assessmentId: number): Observable<any> {
        return this.http.get<any>(`https://org.oxfordrisk.tech/assessments/${assessmentId}/questionGroups`,
            { headers: { authorization: `${this.userToken}` }});
    }

    doCreateClient(client: any): Observable<any> {
        return this.http.post<any>(`https://org.oxfordrisk.tech/clients`, client,
            { headers: { authorization: `${this.userToken}` }});
    }

    doCreateInstrumentResponse(instrumentResponse): Observable<any> {
        return this.http.post<any>(`https://org.oxfordrisk.tech/instrumentResponses`, instrumentResponse,
            { headers: { authorization: `${this.userToken}` }});
    }

    doUpdateInstrumentResponse(instrumentResponse): Observable<any> {
        return this.http.put<any>(`https://org.oxfordrisk.tech/instrumentResponses/${instrumentResponse.id}`, instrumentResponse,
            { headers: { authorization: `${this.userToken}` }});
    }

    doCreateQuestionResponse(questionResponse): Observable<any> {
        return this.http.post<any>(`https://org.oxfordrisk.tech/questionResponses`, questionResponse,
            { headers: { authorization: `${this.userToken}` }});
    }
}
