// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-mdc-form-field {
  padding: 0 10px 10px;
}

.retirement-age .mat-form-input {
  box-sizing: border-box;
  max-width: 100%;
}

.trade-down .mat-form-label {
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/common/edit-goals/edit-goal-retirement/edit-goal-retirement.component.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;AACF;;AAEA;EACE,sBAAA;EACA,eAAA;AACF;;AAGE;EACE,eAAA;AAAJ","sourcesContent":[".mat-mdc-form-field {\n  padding: 0 10px 10px;\n}\n\n.retirement-age .mat-form-input {\n  box-sizing: border-box;\n  max-width: 100%;\n}\n\n.trade-down {\n  & .mat-form-label {\n    font-size: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
