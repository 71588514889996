import { Injectable } from '@angular/core';
import { HandleError, HttpErrorHandler } from '../http-error-handler.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PageableRequest, Client } from '../..//model';
import { AppConfigService } from '../app.config.service';
import { QueryOnlyService } from './iress.service';

@Injectable()
export class IressClientService implements QueryOnlyService<Client> {
  private handleError: HandleError;
  private getUrl = (id: string = ''): string =>
    `${
      this.configService.getConfig().iress.apiUrl
    }/resourceful/entity/client/${id}`;

  constructor(
    private http: HttpClient,
    private configService: AppConfigService,
    private httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('IressClientService');
  }

  query = (pageable: PageableRequest): Observable<Client[]> =>
    this.http
      .get<Client[]>(this.getUrl(), {
        params: pageable,
        headers: { 'X-Iress-Api': '1' },
        withCredentials: true,
      })
      .pipe(catchError(this.handleError('query client portfolios')));

  get = (id: string): Observable<Client> =>
    this.http
      .get<Client>(this.getUrl(id), { headers: { 'X-Iress-Api': '1' } })
      .pipe(catchError(this.handleError('get client portfolio')));
}
