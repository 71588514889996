export interface FinancialPortfolio {
  id?: string;
  name: string;
  profile: string;
  source?: string;
  wrapper: string;
  currency: string;
  value: number;
  description?: string;
  fees: number;
}

export interface FinancialPortfolioWithContributions {
  portfolio: FinancialPortfolio;
  privateContribution: number;
  employerContribution: number;
}
