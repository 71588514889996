import { Injectable } from '@angular/core';
import { HandleError, HttpErrorHandler } from './http-error-handler.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PageableResponse, PageableRequest, Person } from '../model';
import { AppConfigService } from './app.config.service';

@Injectable()
export class PreferenceService {
    private getUrl = (scenarioId, key = '') => `${this.configService.getConfig().apiUrl}/scenarios/${scenarioId}/preference/${key}`;

    private handleError: HandleError;

    constructor(
            private http: HttpClient,
            private configService: AppConfigService,
            private httpErrorHandler: HttpErrorHandler
        ) {
            this.handleError = httpErrorHandler.createHandleError('personsService');
    }

    query(scenarioId: string, pageable: PageableRequest): Observable<PageableResponse<any>> {
        return this.http.get<PageableResponse<any>>(this.getUrl(scenarioId), { params: pageable })
            .pipe(catchError(this.handleError('query preferences')));
    }

    get(scenarioId: string, key: string): Observable<any> {
        return this.http.get<any>(this.getUrl(scenarioId, key))
            .pipe(catchError(this.handleError('get preference')));
    }

    update(scenarioId: string, key: string, value: any): Observable<any> {
        return this.http.put<any>(this.getUrl(scenarioId, key), value)
            .pipe(catchError(this.handleError('update preference')));
    }

    delete(scenarioId: string, key: string): Observable<any> {
        return this.http.delete(this.getUrl(scenarioId, key))
            .pipe(catchError(this.handleError('update preference')));
    }
}