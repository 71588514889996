import { pathOr } from 'ramda';
import {
  Person,
  ResidentialProperty,
  Property,
  InvestmentProperty,
  MortgageLiability,
  UnsecuredLiability,
  Insurance,
  MortgageInsurance,
} from '../model';
import {
  EarnedIncome,
  PensionContributionIncome,
} from '../model/household/income';
import { LivingExpense } from '../model/household/expense';
import { RentExpense } from '../model/household/expense/rent.expense';
import { PensionExpense } from '../model/household/expense/pension.expense';
import { GoalDto } from '../model/goal/goal.dto';
import { Constants } from '../app.constants';

export class ScenarioUtils {
  public static readonly getPerson = (scenario, personId: string) =>
    scenario.household.persons.filter((p) => p.id == personId)[0];

  public static readonly getPartner = (scenario) =>
    ScenarioUtils.getActiveGeneration(scenario).persons.filter(
      (p) => p.primary === false
    )[0];

  public static readonly hasPartner = (scenario) =>
    ScenarioUtils.getActiveGeneration(scenario).persons.filter(
      (p) => p.primary === false
    )[0] != null;

  public static readonly getPrimaryPerson = (scenario) =>
    scenario.household.persons.filter((p) => p.primary === true)[0];

  public static readonly getPersons = (scenario) =>
    scenario.household.generations().flatMap((p) => p.getPersons());

  public static readonly getChildren = (scenario) =>
    ScenarioUtils.getYoungerGeneration(scenario).persons;

  public static readonly getActiveGeneration = (scenario) =>
    scenario.household.generations.filter((g) => g.active === true)[0];

  public static readonly getYoungerGeneration = (scenario) =>
    scenario.household.generations.filter(
      (g) => g.name === 'Younger generation'
    )[0];

  public static readonly getAllAssets = (scenario) =>
    []
      .concat(
        ScenarioUtils.getActiveGeneration(scenario).assets || [],
        scenario.household.persons.flatMap((p) => p.assets || [])
      )
      .map((a) => a.to);

  public static readonly getAllLiabilities = (scenario) =>
    [].concat(
      ScenarioUtils.getActiveGeneration(scenario).debts || [],
      scenario.household.persons.flatMap((p) => p.debts || [])
    );

  public static readonly getAllInsurances = (scenario) =>
    [].concat(
      ScenarioUtils.getActiveGeneration(scenario).insurances || [],
      scenario.household.persons.flatMap((p) => p.insurances || [])
    );

  public static readonly getAllIncomes = (scenario) =>
    [].concat(
      ScenarioUtils.getActiveGeneration(scenario).incomes || [],
      scenario.household.persons.flatMap((p) => p.incomes || [])
    );

  public static readonly getAllExpenses = (scenario) =>
    [].concat(
      ScenarioUtils.getActiveGeneration(scenario).expenses || [],
      scenario.household.persons.flatMap((p) => p.expenses || [])
    );

  public static readonly getAllGoals = (scenario) =>
    [].concat(
      ScenarioUtils.getActiveGeneration(scenario).goals || [],
      scenario.household.persons.flatMap((p) => p.goals || [])
    );

  public static readonly getPersonGoals = (scenario, primary) =>
    scenario.household.persons
      .filter((p) => p.primary === primary)
      .map((p) => p.goals || [])
      .flatMap((g) => g);

  public static readonly getGoalOwner = (scenario, goalId) => {
    return ScenarioUtils.getPersonGoals(scenario, true).filter(
      (g) => goalId === g.id
    ).length > 0
      ? 'PRIMARY'
      : ScenarioUtils.getPersonGoals(scenario, false).filter(
            (g) => goalId === g.id
          ).length > 0
        ? 'PARTNER'
        : 'HOUSEHOLD';
  };

  public static readonly getLivingExpenses = (scenario) =>
    ScenarioUtils.getAllExpenses(scenario).filter(
      (e) => e.class === 'LivingExpense'
    );

  public static readonly getRentExpenses = (scenario) =>
    ScenarioUtils.getAllExpenses(scenario).filter(
      (e) => e.class === 'RentExpense'
    );

  public static readonly getResidentialPropertyAssets = (scenario) =>
    ScenarioUtils.getAllAssets(scenario).filter(
      (e) => e.class === 'ResidentialProperty'
    );

  public static readonly getInvestmentPropertyAssets = (scenario) =>
    ScenarioUtils.getAllAssets(scenario).filter(
      (e) => e.class === 'InvestmentProperty'
    );

  public static readonly getMortgageLiabilities = (scenario) =>
    ScenarioUtils.getAllLiabilities(scenario).filter(
      (e) => e.class === 'Mortgage'
    );

  public static readonly getUnsecuredDebtLiabilities = (scenario) =>
    ScenarioUtils.getAllLiabilities(scenario).filter(
      (e) => e.class === 'UnsecuredDebt'
    );

  public static readonly getEarnedIncomes = (scenario, primary): any[] =>
    scenario.household.persons
      .filter((p) => p.primary === primary)
      .flatMap((p) => p.incomes || [])
      .filter((e) => e.class === 'EarnedIncome');

  public static readonly getEmployerPensionContributions = (
    scenario,
    primary
  ) =>
    scenario.household.persons
      .filter((p) => p.primary === primary)
      .flatMap((p) => p.incomes || [])
      .filter((e) => e.class === 'EmployerPensionContribution');

  public static readonly getEmployeePensionContributions = (
    scenario,
    primary
  ) =>
    scenario.household.persons
      .filter((p) => p.primary === primary)
      .flatMap((p) => p.expenses || [])
      .filter((e) => e.class === 'EmployeePensionContribution');

  public static readonly getLifeInsurances = (scenario, primary) =>
    scenario.household.persons
      .filter((p) => p.primary === primary)
      .flatMap((p) => p.insurances || [])
      .filter((e) => e.class === 'LifeInsurance');

  public static readonly getCriticalIllnessInsurances = (scenario, primary) =>
    scenario.household.persons
      .filter((p) => p.primary === primary)
      .flatMap((p) => p.insurances || [])
      .filter((e) => e.class === 'CriticalIllnessInsurance');

  public static readonly getDisabilityInsurances = (scenario, primary) =>
    scenario.household.persons
      .filter((p) => p.primary === primary)
      .flatMap((p) => p.insurances || [])
      .filter((e) => e.class === 'DisabilityInsurance');

  public static readonly getMortgageLifeInsurances = (scenario, primary) =>
    scenario.household.persons
      .filter((p) => p.primary === primary)
      .flatMap((p) => p.insurances || [])
      .filter((e) => e.class === 'MortgageLifeInsurance');

  public static readonly getIncomesDiff = (diff) =>
    pathOr([], ['incomes', 'modified'], diff)
      .map((i) => {
        const amountDiff = pathOr(
          { base: 'GBP0', other: 'GBP0' },
          ['deltas', 'amount'],
          i
        );
        const baseAmountMultiplier = i.base.frequency === 'MONTHLY' ? 12 : 1;
        const otherAmountMultiplier =
          pathOr(i.base.frequency, ['deltas', 'frequency', 'other'], i) ===
          'MONTHLY'
            ? 12
            : 1;
        const amount =
          otherAmountMultiplier *
            parseFloat(amountDiff.other.substring(3).replace(',', '')) -
          baseAmountMultiplier *
            parseFloat(amountDiff.base.substring(3).replace(',', ''));
        return amount;
      })
      .reduce((a, b) => a + b, 0) +
    pathOr([], ['incomes', 'created'], diff)
      .map((i) =>
        i.frequency === 'MONTHLY'
          ? 12 * ScenarioUtils.parseCurrency(i.amount)
          : ScenarioUtils.parseCurrency(i.amount)
      )
      .reduce((a, b) => a + b, 0) -
    pathOr([], ['incomes', 'deleted'], diff)
      .map((i) =>
        i.frequency === 'MONTHLY'
          ? 12 * ScenarioUtils.parseCurrency(i.amount)
          : ScenarioUtils.parseCurrency(i.amount)
      )
      .reduce((a, b) => a + b, 0);

  public static readonly getExpensesDiff = (diff) =>
    pathOr([], ['expenses', 'modified'], diff)
      .map((i) => {
        const amountDiff = pathOr(
          { base: 'GBP0', other: 'GBP0' },
          ['deltas', 'amount'],
          i
        );
        const baseAmountMultiplier = i.base.frequency === 'MONTHLY' ? 12 : 1;
        const otherAmountMultiplier =
          pathOr(i.base.frequency, ['deltas', 'frequency', 'other'], i) ===
          'MONTHLY'
            ? 12
            : 1;
        const amount =
          otherAmountMultiplier *
            parseFloat(amountDiff.other.substring(3).replace(',', '')) -
          baseAmountMultiplier *
            parseFloat(amountDiff.base.substring(3).replace(',', ''));
        return amount;
      })
      .reduce((a, b) => a + b, 0) +
    pathOr([], ['expenses', 'created'], diff)
      .map((i) =>
        i.frequency === 'MONTHLY'
          ? 12 * ScenarioUtils.parseCurrency(i.amount)
          : ScenarioUtils.parseCurrency(i.amount)
      )
      .reduce((a, b) => a + b, 0) -
    pathOr([], ['expenses', 'deleted'], diff)
      .map((i) =>
        i.frequency === 'MONTHLY'
          ? 12 * ScenarioUtils.parseCurrency(i.amount)
          : ScenarioUtils.parseCurrency(i.amount)
      )
      .reduce((a, b) => a + b, 0);

  public static readonly getAssetsDiff = (diff) =>
    pathOr([], ['assets', 'modified'], diff)
      .map((i) => {
        const amountDiff = pathOr(
          { base: 'GBP0', other: 'GBP0' },
          ['deltas', 'value'],
          i
        );
        const amount =
          parseFloat(amountDiff.other.substring(3).replace(',', '')) -
          parseFloat(amountDiff.base.substring(3).replace(',', ''));
        return amount;
      })
      .reduce((a, b) => a + b, 0) +
    pathOr([], ['assets', 'created'], diff)
      .map((i) => ScenarioUtils.parseCurrency(i.value))
      .reduce((a, b) => a + b, 0) -
    pathOr([], ['assets', 'deleted'], diff)
      .map((i) => ScenarioUtils.parseCurrency(i.value))
      .reduce((a, b) => a + b, 0);

  public static readonly getLiabilitiesDiff = (diff) =>
    pathOr([], ['debts', 'modified'], diff)
      .map((i) => {
        const amountDiff = pathOr(
          { base: 'GBP0', other: 'GBP0' },
          ['deltas', 'amount'],
          i
        );
        const amount =
          parseFloat(amountDiff.other.substring(3).replace(',', '')) -
          parseFloat(amountDiff.base.substring(3).replace(',', ''));
        return amount;
      })
      .reduce((a, b) => a + b, 0) +
    pathOr([], ['debts', 'created'], diff)
      .map((i) => ScenarioUtils.parseCurrency(i.amount))
      .reduce((a, b) => a + b, 0) -
    pathOr([], ['debts', 'deleted'], diff)
      .map((i) => ScenarioUtils.parseCurrency(i.amount))
      .reduce((a, b) => a + b, 0);

  public static readonly getInsurancesDiff = (diff) =>
    pathOr([], ['insurances', 'modified'], diff)
      .map((i) => {
        const amountDiff = pathOr(
          { base: 'GBP0', other: 'GBP0' },
          ['deltas', 'payout'],
          i
        );
        const amount =
          parseFloat(amountDiff.other.substring(3).replace(',', '')) -
          parseFloat(amountDiff.base.substring(3).replace(',', ''));
        return amount;
      })
      .reduce((a, b) => a + b, 0) +
    pathOr([], ['insurances', 'created'], diff)
      .map((i) => ScenarioUtils.parseCurrency(i.payout))
      .reduce((a, b) => a + b, 0) -
    pathOr([], ['insurances', 'deleted'], diff)
      .map((i) => ScenarioUtils.parseCurrency(i.payout))
      .reduce((a, b) => a + b, 0);

  public static readonly getIsaUsageDiff = (diff) => {
    let baseIsaActive = false;
    let otherIsaActive = false;
    const isaDiff = pathOr([], ['preferences', 'modified'], diff).filter(
      (pref) =>
        pathOr('', ['base', 'id'], pref) ===
        'simulation.parameter.savings.sequence'
    )[0];
    if (isaDiff) {
      const baseIsaDetails = pathOr(
        [],
        ['deltas', 'value', 'base'],
        isaDiff
      ).filter((p) => p.id === 'isa')[0];
      baseIsaActive = baseIsaDetails && baseIsaDetails.active === true;
      const otherIsaDetails = pathOr(
        [],
        ['deltas', 'value', 'other'],
        isaDiff
      ).filter((p) => p.id === 'isa')[0];
      otherIsaActive = otherIsaDetails && otherIsaDetails.active === true;
      return [{ active: !!baseIsaActive }, { active: !!otherIsaActive }];
    }
    return null;
  };

  public static readonly getWrapperAllocationDiff = (
    diff,
    assetWrappers,
    assetAllocationPresets
  ) => {
    const result = [];
    const assetAllocationDiff = pathOr(
      [],
      ['preferences', 'modified'],
      diff
    ).filter(
      (pref) => pathOr('', ['base', 'id'], pref) === 'ID.assetAllocationMatrix'
    )[0];
    if (assetAllocationDiff) {
      const baseAssetAllocationPresetNames = pathOr(
        [],
        ['deltas', 'value', 'base'],
        assetAllocationDiff
      ).map((allocation) =>
        ScenarioUtils.getAllocationPresetName(
          allocation,
          assetAllocationPresets
        )
      );
      const otherAssetAllocationPresetNames = pathOr(
        [],
        ['deltas', 'value', 'other'],
        assetAllocationDiff
      ).map((allocation) =>
        ScenarioUtils.getAllocationPresetName(
          allocation,
          assetAllocationPresets
        )
      );
      for (
        let allocationIndex = 0;
        allocationIndex < assetWrappers.length;
        allocationIndex++
      ) {
        if (
          baseAssetAllocationPresetNames[allocationIndex] !==
          otherAssetAllocationPresetNames[allocationIndex]
        ) {
          result.push([
            {
              wrapper: assetWrappers[allocationIndex],
              preset: baseAssetAllocationPresetNames[allocationIndex]
                ? baseAssetAllocationPresetNames[allocationIndex]
                : 'Default allocation',
            },
            {
              wrapper: assetWrappers[allocationIndex],
              preset: otherAssetAllocationPresetNames[allocationIndex]
                ? otherAssetAllocationPresetNames[allocationIndex]
                : 'Default allocation',
            },
          ]);
        }
      }
    }
    return result.filter((r) => r[0].preset !== r[1].preset);
  };

  private static readonly getAllocationPresetName = (allocation, presets) => {
    let matchingPresetName = 'Default allocation';
    if (!presets) {
      return matchingPresetName + ': Check presets';
    }

    if (allocation === null) {
      return 'Default allocation';
    }

    if (
      allocation === undefined ||
      !allocation.length ||
      allocation.length === 0
    ) {
      return matchingPresetName + ': Check allocation';
    }

    let presetNames = null;
    try {
      presetNames = Object.keys(presets);
    } catch (error) {
      return matchingPresetName + ': Check object';
    }

    if (!presetNames || !presetNames.length || presetNames.length === 0) {
      return matchingPresetName + ': Check preset names';
    }

    matchingPresetName = 'Default allocation';
    presetNames.map((presetName) => {
      let preset = presets[presetName];
      if (
        preset.filter((percentage, index) => allocation[index] === percentage)
          .length === allocation.length
      ) {
        matchingPresetName = presetName;
      }
    });

    if (!matchingPresetName.startsWith('Default')) {
      matchingPresetName =
        matchingPresetName.charAt(0).toUpperCase() +
        matchingPresetName.slice(1);
    }

    return matchingPresetName;
  };

  public static readonly parseCurrency = (curr) => {
    try {
      return parseFloat(curr.substring(3).replace(',', ''));
    } catch (e) {
      return 0;
    }
  };

  public static readonly toSummary = (scenario) => {
    let d = new Date();
    let primary: Person = ScenarioUtils.getPrimaryPerson(scenario);
    let partner: Person = ScenarioUtils.getPartner(scenario);

    return {
      username: localStorage.getItem(Constants.LOCAL_STORAGE_USERNAME) || 'N/A',
      primary: {
        age: d.getFullYear() - primary.yearOfBirth,
        expectedRetirementAge: primary.expectedRetirementAge,
        gender: primary.gender,
      },
      partner: !partner
        ? {}
        : {
            age: d.getFullYear() - primary.yearOfBirth,
            expectedRetirementAge: primary.expectedRetirementAge,
            gender: primary.gender,
          },
      numberOfChildren: ScenarioUtils.getChildren(scenario),

      primaryEarnedIncomes: ScenarioUtils.getEarnedIncomes(scenario, true).map(
        (i: EarnedIncome) => {
          return {
            id: i.id,
            name: i.name,
            amount: i.amount,
            frequency: i.frequency,
            endDate: i.endDate,
          };
        }
      ),
      partnerEarnedIncomes: !partner
        ? []
        : ScenarioUtils.getEarnedIncomes(scenario, false).map(
            (i: EarnedIncome) => {
              return {
                id: i.id,
                name: i.name,
                amount: i.amount,
                frequency: i.frequency,
                endDate: i.endDate,
              };
            }
          ),

      primaryEmployerPensionContributions:
        ScenarioUtils.getEmployerPensionContributions(scenario, true).map(
          (i: PensionContributionIncome) => {
            return {
              id: i.id,
              name: i.name,
              earnedIncomeId: i.earnedIncomeId,
              earnedIncomePercentSaved: i.earnedIncomePercentSaved,
              portfolioId: i.portfolioId,
            };
          }
        ),
      partnerEmployerPensionContributions: !partner
        ? []
        : ScenarioUtils.getEmployerPensionContributions(scenario, false).map(
            (i: PensionContributionIncome) => {
              return {
                id: i.id,
                name: i.name,
                earnedIncomeId: i.earnedIncomeId,
                earnedIncomePercentSaved: i.earnedIncomePercentSaved,
                portfolioId: i.portfolioId,
              };
            }
          ),

      primaryEmployeePensionContributions:
        ScenarioUtils.getEmployeePensionContributions(scenario, true).map(
          (i: PensionExpense) => {
            return {
              id: i.id,
              name: i.name,
              earnedIncomeId: i.earnedIncomeId,
              earnedIncomePercentSaved: i.earnedIncomePercentSaved,
              portfolioId: i.portfolioId,
            };
          }
        ),
      partnerEmployeePensionContributions: !partner
        ? []
        : ScenarioUtils.getEmployeePensionContributions(scenario, false).map(
            (i: PensionExpense) => {
              return {
                id: i.id,
                name: i.name,
                earnedIncomeId: i.earnedIncomeId,
                earnedIncomePercentSaved: i.earnedIncomePercentSaved,
                portfolioId: i.portfolioId,
              };
            }
          ),

      livingExpenses: ScenarioUtils.getLivingExpenses(scenario).map(
        (e: LivingExpense) => {
          return {
            id: e.id,
            name: e.name,
            amount: e.amount,
            frequency: e.frequency,
            endDate: e.endDate,
          };
        }
      ),
      rentExpenses: ScenarioUtils.getRentExpenses(scenario).map(
        (e: RentExpense) => {
          return {
            id: e.id,
            name: e.name,
            amount: e.amount,
            frequency: e.frequency,
            endDate: e.endDate,
          };
        }
      ),

      residentialProperties: ScenarioUtils.getResidentialPropertyAssets(
        scenario
      ).map((a: ResidentialProperty) => {
        return { id: a.id, name: a.name, value: a.value, primary: a.primary };
      }),
      investmentProperties: ScenarioUtils.getInvestmentPropertyAssets(
        scenario
      ).map((a: InvestmentProperty) => {
        return { id: a.id, name: a.name, value: a.value };
      }),

      mortgages: ScenarioUtils.getMortgageLiabilities(scenario).map(
        (l: MortgageLiability) => {
          return {
            id: l.id,
            name: l.name,
            propertyAssetId: l.propertyAssetId,
            amount: l.amount,
            rate: l.annualAverageInterestRate,
            endDate: l.endDate,
            mortgageType: l.mortgageType,
            repaymentType: l.repaymentType,
          };
        }
      ),
      unsecuredDebts: ScenarioUtils.getUnsecuredDebtLiabilities(scenario).map(
        (l: UnsecuredLiability) => {
          return {
            id: l.id,
            name: l.name,
            amount: l.amount,
            rate: l.annualAverageInterestRate,
            endDate: l.endDate,
            repaymentType: l.repaymentType,
          };
        }
      ),

      primaryLifeInsurances: ScenarioUtils.getLifeInsurances(
        scenario,
        true
      ).map((i: Insurance) => {
        return {
          id: i.id,
          name: i.name,
          payout: i.payout,
          endDate: i.endDate,
          joint: i.joint,
        };
      }),
      partnerLifeInsurances: !partner
        ? []
        : ScenarioUtils.getLifeInsurances(scenario, false).map(
            (i: Insurance) => {
              return {
                id: i.id,
                name: i.name,
                payout: i.payout,
                endDate: i.endDate,
                joint: i.joint,
              };
            }
          ),

      primaryCriticalIllnessInsurances:
        ScenarioUtils.getCriticalIllnessInsurances(scenario, true).map(
          (i: Insurance) => {
            return {
              id: i.id,
              name: i.name,
              payout: i.payout,
              endDate: i.endDate,
              joint: i.joint,
            };
          }
        ),
      partnerCriticalIllnessInsurances: !partner
        ? []
        : ScenarioUtils.getCriticalIllnessInsurances(scenario, false).map(
            (i: Insurance) => {
              return {
                id: i.id,
                name: i.name,
                payout: i.payout,
                endDate: i.endDate,
                joint: i.joint,
              };
            }
          ),

      primaryDisabilityInsurances: ScenarioUtils.getDisabilityInsurances(
        scenario,
        true
      ).map((i: Insurance) => {
        return {
          id: i.id,
          name: i.name,
          payout: i.payout,
          endDate: i.endDate,
          joint: i.joint,
        };
      }),
      partnerDisabilityInsurances: !partner
        ? []
        : ScenarioUtils.getDisabilityInsurances(scenario, false).map(
            (i: Insurance) => {
              return {
                id: i.id,
                name: i.name,
                payout: i.payout,
                endDate: i.endDate,
                joint: i.joint,
              };
            }
          ),

      primaryMortgageLifeInsurances: ScenarioUtils.getMortgageLifeInsurances(
        scenario,
        true
      ).map((i: MortgageInsurance) => {
        return {
          id: i.id,
          name: i.name,
          mortgageId: i.mortgageId,
          payout: i.payout,
          endDate: i.endDate,
          joint: i.joint,
        };
      }),
      partnerMortgageLifeInsurances: !partner
        ? []
        : ScenarioUtils.getMortgageLifeInsurances(scenario, false).map(
            (i: MortgageInsurance) => {
              return {
                id: i.id,
                name: i.name,
                mortgageId: i.mortgageId,
                payout: i.payout,
                endDate: i.endDate,
                joint: i.joint,
              };
            }
          ),

      goals: ScenarioUtils.getAllGoals(scenario).map((g: GoalDto) => {
        return {
          id: g.id,
          name: g.name,
          type: g.type,
          amount: g.minimumAmount,
          date: g.startDate,
        };
      }),
    };
  };
}
