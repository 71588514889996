import * as moment from 'moment/moment';

export class DateUtils {

  public static yearsAfterGoal(goal, years): string {
    return moment(goal.startDate).add(years, 'year').format();
  }

  public static maxYear(): string {
    return moment().utc().year(2200).startOf('year').format();
  }

  public static thisYear(): string {
    return moment().utc().startOf('year').format();
  }

  public static inYears(years: number): string {
    return moment().utc().startOf('year').add('years', years).format();
  }

  public static atYear(year: number): string {
    return moment().utc().startOf('year').year(year).format();
  }

}
