import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class WizardService {

    private visitedPaths: string[] = [];
    private notificationMap: { [path: string]: boolean } = {};

    private notificationSubject: BehaviorSubject<{ [path: string]: boolean }> = new BehaviorSubject({});
    private visitedSubject: BehaviorSubject<string[]> = new BehaviorSubject([]);

    constructor() { }


    public showNotificationForPath(path: string) {
        this.sendNotification(path, true);
    }

    public hideNotificationForPath(path: string) {
        this.sendNotification(path, false);
    }

    public clearNotifications() {
        this.notificationMap = {};
        this.notificationSubject.next(this.notificationMap);
    }

    private sendNotification(path: string, open: boolean) {
        this.notificationMap[path] = open;
        this.notificationSubject.next(this.notificationMap);
    }

    public listenToNotification(): Observable<{ [path: string]: boolean }> {
        return this.notificationSubject.asObservable();
    }

    public setVisited(path: string) {
        if (!this.visitedPaths.includes(path)) {
            this.visitedPaths.push(path);
            this.visitedSubject.next(this.visitedPaths);
        }
    }

    public listenToVisited(): Observable<string[]> {
        return this.visitedSubject;
    }


}
