export enum BaseGrowthRate {
  CALCULATED = 'CALCULATED',
  NONE = 'NONE',
  CPI = 'CPI',
}

export enum DateRangeType {
  USER_DEFINED = 'USER_DEFINED',
  ON_RETIREMENT = 'ON_RETIREMENT',
  ON_DEATH = 'ON_DEATH',
}

export enum Frequency {
  UNSPECIFIED = 'UNSPECIFIED',
  ONE_OFF = 'ONE_OFF',
  MONTHLY = 'MONTHLY',
  ANNUALLY = 'ANNUALLY',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  QUARTERLY = 'QUARTERLY',
  SEMI_ANNUALLY = 'SEMI_ANNUALLY',
}

export enum DebtType {
  CREDIT_CARD = 'CREDIT_CARD',
  CAR_LOAN = 'CAR_LOAN',
  OTHER = 'OTHER',
}

export enum Currency {
  USD = 'USD',
  GBP = 'GBP',
  EUR = 'EUR',
}

export enum RepaymentType {
  PRINCIPAL_AMORTIZATION = 'PRINCIPAL_AMORTIZATION',
  INTEREST_ONLY_WITH_BALLOON_PAYMENT = 'INTEREST_ONLY_WITH_BALLOON_PAYMENT',
}

export enum MortgageType {
  FIXED_RATE = 'FIXED_RATE',
  ADJUSTABLE_RATE = 'ADJUSTABLE_RATE',
}

export enum Owner {
  PRIMARY = 'PRIMARY',
  PARTNER = 'PARTNER',
  HOUSEHOLD = 'HOUSEHOLD',
}

export enum UnearnedIncomeType {
  ALIMONY = 'ALIMONY',
  DEFINED_BENEFIT_PENSION = 'DEFINED_BENEFIT_PENSION',
  FIXED_ANNUITIES = 'FIXED_ANNUITIES',
  NON_SALARIED_INCOME = 'NON_SALARIED_INCOME',
  CHILD_SUPPORT = 'CHILD_SUPPORT',
  INHERITANCE = 'INHERITANCE',
  WINDFALL = 'WINDFALL',
  OTHER = 'OTHER',
}

export enum DiagnosticType {
  CHOICE_LIFESTYLE_PRIORITY = 'CHOICE_LIFESTYLE_PRIORITY',
  CHOICE_SPEND_ON_PREVIOUS_GOALS = 'CHOICE_SPEND_ON_PREVIOUS_GOALS',
  CHOICE_TOO_ILLIQUID_BALANCE_SHEET = 'CHOICE_TOO_ILLIQUID_BALANCE_SHEET',
  CHOICE_UNSUPPORTABLE_DEBT = 'CHOICE_UNSUPPORTABLE_DEBT',
  CHOICE_UNSUPPORTABLE_MORTGAGE = 'CHOICE_UNSUPPORTABLE_MORTGAGE',
  CHOICE_TOO_EXPENSIVE_GOAL = 'CHOICE_TOO_EXPENSIVE_GOAL',
  CHOICE_INSUFFICIENT_PENSION_SAVINGS = 'CHOICE_INSUFFICIENT_PENSION_SAVINGS',
  CHOICE_INSUFFICIENT_TAX_ADVANTAGED_SAVINGS = 'CHOICE_INSUFFICIENT_TAX_ADVANTAGED_SAVINGS',
  CHOICE_EMPLOYEE_PENSION_LOW_RISK = 'CHOICE_EMPLOYEE_PENSION_LOW_RISK',
  CHOICE_EMPLOYEE_PENSION_HIGH_RISK = 'CHOICE_EMPLOYEE_PENSION_HIGH_RISK',
  CHOICE_INSUFFICIENT_CRITICAL_ILLNESS_PROTECTION = 'CHOICE_INSUFFICIENT_CRITICAL_ILLNESS_PROTECTION',
  CHOICE_INSUFFICIENT_DISABILITY_PROTECTION = 'CHOICE_INSUFFICIENT_DISABILITY_PROTECTION',

  EVENT_ASSET_ALLOCATION = 'EVENT_ASSET_ALLOCATION',
  EVENT_BAD_MARKETS = 'EVENT_BAD_MARKETS',
  EVENT_EARLY_DEATH = 'EVENT_EARLY_DEATH',
  EVENT_SHORT_TERM_VOLATILITY = 'EVENT_SHORT_TERM_VOLATILITY',

  FACT_GOAL_TOO_IMPROBABLE = 'FACT_GOAL_TOO_IMPROBABLE',

  NONE = 'NONE',
}
