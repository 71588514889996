import { Injectable } from '@angular/core';
import { HandleError, HttpErrorHandler } from '../http-error-handler.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PageableRequest, Portfolio } from '../../model';
import { AppConfigService } from '../app.config.service';
import { QueryOnlyServiceForEntity } from './iress.service';


@Injectable()
export class IressPortfolioService implements QueryOnlyServiceForEntity<Portfolio> {

  private handleError: HandleError;
  private getUrl = (entityId: string, id: string = ''): string =>
    `${this.configService.getConfig().iress.apiUrl}/resourceful/entity/client/${entityId}/portfolio/${id}`;

  constructor(
    private http: HttpClient,
    private configService: AppConfigService,
    private httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('IressPortfolioService');
  }

  query = (entityId: string, pageable: PageableRequest): Observable<Portfolio[]> =>
    this.http.get<Portfolio[]>(this.getUrl(entityId), { params: pageable, headers: { 'X-Iress-Api': '1' }, withCredentials: true })
      .pipe(
        catchError(this.handleError('query client portfolios')));

  get = (entityId: string, id: string): Observable<Portfolio> =>
    this.http.get<Portfolio>(this.getUrl(entityId, id), { headers: { 'X-Iress-Api': '1' } })
      .pipe(
        catchError(this.handleError('get client portfolio')));

}
