// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
}

mat-card-header mat-card-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 2em;
}

.account-name {
  font-weight: 600;
}

.account-meta {
  font-size: 0.8em;
  padding-top: 10px;
}

.account {
  padding: 15px;
}
.account:first-child {
  padding-left: 0;
}
.account:last-child {
  padding-right: 0;
}

@media screen and (max-width: 599px) {
  div.account {
    padding: 5px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    border-right: 0;
  }
  div.account:last-child {
    border-bottom: 0;
  }
}
.account-container {
  overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/components/common/moneyhub/connected-bank/connected-bank.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,gBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,gBAAA;EACA,iBAAA;AACF;;AAEA;EACE,aAAA;AACF;AAAE;EACE,eAAA;AAEJ;AAAE;EACE,gBAAA;AAEJ;;AAEA;EACE;IACE,cAAA;IACA,4CAAA;IACA,eAAA;EACF;EAAE;IACE,gBAAA;EAEJ;AACF;AAEA;EACE,gBAAA;AAAF","sourcesContent":[":host {\n  display: flex;\n}\n\nmat-card-header mat-card-title {\n  font-size: 18px;\n  font-weight: 600;\n  line-height: 2em;\n}\n\n.account-name {\n  font-weight: 600;\n}\n\n.account-meta {\n  font-size: 0.8em;\n  padding-top: 10px;\n}\n\n.account {\n  padding: 15px;\n  &:first-child {\n    padding-left: 0;\n  }\n  &:last-child {\n    padding-right: 0;\n  }\n}\n\n@media screen and (max-width: 599px) {\n  div.account {\n    padding: 5px 0;\n    border-bottom: 1px solid rgba(0, 0, 0, 0.12);\n    border-right: 0;\n    &:last-child {\n      border-bottom: 0;\n    }\n  }\n}\n\n.account-container {\n  overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
