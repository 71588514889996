import { Component, OnInit } from '@angular/core';
import { OxfordRiskService } from 'src/app/services';
import { flatMap, map, takeLast, catchError, finalize, tap } from 'rxjs/operators';
import { Observable, of, concat, forkJoin } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from 'src/app/app.constants';
import { MatDialog } from '@angular/material/dialog';
import { RiskDialogComponent } from './risk-dialog/risk.dialog.component';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-assessment',
  templateUrl: './assessment.component.html',
  styleUrls: ['./assessment.component.scss']
})
export class AssessmentComponent implements OnInit {

  private firstVisit: boolean;
  public resultsReady: boolean;
  public navigation: any;
  public loading = false;
  private instrument;
  private client;
  public report;
  private instrumentResponse;
  private assessment;
  private questionGroups;
  public questions$: Observable<any>;
  public questionResponses = [];
  public currentStep = 0;

  constructor(
    private notifier: NotifierService,
    private router: Router,
    private dialog: MatDialog,
    private oxfordRiskService: OxfordRiskService,
    private route: ActivatedRoute
  ) {
    this.questions$ = this.oxfordRiskService.queryLiveInstruments()
      .pipe(
        flatMap(r => {
          this.instrument = r.data[0];
          return this.oxfordRiskService.queryAssessmentsForInstrument(this.instrument.id);
        }),
        flatMap(a => {
          this.assessment = a.data[0];
          return this.oxfordRiskService.queryQuestionGroupsForAssessment(this.assessment.id);
        }),
        map(r => {
          this.questionResponses = r.data[0].questions.data.map(_ => null);
          this.questionGroups = r.data[0];
          return r.data;
        }));
  }

  ngOnInit() {
    this.firstVisit = true;
    this.resultsReady = false;
    this.navigation = (this.route.data as any).value.navigation;
  }

  generateRandomString() {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijkjlmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 32; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  }

  calculateRisk$() {
    return of({})
      .pipe(
        tap(() => this.loading = true),
        flatMap(() => this.oxfordRiskService.createClient({
          status: 'Active',
          country_of_residence: '',
          client_infos: {},
          adviser_id: null,
          date_of_birth: null,
          phase: 'Unassigned',
          client_id: this.generateRandomString()
        })),
        flatMap(r => {
          this.client = r;
          return this.oxfordRiskService.createInstrumentResponse({
            instrument_id: this.instrument.id,
            user_id: r.client_id,
            status: 'Incomplete',
            adviser_id: null,
            question_responses: [],
            assessments: [this.assessment.id],
            group_id: null
          });
        }),
        flatMap(r => {
          this.instrumentResponse = r;
          return forkJoin(this.questionResponses.map((q, idx) => ({
            instrument_response_id: this.instrumentResponse.id,
            question_ref_id: this.questionGroups.questions.data[idx].ref_id,
            value: q
          })).map(q => this.oxfordRiskService.createQuestionResponse(q)))
            .pipe(takeLast(1));
        }),
        flatMap(r => {
          return this.oxfordRiskService.updateInstrumentResponse({ ...this.instrumentResponse, status: 'Completed' })
            .pipe(catchError(() => of({})));
        }),
        flatMap(r => this.oxfordRiskService.getReportForInstrumentResponse(this.instrumentResponse.id)),
        catchError(() => {
          this.notifier.notify(Constants.ERROR, 'Failed to complete survey');
          return of({});
        }),
        finalize(() => this.loading = false)
      );
  }

  onNextPress() {
    if (this.report) {
      this.router.navigate(['..', 'graph'], { relativeTo: this.route });
    } else {
      this.submitResponse();
    }
  }

  submitResponse() {
    if (this.currentStep < this.totalSteps) {
      return;
    }

    const sub = {
      next: r => {
        this.loading = false;
        this.firstVisit = false;
        const toleranceKey = Object.keys(r).filter(k => Object.keys(r[k]).indexOf('risk_tolerance') >= 0)[0];
        const riskKey = Object.keys(r).filter(k => Object.keys(r[k]).indexOf('risk_profile') >= 0)[0];
        if (riskKey && toleranceKey) {
          // localStorage.setItem(Constants.LOCAL_STORAGE_RISK_TOLERANCE_CONTENT, r[toleranceKey]['risk_tolerance'].content);
          localStorage.setItem(Constants.LOCAL_STORAGE_RISK_PROFILE_CONTENT, r[riskKey]['risk_profile'].content);
          localStorage.setItem(Constants.LOCAL_STORAGE_RISK_PROFILE_SCORE, r[riskKey]['risk_profile'].score);
          localStorage.setItem(Constants.LOCAL_STORAGE_RISK_PROFILE_BOUNDARY_TEXT, r[riskKey]['risk_profile'].category_boundary_text);
          this.resultsReady = true;
        }
        this.report = Object.keys(r)['flatMap'](k => Object.keys(r[k]).map(innerKey => r[k][innerKey].content));
      },
      error: () => { this.loading = false; this.notifier.notify(Constants.ERROR, 'Failed to submit responses'); }
    };

    // if (this.firstVisit) {
    //   this.dialog.open(GraphTutorialComponent,
    //     { disableClose: true, panelClass: 'modal-tutorial' }).afterClosed().pipe(takeLast(1))
    //     .pipe(flatMap(() => this.calculateRisk$()))
    //     .subscribe(sub)
    // } else {
    this.calculateRisk$().subscribe(sub);
    // }
    return;
  }

  str(v) { return JSON.stringify(v); }

  get totalSteps() {
    return (this.questionGroups &&
      this.questionGroups.questions &&
      this.questionGroups.questions.data)
      ? this.questionGroups.questions.data.length
      : 0;
  }

  get calculatedTolerance() {
    const t = localStorage.getItem(Constants.LOCAL_STORAGE_RISK_PROFILE_BOUNDARY_TEXT);
    if (!t) {
      return null;
    }
    return t.toLowerCase();
  }

  redo() {
    this.resultsReady = false;
    this.currentStep = 0;
    this.report = null;
  }

}
