import { Component, Input, Output, EventEmitter, signal } from '@angular/core';
import { Constants } from 'src/app/app.constants';
import { IMoneyhubTransactionData } from 'src/app/model';
import { Income } from 'src/app/model/household/income/income';

interface IViewIncome extends Income {
  isFromLinkedAccounts: boolean;
}

@Component({
  selector: 'app-income',
  templateUrl: './income.component.html',
  styleUrls: ['./income.component.scss'],
})
export class IncomeComponent {
  @Input() income: IViewIncome;
  @Input() linkedIncomes: IMoneyhubTransactionData[];
  @Input() currency: string;

  @Output() update = new EventEmitter<Income>();
  @Output() remove = new EventEmitter<Income>();
  @Output() updateLinked = new EventEmitter<Income>();
  @Output() removeLinked = new EventEmitter<Income>();

  isEdit = signal(false);

  private _isDirty = false;

  onUpdate() {
    this._isDirty = true;
  }

  onRemove() {
    this.remove.emit(this.income);
  }

  onDismiss() {
    this._isDirty && this.update.emit(this.income);
    this.isEdit.set(false);
  }

  onUpdateLinked() {
    this.updateLinked.emit(this.income);
  }

  onRemoveLinked() {
    this.removeLinked.emit(this.income);
  }

  getFrequencyLabel(frequency) {
    return Constants.FREQUENCY_LABELS.find((f) => f.id === frequency)?.label;
  }

  getEndsOnLabel(option: string) {
    return Constants.ENDS_ON_LABELS.find((o) => o.id === option)?.label;
  }

  get frequencies() {
    return Constants.SELECTABLE_FREQUENCIES;
  }

  get currentYear() {
    return new Date().getFullYear();
  }

  get endsOnOptions() {
    return Constants.SELECTABLE_END_DATES;
  }
}
