export interface LoginResponse {
  token: {
    accessToken: string;
    refreshToken: string;
    expiresIn: number;
  };
}

export interface SocialLoginErrorResponse {
  error: string;
}
