import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import {
  OnboardingContainerComponent, FamilyComponent, BalanceComponent, ConnectionsComponent, AssessmentComponent,
  IncomeComponent, ProtectionComponent, FutureComponent, ConnectComponent, MoneyhubComponent, IRESSComponent
} from './components/onboarding';
import {
  LandingComponent, WelcomeComponent
} from './components/landing';
import { ConnectedComponent } from './components/common';
import { OverviewContainerComponent } from './components/overview';
import { GraphComponent } from './components/overview/components/graph/graph.component';
import { AuthGuard } from './guards/auth.guard';
import { MoneyhubAuthGuard } from './guards/moneyhub.auth.guard';
import { AppComponent } from './app.component';

export const routes: Routes = [
  {
    path: '',
    component: AppComponent,
    pathMatch: 'full'
  },
  {
    path: 'connected',
    component: ConnectedComponent,
    canActivate: [MoneyhubAuthGuard],
    pathMatch: 'full'
  },
  {
    path: 'landing',
    component: LandingComponent,
    children: [
      {
        path: 'welcome',
        component: WelcomeComponent,
        canActivate: [AuthGuard],
      }
    ]
  },
  // {
  //   path: 'auth',
  //   component: AuthComponent,
  //   children: [
  //     {
  //       path: 'login',
  //       component: LoginComponent,
  //     }, {
  //       path: 'register',
  //       component: RegistrationComponent
  //     }, {
  //       path: 'forgotPassword',
  //       component: ForgotPasswordComponent
  //     }, {
  //       path: 'guest',
  //       component: GuestComponent
  //     }, {
  //       path: 'social',
  //       component: SocialComponent
  //     }
  //   ]
  // },
  {
    path: 'scenario/:scenarioId/onboarding',
    component: OnboardingContainerComponent,
    children: [
      {
        path: 'family', component: FamilyComponent, pathMatch: 'full', data: {
          navigation: {
            right: { link: '../connect', linkAccountsOn: '../connect', linkAccountsOff: '../income', text: 'Next' }
          }
        }
      },
      {
        path: 'connect', component: ConnectComponent, pathMatch: 'full', data: {
          navigation: {
            left: { link: '../family', text: 'Back' },
            right: { link: '../income', text: 'Next' }
          }
        }
      },
      {
        path: 'accounts', component: ConnectionsComponent, pathMatch: 'full', data: {
          navigation: {
            left: { link: '../connect', text: 'Back' },
            right: { link: '../income', text: 'Next' }
          }
        }
      },
      {
        path: 'moneyhub', component: MoneyhubComponent, pathMatch: 'full', data: {
          navigation: {
            left: { link: '../connect', text: 'Back' },
            right: { link: '../income', text: 'Next' }
          }
        }
      },
      {
        path: 'iress', component: IRESSComponent, pathMatch: 'full', data: {
          navigation: {
            left: { link: '../connect', text: 'Back' },
            right: { link: '../income', text: 'Next' }
          }
        }
      },
      {
        path: 'income', component: IncomeComponent, pathMatch: 'full', data: {
          navigation: {
            left: { linkAccountsOn: '../connect', linkAccountsOff: '../family', text: 'Back' },
            right: { link: '../balance', text: 'Next' }
          }
        }
      }, {
        path: 'balance', component: BalanceComponent, pathMatch: 'full', data: {
          navigation: {
            left: { link: '../income', text: 'Back' },
            right: { link: '../protection', text: 'Next' }
          }
        }
      }, {
        path: 'protection', component: ProtectionComponent, pathMatch: 'full', data: {
          navigation: {
            left: { link: '../balance', text: 'Back' },
            right: { linkRiskOn: '../risk', linkRiskOff: '../future', text: 'Next' }
          }
        }
      }, {
        path: 'risk', component: AssessmentComponent, pathMatch: 'full', data: {
          navigation: {
            left: { link: '../protection', text: 'Back' },
            right: { link: '../future', text: 'Next' }
          }
        }
      }, {
        path: 'future', component: FutureComponent, pathMatch: 'full', data: {
          navigation: {
            left: { linkRiskOff: '../protection', linkRiskOn: '../risk', text: 'Back' },
            right: { link: '../../overview/graph', text: 'Finish' }
          }
        }
      }
    ]
  }, {
    path: 'scenario/:scenarioId/overview',
    component: OverviewContainerComponent,
    children: [
      {
        path: 'graph', component: GraphComponent, pathMatch: 'full',
      }, {
        path: 'family', component: FamilyComponent, pathMatch: 'full', data: {
          navigation: {
            left: { link: '../../overview/graph', text: 'Back' }
          }
        }
      }, {
        path: 'income', component: IncomeComponent, pathMatch: 'full', data: {
          navigation: {
            left: { linkAccountsOn: '../../overview/graph', linkAccountsOff: '../../overview/graph', text: 'Back' }
          }
        }
      }, {
        path: 'balance', component: BalanceComponent, pathMatch: 'full', data: {
          navigation: {
            left: { link: '../../overview/graph', text: 'Back' }
          }
        }
      }, {
        path: 'protection', component: ProtectionComponent, pathMatch: 'full', data: {
          navigation: {
            left: { link: '../../overview/graph', text: 'Back' }
          }
        }
      }, {
        path: 'future', component: FutureComponent, pathMatch: 'full', data: {
          navigation: {
            left: { linkRiskOff: '../../overview/graph', linkRiskOn: '../../overview/graph', text: 'Back' }
          }
        }
      },
      {
        path: 'accounts', component: ConnectionsComponent, pathMatch: 'full', data: {
          navigation: {
            left: { link: '../../overview/graph', text: 'Back' }
          }
        }
      },
      {
        path: 'connect', component: ConnectComponent, pathMatch: 'full', data: {
          navigation: {
            left: { link: '../../overview/accounts', text: 'Back' }
          }
        }
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    paramsInheritanceStrategy: 'always',
    initialNavigation: 'disabled'
  })],
  exports: [RouterModule],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ]
})
export class AppRoutingModule { }
