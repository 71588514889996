import { OnInit, Component, Input, Output, EventEmitter } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';

@Component({
    selector: 'app-year-picker',
    templateUrl: './year-picker.component.html',
    styleUrls: ['./year-picker.component.scss']
})
export class YearPickerComponent implements OnInit {

    @Input() value: string;
    @Input() max: number;
    @Input() min: number;
    @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
    @Input() disabled: boolean;

    private _yearList: number[];
    private _selected: number;

    constructor() {
        this._yearList = [];
    }

    onChange(ev: MatSelectChange) {
        const date = new Date();
        date.setFullYear(ev.value);
        this.valueChange.next(date.toISOString());
    }

    ngOnInit() {
        const fromYear = this.min || new Date().getFullYear() - 20;
        const toYear = this.max || fromYear + 100;
        this._selected = this.value ? new Date(Date.parse(this.value)).getFullYear() : new Date().getFullYear();
        for (let i = fromYear; i <= toYear; i++) {
            this._yearList.push(i);
        }
    }

    get yearList() {
        return this._yearList;
    }

    get selected() {
        return this._selected;
    }

    set selected(val: number) {
        this._selected = val;
    }
}