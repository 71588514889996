// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-mdc-form-field {
  padding: 0 10px 10px;
}

.funding-sources {
  min-height: 100px;
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
}
.funding-sources .mat-mdc-icon-button {
  height: 25px;
  width: 25px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/common/edit-goals/edit-goal-base/edit-goal-base.component.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;AACF;;AAEA;EACE,iBAAA;EACA,iBAAA;EACA,gBAAA;EACA,kBAAA;AACF;AACE;EACE,YAAA;EACA,WAAA;AACJ","sourcesContent":[".mat-mdc-form-field {\n  padding: 0 10px 10px;\n}\n\n.funding-sources {\n  min-height: 100px;\n  max-height: 250px;\n  overflow-y: auto;\n  overflow-x: hidden;\n\n  .mat-mdc-icon-button {\n    height: 25px;\n    width: 25px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
