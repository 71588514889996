import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GoalAchievability } from 'src/app/model/household/result/goal.achievability';
import { GoalDiagnostic } from 'src/app/model/household/result/goal.diagnostic';
import { GoalSnapshot } from 'src/app/model/household/result/goal.snapshot';
import { DiagnosticsUtils } from 'src/app/utils/diagnostics.utils';
import { GoalUtils } from 'src/app/utils/goal.utils';
import { DiagnosticType } from 'src/app/model/enums';
import { Constants } from 'src/app/app.constants';

export interface DialogData {
    goal: GoalSnapshot,
    scenario: any,
    achievability: GoalAchievability;
    diagnostics: GoalDiagnostic[];
}

@Component({
    selector: 'diagnostic-dialog-component',
    templateUrl: './diagnostic.dialog.component.html',
    styleUrls: ['./diagnostic.dialog.component.scss']
})
export class DiagnosticDialogComponent {

    public isRiskDiagnostic(d) {
        if ([
            'WRAPPER_GIA_RISK_INCREASE_PRIMARY',
            'WRAPPER_GIA_RISK_INCREASE_PARTNER',
            'WRAPPER_TAX_ADVANTAGED_RISK_INCREASE_PRIMARY',
            'WRAPPER_TAX_ADVANTAGED_RISK_INCREASE_PARTNER',
            'WRAPPER_PENSION_RISK_INCREASE_PRIMARY',
            'WRAPPER_PENSION_RISK_INCREASE_PARTNER'
        ].indexOf(d.diagnostic.diagnosticType) < 0) {
            return false;
        }
        const presetIndex = d.diagnostic.metaData['PRESETS_INDEX_PER_PORTFOLIO'];
        const selectedRiskLevel = localStorage.getItem(Constants.LOCAL_STORAGE_RISK_PROFILE_SCORE);
        if (!presetIndex || !selectedRiskLevel) {
            return false;
        }

        if (presetIndex > selectedRiskLevel) {
            return true;
        }
        return false;
    }

    private _diagnostics: Array<any>;

    constructor(
        public dialogRef: MatDialogRef<DiagnosticDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) {

        const diagnostics = data.diagnostics
            .filter(d => GoalUtils.getSafeAchievability(d) - GoalUtils.getSafeAchievability(data.achievability)
                > Constants.MINIMUM_ACHIEVABILITY_VS_DIAGNOSTICS_DIFF);

        this._diagnostics = (diagnostics.length === 0
            ? [{ diagnosticType: DiagnosticType.FACT_GOAL_TOO_IMPROBABLE, goalId: data.goal.id, metaData: {} } as GoalDiagnostic]
            : diagnostics)
            .filter(d => !DiagnosticsUtils.typeToActions(d.diagnosticType).filter
                || DiagnosticsUtils.typeToActions(d.diagnosticType).filter() === true)
            .map(d => {
                return {
                    diagnostic: d,
                    action: DiagnosticsUtils.typeToActions(d.diagnosticType),
                    achievabilityIncrease: GoalUtils.getSafeAchievability(d) - GoalUtils.getSafeAchievability(data.achievability),
                };
            })
            .sort((a, b) => b.achievabilityIncrease - a.achievabilityIncrease);
    }

    applyAction = (diagnostic: any): void => this.dialogRef.close(diagnostic);
    close = (): void => this.dialogRef.close();

    get diagnostics() {
        return this._diagnostics;
    }

}