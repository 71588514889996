import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mh-about-dialog',
  templateUrl: './about-dialog.component.html',
  styleUrls: ['./about-dialog.component.scss'],
})
export class MHAboutDialogComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
