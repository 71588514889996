import { Injectable } from '@angular/core';
import { HandleError, HttpErrorHandler } from './http-error-handler.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PageableResponse, PageableRequest } from '../model';
import { GoalAchievability } from '../model/household/result/goal.achievability';
import { AppConfigService } from './app.config.service';

@Injectable()
export class ResultsOverallGoalAchievabilityService {
    private handleError: HandleError;
    private getUrl = () => this.configService.getConfig().apiUrl;

    constructor(
        private http: HttpClient,
        private configService: AppConfigService,
        private httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('resultsOverallGoalAchievabilityService');
    }

    query(scenarioId: string, pageable: PageableRequest): Observable<PageableResponse<GoalAchievability>> {
        return this.http.get<PageableResponse<GoalAchievability>>(`${this.getUrl()}/results/${scenarioId}/achievability/goal`, { params: pageable })
            .pipe(
                catchError(this.handleError('query scenario'))
            );
    }

    get(scenarioId: string, goalId: string): Observable<PageableResponse<GoalAchievability>> {
        return this.http.get<GoalAchievability>(`${this.getUrl()}/results/${scenarioId}/achievability/goal/${goalId}`)
            .pipe(
                catchError(this.handleError('query scenario'))
            );
    }

}