import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Constants } from 'src/app/app.constants';
import { UpgradeDialogComponent } from '../common/upgrade-dialog/upgrade.dialog.component';
import { AppFeatureType } from '../../feature';
import { FeatureService } from 'src/app/feature/feature.service';
import { ScenarioService } from 'src/app/services';
import { LinkToBubeleDialogComponent } from '../common';
import { GuestUtils } from 'src/app/utils';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.container.html',
  styleUrls: ['./overview.container.scss']
})
export class OverviewContainerComponent implements OnInit {

  constructor(
    private featureService: FeatureService,
    private scenarioService: ScenarioService,
    private dialog: MatDialog) {
  }

  ngOnInit() {
  }

  get showAdvisor() {
    return this.featureService.hasFeature(AppFeatureType.Advisor);
  }

  get registered() {
    return GuestUtils.registered();
  }

  get featureAdvisor() {
    return AppFeatureType.Advisor;
  }

  get featureLinkToBubelePopup() {
    return AppFeatureType.LinkToBubelePopup;
  }

  goToBubele = () => {
    this.scenarioService.query(Constants.PAGE_ALL)
      .subscribe(r =>
        this.dialog
          .open(LinkToBubeleDialogComponent, {
            width: '520px',
            height: '340px',
            data: { scenario: r.content[0] },
          })
      );
  };

  showRegistrationPopup = () => this.dialog
    .open(UpgradeDialogComponent, {
      width: '450px',
      height: '250px'
    });

}
