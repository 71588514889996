import { Injectable } from '@angular/core';
import { HandleError, HttpErrorHandler } from '../http-error-handler.service';
import { HttpClient } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { LoginRequest } from '../../model';
import { AppConfigService } from '../app.config.service';
import { Observable } from 'rxjs';


@Injectable()
export class IressSessionService {

  private handleError: HandleError;
  private getUrl = (): string =>
    `${this.configService.getConfig().iress.apiUrl}/resourceful/`;

  constructor(
    private http: HttpClient,
    private configService: AppConfigService,
    private httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('IressLoginService');
  }

  login = (): Observable<any> =>
    this.http.get(this.getUrl(), { headers: { 'X-Iress-Api': '1', 'X-Iress-Api-Authenticate': '1' }, responseType: 'text', observe: 'response', withCredentials: true })
      .pipe(
        catchError(this.handleError('login')));

  getUser = (): Observable<any> =>
    this.http.get(`${this.getUrl()}session/user/`, { headers: { 'X-Iress-Api': '1', 'No-Auth-Challenge': 'true' }, withCredentials: true })
      .pipe(
        catchError(this.handleError('get user')));

}