import { Injectable } from '@angular/core';
import { HandleError, HttpErrorHandler } from './http-error-handler.service';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './app.config.service';
import { Observable, catchError } from 'rxjs';
import { Actions } from '../model';

@Injectable()
export class SolverService {
  private handleError: HandleError;
  private getUrl = () => this.configService.getConfig().apiUrl;

  constructor(
    private http: HttpClient,
    private configService: AppConfigService,
    private httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('solverService');
  }

  get(scenarioId: string): Observable<Actions> {
    return this.http
      .get<Actions>(`${this.getUrl()}/scenarios/${scenarioId}/optimize`)
      .pipe(catchError(this.handleError('get optimized scenario')));
  }
}
