// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.options-wrapper {
  height: calc(100% - 70px);
}

.weather mat-icon {
  font-size: 1.52em;
  line-height: 1em;
}

@media screen and (min-width: 600px) and (max-width: 959px) {
  .weather {
    margin-top: 20px;
  }
}
.hor-sep {
  width: 100%;
  height: 1px;
  border-top: 1px rgba(0, 0, 0, 0.1) solid;
  margin: 5px 0;
}`, "",{"version":3,"sources":["webpack://./src/app/components/overview/components/options/options.component.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;AACF;;AAEA;EACE,iBAAA;EACA,gBAAA;AACF;;AAEA;EACE;IACE,gBAAA;EACF;AACF;AAEA;EACE,WAAA;EACA,WAAA;EACA,wCAAA;EACA,aAAA;AAAF","sourcesContent":[".options-wrapper {\n  height: calc(100% - 70px);\n}\n\n.weather mat-icon {\n  font-size: 1.52em;\n  line-height: 1em;\n}\n\n@media screen and (min-width: 600px) and (max-width: 959px) {\n  .weather {\n    margin-top: 20px;\n  }\n}\n\n.hor-sep {\n  width: 100%;\n  height: 1px;\n  border-top: 1px rgba(0, 0, 0, 0.10) solid;\n  margin: 5px 0;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
