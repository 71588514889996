import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HandleError, HttpErrorHandler } from './http-error-handler.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PageableResponse, Household, PageableRequest } from '../model';
import { AppConfigService } from './app.config.service';

@Injectable()
export class HouseholdService {
  private getUrl = () => this.configService.getConfig().apiUrl + '/households/';
  private handleError: HandleError;

  constructor(
    private http: HttpClient,
    private configService: AppConfigService,
    private httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('householdService');
  }

  query(pageable: PageableRequest): Observable<PageableResponse<Household>> {
    return this.http
      .get<PageableResponse<Household>>(this.getUrl(), { params: pageable })
      .pipe(catchError(this.handleError('query household')));
  }

  get(id: string): Observable<Household> {
    return this.http
      .get<Household>(this.getUrl() + id)
      .pipe(catchError(this.handleError('get household')));
  }

  create(household: Household): Observable<Household> {
    return this.http
      .post<Household>(this.getUrl(), household)
      .pipe(catchError(this.handleError('create household')));
  }

  update(household: Household): Observable<Household> {
    return this.http
      .put<Household>(this.getUrl() + household.id, household)
      .pipe(catchError(this.handleError('update household')));
  }

  delete(id: string): Observable<any> {
    return this.http
      .delete<any>(this.getUrl() + id)
      .pipe(catchError(this.handleError('delete household')));
  }

  getPreference(householdId: string, preference: string) {
    return this.http
      .get<any>(this.getUrl() + householdId + '/preference/' + preference)
      .pipe(catchError(this.handleError('get household preference')));
  }

  setPreference(householdId: string, preference: string, value: any) {
    return this.http
      .put<any>(
        this.getUrl() + householdId + '/preference/' + preference,
        value
      )
      .pipe(catchError(this.handleError('update household preference')));
  }
}
