// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hide {
  display: none;
}

input {
  margin: 15px 0;
  font-size: 18px;
  max-width: 200px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/overview/components/edit/edit.dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEA;EACE,cAAA;EACA,eAAA;EACA,gBAAA;AACF","sourcesContent":[".hide {\n  display: none;\n}\n\ninput {\n  margin: 15px 0;\n  font-size: 18px;\n  max-width: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
