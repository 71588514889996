import { ScenarioUtils } from './scenario.utils';
import { Constants } from '../app.constants';
import { concatMap, map, filter, flatMap, toArray } from 'rxjs/operators';
import { from, empty, concat, iif, of, pipe } from 'rxjs';
import {
  EarnedIncomeBuilder,
  UnearnedIncomeBuilder,
} from '../model/household/income';
import {
  DateRangeType,
  Frequency,
  BaseGrowthRate,
  DiagnosticType,
  UnearnedIncomeType,
  RepaymentType,
  MortgageType,
} from '../model/enums';
import { DateUtils } from './date.utils';
import { DiagnosticContext } from '../services/diagnostics.context.factory';
import { SavingsSequence } from '../model/financial/savings.sequence';
import { GoalFactory } from '../model/goal/goal.factory.class';
import {
  FinancialWrapper,
  HealthStatus,
  Scenario,
  MortgageLiability,
  Person,
  FinancialProfile,
  FinancialPortfolio,
  FinancialClass,
} from '../model';
import { GoalType, Goal } from '../model/goal';
import { GoalDiagnostic } from '../model/household/result/goal.diagnostic';
import {
  SaveFundingSourceBuilder,
  MortgageFundingSource,
  SaveFundingSource,
  MortgageFundingSourceBuilder,
  OtherLoanFundingSourceBuilder,
  UKStudentLoanFundingSourceBuilder,
  FundingSource,
  LiquidAssetsFundingSource,
} from '../model/goal/funding.sources.class';
import { MortgageInsurance, Insurance } from '../model/protection';
import { RentExpenseBuilder } from '../model/household/expense/rent.expense';
import {
  PensionExpenseBuilder,
  PensionExpense,
} from '../model/household/expense/pension.expense';
import { GoalBuilder } from '../model/goal/goal.builder.class';
import { GoalEliminateDebtProperties } from '../model/goal/goal.properties.class';
import * as moment from 'moment/moment';

export class DiagnosticsUtils {
  private static readonly EXPENSE_LIVING_EXPENSES_DECREASE = (
    ctx: DiagnosticContext
  ) =>
    ctx.livingExpensesService.query(ctx.scenario.id, Constants.PAGE_ALL).pipe(
      concatMap((r) =>
        from(r.content).pipe(
          concatMap((c) => {
            c.amount *= DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
              ctx.goalDiagnostic,
              Constants.DIAGNOSTICS_METADATA_ITEM_EXPENSE_AMOUNT_PERCENTAGE,
              Constants.DIAGNOSTICS_EXPENSE_REDUCE_FACTOR
            );
            return ctx.livingExpensesService.update(ctx.scenario.id, c);
          })
        )
      )
    );

  private static readonly EXPENSE_RENT_VS_MORTGAGE_ON_RESIDENTIAL_PROPERTY = (
    ctx: DiagnosticContext
  ) =>
    ctx.rentExpenseService
      .create(
        ctx.scenario.id,
        new RentExpenseBuilder()
          .name(Constants.DEFAULT_RENT_EXPENSE_NAME)
          .amount(
            DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
              ctx.goalDiagnostic,
              Constants.DIAGNOSTICS_METADATA_ITEM_RENT_VS_MORTGAGE_AMOUNT,
              Constants.DEFAULT_RENT_VS_MORTGAGE_ANNUAL_RENT
            )
          )
          .currency(
            Constants.LOCALE_CONFIG[
              localStorage.getItem(Constants.LOCAL_STORAGE_LOCALE)
            ].currency
          )
          .endDate(DateUtils.maxYear())
          .endsOn(DateRangeType.USER_DEFINED)
          .frequency(Frequency.ANNUALLY)
          .growthRate(BaseGrowthRate.CALCULATED)
          .spreadOverGrowthRate(
            Constants.RENT_VS_MORTGAGE_SPREAD_OVER_GROWTH_RATE
          )
          .survivorAdjustmentPercentage(1)
          .startDate(DateUtils.thisYear())
          .startsOn(DateRangeType.USER_DEFINED)
          .build()
      )
      .pipe(
        flatMap(() =>
          iif(
            () =>
              ScenarioUtils.getAllGoals(ctx.scenario).find(
                (g) => g.class === 'RetirementGoal'
              ),
            of({})
              .pipe(
                flatMap(() =>
                  ctx.typedGoalService.get(
                    ctx.scenario.id,
                    ScenarioUtils.getAllGoals(ctx.scenario).find(
                      (g) => g.class === 'RetirementGoal'
                    ).id
                  )
                )
              )
              .pipe(
                flatMap((retirement) => {
                  retirement.properties.tradeDownHouse = true;
                  retirement.properties.tradeDownNewHousePercentage = 0;
                  retirement.properties.tradeDownDate = DateUtils.thisYear();
                  return ctx.typedGoalService.update(
                    ctx.scenario.id,
                    retirement
                  );
                })
              ),
            of({}).pipe(
              flatMap(() => {
                const primary: Person = ScenarioUtils.getPrimaryPerson(
                  ctx.scenario
                ).expectedRetirementAge;
                const retirementGoal = GoalFactory.retirementGoal(
                  DateUtils.atYear(
                    primary.yearOfBirth + primary.expectedRetirementAge
                  )
                );
                retirementGoal.properties.tradeDownHouse = true;
                retirementGoal.properties.tradeDownNewHousePercentage = 0;
                retirementGoal.properties.tradeDownDate = DateUtils.thisYear();
                return ctx.typedGoalService.create(
                  ctx.scenario.id,
                  retirementGoal
                );
              })
            )
          )
        )
      );

  private static readonly EXPENSE_MORTGAGE_ON_RESIDENTIAL_PROPERTY_VS_RENT = (
    ctx: DiagnosticContext
  ) => {
    const term = DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
      ctx.goalDiagnostic,
      Constants.DIAGNOSTICS_METADATA_ITEM_GOAL_BORROW_TERM,
      0
    );
    const amount = DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
      ctx.goalDiagnostic,
      Constants.DIAGNOSTICS_METADATA_ITEM_GOAL_BORROW_AMOUNT,
      0
    );
    const rate = DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
      ctx.goalDiagnostic,
      Constants.DIAGNOSTICS_METADATA_ITEM_GOAL_BORROW_RATE,
      0
    );
    const propertyValue = DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
      ctx.goalDiagnostic,
      Constants.DIAGNOSTICS_METADATA_ITEM_EXPENSE_NEW_PROPERTY_VALUE,
      0
    );

    const fundingSource: MortgageFundingSource =
      new MortgageFundingSourceBuilder()
        .withAmount(amount)
        .withTermYears(term)
        .withAnnualInterestRate(rate)
        .build();

    return ctx.typedGoalService.create(
      ctx.scenario.id,
      new GoalBuilder()
        .buyAHouse()
        .withName(Constants.DEFAULT_RESIDENTIAL_PROPERTY_NAME)
        .inDate(DateUtils.thisYear())
        .withAmount(propertyValue)
        .withFundingSources([new LiquidAssetsFundingSource(), fundingSource])
        .build()
    );
  };

  private static readonly INCOME_EARNED_INCOME_INCREASE_PRIMARY = (
    ctx: DiagnosticContext
  ) =>
    ctx.earnedIncomesService
      .queryForPerson(
        ctx.scenario.id,
        ScenarioUtils.getPrimaryPerson(ctx.scenario).id,
        Constants.PAGE_ALL
      )
      .pipe(
        concatMap((r) =>
          iif(
            () => r.content.length > 0,
            from(r.content).pipe(
              concatMap((c) => {
                c.amount *=
                  1 +
                  DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
                    ctx.goalDiagnostic,
                    Constants.DIAGNOSTICS_METADATA_ITEM_INCOME_EARNED_INCREASE,
                    Constants.DIAGNOSTICS_INCOME_INCREASE_FACTOR
                  );
                return ctx.earnedIncomesService.updateForPerson(
                  ctx.scenario.id,
                  ScenarioUtils.getPrimaryPerson(ctx.scenario).id,
                  c
                );
              })
            ),
            ctx.earnedIncomesService.createForPerson(
              ctx.scenario.id,
              ScenarioUtils.getPrimaryPerson(ctx.scenario).id,
              new EarnedIncomeBuilder()
                .name('My added income')
                .currency(
                  Constants.LOCALE_CONFIG[
                    localStorage.getItem(Constants.LOCAL_STORAGE_LOCALE)
                  ].currency
                )
                .amount(
                  DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
                    ctx.goalDiagnostic,
                    Constants.DIAGNOSTICS_METADATA_ITEM_INCOME_EARNED_ADD,
                    Constants.DEFAULT_EARNED_INCOME_AMOUNT
                  )
                )
                .startDate(DateUtils.thisYear())
                .endDate(DateUtils.thisYear())
                .endsOn(DateRangeType.ON_RETIREMENT)
                .frequency(Frequency.ANNUALLY)
                .growthRate(BaseGrowthRate.CALCULATED)
                .build()
            )
          )
        )
      );

  private static readonly INCOME_EARNED_INCOME_INCREASE_PARTNER = (
    ctx: DiagnosticContext
  ) =>
    ctx.earnedIncomesService
      .queryForPerson(
        ctx.scenario.id,
        ScenarioUtils.getPartner(ctx.scenario).id,
        Constants.PAGE_ALL
      )
      .pipe(
        concatMap((r) =>
          iif(
            () => r.content.length > 0,
            from(r.content).pipe(
              concatMap((c) => {
                c.amount *=
                  1 +
                  DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
                    ctx.goalDiagnostic,
                    Constants.DIAGNOSTICS_METADATA_ITEM_INCOME_EARNED_INCREASE,
                    Constants.DIAGNOSTICS_INCOME_INCREASE_FACTOR
                  );
                return ctx.earnedIncomesService.updateForPerson(
                  ctx.scenario.id,
                  ScenarioUtils.getPartner(ctx.scenario).id,
                  c
                );
              })
            ),
            ctx.earnedIncomesService.createForPerson(
              ctx.scenario.id,
              ScenarioUtils.getPartner(ctx.scenario).id,
              new EarnedIncomeBuilder()
                .name("My partner's added income")
                .currency(
                  Constants.LOCALE_CONFIG[
                    localStorage.getItem(Constants.LOCAL_STORAGE_LOCALE)
                  ].currency
                )
                .amount(
                  DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
                    ctx.goalDiagnostic,
                    Constants.DIAGNOSTICS_METADATA_ITEM_INCOME_EARNED_ADD,
                    Constants.DEFAULT_EARNED_INCOME_AMOUNT
                  )
                )
                .startDate(DateUtils.thisYear())
                .endDate(DateUtils.thisYear())
                .endsOn(DateRangeType.ON_RETIREMENT)
                .frequency(Frequency.ANNUALLY)
                .growthRate(BaseGrowthRate.CALCULATED)
                .build()
            )
          )
        )
      );

  private static readonly INCOME_WORK_PART_TIME_AFTER_RETIREMENT_PRIMARY = (
    ctx: DiagnosticContext
  ) =>
    ctx.earnedIncomesService
      .queryForPerson(
        ctx.scenario.id,
        ScenarioUtils.getPrimaryPerson(ctx.scenario).id,
        Constants.PAGE_ALL
      )
      .pipe(
        concatMap((r) =>
          from(r.content).pipe(
            concatMap((i) =>
              ctx.earnedIncomesService.createForPerson(
                ctx.scenario.id,
                ScenarioUtils.getPrimaryPerson(ctx.scenario).id,
                new EarnedIncomeBuilder()
                  .name('My post-retirement income')
                  .currency(
                    Constants.LOCALE_CONFIG[
                      localStorage.getItem(Constants.LOCAL_STORAGE_LOCALE)
                    ].currency
                  )
                  .amount(
                    i.amount *
                      DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
                        ctx.goalDiagnostic,
                        Constants.DIAGNOSTICS_METADATA_ITEM_WORK_AFTER_RETIREMENT_PERCENTAGE,
                        Constants.DIAGNOSTICS_INCOME_PART_TIME_AFTER_RETIREMENT_FACTOR
                      )
                  )
                  .startDate(
                    DateUtils.inYears(
                      ScenarioUtils.getPrimaryPerson(ctx.scenario)
                        .expectedRetirementAge +
                        ScenarioUtils.getPrimaryPerson(ctx.scenario)
                          .yearOfBirth -
                        moment.utc().year()
                    )
                  )
                  .endDate(
                    DateUtils.inYears(
                      DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
                        ctx.goalDiagnostic,
                        Constants.DIAGNOSTICS_METADATA_ITEM_WORK_AFTER_RETIREMENT_MAX_AGE,
                        Constants.DIAGNOSTICS_INCOME_PART_TIME_MAX_AGE
                      ) +
                        ScenarioUtils.getPrimaryPerson(ctx.scenario)
                          .yearOfBirth -
                        moment.utc().year()
                    )
                  )
                  .frequency(Frequency.ANNUALLY)
                  .growthRate(BaseGrowthRate.CALCULATED)
                  .build()
              )
            )
          )
        )
      );

  private static readonly INCOME_WORK_PART_TIME_AFTER_RETIREMENT_PARTNER = (
    ctx: DiagnosticContext
  ) =>
    ctx.earnedIncomesService
      .queryForPerson(
        ctx.scenario.id,
        ScenarioUtils.getPartner(ctx.scenario).id,
        Constants.PAGE_ALL
      )
      .pipe(
        concatMap((r) =>
          from(r.content).pipe(
            concatMap((i) =>
              ctx.earnedIncomesService.createForPerson(
                ctx.scenario.id,
                ScenarioUtils.getPartner(ctx.scenario).id,
                new EarnedIncomeBuilder()
                  .name("My partner's post-retirement income")
                  .currency(
                    Constants.LOCALE_CONFIG[
                      localStorage.getItem(Constants.LOCAL_STORAGE_LOCALE)
                    ].currency
                  )
                  .amount(
                    i.amount *
                      DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
                        ctx.goalDiagnostic,
                        Constants.DIAGNOSTICS_METADATA_ITEM_WORK_AFTER_RETIREMENT_PERCENTAGE,
                        Constants.DIAGNOSTICS_INCOME_PART_TIME_AFTER_RETIREMENT_FACTOR
                      )
                  )
                  .startDate(
                    DateUtils.inYears(
                      ScenarioUtils.getPartner(ctx.scenario)
                        .expectedRetirementAge +
                        ScenarioUtils.getPartner(ctx.scenario).yearOfBirth -
                        moment.utc().year()
                    )
                  )
                  .endDate(
                    DateUtils.inYears(
                      DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
                        ctx.goalDiagnostic,
                        Constants.DIAGNOSTICS_METADATA_ITEM_WORK_AFTER_RETIREMENT_MAX_AGE,
                        Constants.DIAGNOSTICS_INCOME_PART_TIME_MAX_AGE
                      ) +
                        ScenarioUtils.getPartner(ctx.scenario).yearOfBirth -
                        moment.utc().year()
                    )
                  )
                  .frequency(Frequency.ANNUALLY)
                  .growthRate(BaseGrowthRate.CALCULATED)
                  .build()
              )
            )
          )
        )
      );

  private static readonly GOAL_SWITCH_OFF_EARLIER_GOALS = (
    ctx: DiagnosticContext
  ) =>
    ctx.typedGoalService.query(ctx.scenario.id, Constants.PAGE_ALL).pipe(
      concatMap((r) =>
        from(
          r.content
            .filter((g) => g.id !== ctx.goalId)
            .filter((g) =>
              DiagnosticsUtils.goalsOverlap(
                r.content.find((o) => o.id === ctx.goalId),
                g,
                DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
                  ctx.goalDiagnostic,
                  Constants.DIAGNOSTICS_METADATA_ITEM_GOAL_REMOVE_GOALS_YEARS_BEFORE,
                  Constants.DIAGNOSTICS_GOAL_REMOVE_PREVIOUS_GOAL_IN_YEARS
                )
              )
            )
        ).pipe(
          concatMap((g) => ctx.typedGoalService.delete(ctx.scenario.id, g.id))
        )
      )
    );

  private static readonly GOAL_SAVE_IN_YEARS = (ctx: DiagnosticContext) =>
    ctx.typedGoalService.query(ctx.scenario.id, Constants.PAGE_ALL).pipe(
      concatMap((r) =>
        from(r.content.filter((g) => g.id === ctx.goalId)).pipe(
          concatMap((c) => {
            const years = DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
              ctx.goalDiagnostic,
              Constants.DIAGNOSTICS_METADATA_ITEM_GOAL_SAVE_IN_YEARS,
              0
            );

            const source = new SaveFundingSourceBuilder()
              .withName('save for goal')
              .withSaveInYears(years)
              .build();

            c.fundingSources.unshift(source);

            return ctx.typedGoalService.update(ctx.scenario.id, c);
          })
        )
      )
    );

  private static readonly GOAL_BORROW = (ctx: DiagnosticContext) =>
    ctx.typedGoalService.get(ctx.scenario.id, ctx.goalId).pipe(
      concatMap((c) => {
        const type = DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
          ctx.goalDiagnostic,
          Constants.DIAGNOSTICS_METADATA_ITEM_GOAL_BORROW_TYPE,
          Constants.DIAGNOSTICS_METADATA_ITEM_GOAL_BORROW_TYPE_NONE
        );

        let source: FundingSource;
        switch (type) {
          case Constants.FUNDING_SOURCE_CLASS_MORTGAGE:
            source = new MortgageFundingSourceBuilder()
              .withAmount(
                DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
                  ctx.goalDiagnostic,
                  Constants.DIAGNOSTICS_METADATA_ITEM_GOAL_BORROW_AMOUNT,
                  0
                )
              )
              .withTermYears(
                DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
                  ctx.goalDiagnostic,
                  Constants.DIAGNOSTICS_METADATA_ITEM_GOAL_BORROW_TERM,
                  0
                )
              )
              .withAnnualInterestRate(
                DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
                  ctx.goalDiagnostic,
                  Constants.DIAGNOSTICS_METADATA_ITEM_GOAL_BORROW_RATE,
                  0
                )
              )
              .build();
            break;
          case Constants.FUNDING_SOURCE_CLASS_OTHER_LOAN:
            source = new OtherLoanFundingSourceBuilder()
              .withAmount(
                DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
                  ctx.goalDiagnostic,
                  Constants.DIAGNOSTICS_METADATA_ITEM_GOAL_BORROW_AMOUNT,
                  0
                )
              )
              .withTermYears(
                DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
                  ctx.goalDiagnostic,
                  Constants.DIAGNOSTICS_METADATA_ITEM_GOAL_BORROW_TERM,
                  0
                )
              )
              .withAnnualInterestRate(
                DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
                  ctx.goalDiagnostic,
                  Constants.DIAGNOSTICS_METADATA_ITEM_GOAL_BORROW_RATE,
                  0
                )
              )
              .build();
            break;
          case Constants.FUNDING_SOURCE_CLASS_UK_STUDENT_LOAN:
            source = new UKStudentLoanFundingSourceBuilder()
              .withAmount(
                DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
                  ctx.goalDiagnostic,
                  Constants.DIAGNOSTICS_METADATA_ITEM_GOAL_BORROW_AMOUNT,
                  0
                )
              )
              .build();
            break;
          default:
            return of({});
        }

        c.fundingSources = c.fundingSources.filter((f) => f.class !== type);
        c.fundingSources.unshift(source);

        return ctx.typedGoalService.update(ctx.scenario.id, c);
      })
    );

  private static readonly GOAL_MOVE_IT_TO_THE_FUTURE = (
    ctx: DiagnosticContext
  ) =>
    ctx.typedGoalService.query(ctx.scenario.id, Constants.PAGE_ALL).pipe(
      concatMap((r) =>
        from(r.content.filter((g) => g.id === ctx.goalId)).pipe(
          concatMap((c) => {
            const years =
              c.type === GoalType.RETIREMENT
                ? Constants.DIAGNOSTICS_GOAL_RETIREMENT_MOVE_IT_TO_THE_FUTURE_YEARS
                : Constants.DIAGNOSTICS_GOAL_MOVE_IT_TO_THE_FUTURE_YEARS;

            c.startDate = moment(c.startDate, moment.ISO_8601)
              .add(years, 'years')
              .toISOString();
            c.endDate = moment(c.endDate, moment.ISO_8601)
              .add(years, 'years')
              .toISOString();

            return ctx.typedGoalService.update(ctx.scenario.id, c);
          })
        )
      )
    );

  private static PROPERTY_MOVE_TO_SMALLER_PROPERTY = (ctx: DiagnosticContext) =>
    ctx.typedGoalService.query(ctx.scenario.id, Constants.PAGE_ALL).pipe(
      concatMap((r) =>
        from(r.content.filter((g) => g.type === GoalType.RETIREMENT)).pipe(
          concatMap((c) => {
            const percentage = DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
              ctx.goalDiagnostic,
              Constants.DIAGNOSTICS_METADATA_ITEM_PROPERTY_MOVE_TO_SMALLER_PROPERTY_PERCENTAGE,
              0
            );
            const year = DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
              ctx.goalDiagnostic,
              Constants.DIAGNOSTICS_METADATA_ITEM_PROPERTY_MOVE_TO_SMALLER_PROPERTY_YEAR,
              0
            );

            DiagnosticsUtils.retirementGoalSetTradeDownYear(
              c,
              percentage,
              year
            );

            return ctx.typedGoalService.update(ctx.scenario.id, c);
          })
        )
      )
    );

  private static DEBT_RESIDENTIAL_PROPERTIES_REMORTGAGE = (
    ctx: DiagnosticContext
  ) =>
    ctx.propertyService
      .queryResidentialProperties(ctx.scenario.id, Constants.PAGE_ALL)
      .pipe(
        concatMap((r) =>
          from(r.content).pipe(
            concatMap((c) => {
              const mortgage = DiagnosticsUtils.findMortgageByPropertyId(
                ctx.scenario,
                c.id
              );

              const ltv = DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
                ctx.goalDiagnostic,
                Constants.DIAGNOSTICS_METADATA_ITEM_GOAL_BORROW_LTV,
                Constants.DEFAULT_DEBT_MORTGAGE.LTV
              );
              const term = DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
                ctx.goalDiagnostic,
                Constants.DIAGNOSTICS_METADATA_ITEM_GOAL_BORROW_TERM,
                Constants.DEFAULT_DEBT_MORTGAGE.term
              );
              const rate = DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
                ctx.goalDiagnostic,
                Constants.DIAGNOSTICS_METADATA_ITEM_GOAL_BORROW_RATE,
                Constants.DEFAULT_DEBT_MORTGAGE.rate
              );
              const increase = DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
                ctx.goalDiagnostic,
                Constants.DIAGNOSTICS_METADATA_ITEM_GOAL_BORROW_AMOUNT_INCREASE_PERCENTAGE,
                0
              );

              if (mortgage == null) {
                const m: MortgageLiability = {
                  name: Constants.DEFAULT_MORTGAGE_NAME,
                  amount: ltv * c.value,
                  balanceAmount: ltv * c.value,
                  currency:
                    Constants.LOCALE_CONFIG[
                      localStorage.getItem(Constants.LOCAL_STORAGE_LOCALE)
                    ].currency,
                  startDate: DateUtils.thisYear(),
                  balanceDate: DateUtils.thisYear(),
                  endDate: DateUtils.inYears(term),
                  annualAverageInterestRate:
                    Constants.DEFAULT_DEBT_MORTGAGE.rate,
                  repaymentType: RepaymentType.PRINCIPAL_AMORTIZATION,
                  mortgageType: MortgageType.FIXED_RATE,
                  propertyAssetId: c.id,
                };

                const p: FinancialPortfolio = {
                  name: Constants.DEFAULT_FINANCIAL_ASSET_FROM_REMORTGAGE_NAME,
                  profile: Constants.ALLOCATION_PRESET_NAMES[0].id,
                  wrapper: Constants.DEFAULT_FINANCIAL_ASSET_WRAPPER,
                  currency:
                    Constants.LOCALE_CONFIG[
                      localStorage.getItem(Constants.LOCAL_STORAGE_LOCALE)
                    ].currency,
                  value: ltv * c.value,
                  fees: Constants.DEFAULT_FINANCIAL_PORTFOLIO_CASH_FEES,
                };

                return ctx.mortgageService
                  .create(ctx.scenario.id, m)
                  .pipe(
                    concatMap(() =>
                      ctx.financialAssetsService.createFinancialPortfolio(
                        ctx.scenario.id,
                        ScenarioUtils.getPrimaryPerson(ctx.scenario).id,
                        p
                      )
                    )
                  );
              }

              if (mortgage.annualAverageInterestRate > rate) {
                return ctx.mortgageService
                  .get(ctx.scenario.id, mortgage.id)
                  .pipe(
                    flatMap((m) => {
                      m.amount = m.amount * (1 + increase);
                      m.balanceAmount = m.amount * (1 + increase);
                      m.endDate = DateUtils.inYears(term);
                      m.annualAverageInterestRate = rate;

                      return ctx.mortgageService.update(ctx.scenario.id, m);
                    })
                  );
              }

              return of({});
            })
          )
        )
      );

  private static DEBT_INVESTMENT_PROPERTIES_REMORTGAGE = (
    ctx: DiagnosticContext
  ) =>
    ctx.propertyService
      .queryInvestmentProperties(ctx.scenario.id, Constants.PAGE_ALL)
      .pipe(
        concatMap((r) =>
          from(r.content).pipe(
            concatMap((c) => {
              const mortgage = DiagnosticsUtils.findMortgageByPropertyId(
                ctx.scenario,
                c.id
              );

              const ltv = DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
                ctx.goalDiagnostic,
                Constants.DIAGNOSTICS_METADATA_ITEM_GOAL_BORROW_LTV,
                Constants.DEFAULT_DEBT_MORTGAGE.LTV
              );
              const term = DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
                ctx.goalDiagnostic,
                Constants.DIAGNOSTICS_METADATA_ITEM_GOAL_BORROW_TERM,
                Constants.DEFAULT_DEBT_MORTGAGE.term
              );
              const rate = DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
                ctx.goalDiagnostic,
                Constants.DIAGNOSTICS_METADATA_ITEM_GOAL_BORROW_RATE,
                Constants.DEFAULT_DEBT_MORTGAGE.rate
              );
              const increase = DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
                ctx.goalDiagnostic,
                Constants.DIAGNOSTICS_METADATA_ITEM_GOAL_BORROW_AMOUNT_INCREASE_PERCENTAGE,
                0
              );

              if (mortgage == null) {
                const m: MortgageLiability = {
                  name: Constants.DEFAULT_MORTGAGE_NAME,
                  amount: ltv * c.value,
                  balanceAmount: ltv * c.value,
                  currency:
                    Constants.LOCALE_CONFIG[
                      localStorage.getItem(Constants.LOCAL_STORAGE_LOCALE)
                    ].currency,
                  startDate: DateUtils.thisYear(),
                  balanceDate: DateUtils.thisYear(),
                  endDate: DateUtils.inYears(term),
                  annualAverageInterestRate:
                    Constants.DEFAULT_DEBT_MORTGAGE.rate,
                  repaymentType: RepaymentType.PRINCIPAL_AMORTIZATION,
                  mortgageType: MortgageType.FIXED_RATE,
                  propertyAssetId: c.id,
                };

                const p: FinancialPortfolio = {
                  name: Constants.DEFAULT_FINANCIAL_ASSET_FROM_REMORTGAGE_NAME,
                  profile: Constants.ALLOCATION_PRESET_NAMES[0].id,
                  wrapper: Constants.DEFAULT_FINANCIAL_ASSET_WRAPPER,
                  currency:
                    Constants.LOCALE_CONFIG[
                      localStorage.getItem(Constants.LOCAL_STORAGE_LOCALE)
                    ].currency,
                  value: ltv * c.value,
                  fees: Constants.DEFAULT_FINANCIAL_PORTFOLIO_CASH_FEES,
                };

                return ctx.mortgageService
                  .create(ctx.scenario.id, m)
                  .pipe(
                    concatMap((m) =>
                      ctx.financialAssetsService.createFinancialPortfolio(
                        ctx.scenario.id,
                        ScenarioUtils.getPrimaryPerson(ctx.scenario).id,
                        p
                      )
                    )
                  );
              }

              if (mortgage.annualAverageInterestRate > rate) {
                return ctx.mortgageService
                  .get(ctx.scenario.id, mortgage.id)
                  .pipe(
                    flatMap((m) => {
                      m.amount = m.amount * (1 + increase);
                      m.balanceAmount = m.amount * (1 + increase);
                      m.endDate = DateUtils.inYears(term);
                      m.annualAverageInterestRate = rate;

                      return ctx.mortgageService.update(ctx.scenario.id, m);
                    })
                  );
              }

              return of({});
            })
          )
        )
      );

  private static readonly DEBT_ELIMINATE_DEBT = (ctx: DiagnosticContext) =>
    ctx.unsecuredLiabilityService
      .query(ctx.scenario.id, Constants.PAGE_ALL)
      .pipe(
        concatMap((r) =>
          from(r.content).pipe(
            concatMap((l) => {
              const term: number =
                DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
                  ctx.goalDiagnostic,
                  Constants.DIAGNOSTICS_METADATA_ITEM_GOAL_BORROW_TERM,
                  0
                );
              const rates: number[][] =
                DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
                  ctx.goalDiagnostic,
                  Constants.DIAGNOSTICS_METADATA_ITEM_GOAL_BORROW_RATE_MATRIX,
                  []
                );

              const rate = DiagnosticsUtils.getInRange(
                rates,
                1,
                Math.abs(l.amount)
              );

              const liquidAssetsFundingSource: LiquidAssetsFundingSource =
                new LiquidAssetsFundingSource();

              const otherLoanFundingSource = new OtherLoanFundingSourceBuilder()
                .withAmount(Math.abs(l.amount))
                .withTermYears(term)
                .withAnnualInterestRate(rate)
                .build();

              const g: Goal = new GoalBuilder()
                .eliminateDebt()
                .withName(Constants.DEFAULT_GOAL_ELIMINATE_DEBT_NAME)
                .inDate(DateUtils.thisYear())
                .withAmount(Math.abs(l.amount))
                .withFundingSources(
                  l.annualAverageInterestRate > rate
                    ? [liquidAssetsFundingSource, otherLoanFundingSource]
                    : [liquidAssetsFundingSource]
                )
                .withProperties(new GoalEliminateDebtProperties(l.id))
                .build();

              return ctx.typedGoalService.create(ctx.scenario.id, g);
            })
          )
        )
      );

  private static readonly PROPERTY_SELL_BUY_TO_LET_PROPERTIES = (
    ctx: DiagnosticContext
  ) =>
    ctx.propertyService
      .queryInvestmentProperties(ctx.scenario.id, Constants.PAGE_ALL)
      .pipe(
        concatMap((r) =>
          from(r.content)
            .pipe(
              concatMap((p) =>
                ctx.propertyService.deleteInvestmentProperty(
                  ctx.scenario.id,
                  p.id
                )
              )
            )
            .pipe(
              concatMap((_) => {
                const amount = DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
                  ctx.goalDiagnostic,
                  Constants.DIAGNOSTICS_METADATA_ITEM_BUY_TO_LET_SURPLUS,
                  Constants.DEFAULT_BUY_TO_LET_SURPLUS_AMOUNT
                );
                const unearnedIncome = new UnearnedIncomeBuilder()
                  .type(UnearnedIncomeType.OTHER)
                  .currency(
                    Constants.LOCALE_CONFIG[
                      localStorage.getItem(Constants.LOCAL_STORAGE_LOCALE)
                    ].currency
                  )
                  .amount(amount)
                  .taxable(false)
                  .name(Constants.DEFAULT_SELL_PROPERTY_INCOME_NAME)
                  .startDate(DateUtils.thisYear())
                  .endDate(DateUtils.inYears(1))
                  .endsOn(DateRangeType.USER_DEFINED)
                  .frequency(Frequency.ONE_OFF)
                  .growthRate(BaseGrowthRate.CALCULATED)
                  .build();
                return ctx.unearnedIncomesService.createForPerson(
                  ctx.scenario.id,
                  ScenarioUtils.getPrimaryPerson(ctx.scenario).id,
                  unearnedIncome
                );
              })
            )
        )
      );

  private static readonly LIFESTYLE_TOO_UNHEALTHY_PRIMARY = (
    ctx: DiagnosticContext
  ) =>
    of(ScenarioUtils.getPrimaryPerson(ctx.scenario)).pipe(
      concatMap((primary) => {
        const healthStatusIdx = parseInt(
          DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
            ctx.goalDiagnostic,
            Constants.DIAGNOSTICS_METADATA_ITEM_HEALTH_STATUS_INDEX,
            Constants.DEFAULT_PRIMARY_HEALTH_STATUS_IDΧ
          ),
          10
        );
        primary.healthStatus =
          HealthStatus[Object.keys(HealthStatus)[healthStatusIdx]];
        return ctx.personService.updatePrimary(ctx.scenario.id, primary);
      })
    );

  private static readonly LIFESTYLE_TOO_UNHEALTHY_PARTNER = (
    ctx: DiagnosticContext
  ) =>
    of(ScenarioUtils.getPartner(ctx.scenario)).pipe(
      concatMap((partner) => {
        const healthStatusIdx = parseInt(
          DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
            ctx.goalDiagnostic,
            Constants.DIAGNOSTICS_METADATA_ITEM_HEALTH_STATUS_INDEX,
            Constants.DEFAULT_PARTNER_HEALTH_STATUS_IDX
          ),
          10
        );
        partner.healthStatus =
          HealthStatus[Object.keys(HealthStatus)[healthStatusIdx]];
        return ctx.personService.updatePartner(ctx.scenario.id, partner);
      })
    );

  private static readonly GOAL_SPEND_LESS_MONEY_ON_GOAL = (
    ctx: DiagnosticContext
  ) =>
    ctx.typedGoalService.get(ctx.scenario.id, ctx.goalId).pipe(
      concatMap((c) => {
        const defaultFactor =
          c.type === GoalType.BUY_A_HOUSE || c.type === GoalType.BUY_TO_LET
            ? Constants.DIAGNOSTICS_GOAL_PROPERTY_RELATED_EXPENSE_REDUCE_FACTOR
            : Constants.DIAGNOSTICS_GOAL_EXPENSE_REDUCE_FACTOR;
        const factor = DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
          ctx.goalDiagnostic,
          Constants.DIAGNOSTICS_METADATA_ITEM_GOAL_REDUCE_AMOUNT_PERCENTAGE,
          defaultFactor
        );

        c.minimumAmount *= factor;
        c.desiredAmount *= factor;
        return ctx.typedGoalService.update(ctx.scenario.id, c);
      })
    );

  private static readonly GOAL_BUY_A_HOUSE_DOWNPAYMENT = (
    ctx: DiagnosticContext
  ) =>
    ctx.typedGoalService
      .get(ctx.scenario.id, ctx.goalId)
      .pipe(
        filter(
          (goal) =>
            goal.type === GoalType.BUY_A_HOUSE ||
            goal.type === GoalType.BUY_TO_LET
        )
      )
      .pipe(
        concatMap((goal) => {
          const mortgage = DiagnosticsUtils.getMortgageFundingSource(goal);
          if (!mortgage) {
            return of({});
          }

          const deposit = DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
            ctx.goalDiagnostic,
            Constants.DIAGNOSTICS_METADATA_ITEM_GOAL_BUY_A_HOUSE_DOWNPAYMENT,
            0
          );
          const mortgageRateIncrease =
            DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
              ctx.goalDiagnostic,
              Constants.DIAGNOSTICS_METADATA_ITEM_BUY_HOUSE_DOWNPAYMENT_MORTGAGE_RATE,
              Constants.DEFAULT_BUY_HOUSE_DOWNPAYMENT_RATE_INCREASE
            );

          const primaryAge =
            moment.utc().year() -
            ScenarioUtils.getPrimaryPerson(ctx.scenario).yearOfBirth;
          const yearsToRetirement =
            ScenarioUtils.getPrimaryPerson(ctx.scenario).expectedRetirementAge -
            primaryAge;

          mortgage.amount = goal.minimumAmount - deposit;
          mortgage.annualInterestRate += mortgageRateIncrease;
          mortgage.termYears = Math.min(mortgage.termYears, yearsToRetirement);
          return ctx.typedGoalService.update(ctx.scenario.id, goal);
        })
      );

  private static readonly GOAL_BUY_A_HOUSE_MORTGAGE_AFFORDABILITY = (
    ctx: DiagnosticContext
  ) =>
    ctx.typedGoalService.get(ctx.scenario.id, ctx.goalId).pipe(
      concatMap((goal) => {
        const mortgage = DiagnosticsUtils.getMortgageFundingSource(goal);
        if (!mortgage) {
          return of({});
        }

        mortgage.amount = DiagnosticsUtils.floorToThousand(
          DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
            ctx.goalDiagnostic,
            Constants.DIAGNOSTICS_METADATA_ITEM_BUY_HOUSE_MORTGAGE,
            mortgage.amount
          )
        );

        const primaryAge =
          moment.utc().year() -
          ScenarioUtils.getPrimaryPerson(ctx.scenario).yearOfBirth;
        const yearsToRetirement =
          ScenarioUtils.getPrimaryPerson(ctx.scenario).expectedRetirementAge -
          primaryAge;
        mortgage.termYears = Math.min(mortgage.termYears, yearsToRetirement);
        if (mortgage.amount === 0) {
          DiagnosticsUtils.removeMortgageFundingSource(goal);
        }

        return ctx.typedGoalService.update(ctx.scenario.id, goal);
      })
    );

  private static readonly INSURANCE_MORTGAGE_LIFE_INSURANCE_INCREASE = (
    ctx: DiagnosticContext
  ) =>
    of(ScenarioUtils.getMortgageLiabilities(ctx.scenario)).pipe(
      concatMap((mortgages) => {
        const insurances = ScenarioUtils.getMortgageLifeInsurances(
          ctx.scenario,
          true
        );
        return concat(
          ...mortgages
            .filter(
              (m) =>
                insurances.filter((i) => i.mortgage.id === m.id).length === 0
            )
            .map((mortgage) => {
              const mortgageInsurance: MortgageInsurance = {
                frequency: Constants.DEFAULT_INSURANCE_FREQUENCY,
                joint: true,
                name: Constants.DEFAULT_MORTGAGE_LIFE_INSURANCE_NAME,
                payout: Constants.DEFAULT_INSURANCE_PAYOUT,
                payoutCurrency:
                  Constants.LOCALE_CONFIG[
                    localStorage.getItem(Constants.LOCAL_STORAGE_LOCALE)
                  ].currency,
                startDate: DateUtils.thisYear(),
                endDate: DateUtils.thisYear(),
                startsOn: Constants.DEFAULT_STARTS_ON,
                endsOn: Constants.DEFAULT_ENDS_ON,
                mortgageId: mortgage.id,
              };
              return ctx.mortgageLifeInsuranceService.createForPerson(
                ctx.scenario.id,
                ScenarioUtils.getPrimaryPerson(ctx.scenario).id,
                mortgageInsurance
              );
            })
        );
      })
    );

  private static readonly INSURANCE_TERM_LIFE_INSURANCE_INCREASE_PRIMARY = (
    ctx: DiagnosticContext
  ) =>
    iif(
      () => ScenarioUtils.getLifeInsurances(ctx.scenario, true).length === 0,
      of({}),
      of({}).pipe(
        concatMap((e) =>
          from(ScenarioUtils.getLifeInsurances(ctx.scenario, true)).pipe(
            concatMap((i: Insurance) =>
              ctx.lifeInsuranceService.deleteForPerson(
                ctx.scenario.id,
                ScenarioUtils.getPrimaryPerson(ctx.scenario).id,
                i.id
              )
            )
          )
        )
      )
    ).pipe(
      concatMap((e) => {
        const payout: number = DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
          ctx.goalDiagnostic,
          Constants.DIAGNOSTICS_METADATA_ITEM_INSURANCE_COVER_AMOUNT,
          0
        );
        const endYear: number =
          DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
            ctx.goalDiagnostic,
            Constants.DIAGNOSTICS_METADATA_ITEM_INSURANCE_END_YEAR,
            0
          );

        const insurance: Insurance = {
          name: Constants.DEFAULT_LIFE_INSURANCE_NAME,
          frequency: Constants.DEFAULT_INSURANCE_FREQUENCY,
          joint: false,
          payout,
          payoutCurrency:
            Constants.LOCALE_CONFIG[
              localStorage.getItem(Constants.LOCAL_STORAGE_LOCALE)
            ].currency,
          startDate: DateUtils.thisYear(),
          endDate: DateUtils.atYear(endYear),
          startsOn: DateRangeType.USER_DEFINED,
          endsOn: DateRangeType.USER_DEFINED,
        };
        return ctx.lifeInsuranceService.createForPerson(
          ctx.scenario.id,
          ScenarioUtils.getPrimaryPerson(ctx.scenario).id,
          insurance
        );
      })
    );

  private static readonly INSURANCE_TERM_LIFE_INSURANCE_INCREASE_PARTNER = (
    ctx: DiagnosticContext
  ) =>
    iif(
      () => ScenarioUtils.getLifeInsurances(ctx.scenario, false).length === 0,
      of({}),
      of({}).pipe(
        concatMap((e) =>
          from(ScenarioUtils.getLifeInsurances(ctx.scenario, false)).pipe(
            concatMap((i: Insurance) =>
              ctx.lifeInsuranceService.deleteForPerson(
                ctx.scenario.id,
                ScenarioUtils.getPartner(ctx.scenario).id,
                i.id
              )
            )
          )
        )
      )
    ).pipe(
      concatMap((e) => {
        const payout: number = DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
          ctx.goalDiagnostic,
          Constants.DIAGNOSTICS_METADATA_ITEM_INSURANCE_COVER_AMOUNT,
          0
        );
        const endYear: number =
          DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
            ctx.goalDiagnostic,
            Constants.DIAGNOSTICS_METADATA_ITEM_INSURANCE_END_YEAR,
            0
          );

        const insurance: Insurance = {
          name: Constants.DEFAULT_LIFE_INSURANCE_NAME_PARTNER,
          frequency: Constants.DEFAULT_INSURANCE_FREQUENCY,
          joint: false,
          payout,
          payoutCurrency:
            Constants.LOCALE_CONFIG[
              localStorage.getItem(Constants.LOCAL_STORAGE_LOCALE)
            ].currency,
          startDate: DateUtils.thisYear(),
          endDate: DateUtils.atYear(endYear),
          startsOn: DateRangeType.USER_DEFINED,
          endsOn: DateRangeType.USER_DEFINED,
        };
        return ctx.lifeInsuranceService.createForPerson(
          ctx.scenario.id,
          ScenarioUtils.getPartner(ctx.scenario).id,
          insurance
        );
      })
    );

  private static readonly INSURANCE_DISABILITY_INSURANCE_INCREASE_PRIMARY = (
    ctx: DiagnosticContext
  ) =>
    iif(
      () =>
        ScenarioUtils.getDisabilityInsurances(ctx.scenario, true).length === 0,
      of({}),
      of({}).pipe(
        concatMap((e) =>
          from(ScenarioUtils.getDisabilityInsurances(ctx.scenario, true)).pipe(
            concatMap((i: Insurance) =>
              ctx.disabilityInsuranceService.deleteForPerson(
                ctx.scenario.id,
                ScenarioUtils.getPrimaryPerson(ctx.scenario).id,
                i.id
              )
            )
          )
        )
      )
    ).pipe(
      concatMap((e) => {
        const payout: number = DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
          ctx.goalDiagnostic,
          Constants.DIAGNOSTICS_METADATA_ITEM_INSURANCE_COVER_AMOUNT,
          0
        );
        const endYear: number =
          DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
            ctx.goalDiagnostic,
            Constants.DIAGNOSTICS_METADATA_ITEM_INSURANCE_END_YEAR,
            0
          );

        const insurance: Insurance = {
          name: Constants.DEFAULT_DISABILITY_INSURANCE_NAME,
          frequency: Frequency.ANNUALLY,
          joint: false,
          payout,
          payoutCurrency:
            Constants.LOCALE_CONFIG[
              localStorage.getItem(Constants.LOCAL_STORAGE_LOCALE)
            ].currency,
          startDate: DateUtils.thisYear(),
          endDate: DateUtils.atYear(endYear),
          startsOn: DateRangeType.USER_DEFINED,
          endsOn: DateRangeType.USER_DEFINED,
        };
        return ctx.disabilityInsuranceService.createForPerson(
          ctx.scenario.id,
          ScenarioUtils.getPrimaryPerson(ctx.scenario).id,
          insurance
        );
      })
    );

  private static readonly INSURANCE_DISABILITY_INSURANCE_INCREASE_PARTNER = (
    ctx: DiagnosticContext
  ) =>
    iif(
      () =>
        ScenarioUtils.getDisabilityInsurances(ctx.scenario, false).length === 0,
      of({}),
      of({}).pipe(
        concatMap((e) =>
          from(ScenarioUtils.getDisabilityInsurances(ctx.scenario, false)).pipe(
            concatMap((i: Insurance) =>
              ctx.disabilityInsuranceService.deleteForPerson(
                ctx.scenario.id,
                ScenarioUtils.getPartner(ctx.scenario).id,
                i.id
              )
            )
          )
        )
      )
    ).pipe(
      concatMap((e) => {
        const payout: number = DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
          ctx.goalDiagnostic,
          Constants.DIAGNOSTICS_METADATA_ITEM_INSURANCE_COVER_AMOUNT,
          0
        );
        const endYear: number =
          DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
            ctx.goalDiagnostic,
            Constants.DIAGNOSTICS_METADATA_ITEM_INSURANCE_END_YEAR,
            0
          );

        const insurance: Insurance = {
          name: Constants.DEFAULT_DISABILITY_INSURANCE_NAME_PARTNER,
          frequency: Frequency.ANNUALLY,
          joint: false,
          payout,
          payoutCurrency:
            Constants.LOCALE_CONFIG[
              localStorage.getItem(Constants.LOCAL_STORAGE_LOCALE)
            ].currency,
          startDate: DateUtils.thisYear(),
          endDate: DateUtils.atYear(endYear),
          startsOn: DateRangeType.USER_DEFINED,
          endsOn: DateRangeType.USER_DEFINED,
        };
        return ctx.disabilityInsuranceService.createForPerson(
          ctx.scenario.id,
          ScenarioUtils.getPartner(ctx.scenario).id,
          insurance
        );
      })
    );

  private static readonly INSURANCE_CRITICAL_ILLNESS_INSURANCE_INCREASE_PRIMARY =
    (ctx: DiagnosticContext) =>
      iif(
        () =>
          ScenarioUtils.getCriticalIllnessInsurances(ctx.scenario, true)
            .length === 0,
        of({}),
        of({}).pipe(
          concatMap((e) =>
            from(
              ScenarioUtils.getCriticalIllnessInsurances(ctx.scenario, true)
            ).pipe(
              concatMap((i: Insurance) =>
                ctx.criticalIllnessInsuranceService.deleteForPerson(
                  ctx.scenario.id,
                  ScenarioUtils.getPrimaryPerson(ctx.scenario).id,
                  i.id
                )
              )
            )
          )
        )
      ).pipe(
        concatMap((e) => {
          const payout: number =
            DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
              ctx.goalDiagnostic,
              Constants.DIAGNOSTICS_METADATA_ITEM_INSURANCE_COVER_AMOUNT,
              0
            );
          const endYear: number =
            DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
              ctx.goalDiagnostic,
              Constants.DIAGNOSTICS_METADATA_ITEM_INSURANCE_END_YEAR,
              0
            );

          const insurance: Insurance = {
            name: Constants.DEFAULT_CRITICAL_ILLNESS_INSURANCE_NAME,
            frequency: Frequency.ONE_OFF,
            joint: false,
            payout,
            payoutCurrency:
              Constants.LOCALE_CONFIG[
                localStorage.getItem(Constants.LOCAL_STORAGE_LOCALE)
              ].currency,
            startDate: DateUtils.thisYear(),
            endDate: DateUtils.atYear(endYear),
            startsOn: DateRangeType.USER_DEFINED,
            endsOn: DateRangeType.USER_DEFINED,
          };
          return ctx.criticalIllnessInsuranceService.createForPerson(
            ctx.scenario.id,
            ScenarioUtils.getPrimaryPerson(ctx.scenario).id,
            insurance
          );
        })
      );

  private static readonly INSURANCE_CRITICAL_ILLNESS_INSURANCE_INCREASE_PARTNER =
    (ctx: DiagnosticContext) =>
      iif(
        () =>
          ScenarioUtils.getCriticalIllnessInsurances(ctx.scenario, false)
            .length === 0,
        of({}),
        of({}).pipe(
          concatMap((e) =>
            from(
              ScenarioUtils.getCriticalIllnessInsurances(ctx.scenario, false)
            ).pipe(
              concatMap((i: Insurance) =>
                ctx.criticalIllnessInsuranceService.deleteForPerson(
                  ctx.scenario.id,
                  ScenarioUtils.getPartner(ctx.scenario).id,
                  i.id
                )
              )
            )
          )
        )
      ).pipe(
        concatMap((e) => {
          const payout: number =
            DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
              ctx.goalDiagnostic,
              Constants.DIAGNOSTICS_METADATA_ITEM_INSURANCE_COVER_AMOUNT,
              0
            );
          const endYear: number =
            DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
              ctx.goalDiagnostic,
              Constants.DIAGNOSTICS_METADATA_ITEM_INSURANCE_END_YEAR,
              0
            );

          const insurance: Insurance = {
            name: Constants.DEFAULT_CRITICAL_ILLNESS_INSURANCE_NAME,
            frequency: Frequency.ONE_OFF,
            joint: false,
            payout,
            payoutCurrency:
              Constants.LOCALE_CONFIG[
                localStorage.getItem(Constants.LOCAL_STORAGE_LOCALE)
              ].currency,
            startDate: DateUtils.thisYear(),
            endDate: DateUtils.atYear(endYear),
            startsOn: DateRangeType.USER_DEFINED,
            endsOn: DateRangeType.USER_DEFINED,
          };
          return ctx.criticalIllnessInsuranceService.createForPerson(
            ctx.scenario.id,
            ScenarioUtils.getPartner(ctx.scenario).id,
            insurance
          );
        })
      );

  private static savingsGiaInvestmentsEnableForPerson = (
    ctx: DiagnosticContext,
    person
  ) => {
    let financialClasses: FinancialClass[];
    let financialWrappers: FinancialWrapper[];
    let cashAllocation;
    let cashAllocationPresetName;
    let assetAllocationPresets;
    return ctx.preferenceService
      .get(ctx.scenario.id, Constants.ASSET_ALLOCATION_PRESETS_PREFERENCE_NAME)
      .pipe(
        flatMap((p) => {
          assetAllocationPresets = p;
          return ctx.financialAssetsService.queryFinancialClasses(
            Constants.PAGE_ALL
          );
        })
      )
      .pipe(
        flatMap((c) => {
          financialClasses = c.content;
          cashAllocation = Array(financialClasses.length).fill(0);
          cashAllocation[financialClasses.findIndex((c) => c.type === 'CASH')] =
            1;

          cashAllocationPresetName = Object.keys(assetAllocationPresets).find(
            (k) => {
              if (assetAllocationPresets[k].length !== cashAllocation.length) {
                return false;
              }
              for (let i = 0; i < assetAllocationPresets[k].length; i++) {
                if (assetAllocationPresets[k][i] !== cashAllocation[i]) {
                  return false;
                }
              }
              return true;
            }
          );
          return ctx.financialAssetsService.queryFinancialWrappers(
            Constants.PAGE_ALL
          );
        })
      )
      .pipe(
        flatMap((w) => {
          financialWrappers = w.content;
          return ctx.preferenceService
            .get(ctx.scenario.id, Constants.SAVINGS_SEQUENCE_PREFERENCE_NAME)
            .pipe(
              flatMap((s: SavingsSequence[]) => {
                s.filter(
                  (s) => s.type === 'DISTRIBUTE_IN_INVESTMENT_PORTFOLIOS'
                ).forEach((s) => (s.active = true));

                return ctx.preferenceService.update(
                  ctx.scenario.id,
                  Constants.SAVINGS_SEQUENCE_PREFERENCE_NAME,
                  s
                );
              })
            );
        })
      )
      .pipe(
        flatMap(() =>
          iif(
            () =>
              ctx.goalDiagnostic.metaData[
                Constants.DIAGNOSTICS_METADATA_ITEM_WRAPPER_PRESETS_INDEX
              ],
            ctx.financialAssetsService.createFinancialPortfolio(
              ctx.scenario.id,
              person.id,
              {
                name: 'Investment portfolio',
                wrapper: financialWrappers.find((w) => w.type === 'GENERAL').id,
                profile: Object.keys(assetAllocationPresets)[
                  Number(
                    DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
                      ctx.goalDiagnostic,
                      Constants.DIAGNOSTICS_METADATA_ITEM_WRAPPER_PRESETS_INDEX,
                      0
                    )
                  )
                ],
                value: 0,
                currency:
                  Constants.LOCALE_CONFIG[
                    localStorage.getItem(Constants.LOCAL_STORAGE_LOCALE)
                  ].currency,
                fees: Constants.DEFAULT_FINANCIAL_PORTFOLIO_INVESTMENT_FEES,
              }
            ),
            ctx.financialAssetsService
              .queryFinancialPortfolios(
                ctx.scenario.id,
                person.id,
                Constants.PAGE_ALL
              )
              .pipe(
                concatMap((resp) =>
                  from(
                    resp.content.filter(
                      (p) =>
                        !!DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
                          ctx.goalDiagnostic,
                          Constants.DIAGNOSTICS_METADATA_ITEM_WRAPPER_PRESETS_INDEX_PER_PORTFOLIO,
                          {}
                        )[p.id]
                    )
                  ).pipe(
                    flatMap((p) =>
                      ctx.financialAssetsService.updateFinancialPortfolio(
                        ctx.scenario.id,
                        person.id,
                        {
                          ...p,
                          fees: Constants.DEFAULT_FINANCIAL_PORTFOLIO_INVESTMENT_FEES,
                          profile: Object.keys(assetAllocationPresets)[
                            DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
                              ctx.goalDiagnostic,
                              Constants.DIAGNOSTICS_METADATA_ITEM_WRAPPER_PRESETS_INDEX_PER_PORTFOLIO,
                              {}
                            )[p.id]
                          ],
                        }
                      )
                    )
                  )
                )
              )
          )
        )
      );
  };

  private static readonly SAVINGS_TAX_ADVANTAGED_INVESTMENTS_ENABLE_PRIMARY = (
    ctx: DiagnosticContext
  ) =>
    ctx.preferenceService
      .get(ctx.scenario.id, Constants.SAVINGS_SEQUENCE_PREFERENCE_NAME)
      .pipe(
        concatMap((r) => {
          (r as SavingsSequence[])
            .filter((s) => s.type === 'TAX_ADVANTAGED')
            .forEach((s) => (s.active = true));

          return ctx.preferenceService.update(
            ctx.scenario.id,
            Constants.SAVINGS_SEQUENCE_PREFERENCE_NAME,
            r
          );
        })
      );

  private static readonly SAVINGS_TAX_ADVANTAGED_INVESTMENTS_ENABLE_PARTNER = (
    ctx: DiagnosticContext
  ) =>
    ctx.preferenceService
      .get(ctx.scenario.id, Constants.SAVINGS_SEQUENCE_PREFERENCE_NAME)
      .pipe(
        concatMap((r) => {
          (r as SavingsSequence[])
            .filter((s) => s.type === 'TAX_ADVANTAGED')
            .forEach((s) => (s.active = true));

          return ctx.preferenceService.update(
            ctx.scenario.id,
            Constants.SAVINGS_SEQUENCE_PREFERENCE_NAME,
            r
          );
        })
      );

  private static readonly SAVINGS_GIA_INVESTMENTS_ENABLE_PRIMARY = (
    ctx: DiagnosticContext
  ) =>
    DiagnosticsUtils.savingsGiaInvestmentsEnableForPerson(
      ctx,
      ScenarioUtils.getPrimaryPerson(ctx.scenario)
    );

  private static readonly SAVINGS_GIA_INVESTMENTS_ENABLE_PARTNER = (
    ctx: DiagnosticContext
  ) =>
    DiagnosticsUtils.savingsGiaInvestmentsEnableForPerson(
      ctx,
      ScenarioUtils.getPartner(ctx.scenario)
    );

  private static getAdjustRiskPresetObservable = (
    ctx: DiagnosticContext,
    person: Person,
    wrapperType: string,
    decrease: boolean = false
  ) => {
    let profiles: FinancialProfile[];
    let wrappers: any[];

    return ctx.financialAssetsService
      .queryFinancialWrappers(Constants.PAGE_ALL)
      .pipe(
        flatMap((resp) => {
          wrappers = resp.content;
          return ctx.financialAssetsService.queryFinancialProfiles(
            Constants.PAGE_ALL
          );
        }),
        map((r) => (profiles = r.content)),
        flatMap(() =>
          ctx.financialAssetsService.queryFinancialPortfolios(
            ctx.scenario.id,
            person.id,
            Constants.PAGE_ALL
          )
        ),
        flatMap((resp) => {
          if (
            resp.content.filter(
              (p) =>
                wrappers.find((w) => w.id === p.wrapper).type === wrapperType
            ).length === 0
          ) {
            return of({});
          }
          return concat(
            ...resp.content
              .filter(
                (p) =>
                  wrappers.find((w) => w.id === p.wrapper).type === wrapperType
              )
              .map((p) => {
                console.log(p);
                const allocationPresetMap =
                  DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
                    ctx.goalDiagnostic,
                    Constants.DIAGNOSTICS_METADATA_ITEM_WRAPPER_PRESETS_INDEX_PER_PORTFOLIO,
                    null
                  );
                const levels = allocationPresetMap[p.id];
                const currentIdx = profiles.findIndex(
                  (pr) => pr.name === p.profile
                );
                const idx = decrease
                  ? Math.max(0, currentIdx - levels)
                  : Math.min(profiles.length - 1, currentIdx + levels);
                const fees =
                  idx === 0
                    ? Constants.DEFAULT_FINANCIAL_PORTFOLIO_CASH_FEES
                    : Constants.DEFAULT_FINANCIAL_PORTFOLIO_INVESTMENT_FEES;
                p.profile = profiles[idx] ? profiles[idx].name : p.profile;
                p.fees = fees;

                return ctx.financialAssetsService.updateFinancialPortfolio(
                  ctx.scenario.id,
                  person.id,
                  p
                );
              })
          );
        })
      );
  };

  private static readonly WRAPPER_TAX_ADVANTAGED_RISK_INCREASE_PRIMARY = (
    ctx: DiagnosticContext
  ) =>
    DiagnosticsUtils.getAdjustRiskPresetObservable(
      ctx,
      ScenarioUtils.getPrimaryPerson(ctx.scenario),
      'TAX_ADVANTAGED',
      false
    );

  private static readonly WRAPPER_TAX_ADVANTAGED_RISK_INCREASE_PARTNER = (
    ctx: DiagnosticContext
  ) =>
    DiagnosticsUtils.getAdjustRiskPresetObservable(
      ctx,
      ScenarioUtils.getPartner(ctx.scenario),
      'TAX_ADVANTAGED',
      false
    );

  private static readonly WRAPPER_TAX_ADVANTAGED_RISK_DECREASE_PRIMARY = (
    ctx: DiagnosticContext
  ) =>
    DiagnosticsUtils.getAdjustRiskPresetObservable(
      ctx,
      ScenarioUtils.getPrimaryPerson(ctx.scenario),
      'TAX_ADVANTAGED',
      true
    );

  private static readonly WRAPPER_TAX_ADVANTAGED_RISK_DECREASE_PARTNER = (
    ctx: DiagnosticContext
  ) =>
    DiagnosticsUtils.getAdjustRiskPresetObservable(
      ctx,
      ScenarioUtils.getPartner(ctx.scenario),
      'TAX_ADVANTAGED',
      true
    );

  private static readonly WRAPPER_GIA_RISK_INCREASE_PRIMARY = (
    ctx: DiagnosticContext
  ) =>
    DiagnosticsUtils.getAdjustRiskPresetObservable(
      ctx,
      ScenarioUtils.getPrimaryPerson(ctx.scenario),
      'GENERAL',
      false
    );

  private static readonly WRAPPER_GIA_RISK_INCREASE_PARTNER = (
    ctx: DiagnosticContext
  ) =>
    DiagnosticsUtils.getAdjustRiskPresetObservable(
      ctx,
      ScenarioUtils.getPartner(ctx.scenario),
      'GENERAL',
      false
    );

  private static readonly WRAPPER_GIA_RISK_DECREASE_PRIMARY = (
    ctx: DiagnosticContext
  ) =>
    DiagnosticsUtils.getAdjustRiskPresetObservable(
      ctx,
      ScenarioUtils.getPrimaryPerson(ctx.scenario),
      'GENERAL',
      true
    );

  private static readonly WRAPPER_GIA_RISK_DECREASE_PARTNER = (
    ctx: DiagnosticContext
  ) =>
    DiagnosticsUtils.getAdjustRiskPresetObservable(
      ctx,
      ScenarioUtils.getPartner(ctx.scenario),
      'GENERAL',
      true
    );

  private static readonly WRAPPER_PENSION_RISK_INCREASE_PRIMARY = (
    ctx: DiagnosticContext
  ) =>
    DiagnosticsUtils.getAdjustRiskPresetObservable(
      ctx,
      ScenarioUtils.getPrimaryPerson(ctx.scenario),
      'PENSION',
      false
    );

  private static readonly WRAPPER_PENSION_RISK_INCREASE_PARTNER = (
    ctx: DiagnosticContext
  ) =>
    DiagnosticsUtils.getAdjustRiskPresetObservable(
      ctx,
      ScenarioUtils.getPartner(ctx.scenario),
      'PENSION',
      false
    );

  private static readonly WRAPPER_PENSION_RISK_DECREASE_PRIMARY = (
    ctx: DiagnosticContext
  ) =>
    DiagnosticsUtils.getAdjustRiskPresetObservable(
      ctx,
      ScenarioUtils.getPrimaryPerson(ctx.scenario),
      'PENSION',
      true
    );

  private static readonly WRAPPER_PENSION_RISK_DECREASE_PARTNER = (
    ctx: DiagnosticContext
  ) =>
    DiagnosticsUtils.getAdjustRiskPresetObservable(
      ctx,
      ScenarioUtils.getPartner(ctx.scenario),
      'PENSION',
      true
    );

  private static readonly WRAPPER_PENSION_CONTRIBUTION_INCREASE_PRIMARY = (
    ctx: DiagnosticContext
  ) =>
    iif(
      () =>
        DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
          ctx.goalDiagnostic,
          Constants.DIAGNOSTICS_METADATA_ITEM_WRAPPER_EMPLOYEE_PENSION_CONTRIBUTION_EXPENSE_INCREASE_PERCENTAGE,
          0
        ) === 0,
      ctx.earnedIncomesService
        .queryForPerson(
          ctx.scenario.id,
          ScenarioUtils.getPrimaryPerson(ctx.scenario).id,
          Constants.PAGE_ALL
        )
        .pipe(
          concatMap((r) =>
            from(r.content).pipe(
              concatMap((i) => {
                const incomePercentage: number =
                  DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
                    ctx.goalDiagnostic,
                    Constants.DIAGNOSTICS_METADATA_ITEM_WRAPPER_EMPLOYEE_PENSION_CONTRIBUTION_EXPENSE_ADD_PERCENTAGE,
                    Constants.DEFAULT_PENSION_EXPENSE_INCOME_PERCENT_SAVED
                  );

                const p: FinancialPortfolio = {
                  name: 'Pension portfolio',
                  profile: Constants.ALLOCATION_PRESET_NAMES[0].id,
                  wrapper: Constants.DEFAULT_FINANCIAL_ASSET_WRAPPER,
                  currency:
                    Constants.LOCALE_CONFIG[
                      localStorage.getItem(Constants.LOCAL_STORAGE_LOCALE)
                    ].currency,
                  value: 0,
                  fees: Constants.DEFAULT_FINANCIAL_PORTFOLIO_CASH_FEES,
                };

                return ctx.financialAssetsService
                  .createFinancialPortfolio(
                    ctx.scenario.id,
                    ScenarioUtils.getPrimaryPerson(ctx.scenario).id,
                    p
                  )
                  .pipe(
                    concatMap((p) =>
                      ctx.pensionExpenseService.createForPerson(
                        ctx.scenario.id,
                        ScenarioUtils.getPrimaryPerson(ctx.scenario).id,
                        new PensionExpenseBuilder()
                          .name(Constants.DEFAULT_PENSION_EXPENSE_NAME)
                          .portfolioId(p.id)
                          .earnedIncomeId(i.id)
                          .earnedIncomePercentSaved(incomePercentage)
                          .build()
                      )
                    )
                  );
              })
            )
          )
        ),
      ctx.pensionExpenseService
        .queryForPerson(
          ctx.scenario.id,
          ScenarioUtils.getPrimaryPerson(ctx.scenario).id,
          Constants.PAGE_ALL
        )
        .pipe(
          concatMap((r) =>
            from(r.content).pipe(
              concatMap((e) => {
                const incomePercentage: number =
                  DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
                    ctx.goalDiagnostic,
                    Constants.DIAGNOSTICS_METADATA_ITEM_WRAPPER_EMPLOYEE_PENSION_CONTRIBUTION_EXPENSE_INCREASE_PERCENTAGE,
                    Constants.DEFAULT_PENSION_EXPENSE_INCOME_PERCENT_SAVED
                  );

                e.earnedIncomePercentSaved += incomePercentage;

                return ctx.pensionExpenseService.updateForPerson(
                  ctx.scenario.id,
                  ScenarioUtils.getPrimaryPerson(ctx.scenario).id,
                  e
                );
              })
            )
          )
        )
    ).pipe(
      concatMap((r) =>
        iif(
          () =>
            DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
              ctx.goalDiagnostic,
              Constants.DIAGNOSTICS_METADATA_ITEM_WRAPPER_EMPLOYER_PENSION_CONTRIBUTION_INCOME_INCREASE_PERCENTAGE,
              0
            ) === 0,
          of({}),
          ctx.pensionIncomeService
            .queryForPerson(
              ctx.scenario.id,
              ScenarioUtils.getPrimaryPerson(ctx.scenario).id,
              Constants.PAGE_ALL
            )
            .pipe(
              concatMap((r) =>
                from(r.content).pipe(
                  concatMap((i) => {
                    const incomePercentage: number =
                      DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
                        ctx.goalDiagnostic,
                        Constants.DIAGNOSTICS_METADATA_ITEM_WRAPPER_EMPLOYER_PENSION_CONTRIBUTION_INCOME_INCREASE_PERCENTAGE,
                        Constants.DEFAULT_PENSION_INCOME_PERCENT_SAVED
                      );

                    i.earnedIncomePercentSaved += incomePercentage;

                    return ctx.pensionIncomeService.updateForPerson(
                      ctx.scenario.id,
                      ScenarioUtils.getPrimaryPerson(ctx.scenario).id,
                      i
                    );
                  })
                )
              )
            )
        )
      )
    );

  private static readonly WRAPPER_PENSION_CONTRIBUTION_INCREASE_PARTNER = (
    ctx: DiagnosticContext
  ) =>
    iif(
      () =>
        DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
          ctx.goalDiagnostic,
          Constants.DIAGNOSTICS_METADATA_ITEM_WRAPPER_EMPLOYEE_PENSION_CONTRIBUTION_EXPENSE_INCREASE_PERCENTAGE,
          0
        ) === 0,
      ctx.earnedIncomesService
        .queryForPerson(
          ctx.scenario.id,
          ScenarioUtils.getPartner(ctx.scenario).id,
          Constants.PAGE_ALL
        )
        .pipe(
          concatMap((r) =>
            from(r.content).pipe(
              concatMap((i) => {
                const incomePercentage: number =
                  DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
                    ctx.goalDiagnostic,
                    Constants.DIAGNOSTICS_METADATA_ITEM_WRAPPER_EMPLOYEE_PENSION_CONTRIBUTION_EXPENSE_ADD_PERCENTAGE,
                    Constants.DEFAULT_PENSION_EXPENSE_INCOME_PERCENT_SAVED
                  );

                const p: FinancialPortfolio = {
                  name: 'Pension portfolio',
                  profile: Constants.ALLOCATION_PRESET_NAMES[0].id,
                  wrapper: Constants.DEFAULT_FINANCIAL_ASSET_WRAPPER,
                  currency:
                    Constants.LOCALE_CONFIG[
                      localStorage.getItem(Constants.LOCAL_STORAGE_LOCALE)
                    ].currency,
                  value: 0,
                  fees: Constants.DEFAULT_FINANCIAL_PORTFOLIO_CASH_FEES,
                };

                return ctx.financialAssetsService
                  .createFinancialPortfolio(
                    ctx.scenario.id,
                    ScenarioUtils.getPartner(ctx.scenario).id,
                    p
                  )
                  .pipe(
                    concatMap((p) =>
                      ctx.pensionExpenseService.createForPerson(
                        ctx.scenario.id,
                        ScenarioUtils.getPartner(ctx.scenario).id,
                        new PensionExpenseBuilder()
                          .name(Constants.DEFAULT_PENSION_EXPENSE_NAME)
                          .portfolioId(p.id)
                          .earnedIncomeId(i.id)
                          .earnedIncomePercentSaved(incomePercentage)
                          .build()
                      )
                    )
                  );
              })
            )
          )
        ),
      ctx.pensionExpenseService
        .queryForPerson(
          ctx.scenario.id,
          ScenarioUtils.getPartner(ctx.scenario).id,
          Constants.PAGE_ALL
        )
        .pipe(
          concatMap((r) =>
            from(r.content).pipe(
              concatMap((e) => {
                const incomePercentage: number =
                  DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
                    ctx.goalDiagnostic,
                    Constants.DIAGNOSTICS_METADATA_ITEM_WRAPPER_EMPLOYEE_PENSION_CONTRIBUTION_EXPENSE_INCREASE_PERCENTAGE,
                    Constants.DEFAULT_PENSION_EXPENSE_INCOME_PERCENT_SAVED
                  );

                e.earnedIncomePercentSaved += incomePercentage;

                return ctx.pensionExpenseService.updateForPerson(
                  ctx.scenario.id,
                  ScenarioUtils.getPartner(ctx.scenario).id,
                  e
                );
              })
            )
          )
        )
    ).pipe(
      concatMap((r) =>
        iif(
          () =>
            DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
              ctx.goalDiagnostic,
              Constants.DIAGNOSTICS_METADATA_ITEM_WRAPPER_EMPLOYER_PENSION_CONTRIBUTION_INCOME_INCREASE_PERCENTAGE,
              0
            ) === 0,
          of({}),
          ctx.pensionIncomeService
            .queryForPerson(
              ctx.scenario.id,
              ScenarioUtils.getPartner(ctx.scenario).id,
              Constants.PAGE_ALL
            )
            .pipe(
              concatMap((r) =>
                from(r.content).pipe(
                  concatMap((i) => {
                    const incomePercentage: number =
                      DiagnosticsUtils.getDiagnosticsMetaDataOrDefault(
                        ctx.goalDiagnostic,
                        Constants.DIAGNOSTICS_METADATA_ITEM_WRAPPER_EMPLOYER_PENSION_CONTRIBUTION_INCOME_INCREASE_PERCENTAGE,
                        Constants.DEFAULT_PENSION_INCOME_PERCENT_SAVED
                      );

                    i.earnedIncomePercentSaved += incomePercentage;

                    return ctx.pensionIncomeService.updateForPerson(
                      ctx.scenario.id,
                      ScenarioUtils.getPartner(ctx.scenario).id,
                      i
                    );
                  })
                )
              )
            )
        )
      )
    );

  private static readonly _typeToActions = {
    // EXPENSE
    EXPENSE_LIVING_EXPENSES_DECREASE: {
      id: 'EXPENSE_LIVING_EXPENSES_DECREASE',
      problem: 'Your lifestyle expenses are affecting your goals',
      fix: 'Consider reducing your expenses a little',
      action: DiagnosticsUtils.EXPENSE_LIVING_EXPENSES_DECREASE,
    },
    EXPENSE_RENT_VS_MORTGAGE_ON_RESIDENTIAL_PROPERTY: {
      id: 'EXPENSE_RENT_VS_MORTGAGE_ON_RESIDENTIAL_PROPERTY',
      problem: 'Consider renting instead of owing your property',
      fix: 'Consider renting instead of owing your property',
      action: DiagnosticsUtils.EXPENSE_RENT_VS_MORTGAGE_ON_RESIDENTIAL_PROPERTY,
    },
    EXPENSE_MORTGAGE_ON_RESIDENTIAL_PROPERTY_VS_RENT: {
      id: 'EXPENSE_MORTGAGE_ON_RESIDENTIAL_PROPERTY_VS_RENT',
      problem:
        'With the rent you currently pay, you could afford to buy a home',
      fix: 'Considering buying a property instead of renting',
      action: DiagnosticsUtils.EXPENSE_MORTGAGE_ON_RESIDENTIAL_PROPERTY_VS_RENT,
    },

    // INCOME
    INCOME_EARNED_INCOME_INCREASE_PRIMARY: {
      id: 'INCOME_EARNED_INCOME_INCREASE_PRIMARY',
      problem:
        'A slightly higher household income could make your future more achievable',
      fix: 'Consider increasing your income a little',
      action: DiagnosticsUtils.INCOME_EARNED_INCOME_INCREASE_PRIMARY,
    },
    INCOME_EARNED_INCOME_INCREASE_PARTNER: {
      id: 'INCOME_EARNED_INCOME_INCREASE_PARTNER',
      problem:
        'A slightly higher household income could make your future more achievable',
      fix: "Consider increasing your partner's income a little",
      action: DiagnosticsUtils.INCOME_EARNED_INCOME_INCREASE_PARTNER,
    },
    INCOME_WORK_PART_TIME_AFTER_RETIREMENT_PRIMARY: {
      id: 'INCOME_WORK_PART_TIME_AFTER_RETIREMENT_PRIMARY',
      problem:
        'Your retirement objectives need a bit more income to support them',
      fix: 'Consider working part time after retirement',
      action: DiagnosticsUtils.INCOME_WORK_PART_TIME_AFTER_RETIREMENT_PRIMARY,
    },
    INCOME_WORK_PART_TIME_AFTER_RETIREMENT_PARTNER: {
      id: 'INCOME_WORK_PART_TIME_AFTER_RETIREMENT_PARTNER',
      problem:
        'Your retirement objectives need a bit more income to support them',
      fix: 'Consider your partner working part time after retirement',
      action: DiagnosticsUtils.INCOME_WORK_PART_TIME_AFTER_RETIREMENT_PARTNER,
    },

    // GOAL
    GOAL_SPEND_LESS_MONEY_ON_GOAL: {
      id: 'GOAL_SPEND_LESS_MONEY_ON_GOAL',
      problem: 'This goal may be too expensive for your means',
      fix: 'Consider spending less on this goal',
      action: DiagnosticsUtils.GOAL_SPEND_LESS_MONEY_ON_GOAL,
    },
    GOAL_MOVE_IT_TO_THE_FUTURE: {
      id: 'GOAL_MOVE_IT_TO_THE_FUTURE',
      problem: 'The timing of this goal may be causing an issue for you',
      fix: 'Consider moving it to the future',
      action: DiagnosticsUtils.GOAL_MOVE_IT_TO_THE_FUTURE,
    },
    GOAL_SAVE_IN_YEARS: {
      id: 'GOAL_SAVE_IN_YEARS',
      problem: 'Your finances are being used up by other expenses and goals',
      fix: 'Consider saving specifically for this goal',
      action: DiagnosticsUtils.GOAL_SAVE_IN_YEARS,
    },
    GOAL_SWITCH_OFF_EARLIER_GOALS: {
      id: 'GOAL_SWITCH_OFF_EARLIER_GOALS',
      problem: 'Your prior goals are consuming your money',
      fix: 'Consider removing a previous goal',
      action: DiagnosticsUtils.GOAL_SWITCH_OFF_EARLIER_GOALS,
    },
    GOAL_BUY_A_HOUSE_MORTGAGE_AFFORDABILITY: {
      id: 'GOAL_BUY_A_HOUSE_MORTGAGE_AFFORDABILITY',
      problem: 'This mortgage seems to large for your financial situation',
      fix: 'Consider decreasing the mortgage amount',
      action: DiagnosticsUtils.GOAL_BUY_A_HOUSE_MORTGAGE_AFFORDABILITY,
    },
    GOAL_BORROW: {
      id: 'GOAL_BORROW',
      problem: "You don't have sufficient finances to pay for this goal",
      fix: 'Consider borrowing money to fund this goal',
      action: DiagnosticsUtils.GOAL_BORROW,
    },
    GOAL_BUY_A_HOUSE_DOWNPAYMENT: {
      id: 'GOAL_BUY_A_HOUSE_DOWNPAYMENT',
      problem: "You don't have sufficient cash to pay for the downpayment",
      fix: 'Consider decreasing the downpayment',
      action: DiagnosticsUtils.GOAL_BUY_A_HOUSE_DOWNPAYMENT,
    },

    // PROPERTY
    PROPERTY_SELL_BUY_TO_LET_PROPERTIES: {
      id: 'PROPERTY_SELL_BUY_TO_LET_PROPERTIES',
      problem: 'Your money is locked up in your investment properties',
      fix: 'Consider selling your investment properties and releasing some equity',
      action: DiagnosticsUtils.PROPERTY_SELL_BUY_TO_LET_PROPERTIES,
    },
    PROPERTY_MOVE_TO_SMALLER_PROPERTY: {
      id: 'PROPERTY_MOVE_TO_SMALLER_PROPERTY',
      problem: 'Your money is locked up in your home',
      fix: 'Consider moving to a smaller property and releasing some equity',
      action: DiagnosticsUtils.PROPERTY_MOVE_TO_SMALLER_PROPERTY,
    },

    // DEBT
    DEBT_RESIDENTIAL_PROPERTIES_REMORTGAGE: {
      id: 'DEBT_RESIDENTIAL_PROPERTIES_REMORTGAGE',
      problem: 'The mortgage on your residence may be too expensive',
      fix: 'Consider re-mortgaging your residential property',
      action: DiagnosticsUtils.DEBT_RESIDENTIAL_PROPERTIES_REMORTGAGE,
    },
    DEBT_INVESTMENT_PROPERTIES_REMORTGAGE: {
      id: 'DEBT_INVESTMENT_PROPERTIES_REMORTGAGE',
      problem: 'The mortgage on your investment property may be too expensive',
      fix: 'Consider re-mortgaging your investment property',
      action: DiagnosticsUtils.DEBT_INVESTMENT_PROPERTIES_REMORTGAGE,
    },
    DEBT_ELIMINATE_DEBT: {
      id: 'DEBT_ELIMINATE_DEBT',
      problem: 'You may be carrying too much expensive debt',
      fix: 'Consider repaying your debt sooner',
      action: DiagnosticsUtils.DEBT_ELIMINATE_DEBT,
    },

    // INSURANCE
    INSURANCE_MORTGAGE_LIFE_INSURANCE_INCREASE: {
      id: 'INSURANCE_MORTGAGE_LIFE_INSURANCE_INCREASE',
      problem:
        'Your mortgage could be a problem if you or your partner pass away before your time',
      fix: 'Consider increasing your mortgage life cover',
      action: DiagnosticsUtils.INSURANCE_MORTGAGE_LIFE_INSURANCE_INCREASE,
    },
    INSURANCE_TERM_LIFE_INSURANCE_INCREASE_PRIMARY: {
      id: 'INSURANCE_TERM_LIFE_INSURANCE_INCREASE_PRIMARY',
      problem:
        'Your household is not sufficiently covered if you pass away before your time',
      fix: 'Consider increasing your term life cover',
      action: DiagnosticsUtils.INSURANCE_TERM_LIFE_INSURANCE_INCREASE_PRIMARY,
    },
    INSURANCE_TERM_LIFE_INSURANCE_INCREASE_PARTNER: {
      id: 'INSURANCE_TERM_LIFE_INSURANCE_INCREASE_PARTNER',
      problem:
        'Your household is not sufficiently covered if your partner passes away before their time',
      fix: "Consider increasing your partner's term life cover",
      action: DiagnosticsUtils.INSURANCE_TERM_LIFE_INSURANCE_INCREASE_PARTNER,
    },
    INSURANCE_DISABILITY_INSURANCE_INCREASE_PRIMARY: {
      id: 'INSURANCE_DISABILITY_INSURANCE_INCREASE_PRIMARY',
      problem:
        'You are not sufficiently covered if you become disabled and unable to work',
      fix: 'Consider increasing your disability insurance cover',
      action: DiagnosticsUtils.INSURANCE_DISABILITY_INSURANCE_INCREASE_PRIMARY,
    },
    INSURANCE_DISABILITY_INSURANCE_INCREASE_PARTNER: {
      id: 'INSURANCE_DISABILITY_INSURANCE_INCREASE_PARTNER',
      problem:
        'You are not sufficiently covered if your partner become disabled and unable to work',
      fix: "Consider increasing your partner's disability insurance cover",
      action: DiagnosticsUtils.INSURANCE_DISABILITY_INSURANCE_INCREASE_PARTNER,
    },
    INSURANCE_CRITICAL_ILLNESS_INSURANCE_INCREASE_PRIMARY: {
      id: 'INSURANCE_CRITICAL_ILLNESS_INSURANCE_INCREASE_PRIMARY',
      problem: 'You are not sufficiently covered if you become critically ill',
      fix: 'Consider increasing your critical illness cover',
      action:
        DiagnosticsUtils.INSURANCE_CRITICAL_ILLNESS_INSURANCE_INCREASE_PRIMARY,
    },
    INSURANCE_CRITICAL_ILLNESS_INSURANCE_INCREASE_PARTNER: {
      id: 'INSURANCE_CRITICAL_ILLNESS_INSURANCE_INCREASE_PARTNER',
      problem:
        'You are not sufficiently covered if your partner becomes critically ill',
      fix: "Consider increasing your partner's critical illness cover",
      action:
        DiagnosticsUtils.INSURANCE_CRITICAL_ILLNESS_INSURANCE_INCREASE_PARTNER,
    },

    // LIFESTYLE
    LIFESTYLE_TOO_UNHEALTHY_PRIMARY: {
      id: 'LIFESTYLE_TOO_UNHEALTHY_PRIMARY',
      problem:
        'Your current health status has an impact on your desired future',
      fix: 'Consider ways to improve your lifestyle',
      action: DiagnosticsUtils.LIFESTYLE_TOO_UNHEALTHY_PRIMARY,
    },
    LIFESTYLE_TOO_UNHEALTHY_PARTNER: {
      id: 'LIFESTYLE_TOO_UNHEALTHY_PARTNER',
      problem:
        "Your partner's current health status has an impact on your future plans",
      fix: "Consider ways to improve your partner's lifestyle",
      action: DiagnosticsUtils.LIFESTYLE_TOO_UNHEALTHY_PARTNER,
    },

    // WRAPPERS
    SAVINGS_GIA_INVESTMENTS_ENABLE_PRIMARY: {
      id: 'SAVINGS_GIA_INVESTMENTS_ENABLE_PRIMARY',
      problem: 'You may not be investing sufficiently',
      fix: 'Consider investing your excess savings',
      action: DiagnosticsUtils.SAVINGS_GIA_INVESTMENTS_ENABLE_PRIMARY,
    },
    SAVINGS_GIA_INVESTMENTS_ENABLE_PARTNER: {
      id: 'SAVINGS_GIA_INVESTMENTS_ENABLE_PARTNER',
      problem: 'Your partner may not be investing sufficiently',
      fix: "Consider investing your partner's excess savings",
      action: DiagnosticsUtils.SAVINGS_GIA_INVESTMENTS_ENABLE_PARTNER,
    },
    SAVINGS_TAX_ADVANTAGED_INVESTMENTS_ENABLE_PRIMARY: {
      id: 'SAVINGS_TAX_ADVANTAGED_INVESTMENTS_ENABLE_PRIMARY',
      problem:
        'You may not be using your tax efficient savings capacity to its fullest',
      fix: 'Consider using your ISA to the maximum extent',
      action:
        DiagnosticsUtils.SAVINGS_TAX_ADVANTAGED_INVESTMENTS_ENABLE_PRIMARY,
    },
    SAVINGS_TAX_ADVANTAGED_INVESTMENTS_ENABLE_PARTNER: {
      id: 'SAVINGS_TAX_ADVANTAGED_INVESTMENTS_ENABLE_PARTNER',
      problem:
        'Your partner may not be using your tax efficient savings capacity to its fullest',
      fix: "Consider using your partner's ISA to the maximum extent",
      action:
        DiagnosticsUtils.SAVINGS_TAX_ADVANTAGED_INVESTMENTS_ENABLE_PARTNER,
    },
    WRAPPER_TAX_ADVANTAGED_RISK_INCREASE_PRIMARY: {
      id: 'WRAPPER_TAX_ADVANTAGED_RISK_INCREASE_PRIMARY',
      problem:
        'You may be taking too little investment risk in your tax advantaged savings',
      fix: 'Consider increasing investment risk in your tax-advantaged savings by one level',
      action: DiagnosticsUtils.WRAPPER_TAX_ADVANTAGED_RISK_INCREASE_PRIMARY,
    },
    WRAPPER_TAX_ADVANTAGED_RISK_INCREASE_PARTNER: {
      id: 'WRAPPER_TAX_ADVANTAGED_RISK_INCREASE_PARTNER',
      problem:
        'Your partner may be taking too little investment risk in your tax advantaged savings',
      fix: "Consider increasing your partner's investment risk in your tax-advantaged savings by one level",
      action: DiagnosticsUtils.WRAPPER_TAX_ADVANTAGED_RISK_INCREASE_PARTNER,
    },
    WRAPPER_TAX_ADVANTAGED_RISK_DECREASE_PRIMARY: {
      id: 'WRAPPER_TAX_ADVANTAGED_RISK_DECREASE_PRIMARY',
      problem:
        'You may achieve your future plans by taking less investment risk in your tax advantaged savings',
      fix: 'Consider decreasing investment risk in your tax-advantaged savings by one level',
      action: DiagnosticsUtils.WRAPPER_TAX_ADVANTAGED_RISK_DECREASE_PRIMARY,
    },
    WRAPPER_TAX_ADVANTAGED_RISK_DECREASE_PARTNER: {
      id: 'WRAPPER_TAX_ADVANTAGED_RISK_DECREASE_PARTNER',
      problem:
        'Your partner may achieve your future plans by taking less investment risk in your tax advantaged savings',
      fix: "Consider decreasing your partner's investment risk in your tax-advantaged savings by one level",
      action: DiagnosticsUtils.WRAPPER_TAX_ADVANTAGED_RISK_DECREASE_PARTNER,
    },
    WRAPPER_GIA_RISK_INCREASE_PRIMARY: {
      id: 'WRAPPER_GIA_RISK_INCREASE_PRIMARY',
      problem:
        'You may be taking too little investment risk in your taxable savings',
      fix: 'Consider increasing investment risk in your general savings by one level',
      action: DiagnosticsUtils.WRAPPER_GIA_RISK_INCREASE_PRIMARY,
    },
    WRAPPER_GIA_RISK_INCREASE_PARTNER: {
      id: 'WRAPPER_GIA_RISK_INCREASE_PARTNER',
      problem:
        'Your partner may be taking too little investment risk in your taxable savings',
      fix: "Consider increasing your partner's investment risk in your general savings by one level",
      action: DiagnosticsUtils.WRAPPER_GIA_RISK_INCREASE_PARTNER,
    },
    WRAPPER_GIA_RISK_DECREASE_PRIMARY: {
      id: 'WRAPPER_GIA_RISK_DECREASE_PRIMARY',
      problem:
        'You may achieve your future plans by taking less investment risk in your taxable savings',
      fix: 'Consider decreasing investment risk in your taxable savings by one level',
      action: DiagnosticsUtils.WRAPPER_GIA_RISK_DECREASE_PRIMARY,
    },
    WRAPPER_GIA_RISK_DECREASE_PARTNER: {
      id: 'WRAPPER_GIA_RISK_DECREASE_PARTNER',
      problem:
        'Your partner may achieve your future plans by taking less investment risk in your taxable savings',
      fix: "Consider decreasing your partner's investment risk in your taxable savings by one level",
      action: DiagnosticsUtils.WRAPPER_GIA_RISK_DECREASE_PARTNER,
    },
    WRAPPER_PENSION_RISK_INCREASE_PRIMARY: {
      id: 'WRAPPER_PENSION_RISK_INCREASE_PRIMARY',
      problem: 'You may be taking too little investment risk in your Pension',
      fix: 'Consider increasing investment risk in your Pension by one level',
      action: DiagnosticsUtils.WRAPPER_PENSION_RISK_INCREASE_PRIMARY,
    },
    WRAPPER_PENSION_RISK_INCREASE_PARTNER: {
      id: 'WRAPPER_PENSION_RISK_INCREASE_PARTNER',
      problem:
        'Your partner may be taking too little investment risk in your Pension',
      fix: "Consider increasing your partner's investment risk in your Pension by one level",
      action: DiagnosticsUtils.WRAPPER_PENSION_RISK_INCREASE_PARTNER,
    },
    WRAPPER_PENSION_RISK_DECREASE_PRIMARY: {
      id: 'WRAPPER_PENSION_RISK_DECREASE_PRIMARY',
      problem:
        'You may achieve your future plans by taking less investment risk in your Pension',
      fix: 'Consider decreasing investment risk in your Pension by one level',
      action: DiagnosticsUtils.WRAPPER_PENSION_RISK_DECREASE_PRIMARY,
    },
    WRAPPER_PENSION_RISK_DECREASE_PARTNER: {
      id: 'WRAPPER_PENSION_RISK_DECREASE_PARTNER',
      problem:
        'Your partner may achieve your future plans by taking less investment risk in your Pension',
      fix: "Consider decreasing your partner's investment risk in your Pension by one level",
      action: DiagnosticsUtils.WRAPPER_PENSION_RISK_DECREASE_PARTNER,
    },
    WRAPPER_PENSION_CONTRIBUTION_INCREASE_PRIMARY: {
      id: 'WRAPPER_PENSION_CONTRIBUTION_INCREASE_PRIMARY',
      problem:
        'Your pension contributions may be insufficient to fund your future plans',
      fix: 'Consider increasing your pension contributions',
      action: DiagnosticsUtils.WRAPPER_PENSION_CONTRIBUTION_INCREASE_PRIMARY,
    },
    WRAPPER_PENSION_CONTRIBUTION_INCREASE_PARTNER: {
      id: 'WRAPPER_PENSION_CONTRIBUTION_INCREASE_PARTNER',
      problem:
        "Your partner's pension contributions may be insufficient to fund your future plans",
      fix: "Consider increasing your partner's pension contributions",
      action: DiagnosticsUtils.WRAPPER_PENSION_CONTRIBUTION_INCREASE_PARTNER,
    },

    // catch-all
    FACT_GOAL_TOO_IMPROBABLE: {
      id: 'FACT_GOAL_TOO_IMPROBABLE',
      problem: 'This goal may not be realistic',
      fix: 'Consider spending less on this goal',
      action: DiagnosticsUtils.GOAL_SPEND_LESS_MONEY_ON_GOAL,
    },
  };

  private static resolvePresetName(
    allocation: number[][],
    wrapper: FinancialWrapper,
    wrapperIdx: number,
    presets: { string: number[] }
  ) {
    return (
      Object.keys(presets).find(
        (presetName) =>
          JSON.stringify(presets[presetName]) ===
          JSON.stringify(allocation[wrapperIdx])
      ) || Object.keys(presets)[0]
    );
  }

  private static readonly goalsOverlap = (
    diagnosed: any,
    goal: any,
    years: number
  ): boolean => {
    const goalRange: number[] = [
      moment(goal.startDate).year() -
        (
          DiagnosticsUtils.getSaveInYearsFundingSource(goal) || {
            saveInYears: 0,
          }
        ).saveInYears,
      moment(goal.startDate).year(),
    ];
    const diagnosedGoalRange: number[] = [
      moment(diagnosed.startDate).year() -
        (
          DiagnosticsUtils.getSaveInYearsFundingSource(diagnosed) || {
            saveInYears: 0,
          }
        ).saveInYears -
        years,
      moment(diagnosed.startDate).year(),
    ];

    return DiagnosticsUtils.datesOverlap(goalRange, diagnosedGoalRange);
  };

  private static readonly retirementGoalSetTradeDownYear = (
    goal: Goal,
    percentage: number,
    year: number
  ): void => {
    goal.properties[Constants.GOAL_PROPERTY_TRADE_DOWN_HOUSE] = true;
    goal.properties[Constants.GOAL_PROPERTY_TRADE_DOWN_NEW_HOUSE_PERCENTAGE] =
      percentage;
    goal.properties[Constants.GOAL_PROPERTY_TRADE_DOWN_DATE] =
      DateUtils.atYear(year);
  };

  private static readonly datesOverlap = (
    first: number[],
    second: number[]
  ): boolean =>
    Math.max(first[1], second[1]) - Math.min(first[0], second[0]) <=
    first[1] - first[0] + (second[1] - second[0]);

  private static readonly floorToThousand = (v: number): number =>
    Math.floor(v / 1000) * 1000;

  private static readonly getDiagnosticsMetaDataOrDefault = (
    goalDiagnostic: GoalDiagnostic,
    key: string,
    def?: any
  ) =>
    goalDiagnostic.metaData[key] === undefined
      ? def
      : goalDiagnostic.metaData[key];

  private static readonly getSaveInYearsFundingSource = (
    goal: Goal
  ): SaveFundingSource =>
    goal.fundingSources.find(
      (s) => s.class === Constants.FUNDING_SOURCE_CLASS_SAVE_IN_YEARS
    ) as SaveFundingSource;

  private static readonly getMortgageFundingSource = (
    goal: Goal
  ): MortgageFundingSource =>
    goal.fundingSources.find(
      (s) => s.class === Constants.FUNDING_SOURCE_CLASS_MORTGAGE
    ) as MortgageFundingSource;

  private static readonly removeMortgageFundingSource = (goal: Goal): void => {
    goal.fundingSources = goal.fundingSources.filter(
      (s) => s.class !== Constants.FUNDING_SOURCE_CLASS_MORTGAGE
    );
  };

  private static readonly findMortgageByPropertyId = (
    scenario: Scenario,
    propertyId: string
  ) =>
    ScenarioUtils.getAllLiabilities(scenario).filter(
      (l) => l.property.id === propertyId
    )[0];

  private static readonly getInRange = (
    arr: number[][],
    valueIdx: number,
    amount: number
  ): number => {
    for (let idx = 0, prevIdx = -1; idx <= arr.length; prevIdx = idx, idx++) {
      const lowerBound: number =
        prevIdx < 0 ? Number.NEGATIVE_INFINITY : arr[prevIdx][0];
      const upperBound: number =
        idx >= arr.length ? Number.POSITIVE_INFINITY : arr[idx][0];
      if (amount >= lowerBound && amount < upperBound) {
        return arr[Math.max(prevIdx, 0)][valueIdx];
      }
    }
    return 0;
  };

  public static readonly typeToActions = (type: DiagnosticType) =>
    Object.assign({}, DiagnosticsUtils._typeToActions[type.toString()]);
}
