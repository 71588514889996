import { Component, Input, OnInit } from '@angular/core';
import { IMoneyhubBank } from '../../../../model/moneyhub/envizage-connect.model';

@Component({
  selector: 'app-bank-avatar',
  templateUrl: './bank-avatar.component.html',
  styleUrls: ['./bank-avatar.component.scss']
})
export class BankAvatarComponent implements OnInit {

  @Input() bank: IMoneyhubBank;

  constructor() { }

  ngOnInit() {
  }

}
