import { OnInit, Component, Input, Output, EventEmitter, ComponentFactoryResolver, Injector, ReflectiveInjector, AfterViewInit, AfterContentInit, AfterContentChecked, ViewChild, ViewChildren, ViewContainerRef, QueryList, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { Goal } from 'src/app/model/goal';
import { EditGoalContext } from 'src/app/model/goal/edit.goal.context.class';
import { Constants } from 'src/app/app.constants';
import { FUNDING_SOURCE_COMPONENTS } from '../../funding-sources';
import { MatDialog } from '@angular/material/dialog';
import { AddFundingSourceDialogComponent } from '../funding-source-dialog/funding.source.dialog.component';
import { GoalFactory } from 'src/app/model/goal/goal.factory.class';
import { FundingSourceUtils } from 'src/app/model/goal/funding.sources.class';

@Component({
    selector: 'app-edit-goal-base',
    templateUrl: './edit-goal-base.component.html',
    styleUrls: ['./edit-goal-base.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditGoalBaseComponent implements OnInit, AfterViewInit {

    @Input() ctx: EditGoalContext;
    @Output() deleteGoal: EventEmitter<Goal> = new EventEmitter();
    @Output() updateGoal: EventEmitter<Goal> = new EventEmitter();
    @ViewChildren('sourceCmp', { read: ViewContainerRef }) viewComponents: QueryList<ViewContainerRef>;

    constructor(private resolverParent: ComponentFactoryResolver, private dialogParent: MatDialog, private parentRef: ChangeDetectorRef) {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.renderFundingSources();
    }

    renderFundingSources() {
        for (let i = 0; i < this.viewComponents.length; i++) {
            const cmp = FUNDING_SOURCE_COMPONENTS.find(s => s.class === this.ctx.goal.fundingSources[i].class).component;
            const cmpFactory = this.resolverParent.resolveComponentFactory(cmp);
            this.viewComponents.find((_, cmpIdx) => cmpIdx === i).clear();
            const cRef = this.viewComponents.find((_, cmpIdx) => cmpIdx === i).createComponent(cmpFactory);
            cRef.instance.goal = this.ctx.goal;
            cRef.instance.fundingSource = this.ctx.goal.fundingSources[i];
            cRef.instance.updateGoal = new EventEmitter<any>();
            cRef.instance.scenario = this.ctx.scenario;
        }
    }

    addFundingSource() {
        FundingSourceUtils.GOAL_TYPE_TO_AVAILABLE_FUNDING_SOURCES[this.ctx.goal.type]
        this.dialogParent.open(AddFundingSourceDialogComponent, {
            width: '400px',
            data: {
                scenario: this.ctx.scenario,
                goal: this.ctx.goal,
                availableFundingSources: FundingSourceUtils.GOAL_TYPE_TO_AVAILABLE_FUNDING_SOURCES[this.ctx.goal.type]
                    .filter(s => this.ctx.goal.fundingSources.findIndex(f => f.class === s) === -1)
            }
        }).afterClosed().subscribe(() => {
            this.parentRef.detectChanges();
            this.renderFundingSources();
            this.parentRef.markForCheck();
        });
    }

    onYearChange(value: string) {
        this.ctx.goal.startDate = value;
        this.ctx.goal.endDate = value;
    }

    get minYear() {
        return new Date().getFullYear();
    }

    get maxYear() {
        return new Date().getFullYear() + Constants.GOAL_YEAR_RANGE;
    }
}