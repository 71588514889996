import { Injectable } from '@angular/core';
import { HandleError, HttpErrorHandler } from './http-error-handler.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PageableResponse, PageableRequest } from '../model';
import { InvestmentProperty } from '../model/properties/investment.property';
import { ResidentialProperty } from '../model/properties/residential.property';
import { AppConfigService } from './app.config.service';

@Injectable()
export class PropertyService {
    private handleError: HandleError;
    private getResidentialPropUrl = (scenarioId, id = '') => `${this.configService.getConfig().apiUrl}/scenarios/${scenarioId}/assets/properties/residential/${id}`;
    private getInvestmentPropUrl = (scenarioId, id = '') => `${this.configService.getConfig().apiUrl}/scenarios/${scenarioId}/assets/properties/investment/${id}`;

    constructor(
            private http: HttpClient,
            private configService: AppConfigService,
            private httpErrorHandler: HttpErrorHandler
        ) {
            this.handleError = httpErrorHandler.createHandleError('liabilityService');
    }

    // Residential
    queryResidentialProperties(scenarioId: string, pageable: PageableRequest): Observable<PageableResponse<ResidentialProperty>> {
        return this.http.get<PageableResponse<ResidentialProperty>>(this.getResidentialPropUrl(scenarioId), { params: pageable })
            .pipe(catchError(this.handleError('query residential properties')));
    }

    getResidentialProperty(scenarioId: string, id: string): Observable<ResidentialProperty> {
        return this.http.get<ResidentialProperty>(this.getResidentialPropUrl(scenarioId, id))
            .pipe(catchError(this.handleError('get residential property')));
    }

    createResidentialProperty(scenarioId: string, property: ResidentialProperty): Observable<ResidentialProperty> {
        return this.http.post<ResidentialProperty>(this.getResidentialPropUrl(scenarioId), property)
            .pipe(catchError(this.handleError('create residential property')));
    }

    updateResidentialProperty(scenarioId: string, property: ResidentialProperty): Observable<ResidentialProperty> {
        return this.http.put<ResidentialProperty>(this.getResidentialPropUrl(scenarioId, property.id), property)
            .pipe(catchError(this.handleError('update residential property')));
    }

    deleteResidentialProperty(scenarioId: string, id: string): Observable<any> {
        return this.http.delete<ResidentialProperty>(this.getResidentialPropUrl(scenarioId, id))
            .pipe(catchError(this.handleError('delete residential property')));
    }

    // Investment properties
    queryInvestmentProperties(scenarioId: string, pageable: PageableRequest): Observable<PageableResponse<InvestmentProperty>> {
        return this.http.get<PageableResponse<InvestmentProperty>>(this.getInvestmentPropUrl(scenarioId), { params: pageable })
            .pipe(catchError(this.handleError('query investment properties')));
    }

    getInvestmentProperty(scenarioId: string, id: string): Observable<InvestmentProperty> {
        return this.http.get<InvestmentProperty>(this.getInvestmentPropUrl(scenarioId, id))
            .pipe(catchError(this.handleError('get investment property')));
    }

    createInvestmentProperty(scenarioId: string, mortgage: InvestmentProperty): Observable<InvestmentProperty> {
        return this.http.post<InvestmentProperty>(this.getInvestmentPropUrl(scenarioId), mortgage)
            .pipe(catchError(this.handleError('create investment property')));
    }

    updateInvestmentProperty(scenarioId: string, mortgage: InvestmentProperty): Observable<InvestmentProperty> {
        return this.http.put<InvestmentProperty>(this.getInvestmentPropUrl(scenarioId, mortgage.id), mortgage)
            .pipe(catchError(this.handleError('update investment property')));
    }

    deleteInvestmentProperty(scenarioId: string, id: string): Observable<any> {
        return this.http.delete<InvestmentProperty>(this.getInvestmentPropUrl(scenarioId, id))
            .pipe(catchError(this.handleError('delete investment property')));
    }
}