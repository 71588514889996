import { OnInit, Component, Input, Output, EventEmitter, ComponentFactoryResolver, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Goal } from 'src/app/model/goal';
import { EditGoalBaseComponent } from '../edit-goal-base/edit-goal-base.component';
import { Constants } from 'src/app/app.constants';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-edit-goal-sabbatical',
    templateUrl: './edit-goal-sabbatical.component.html',
    styleUrls: ['./edit-goal-sabbatical.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditGoalSabbaticalComponent extends EditGoalBaseComponent {

    constructor(resolver: ComponentFactoryResolver, dialog: MatDialog, private ref: ChangeDetectorRef) {
        super(resolver, dialog, ref);
    }

    ngOnInit() {
    }

    onChange(value: string) {
        this.ctx.goal.startDate = value;
        this.ctx.goal.endDate = value;
    }

    get minYear() {
        return new Date().getFullYear();
    }

    get maxYear() {
        return new Date().getFullYear() + Constants.GOAL_YEAR_RANGE;
    }
}