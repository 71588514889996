import {
  Component,
  Input,
  Output,
  EventEmitter,
  signal,
  OnInit,
} from '@angular/core';
import { NgModel } from '@angular/forms';
import { InvestmentProperty } from 'src/app/model';
import { RentIncome } from 'src/app/model/household/income';

@Component({
  selector: 'app-income-rent',
  templateUrl: './income-rent.component.html',
  styleUrls: ['./income-rent.component.scss'],
})
export class IncomeRentComponent implements OnInit {
  @Input() income: RentIncome;
  @Input() investmentProperties: Array<InvestmentProperty>;
  @Input() currency: string;

  @Output() update = new EventEmitter<RentIncome>();
  @Output() remove = new EventEmitter<RentIncome>();

  isEdit = signal(false);
  private _isDirty = false;
  private _isRentError = false;
  private _rentValue;

  ngOnInit(): void {
    this._rentValue = this.income.annualNetRentalYieldPercentage;
  }

  onUpdate(el: NgModel) {
    if (!this.income || el.value === null) {
      return;
    }

    const rentValue = el.value;
    const propValue = this.investmentProperties
      .filter((p) => p.id === this.income.propertyAssetId)
      .map((p) => p.value)[0];

    this.income.annualNetRentalYieldPercentage =
      Math.round((parseFloat(rentValue) * 12 * 100000) / propValue) / 100000;

    if (this.income.annualNetRentalYieldPercentage > 1) {
      el.control.markAsTouched();
      el.control.setErrors({ max: true });
      this._isRentError = true;
      return;
    }

    this._isDirty = true;
  }

  onRemove() {
    this.remove.emit(this.income);
  }

  onDismiss() {
    this._isDirty && this.update.emit(this.income);
    if (this._isRentError) {
      this.income.annualNetRentalYieldPercentage = this._rentValue;
      this._isRentError = false;
    }
    this.isEdit.set(false);
  }

  getMonthlyRent(income: RentIncome) {
    return this.investmentProperties
      .filter((i) => i.id === income.propertyAssetId)
      .map((p) => p.value)
      .map((val) => (income.annualNetRentalYieldPercentage * val) / 12)
      .map((val) => Math.round(val))[0];
  }

  getPropertyValue(income: RentIncome) {
    return this.investmentProperties.find(
      (i) => i.id === income.propertyAssetId
    ).value;
  }

  get propertyName() {
    return this.investmentProperties.find(
      (p) => p.id === this.income.propertyAssetId
    )?.name;
  }
}
