import { Injectable } from '@angular/core';
import { HandleError, HttpErrorHandler } from './http-error-handler.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PageableResponse, PageableRequest } from '../model';
import { Service, ServiceForPerson, QueryOnlyService, QueryOnlyServiceForPerson } from './service';
import { LivingExpense } from '../model/household/expense';
import { PensionExpense } from '../model/household/expense/pension.expense';
import { Expense } from '../model/household/expense/expense';
import { AppConfigService } from './app.config.service';
import { RentExpense } from '../model/household/expense/rent.expense';


@Injectable()
export class ExpenseService implements QueryOnlyService<Expense>, QueryOnlyServiceForPerson<Expense> {
    private handleError: HandleError;
    private getUrl = (scenarioId, id = '') => `${this.configService.getConfig().apiUrl}/scenarios/${scenarioId}/expenses/${id}`;
    private getUrlForPerson = (scenarioId, personId, id = '') => `${this.configService.getConfig().apiUrl}/scenarios/${scenarioId}/persons/${personId}/expenses/${id}`;

    constructor(
        private http: HttpClient,
        private configService: AppConfigService,
        private httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('expenseService');
    }

    query(scenarioId: string, pageable: PageableRequest): Observable<PageableResponse<Expense>> {
        return this.http.get<PageableResponse<Expense>>(this.getUrl(scenarioId), { params: pageable })
            .pipe(
                catchError(this.handleError('query expenses'))
            );
    }

    get(scenarioId: string, id: string): Observable<Expense> {
        return this.http.get<Expense>(this.getUrl(scenarioId, id))
            .pipe(
                catchError(this.handleError('get expense'))
            );
    }

    queryForPerson(scenarioId: string, personId: string, pageable: PageableRequest): Observable<PageableResponse<Expense>> {
        return this.http.get<PageableResponse<Expense>>(this.getUrlForPerson(scenarioId, personId), { params: pageable })
            .pipe(
                catchError(this.handleError('query expense'))
            );
    }

    getForPerson(scenarioId: string, personId: string, id: string): Observable<Expense> {
        return this.http.get<Expense>(this.getUrlForPerson(scenarioId, personId, id))
            .pipe(
                catchError(this.handleError('get expense'))
            );
    }

}

@Injectable()
export class RentExpenseService implements Service<RentExpense> {
    private handleError: HandleError;
    private getUrl = (scenarioId, id = '') => `${this.configService.getConfig().apiUrl}/scenarios/${scenarioId}/expenses/rent/${id}`;

    constructor(
        private http: HttpClient,
        private configService: AppConfigService,
        private httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('rentExpenseService');
    }

    query(scenarioId: string, pageable: PageableRequest): Observable<PageableResponse<RentExpense>> {
        return this.http.get<PageableResponse<RentExpense>>(this.getUrl(scenarioId), { params: pageable })
            .pipe(
                catchError(this.handleError('query rent expenses'))
            );
    }

    get(scenarioId: string, id: string): Observable<RentExpense> {
        return this.http.get<RentExpense>(this.getUrl(scenarioId, id))
            .pipe(
                catchError(this.handleError('get rent expense'))
            );
    }

    create(scenarioId: string, expense: RentExpense): Observable<RentExpense> {
        return this.http.post<RentExpense>(this.getUrl(scenarioId), expense)
            .pipe(
                catchError(this.handleError('create rent expense'))
            );
    }

    update(scenarioId: string, expense: RentExpense): Observable<RentExpense> {
        return this.http.put<RentExpense>(this.getUrl(scenarioId, expense.id), expense)
            .pipe(
                catchError(this.handleError('update rent expense'))
            );
    }

    delete(scenarioId: string, id: string): Observable<any> {
        return this.http.delete<any>(this.getUrl(scenarioId, id))
            .pipe(
                catchError(this.handleError('delete rent expense'))
            );
    }
}

@Injectable()
export class LivingExpenseService implements Service<LivingExpense> {
    private handleError: HandleError;
    private getUrl = (scenarioId, id = '') => `${this.configService.getConfig().apiUrl}/scenarios/${scenarioId}/expenses/living/${id}`;

    constructor(
        private http: HttpClient,
        private configService: AppConfigService,
        private httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('livingExpenseService');
    }

    query(scenarioId: string, pageable: PageableRequest): Observable<PageableResponse<LivingExpense>> {
        return this.http.get<PageableResponse<LivingExpense>>(this.getUrl(scenarioId), { params: pageable })
            .pipe(
                catchError(this.handleError('query living expenses'))
            );
    }

    get(scenarioId: string, id: string): Observable<LivingExpense> {
        return this.http.get<LivingExpense>(this.getUrl(scenarioId, id))
            .pipe(
                catchError(this.handleError('get living expense'))
            );
    }

    create(scenarioId: string, expense: LivingExpense): Observable<LivingExpense> {
        return this.http.post<LivingExpense>(this.getUrl(scenarioId), expense)
            .pipe(
                catchError(this.handleError('create living expense'))
            );
    }

    update(scenarioId: string, expense: LivingExpense): Observable<LivingExpense> {
        return this.http.put<LivingExpense>(this.getUrl(scenarioId, expense.id), expense)
            .pipe(
                catchError(this.handleError('update living expense'))
            );
    }

    delete(scenarioId: string, id: string): Observable<any> {
        return this.http.delete<any>(this.getUrl(scenarioId, id))
            .pipe(
                catchError(this.handleError('delete living expense'))
            );
    }

}

@Injectable()
export class PensionExpenseService implements ServiceForPerson<PensionExpense> {
    private handleError: HandleError;
    private getUrlForPerson = (scenarioId, personId, id = '') => `${this.configService.getConfig().apiUrl}/scenarios/${scenarioId}/persons/${personId}/expenses/employee-pension-contribution/${id}`;

    constructor(
        private http: HttpClient,
        private configService: AppConfigService,
        private httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('pensionExpenseService');
    }

    queryForPerson(scenarioId: string, personId: string, pageable: PageableRequest): Observable<PageableResponse<PensionExpense>> {
        return this.http.get<PageableResponse<PensionExpense>>(this.getUrlForPerson(scenarioId, personId), { params: pageable })
            .pipe(
                catchError(this.handleError('query pension contribution expense'))
            );
    }

    getForPerson(scenarioId: string, personId: string, id: string): Observable<PensionExpense> {
        return this.http.get<PensionExpense>(this.getUrlForPerson(scenarioId, personId, id))
            .pipe(
                catchError(this.handleError('get pension contribution expense'))
            );
    }

    createForPerson(scenarioId: string, personId: string, expense: PensionExpense): Observable<PensionExpense> {
        return this.http.post<PensionExpense>(this.getUrlForPerson(scenarioId, personId), expense)
            .pipe(
                catchError(this.handleError('create pension contribution expense'))
            );
    }

    updateForPerson(scenarioId: string, personId: string, expense: PensionExpense): Observable<PensionExpense> {
        return this.http.put<PensionExpense>(this.getUrlForPerson(scenarioId, personId, expense.id), expense)
            .pipe(
                catchError(this.handleError('update pension contribution expense'))
            );
    }

    deleteForPerson(scenarioId: string, personId: string, id: string): Observable<any> {
        return this.http.delete<any>(this.getUrlForPerson(scenarioId, personId, id))
            .pipe(
                catchError(this.handleError('delete pension contribution expense'))
            );
    }

}